import React from 'react';
import { HomeAnnouncementTable, HomeGroupLayout } from 'components';

/**
 * 公告頁面
 */

export const HomeAnnouncementPage = () => {
  return (
    <HomeGroupLayout>
      <HomeAnnouncementTable />
    </HomeGroupLayout>
  );
};


