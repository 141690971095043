import React from 'react';
import { HomeMemberCenterLayout, HomeMemberProfile } from 'components';


/**
 * 在這邊描述這個組件
 */

export const HomeMemberProfilePage = () => {
  return (
    <HomeMemberCenterLayout>
      <HomeMemberProfile />
    </HomeMemberCenterLayout>
  );
};
