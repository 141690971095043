import React from 'react';
import { SingleLayout, HomeSchoolForm } from 'components';
import { UiHomeSchoolFormPage } from './HomeSchoolFormPage.style';


/**
 * 學校教師，新增班級頁面
 */

export const HomeSchoolFormPage = props => {
  return (
    <SingleLayout>
      <UiHomeSchoolFormPage>
        <HomeSchoolForm {...props} />
      </UiHomeSchoolFormPage>
    </SingleLayout>
  );
};
