import React from 'react';
import {
  ClassTable,
  BsHeaderTitle
} from 'components';
import { UiBsContentContainer } from 'styles';


/**
 * 在這邊描述這個組件
 */

export const ClassPage = () => {
  return (
    <>
      <BsHeaderTitle title="班級管理" />
      <UiBsContentContainer>
        <ClassTable />
      </UiBsContentContainer>
    </>
  );
};


