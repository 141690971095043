import React from 'react';
import { useClass } from 'store/class';
import { HomeStudentVideoTable, HomeTeacherVideoTable, Loading } from 'components';
import { UiHomeVideoTable } from './HomeVideoTable.style';

/**
 * 影音列表
 */


export const HomeVideoTable = () => {
  const [{ myClasses: { dataInfo: { userType } } }] = useClass();

  if(!userType) return  <Loading />;

  return (
    <UiHomeVideoTable>
      {
        userType === 'teacher' ? (
          <HomeTeacherVideoTable />
        ) : (
          <HomeStudentVideoTable />
        )
      }
    </UiHomeVideoTable>
  );
};
