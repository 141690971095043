import React from 'react';
import PropTypes from 'prop-types';
import { UiPrompt } from './Prompt.style';


/**
 * 提示
 */

export const Prompt = ({ message = '目前0則貼文' }) => {
  return (
    <UiPrompt tabIndex={0}>
      {message}
    </UiPrompt>
  );
};

Prompt.propTypes = {
  message: PropTypes.string
};


