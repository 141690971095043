import React from 'react';
import { HomePicture, HomeGroupLayout } from 'components';


/**
 * 相片頁面
 */

export const HomePicturePage = () => {
  return (
    <HomeGroupLayout>
      <HomePicture />
    </HomeGroupLayout>
  );
};
