import React from 'react';
import { HomeGroupLayout, HomeStudentHistoryTable } from 'components';

/**
 * 影音學習學生觀看紀錄
 */

export const HomeStudentHistoryPage = () => {
  return (
    <HomeGroupLayout>
      <HomeStudentHistoryTable/>
    </HomeGroupLayout>
  );
};
