import styled from 'styled-components';
import {
  Tabs as MatTabs,
} from '@material-ui/core';

export const UiHomeTeacherVideoTable = styled.div`
	.flex {
		display: flex;
		align-items: center;
		justify-content: space-between;

		> h4 {
			font-size: 20px;
			font-weight: 100;
			line-height: 30px;
			color: #242c3f;
		}
	}
`;


export const UiMatTabs = styled(MatTabs)`
	display: block;
	margin: 1rem 0;

	.MuiTab-textColorInherit.Mui-selected {
		color: #ec7963;
	}

	> div {
		border-bottom: 1px solid #e4e7ec;
	}

	> div > span {
		background-color: #ec7963;
	}
`;

export const UiEmptyContent = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	text-align: center;
	margin-top: 6.25rem;
	p {
		color: #8B90A0;
	}
`;
