import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { UiPhoneFormWrapper, UiPhoneForm, UiHelperText } from './PhoneForm.style';
import { TextField as MatTextField } from '@material-ui/core';
import { Autocomplete as MatAutocomplete } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { countries } from './CountryCode';
import { useSetState } from 'utils/hooks/useSetState';
import libphonenumber from 'google-libphonenumber';
import { FormHelperText as MatFormHelperText } from '@material-ui/core';

/**
 * `PhoneForm`  onFormChange
 */

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
  popper: {
    width: '250px !important'
  },
  root: {
    display: 'inline-flex',
    marginRight: 10
  }
});
export const PhoneForm = ({
  onChange,
  helperText,
  error,
  label,
  defaultPhoneNum,
  defaultCountryNum,
  disabled = false,
  variant = 'filled',
  value,
}) => {
  const classes = useStyles();
  const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
  const [{ number, countryCode }, setState] = useSetState({
    number: defaultPhoneNum || '',
    countryCode: defaultCountryNum || '886'
  });

  const getCountryKeyForCountryCode = (countryCode) => {
    let countryCodeindex;
    countries.forEach((item, index) => {
      if (item.phone === countryCode) countryCodeindex = index;
    });
    return countryCodeindex;
  };

  const numberFormat = num => {
    if (num.substr(0, 1) !== '+') num = '+' + num;
    const parseNumber = phoneUtil.parse(num); // +886987654321;
    const parsePhoneNumber = parseNumber.getNationalNumber(); // 987654321
    const parseCountryCode = parseNumber.getCountryCode().toString(); // 886
    let formatPhoneNumber = {
      countryCode: parseCountryCode,
      phoneNumber: parsePhoneNumber
    };
    return formatPhoneNumber;
  };

  useEffect(() => {
    if (!value || value.length < 6) return;
    const { countryCode, phoneNumber } = numberFormat(value);
    setState({
      countryCode,
      number: phoneNumber,
    });
  }, [value]);

  useEffect(() => {
    onChange('+' + countryCode, number);
  }, [countryCode, number]);

  return (
    <UiPhoneFormWrapper>
      <UiPhoneForm>
        <MatAutocomplete
          style={{ minWidth: 160, }}
          options={countries}
          disabled={disabled}
          defaultValue={countries[getCountryKeyForCountryCode(countryCode)]}
          classes={{
            root: classes.root,
            option: classes.option,
            popper: classes.popper
          }}
          disableClearable
          onChange={(event, val) => {
            val && setState({ countryCode: val.phone });
          }}
          getOptionLabel={option => option.phone}
          renderOption={option => (
            <>
              {option.label} ({option.code}) +{option.phone}
            </>
          )}
          renderInput={params => (
            <MatTextField
              {...params}
              label="國碼"
              variant={variant}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password',// disable autocomplete and autofill
              }}
            />
          )}
        />
        <MatTextField
          variant={variant}
          disabled={disabled}
          error={error}
          label={label}
          value={number}
          onChange={(event) => {
            event && setState({ number: event.target.value.replace(/[^0-9]/gi, '') });
          }}
        />
      </UiPhoneForm>
      {
        (error && helperText) &&
        <UiHelperText>
          <MatFormHelperText>{helperText}</MatFormHelperText>
        </UiHelperText>
      }
    </UiPhoneFormWrapper>
  );
};

PhoneForm.propTypes = {
  onChange: PropTypes.func,
  helperText: PropTypes.string,
  error: PropTypes.bool,
  label: PropTypes.string,
  defaultPhoneNum: PropTypes.string,
  defaultCountryNum: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  variant: PropTypes.string,
};


