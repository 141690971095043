import React from 'react';
import { CustomerForm, BsHeaderTitle } from 'components';
import { UiBsContentContainer } from 'styles';
import { UiCustomerFormPage } from './CustomerFormPage.style';


/**
 * 邀請學生Form 頁面
 */

export const CustomerFormPage = props => {
  return (
    <UiCustomerFormPage>
      <BsHeaderTitle title="學生管理" />
      <UiBsContentContainer>
        <CustomerForm {...props} />
      </UiBsContentContainer>
    </UiCustomerFormPage>
  );
};



