import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSetState } from 'utils/hooks/useSetState';
import { useHomework } from 'store/homework';
import { useTeacher } from 'store/teacher';
import { formatTimestamp } from 'utils/date';
import {
  Button,
  Table,
  Select,
  Checkbox,
  DatePicker
} from 'components';
import { useQuery } from 'utils/hooks/useQuery';
import { TextField as MatTextField } from '@material-ui/core';
import { UiTable, UiflexBox } from 'styles';
import {
  UiTableTop,
  UiTableTopButton,
  UiTableTopSearch,
  UiflexDefault,
  UiAttachmentLink
} from './HomeworkSubmittedStudentTable.style';


/**
 * 學生提交的作業列表
 */

const schema = {
  title: {
    name: '作業名稱',
    defaultValue: ''
  },
  ownerName: {
    name: '出題老師',
    defaultValue: ''
  },
  publishedAt: {
    name: '建立日期',
    defaultValue: ''
  },
  dueAt: {
    name: '截止日期',
    defaultValue: '-'
  },
  submittedAt: {
    name: '最後繳交日期',
    defaultValue: '-',
    importantCondition: 'dueAt'
  },
  attachmentUrl: {
    name: '繳交附件',
    defaultValue: ''
  },
  comment: {
    name: '留言',
    defaultValue: '-'
  },
  submitCount: {
    name: '繳交次數',
    defaultValue: 0
  }
};

const selectDateOptions = [
  {
    name: '建立日期',
    value: 'publishedAt'
  },
  {
    name: '截止日期',
    value: 'dueAt'
  },
  {
    name: '最後繳交日期',
    value: 'submittedAt'
  }
];


export const HomeworkSubmittedStudentTable = () => {
  const history = useHistory();
  const [{ userSubmittedHomeworks }, { getUserSubmissions }] = useHomework();
  const [{ allTeachers }, { getAllTeachers }] = useTeacher();
  const { studentId } = useParams();
  const query = useQuery();

  const [{
    nowPage,
    rowsPage,
    missionTitle,
    isAttachmentOnly,
    startAt,
    endAt,
    studentLength,
    formatData,
    teacherData,
    searchPayload,
    timeType,
    ownerId,
  }, setState] = useSetState({
    nowPage: 0,
    rowsPage: 10,
    missionTitle: '',
    isAttachmentOnly: false,
    startAt: null,
    endAt: null,
    studentLength: 0,
    formatData: [],
    teacherData: [],
    searchPayload: {
      ownerId: query.get('ownerId') || ''
    },
    timeType: '',
    ownerId: query.get('ownerId') || ''
  });
  const goBack = () => {
    history.go(-1);
  };

  const changePage_Rows = params => {
    const { newPage, newRowsPage } = params;
    setState({
      nowPage: newPage,
      rowsPage: newRowsPage
    });
  };
  const onchangeHandler = (value, key) => {
    setState({
      searchPayload: { ...searchPayload, [key]: value },
      [key]: value
    });
  };


  const onchangeStartTimeHandler = (value, key) => {
    const minStartTime = Date.parse(`${formatTimestamp(value)} 00:00:00`);
    setState({
      searchPayload: { ...searchPayload, [key]: minStartTime },
      [key]: minStartTime
    });
  };

  const onchangeEndTimeHandler = (value, key) => {
    const maxEndTime = Date.parse(`${formatTimestamp(value)} 23:59:59`);
    setState({
      searchPayload: { ...searchPayload, [key]: maxEndTime },
      [key]: maxEndTime
    });
  };

  const AttachmentUrl = ({ url }) => (
    <UiAttachmentLink target='_blank' download href={url}>
      下載
    </UiAttachmentLink>
  );


  const calStringToCut = str => {
    if (str && str.length > 6) return str.substr(0, 6) + '...';
    return str;
  };

  const submissionsFormat = data => {
    const nextData = data.map(item => {
      if (Object.prototype.hasOwnProperty.call(item, 'submissions')) {
        return ({
          ...item,
          publishedAt: (item.publishedAt && formatTimestamp(item.publishedAt, 'yyyy-MM-dd HH:mm:ss')),
          dueAt: (item.dueAt && formatTimestamp(item.dueAt, 'yyyy-MM-dd HH:mm:ss')),
          attachmentUrl:
            (item.submissions[studentId]?.attachmentUrl ?
              <AttachmentUrl url={item.submissions[studentId].attachmentUrl} /> : '-'),
          comment: (calStringToCut(item.submissions[studentId]?.comment) || ''),
          submittedAt:
            (formatTimestamp(item.submissions[studentId]?.submittedAt || new Date(), 'yyyy-MM-dd HH:mm:ss')),
          submitCount: (item.submissions[studentId]?.submitCount || 0),
          isLate: item.submissions[studentId]?.submittedAt > item.dueAt || false
        }
        );
      } else {
        return ({
          ...item,
          publishedAt: (item.publishedAt && formatTimestamp(item.publishedAt, 'yyyy-MM-dd HH:mm:ss')),
          dueAt: (item.dueAt && formatTimestamp(item.dueAt, 'yyyy-MM-dd HH:mm:ss')),
          attachmentUrl: '-',
        }
        );
      }
    }).filter(item => item !== undefined);
    setState({
      formatData: nextData,
      studentLength: userSubmittedHomeworks.total || 0
    });
  };

  const formatDataToOptions = data => {
    const nextData = data.map(item => ({
      name: item.nickname,
      value: item.id
    }));

    setState({
      teacherData: [{
        name: '全部',
        value: ''
      }, ...nextData]
    });
  };

  const submitSearchPayload = () => {
    if (nowPage !== 0) {
      setState({
        nowPage: 0
      });
    } else {
      getUserSubmissions({ ...searchPayload, nowPage, rowsPage });
    }

  };
  //比較是否遲交
  const isLateHandler = (submitDate, dueDate) => {
    if (!submitDate || !dueDate) return false;
    return new Date(submitDate).getTime() > new Date(dueDate).getTime();
  };

  useEffect(() => {
    getAllTeachers();
  }, []);

  useEffect(() => {
    getUserSubmissions({ ...searchPayload, nowPage, rowsPage });
  }, [nowPage, rowsPage]);

  useEffect(() => {
    submissionsFormat(userSubmittedHomeworks.data);
  }, [userSubmittedHomeworks]);

  useEffect(() => {
    formatDataToOptions(allTeachers.data);
  }, [allTeachers]);


  return (
    <UiTable>
      <UiTableTop>
        <UiTableTopButton>
          <UiflexBox>
            <Button onClick={goBack}>返回列表</Button>
          </UiflexBox>
        </UiTableTopButton>
        <UiTableTopSearch>
          <UiflexBox>
            <UiflexDefault>
              <Select
                label="時間類型"
                options={selectDateOptions}
                value={timeType}
                submitHandler={value => onchangeHandler(value, 'timeType')} />
            </UiflexDefault>
            {
              timeType &&
              <>
                <DatePicker
                  label="開始時間"
                  value={startAt}
                  minDate={new Date('1900-01-01')}
                  maxDate={endAt || new Date('2100-12-01')}
                  onChange={value => onchangeStartTimeHandler(value, 'startAt')}
                />
                <DatePicker
                  label="結束時間"
                  minDate={startAt || new Date('1900-01-01')}
                  value={endAt}
                  onChange={value => onchangeEndTimeHandler(value, 'endAt')}
                />
              </>
            }


          </UiflexBox>
        </UiTableTopSearch>
        <UiTableTopSearch>
          <UiflexBox>
            <MatTextField
              variant="outlined"
              placeholder='請輸入作業名稱關鍵字'
              value={missionTitle}
              onChange={e => onchangeHandler(e.target.value, 'missionTitle')}
            />
            <UiflexDefault>
              <Select
                label="選擇老師"
                options={teacherData}
                value={ownerId}
                submitHandler={value => onchangeHandler(value, 'ownerId')} />
            </UiflexDefault>
            <UiflexDefault>
              <Checkbox
                onChange={value => onchangeHandler(value !== '' ? true : false, 'isAttachmentOnly')}
                label="繳交附件"
                checkedValue="isAttachmentOnly"
                checked={isAttachmentOnly}
              />
            </UiflexDefault>

            <Button onClick={() => { submitSearchPayload(); }}>搜尋</Button>
          </UiflexBox>
        </UiTableTopSearch>
      </UiTableTop>
      <Table
        data={formatData}
        schema={schema}
        changePage_Rows={changePage_Rows}
        totalPage={studentLength}
        nowPage={nowPage}
        dataConditionFormat={isLateHandler}
      />
    </UiTable>
  );
};
