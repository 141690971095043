import React from 'react';
import AppRoutes from 'routes';
import { useLocationChange } from 'utils/hooks/useLocationChange';


const App = () => {

  useLocationChange();

  return (
    <AppRoutes />
  );
};

export default App;
