import React from 'react';
import PropTypes from 'prop-types';
import { getTime } from 'date-fns';

import { KeyboardDatePicker } from '@material-ui/pickers';
/**
 * 日期組件
 */

export const DatePicker = ({
  label,
  value,
  format = 'yyyy/MM/dd',
  onChange = () => { },
  cancelLabel = '取消',
  okLabel = '確定',
  clearLabel = '清除',
  inputVariant = 'outlined',
  disablePast = false,
  minDate = Date.now(),
  ...props
}) => {

  const onValueChange = date => {
    const dataTimestamp = getTime(date);
    onChange(dataTimestamp);
  };

  return (
    <KeyboardDatePicker
      variant="dialog"
      inputVariant={inputVariant}
      label={label}
      value={value ? new Date(value) : null}
      onChange={onValueChange}
      disablePast={disablePast}
      format={format}
      cancelLabel={cancelLabel}
      okLabel={okLabel}
      clearLabel={clearLabel}
      minDate={minDate}
      clearable
      {...props}
    />
  );
};

DatePicker.propTypes = {
  label: PropTypes.string,
  inputVariant: PropTypes.string,
  value: PropTypes.number,
  format: PropTypes.string,
  onChange: PropTypes.func,
  cancelLabel: PropTypes.string,
  clearLabel: PropTypes.string,
  okLabel: PropTypes.string,
  disablePast: PropTypes.bool,
  minDate: PropTypes.any
};


