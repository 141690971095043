import React from 'react';
// import PropTypes from 'prop-types';
import { HomeGroupLayout, SessionReport } from 'components';
import { UiSessionReportPage } from './SessionReportPage.style';


/**
 * 課堂報告頁面
 */

export const SessionReportPage = () => {
  return (
    <HomeGroupLayout>
      <UiSessionReportPage>
        <SessionReport />
      </UiSessionReportPage>
    </HomeGroupLayout>
  );
};

SessionReportPage.propTypes = {

};


