import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { EDIT_STATUS } from 'constants/index';


import { Table, IconInput, Select, Icon, Button } from 'components';
import { UiAvatar } from './TeacherManagementTable.style';
import { UiTable, UiActionBox, UiflexBox } from 'styles';
import { useSetState } from 'utils/hooks/useSetState';
import { useTeacher } from 'store/teacher';


/**
 * 教師管理列表表單
 */

const schema = {
  nickname: {
    name: '教師暱稱',
    defaultValue: '',
  },
  avatar: {
    name: '照片',
    defaultValue: ''
  },
  mobileNumber: {
    name: '手機號碼',
    defaultValue: '',
  },
  brief: {
    name: '簡介',
    defaultValue: ''
  },
  role: {
    name: '權限',
    defaultValue: ''
  },
};

const stateShowText = [
  {
    id: 'role',
    state: {
      staff: '教師'
    }
  }
];
const stateOptions = [
  {
    name: '手機',
    value: 'mobileNumber'
  },
  {
    name: '暱稱',
    value: 'nickname'
  }
];

export const TeacherManagementTable = () => {
  const { organizationId } = useParams();
  const history = useHistory();
  const [{ teachers: { data, total } }, { getTeachers }] = useTeacher();
  const [{ nowPage, rowsPage, searchKey, searchValue }, setState] = useSetState({
    nowPage: 0,
    rowsPage: 10,
    searchKey: '',
    searchValue: ''
  });
  // table 操作
  const ActionComponents = params => {
    const teacherId = params.params.id;
    return (
      <>
        <Icon
          onClick={() =>
            history.push(
              `/organization/${organizationId}/teachers/${teacherId}/${EDIT_STATUS.EDIT}`
            )}
          name='edit'
        />
      </>
    );
  };
  const changePage_Rows = params => {
    const { newPage, newRowsPage } = params;
    setState({
      nowPage: newPage,
      rowsPage: newRowsPage
    });
  };
  const submitSelectHandler = value => {
    setState({
      searchKey: value,
      nowPage: 0
    });
  };
  const submitInputHandler = value => {
    if (!searchKey) {
      alert('請先選擇搜尋目標');
      return;
    }
    setState({
      searchValue: value,
      nowPage: 0
    });
  };
  useEffect(() => {
    const params = {
      nowPage,
      rowsPage,
      [searchKey]: searchValue
    };
    getTeachers(params);
  }, [nowPage, rowsPage, searchValue]);
  return (
    <UiTable>
      <UiActionBox>
        <UiflexBox>
          <Select label="搜尋目標" options={stateOptions} submitHandler={submitSelectHandler} />
          <IconInput placeholder="搜尋" onChange={submitInputHandler} />
        </UiflexBox>
        <Button
          buttonColor='highlight'
          icon='add'
          onClick={
            () => { history.push(`/organization/${organizationId}/teachers/${EDIT_STATUS.CREATE}`); }
          }>新增教師</Button>
      </UiActionBox>
      <Table
        data={data.map(item => ({ ...item, avatar: <UiAvatar alt={item.nickname} src={item.thumbnailUrl} /> }))}
        schema={schema}
        stateShowText={stateShowText}
        changePage_Rows={changePage_Rows}
        totalPage={total}
        ActionComponents={ActionComponents}
        nowPage={nowPage}
      />
    </UiTable>
  );
};



