import React from 'react';
import {HomeOrganizationLayout,HomeAnnouncementTable } from 'components';

/**
 * 組織公告頁
 */

export const HomeOrganizationAnnouncementPage = () => {
  return (<HomeOrganizationLayout>
    <HomeAnnouncementTable />
  </HomeOrganizationLayout>);
};

