import React,{ useMemo } from 'react';
import { useSetState } from 'utils/hooks/useSetState';
import { BsModal, Table } from 'components';
import { format } from 'date-fns';
import { Tooltip as MatTooltip } from '@material-ui/core';
import { convertArrayToMapById } from 'utils/array';
import PropTypes from 'prop-types';
import {
  UiSessionRecord,
  UiTimeLineContainer,
  UiTimeLineTRow,
  UiTimeLineRow,
  UiTimeLineBlock,
  UiTimeLineBackground,
  UiTimeLine,
  UiTimeText,
  UiRecordTitle,
  UiRecordUser,
  UiRecordUserText,
  UiRecordUserIcon,
  UiDetailTitle,
  UiDetailButton
} from './SessionRecord.style';
import { useUser } from 'store/user';
import { useClass } from 'store/class';


/**
 * 上課記錄
 */

const schema = {
  role: {
    name: '身份',
    defaultValue: '',
  },
  attendeeNumber:{
    name: '座號',
    defaultValue: '',
  },
  nickname:{
    name: '姓名',
    defaultValue: '',
  },
  totalSessionPeriod:{
    name: '上課時長',
    defaultValue: '',
  },
  viewedRecordVideo: {
    name: '錄影觀看次數',
    defaultValue: ''
  },
  joinedSessionAt:{
    name: '進入教室',
    defaultValue: '',
  },
  leftSessionAt:{
    name: '離開教室',
    defaultValue: '',
  },
  attendanceCount:{
    name: '進出教室',
    defaultValue: '',
  },
};

const detailsSchema = {
  order: {
    name: '次序',
    defaultValue: '',
  },
  createAt:{
    name: '觀看時間',
    defaultValue: '',
  },
};
export const SessionRecord = ({ data }) => {
  const [{ myClasses }] = useClass();
  const [{ profile }] = useUser();
  const { dataInfo } = myClasses;
  // 班級身份判斷
  const isUser = dataInfo?.users?.some(user => user === profile.id);

  const startAt = useMemo(() => data?.startAt || 0 ,[data]);
  const endAt = useMemo(() => data?.endAt || 0 ,[data]);
  const [detailsData, setDetailsData] = useSetState({
    nickname: '',
    detailsOpened: false,
    details: []
  });

  const openDetails = (nickname, data) => {
    const details = data.map((item,index) => ({id: item.createAt, order: index + 1, createAt: format(item.createAt, 'yyyy-MM-dd HH:mm') }));
    setDetailsData({
      nickname,
      detailsOpened: true,
      details
    });
  };
  const closeDetails = () => {
    setDetailsData({
      detailsOpened: false,
      details: []
    });
  };
  const formatTime = (targetTime) => {
    const shortBlockLength = 100;
    const largeBlockLength = 216;
    const totalLength = 416;
     //上課時間
     const sessionTime = endAt - startAt;
     //預習時間 20分鐘
     const previewTime = 20 * 60 * 1000;
     //預習實際時間
     const previewActualTime = startAt - previewTime;
     //加課時間 90分鐘
     const extendTime = 90 * 60 * 1000;
     //加課實際時間
     const extendActualTime = endAt + extendTime;


     if(previewActualTime > targetTime) {
       //進入/離開時間比預習還早
       return '0';
     }else if(targetTime >= previewActualTime && targetTime <= startAt){
       //進入/離開的時間在預習時間內
       return (targetTime - previewActualTime) / previewTime * shortBlockLength;
     }else if(targetTime > startAt && targetTime <= endAt){
       //進入/離開的時間在上課時間
       return ((targetTime - startAt) / sessionTime) * largeBlockLength + shortBlockLength;
     }else if( targetTime >= endAt && targetTime < extendActualTime){
       //進入/離開的時間在加課時間
       return ((targetTime - endAt) / extendTime) * shortBlockLength + shortBlockLength + largeBlockLength;
     }else if(targetTime >= extendActualTime){
       return totalLength;
     }
  };

  const timeLintFormat = (enterTime,leftTime) => {
    const startPosition = formatTime(enterTime);
    const endPosition = formatTime(leftTime);
    return {
      startPosition,
      endPosition
    };
  };

  const sessionPeriodFormat = (period,status) =>
    (status === 'attended') ?
    Math.ceil(period / 60) + '分' :
    <div style={{color: '#f00'}}>缺席</div>;

  const viewedRecordVideoFormat = (nickname, viewedRecordVideoTime) => 
  (viewedRecordVideoTime?.length > 0) ?
  <UiDetailButton onClick={() => openDetails(nickname, viewedRecordVideoTime)}>{viewedRecordVideoTime.length}</UiDetailButton> :
  '-';
  
  const staffRecords = useMemo(()=>{
    const {
      hostId,
      hostNickname,
      hostRecord: { leftSessionAt, joinedSessionAt, attendances, totalSessionPeriod, status, viewedRecordVideoTime }
    } = data;
    const staffSessionTime = sessionPeriodFormat(totalSessionPeriod,status);
    const title = `授課老師:${hostNickname}`;
    const viewedRecordVideoTimes = viewedRecordVideoFormat(title, viewedRecordVideoTime);

    return {
      id: hostId,
      userId: hostId,
      role: '授課老師',
      attendeeNumber: '-',
      nickname: hostNickname,
      totalSessionPeriod: staffSessionTime,
      joinedSessionAt: joinedSessionAt ? format(joinedSessionAt,'HH:mm') : '-',
      leftSessionAt: leftSessionAt ? format(leftSessionAt,'HH:mm') : '-',
      attendanceCount: `${attendances?.length}次` || '-',
      viewedRecordVideo: viewedRecordVideoTimes,
      attendances
    };
  },[data]);

  const studentRecords = useMemo(()=>{
    if(!data) return [];
    const { users, userRecords } = data;
    const usersMap = convertArrayToMapById(users);
    const nextUserRecord = userRecords.map(record => {
      const { userId, leftSessionAt, joinedSessionAt, attendances, totalSessionPeriod, status, viewedRecordVideoTime } = record;
      const staffSessionTime = sessionPeriodFormat(totalSessionPeriod,status);
      const title = `學生:${usersMap[userId].nickname}`;
      const viewedRecordVideoTimes = viewedRecordVideoFormat(title, viewedRecordVideoTime);
      return {
        id: userId,
        userId,
        role: '學生',
        attendeeNumber: usersMap[userId].attendeeNumber,
        nickname: usersMap[userId].nickname,
        totalSessionPeriod: staffSessionTime,
        joinedSessionAt: joinedSessionAt ? format(joinedSessionAt,'HH:mm') : '-',
        leftSessionAt: leftSessionAt ? format(leftSessionAt,'HH:mm') : '-',
        attendanceCount: `${attendances?.length}次` || '-',
        viewedRecordVideo: viewedRecordVideoTimes,
        attendances
      };
    });
    return nextUserRecord;
  },[data]);

  const userRecords = useMemo(()=>{
    if(!staffRecords || !studentRecords) return [];
    if (isUser) return studentRecords;
    return [staffRecords,...studentRecords];
  },[staffRecords,studentRecords]);

  return (
    <>
      <UiRecordTitle>
        上課記錄總覽
      </UiRecordTitle>
      <UiSessionRecord>
        {
          !isUser && <>
            <Table.Normal
              data={userRecords || []}
              schema={schema}
              totalPage={userRecords.length}
              isNeedPagination={false}
            />
            <UiTimeLineContainer>
              <UiTimeLineTRow>
                <UiTimeLineBlock bgc="#E4E7EC">預習課程</UiTimeLineBlock>
                <UiTimeLineBlock bgc="#E4E7EC" width="216px">
                  <UiTimeText position="-20px">{format(startAt, 'HH:mm')}</UiTimeText>
                  上課
                  <UiTimeText position="calc(100% - 20px)">{format(endAt, 'HH:mm')}</UiTimeText>
                </UiTimeLineBlock>
                <UiTimeLineBlock bgc="#E4E7EC">加課時間</UiTimeLineBlock>
              </UiTimeLineTRow>
              {
                userRecords.map(record=>{
                  const { userId, attendances,leftSessionAt } = record;
                  return (
                    <UiTimeLineRow key={userId}>
                      <UiTimeLineBlock/>
                      <UiTimeLineBlock bgc="#f2f4f8" width="216px"/>
                      <UiTimeLineBlock/>
                      <UiTimeLineBackground>
                        {
                          attendances && attendances.length > 0 && attendances.map((attendance,index,array)=>{
                            if(array.length === 0) return null;
                            const { joinedAt, leftAt } = attendance;
                            const isLastData = (array.length - 1) === index;
                            const joinText = format(joinedAt,'HH:mm');
                            const leftText = format(leftAt,'HH:mm');
                            const { startPosition,endPosition } = timeLintFormat(joinedAt,leftAt);
                            let showText = '';
                            if(leftSessionAt === '-'){
                              if(isLastData){
                                showText = `進入時間: ${joinText}, 上課中`;
                              }else{
                                showText = `進入時間: ${joinText}, 離開時間: ${leftText}`;
                              }
                            }else {
                              showText = `進入時間: ${joinText}, 離開時間: ${leftText}`;
                            }
                            return (
                              <MatTooltip key={index} title={showText}>
                                <UiTimeLine key={index} left={startPosition} right={endPosition}/>
                              </MatTooltip>
                            );
                          })
                        }
                      </UiTimeLineBackground>
                    </UiTimeLineRow>
                  );
                })
              }
            </UiTimeLineContainer>
          </>
        }
        {
          isUser && <UiTimeLineContainer className="user">
            <UiTimeLineTRow className="user">
              <UiTimeLineBlock bgc="#5F73A9" color="#fff" width="20%">預習課程</UiTimeLineBlock>
              <UiTimeLineBlock bgc="#394F8A" color="#fff" width="60%">
                <UiTimeText position="-20px">{format(startAt, 'HH:mm')}</UiTimeText>
                上課
                <UiTimeText position="calc(100% - 20px)">{format(endAt, 'HH:mm')}</UiTimeText>
              </UiTimeLineBlock>
              <UiTimeLineBlock bgc="#5F73A9" color="#fff" width="20%">加課時間</UiTimeLineBlock>
            </UiTimeLineTRow>
            {
              userRecords.map(record => {
                const { userId, attendances,leftSessionAt } = record;
                  return (
                    <>
                      <UiTimeLineRow key={userId} className="user">
                        <UiTimeLineBlock width="20%" />
                        <UiTimeLineBlock bgc="#f2f4f8" width="60%"/>
                        <UiTimeLineBlock width="20%" />
                        <UiTimeLineBackground>
                          {
                            attendances && attendances.length > 0 && attendances.map((attendance,index,array)=>{
                              if(array.length === 0) return null;
                              const { joinedAt, leftAt } = attendance;
                              const isLastData = (array.length - 1) === index;
                              const joinText = format(joinedAt,'HH:mm');
                              const leftText = format(leftAt,'HH:mm');
                              const { startPosition,endPosition } = timeLintFormat(joinedAt,leftAt);
                              let showText = '';
                              if(leftSessionAt === '-'){
                                if(isLastData){
                                  showText = `進入時間: ${joinText}, 上課中`;
                                }else{
                                  showText = `進入時間: ${joinText}, 離開時間: ${leftText}`;
                                }
                              }else {
                                showText = `進入時間: ${joinText}, 離開時間: ${leftText}`;
                              }
                              return (
                                <MatTooltip key={index} title={showText}>
                                  <UiTimeLine key={index} left={startPosition} right={endPosition}/>
                                </MatTooltip>
                              );
                            })
                          }
                        </UiTimeLineBackground>
                      </UiTimeLineRow>
                      <UiRecordUser>
                        <UiRecordUserIcon />
                        <UiRecordUserText>學生上課時間</UiRecordUserText>
                      </UiRecordUser>
                    </>
                  );
                })
              }
          </UiTimeLineContainer> 
        }
      </UiSessionRecord>
      <BsModal
        open={detailsData.detailsOpened}
        title='錄影觀看紀錄'
        isFull={true}
        oKDisplay={false}
        cancelDisplay={false}
        onCancel={() => closeDetails()}
      >
      <UiDetailTitle>
        {detailsData.nickname}
      </UiDetailTitle>
      <Table
        data={detailsData.details || []}
        schema={detailsSchema}
        totalPage={detailsData.details?.length}
        isNeedPagination={false}
      >
      </Table>
    </BsModal>
    </>
  );
};

SessionRecord.propTypes = {
  data: PropTypes.object,
};
