import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  UiModal,
  UiMatDialogActions,
  UiMatDialogTitle,
  UiMatDialogContent,
  UiMatDialogContentText,
  UiIcon
} from './Modal.style';
import { makeStyles } from '@material-ui/core/styles';
import { Button, } from 'components';


/**
 * 彈窗可以新增標題、內容、按鈕文字&callBack 類型
 */

const useStyles = makeStyles({
	paper: {
		border: '1px solid #acdae8',
		'borderRadius': '15px',
	},

	root: {
		padding: '8px 24px',
	}
});

export const Modal = (
  { size = 'medium',
    text, content, title,
    isOpen,
    buttons,
    getModalState = () => {},
    isIconShow = true,
    isDisableBackdropClick = false,
    children
  }) => {
  const [state, setState] = useState(false);
  const classes = useStyles();
  const modalStateHandler = (callBack) => {
    callBack && callBack();
    getModalState(!isOpen);
    setState(!isOpen);
  };
  useEffect(() => {
    setState(isOpen);
  }, [isOpen]);
  return (
    <UiModal
      onClick={(e) => {
        e.stopPropagation();
      }}
      open={state || false}
      onClose={() => modalStateHandler()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{ classes: { root: classes.paper } }}
      maxWidth={false}
      size={size}
      disableBackdropClick={isDisableBackdropClick}
    >
      {title && <UiMatDialogTitle className={classes.root}>{title}</UiMatDialogTitle>}
      {isIconShow && <UiIcon name="errorOutline" haveBg={false} />}
      {(content || text) && <UiMatDialogContent>
        <UiMatDialogContentText dangerouslySetInnerHTML={{ __html: text }} />
        {content}
      </UiMatDialogContent>}
      {children}
      {buttons && <UiMatDialogActions>
        {buttons.map((button, index) => {
          return (
            <Button
              onClick={() => modalStateHandler(button.func)}
              key={button.text}
              buttonColor={button.color}
              disabled={button.disabled}
              loading={button.loading}
              autoFocus={index === 0 && true
              }>
              {button.text}
            </Button>
          );
        })
        }
      </UiMatDialogActions>
      }
    </UiModal>);
};


Modal.propTypes = {
  size: PropTypes.string,
  text: PropTypes.string,
  content: PropTypes.node,
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      func: PropTypes.func
    })
  ),
  getModalState: PropTypes.func,
  isIconShow: PropTypes.bool,
  children: PropTypes.node,
  isDisableBackdropClick: PropTypes.bool,
};



