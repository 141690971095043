import React from 'react';
import PropTypes from 'prop-types';
import getTime from 'date-fns/getTime';

import { useServerTime } from 'utils/hooks/useServerTime';
import { KeyboardDateTimePicker } from '@material-ui/pickers';

// import { UiDateTimePicker } from './DateTimePicker.style';


/**
 * 時間日期組件
 */

export const DateTimePicker = ({
  cancelLabel,
  clearLabel,
  disablePast,
  format,
  inputVariant,
  label,
  okLabel,
  onChange,
  value,
  ...props
}) => {
  const [{ timestamp }, { fetchServerTime }] = useServerTime();
  const onValueChange = date => onChange(date ? getTime(date) : null);

  return (
    <KeyboardDateTimePicker
      ampm={false}
      cancelLabel={cancelLabel}
      clearable
      clearLabel={clearLabel}
      disablePast={disablePast}
      format={format}
      initialFocusedDate={timestamp}
      inputVariant={inputVariant}
      label={label}
      okLabel={okLabel}
      onChange={onValueChange}
      onClick={fetchServerTime}
      // onError={console.log}
      value={value ? new Date(value) : null}
      variant="dialog"
      {...props}
    />
  );
};

DateTimePicker.defaultProps = {
  cancelLabel: '取消',
  clearLabel: '清除',
  disablePast: false,
  format: 'yyyy/MM/dd HH:mm',
  inputVariant: 'outlined',
  label: null,
  okLabel: '確定',
  onChange: () => {},
};

DateTimePicker.propTypes = {
  label: PropTypes.string,
  inputVariant: PropTypes.string,
  value: PropTypes.number,
  format: PropTypes.string,
  onChange: PropTypes.func,
  cancelLabel: PropTypes.string,
  clearLabel: PropTypes.string,
  okLabel: PropTypes.string,
  disablePast: PropTypes.bool
};


