import styled from 'styled-components';
import { Avatar as MatAvatar } from '@material-ui/core';
export const UiHomeworkCard = styled.div`
	display: flex;
	width: 100%;
`;

export const UiMatAvatar = styled(MatAvatar)`
	margin-right: 0.5rem;
`;
