import React from 'react';

import { AuthorityTable } from 'components';

/**
 * 付費帳號列表
 */

export const AuthorityPage = () => {
  return (
    <AuthorityTable />
  );
};
