import styled from 'styled-components';

export const UiSessionGroup = styled.div`
	overflow-x: hidden;
	margin-top: 2rem;
	padding-bottom: 20px;
	max-height: 410px;
	flex: 1;
	margin-bottom: auto;

	& > div + div {
		margin-top: 24px;
	}
	> p {
		font-size: 1rem;
		color: #3A4052;
	}
`;
