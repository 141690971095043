import React from 'react';

import { HomeInfo, HomeGroupLayout } from 'components';


/**
 * 班級簡介頁面
 */

export const HomeInfoPage = () => {
  return (
    <HomeGroupLayout>
      <HomeInfo />
    </HomeGroupLayout>
  );
};



