import React from 'react';
import { UiBsContentContainer } from 'styles';
import { SessionForm, BsHeaderTitle } from 'components';
import { UiOrganizationSessionFormPage } from './OrganizationSessionFormPage.style';
/**
 * 創建課程
 */

export const OrganizationSessionFormPage = props => {
  return (
    <>
      <BsHeaderTitle title="課程總覽" />
      <UiOrganizationSessionFormPage>
        <UiBsContentContainer>
          <SessionForm {...props} />
        </UiBsContentContainer>
      </UiOrganizationSessionFormPage>
    </>
  );
};

OrganizationSessionFormPage.propTypes = {

};


