import styled from 'styled-components';

export const UiSessionsTableButtonBox = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;

	& > * + * {
		margin-top: 5px;
	}
`;

export const UiTitle = styled.div`
	font-size: 20px;
	color: #3c4858;
`