import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'components';
import { UiButtonSelect, UiButtonOption, UiButtonOptionText, UiButtonOptionBackgound, UiButtonBox } from './ButtonSelect.style';
import { useSetState } from 'utils/hooks/useSetState';

// option prop example
// option = [
//   {
//     label: 'xxxx',
//     onClick: () => {},
//   },
//   {
//     // ....
//   }
// ]


/**
 * 按鈕 下拉選單
 */
export const ButtonSelect = ({
  children,
  option,
}) => {
  const [{
    isOpenList,
  }, setState] = useSetState({
    isOpenList: false,
  });

  const listToggle = () => {
    setState({isOpenList: !isOpenList})
  }

  const optionClick = click => {
    click();
    setState({isOpenList: false});
  }

  return (
    <UiButtonSelect>
      <UiButtonBox>
        <Button onClick={listToggle}>{children}</Button>
        {
          isOpenList && <UiButtonOption>
            {
              option?.length > 0 && option.map((item, index) => {
                return (
                  <UiButtonOptionText key={index} onClick={() => { optionClick(item.onClick) }}>
                    {item.label}
                  </UiButtonOptionText>
                )
              })
            }
          </UiButtonOption>
        }
      </UiButtonBox>
      {
        isOpenList && <UiButtonOptionBackgound onClick={listToggle} />
      }
    </UiButtonSelect>
  );
};

ButtonSelect.propTypes = {
  children: PropTypes.string,
  option: PropTypes.array,
};


