import React from 'react';
import PropTypes from 'prop-types';
import { UiSectionCardItem, UiLabel, UiContent } from './SectionCardItem.style';


/**
 * 區塊卡牌組件
 */

export const SectionCardItem = ({
  label = '',
  children,
  className
}) => {
  return (
    <UiSectionCardItem className={className}>
      <UiLabel>{label}</UiLabel>
      <UiContent>{children}</UiContent>
    </UiSectionCardItem>
  );
};

SectionCardItem.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string
};


