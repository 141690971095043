import styled from 'styled-components';
import Slider from 'react-slick';

export const UiSlider = styled(Slider)`
	position: relative;
	padding: 0 2rem;
`;

export const UiArrowButton = styled.button`
	position: absolute;
	top: 50%;
	right: ${({ right }) => right ? 0 : 'inherit'};
	left: ${({ left }) => left ? 0 : 'inherit'};
	display: flex;
	align-items: center;
	justify-content: center;
	width: 2rem;
	height: 2rem;
	transform: translateY(-50%);
	z-index: 10;
	cursor: pointer;
`;
