import React from 'react';
import PropTypes from 'prop-types';
import getTime from 'date-fns/getTime';

import { KeyboardTimePicker } from '@material-ui/pickers';

// import { UiTimePicker } from './TimePicker.style';


/**
 * 在這邊描述這個組件
 */

export const TimePicker = ({
  label,
  value,
  format = 'HH:mm',
  onChange = () => { },
  cancelLabel = '取消',
  okLabel = '確定',
  clearLabel = '清除',
  inputVariant = 'outlined',
  disablePast = false
}) => {

  const onValueChange = date => onChange(getTime(date));

  return (
    <KeyboardTimePicker
      mask="__:__ _M"
      variant="dialog"
      inputVariant={inputVariant}
      ampm={false}
      label={label}
      value={value ? new Date(value) : null}
      onChange={onValueChange}
      // onError={console.log}
      disablePast={disablePast}
      format={format}
      cancelLabel={cancelLabel}
      okLabel={okLabel}
      clearLabel={clearLabel}
      clearable
    />
  );
};

TimePicker.propTypes = {
  label: PropTypes.string,
  inputVariant: PropTypes.string,
  value: PropTypes.number,
  format: PropTypes.string,
  onChange: PropTypes.func,
  cancelLabel: PropTypes.string,
  clearLabel: PropTypes.string,
  okLabel: PropTypes.string,
  disablePast: PropTypes.bool
};


