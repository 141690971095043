import styled from 'styled-components';

export const UiSetAQuestion = styled.div`
	display: block;
`;

export const UiSelectBox = styled.div`
	display: flex;

	> div:first-child {
		margin-right: 20px;
	}
`

export const UiIconBox = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: min-content;
	background-color: #fafafc;
	border-radius: 8px;

	svg {
		position: relative;
		top: 6px;
		left: 4px;
		font-size: 2.3rem;
		cursor: pointer;
	}

	> span {
		margin: 0 0.5rem;
	}

	> div {
		width: 0.4px;
		height: 32px;
		background-color: #d5d7de;
	}
`

export const UiPdf = styled.span`
	svg {
		> path {
			fill: #121232;
		}

		&:hover {
			> path {
				fill: #2a2a47;
			}
		}
	}
`

export const UiPlane = styled.span`
	svg {
		> path {
			fill: #ec7963;
		}

		&:hover {
			> path {
				fill: #ee8773;
			}
		}
	}
`

export const UiNoDataBox = styled.div`
	width: 100%;
`

export const UiPictureBox = styled.div`
	display: block;
	margin: auto;
	width: 36%;
	height: 240px;
	background: url("${({ path }) => path}");
	background-position: center;
	background-size: cover;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
`

export const UiNoDataText = styled.div`
	margin-top: 4rem;
	font-size: 16px;
	text-align: center;
	color: #8b90a0;
	line-height: 2;
`