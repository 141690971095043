import React, { useCallback, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { useSetState } from 'utils/hooks/useSetState';
import { UiAside, UiAsideTitle, UiAsideItem, UiAsideText } from './Aside.style';

/**
 * Aside 選單
 */

export const Aside = ({ asideRoutes = [] }) => {

  const [{ listData }, setState] = useSetState({ listData: asideRoutes.map(item => ({ ...item, isOpen: false })) });
  const history = useHistory();
  const nowPagePath = history.location.pathname;

  const goToPath = useCallback(path => () => {
    history.push(path);
  }, [history]);

  const clickHandler = useCallback(index => () => {
    let nextListData = listData.concat();
    nextListData[index].isOpen = !nextListData[index].isOpen;
    setState({ listData: nextListData });
  }, [listData, setState]);

  return (
    <UiAside>
      {
        listData.map((item, index) => {
          const hasRoutes = item.routes && item.routes.length > 0;
          return hasRoutes && (
            <Fragment key={index}>
              <UiAsideTitle key={index} button onClick={clickHandler(index)}>
                <UiAsideText>
                  {item.label}
                </UiAsideText>
              </UiAsideTitle>
              {
                item.routes.map((route) => (
                  <UiAsideItem
                    key={route.path}
                    active={route.path === nowPagePath ? true : false}
                    button
                    onClick={goToPath(route.path)}
                  >
                    <UiAsideText>{route.label}</UiAsideText>
                  </UiAsideItem>
                ))
              }
            </Fragment>
          );
        })
      }
    </UiAside>
  );
};


Aside.propTypes = {
  asideRoutes: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      path: PropTypes.string,
      routes: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          path: PropTypes.string
        })
      )
    })
  )
};

