import React, { useEffect } from 'react';
import { format } from 'date-fns';
import { makeStyles } from '@material-ui/core/styles';

import {
  Card,
  CardHeader,
  CardBody,
  CardIcon,
  CardFooter
} from 'components/material-dashboard-pro-react';
import styles from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle.js';


import { PERMISSIONS, DASHBOARD_SUMMARY_CATEGORY } from 'constants/index';
import { useOrganization } from 'store/organization';
import { useUser } from 'store/user';
import { Icon, SessionManagementTable } from 'components';
import { useParams } from 'react-router-dom';

import { UiGridContainer, UiGridItem } from 'styles';
import {
  UiBsHomeDashboard,
  UiCardTitle,
  UiStatsLabel,
  UiStats
} from './BsHomeDashboard.style';


/**
 * 機構概況 dashboard
 */
const useStyles = makeStyles(styles);

const girds = {
  desktop: 25,
  notebook: 25,
  pad: 25,
  phone: 100
};

const cardMap = {
  [DASHBOARD_SUMMARY_CATEGORY.GROUP]: {
    label: '班級總數',
    icon: 'school',
    color: 'warning',
    statsLabel: '最新創立時間',
    permission: PERMISSIONS.VIEW_GROUP
  },
  [DASHBOARD_SUMMARY_CATEGORY.STAFF]: {
    label: '教師人數',
    icon: 'person',
    color: 'success',
    statsLabel: '最新加入時間',
    permission: PERMISSIONS.VIEW_STAFF_PROFILE
  },
  [DASHBOARD_SUMMARY_CATEGORY.CUSTOMER]: {
    label: '學生人數',
    icon: 'people',
    color: 'danger',
    statsLabel: '最新加入時間',
    permission: PERMISSIONS.VIEW_CUSTOMER_PROFILE
  },
  [DASHBOARD_SUMMARY_CATEGORY.RESOURCE]: {
    label: '教學資源',
    icon: 'viewList',
    color: 'info',
    statsLabel: '最新上傳時間',
    permission: PERMISSIONS.VIEW_RESOURCE
  }
};

export const BsHomeDashboard = () => {
  const classes = useStyles();
  const [{ myOrganization: { myPermissions } }] = useUser();
  const { organizationId } = useParams();
  localStorage.setItem('organizationId', organizationId);
  const [
    { dashboardSummary },
    {
      getDashboardClassSummary,
      getDashboardTeacherSummary,
      getDashboardStudentSummary,
      getDashboardResourceSummary
    }] = useOrganization();


  useEffect(() => {
    if (!myPermissions || myPermissions.length < 1) return;
    Object.entries(cardMap)
      .filter(([, item]) => myPermissions.includes(item.permission))
      .forEach(([key,]) => {
        switch (key) {
          case DASHBOARD_SUMMARY_CATEGORY.GROUP:
            getDashboardClassSummary();
            break;
          case DASHBOARD_SUMMARY_CATEGORY.STAFF:
            getDashboardTeacherSummary();
            break;
          case DASHBOARD_SUMMARY_CATEGORY.CUSTOMER:
            getDashboardStudentSummary();
            break;
          case DASHBOARD_SUMMARY_CATEGORY.RESOURCE:
            getDashboardResourceSummary();
            break;
          default:
            break;
        }
      });
  }, [myPermissions]);

  return (
    <UiBsHomeDashboard>
      <UiGridContainer>
        {
          Object.entries(cardMap)
            .filter(([, item]) => myPermissions.includes(item.permission))
            .map(([key, item]) => (
              <UiGridItem key={key} {...girds}>
                {/*TODO: 需要被換成 SummaryCard 組件  */}
                <Card>
                  <CardHeader color={item.color} stats icon>
                    <CardIcon color={item.color}>
                      <Icon name={item.icon} haveBg={false} />
                    </CardIcon>
                    <p className={classes.cardCategory}>{item.label}</p>
                    <UiCardTitle className={classes.cardTitle}>
                      {dashboardSummary[key] ? dashboardSummary[key]['total'] : 0}
                    </UiCardTitle>
                  </CardHeader>
                  <CardFooter stats>
                    <UiStats className={classes.stats}>
                      <UiStatsLabel>
                        {item.statsLabel}
                      </UiStatsLabel>
                      <div>
                        {
                          dashboardSummary[key] ?
                            format(new Date(dashboardSummary[key]['latestCreatedAt']), 'yyyy/MM/dd')
                            : '----/--/--'
                        }
                      </div>
                    </UiStats>
                  </CardFooter>
                </Card>
              </UiGridItem>
            ))
        }
      </UiGridContainer>
      <Card>
        <CardHeader color="success" icon>
          <CardIcon color="success">
            <Icon name="importContacts" haveBg={false} />
          </CardIcon>
          <UiCardTitle className={classes.cardTitle}>今日課程</UiCardTitle>
        </CardHeader>
        <CardBody>
          <SessionManagementTable noFilter defaultDuration={'NOW'} />
        </CardBody>
      </Card>
    </UiBsHomeDashboard>
  );
};

