import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';

import { EDIT_STATUS } from 'constants/index';
import { Table, IconInput, Select, Button, DatePicker, Icon, Modal } from 'components';
import { useClass } from 'store/class';
import { useSetState } from 'utils/hooks/useSetState';
import { useAlert } from 'utils/hooks/useAlert';

import { UiTable, UiActionBox, UiflexBox, UiTableButtonBox, UiEmphasize } from 'styles';


/**
 * 班級列表
 */
const schema = {
  name: {
    name: '班級名稱',
    defaultValue: ''
  },
  createDate: {
    name: '建班時間',
    defaultValue: ''
  },
  expirationTime: {
    name: '有效時間',
    defaultValue: ''
  },
  isExpired: {
    name: '班級狀態',
    defaultValue: ''
  },
  ownerName: {
    name: '導師',
    defaultValue: ''
  }
};

const selectInputOptions = [
  {
    name: '搜尋導師暱稱',
    value: 'ownerName'
  },
  {
    name: '搜尋導師手機號碼',
    value: 'ownerMobileNumber'
  }
];

const expiredOptions = [
  {
    name: '全部',
    value: ''
  },
  {
    name: '未到期',
    value: 'false'
  },
  {
    name: '已到期',
    value: 'true'
  }
];

const expiredShowText = [
  {
    id: 'isExpired',
    state: {
      false: '未到期',
      true: '已到期',
    }
  }
];

export const ClassTable = () => {
  const { setAlert } = useAlert();
  const history = useHistory();
  const { organizationId } = useParams();
  const [{ classes }, { getClasses, removeClassInfo }] = useClass();
  const { data: classData, total: classTotalPage, dataMap: classDataMap } = classes;
  const [
    { goal,
      nowPage,
      rowsPage,
      name,
      ownerName,
      ownerMobileNumber,
      createdAfter,
      status,
      expired,
      deleteModalState,
      actionClassId,
    }, setState] = useSetState({
      goal: '',
      nowPage: 0,
      rowsPage: 10,
      name: '',
      ownerName: '',
      ownerMobileNumber: '',
      createdAfter: null,
      status: '',
      expired: '',
      deleteModalState: false,
      actionClassId: null,
    });

  const changePage_Rows = params => {
    const { newPage, newRowsPage } = params;
    setState({
      nowPage: newPage,
      rowsPage: newRowsPage
    });
  };

  const dateHandler = value => {
    setState({
      createdAfter: value,
      nowPage: 0
    });
  };

  // table 操作
  const ActionComponents = ({ params: { id } }) => {
    const goPage = editStatus => () => history.push(`/organization/${organizationId}/class/${id}/${editStatus}`);

    return (
      <UiTableButtonBox>
        <Button
          onClick={() => history.push(`/organization/${organizationId}/class/${id}/session`)}
        >
          課程管理
        </Button>
        <Button
          onClick={() => history.push(`/organization/${organizationId}/class/${id}/customer`)}
        >
          成員管理
        </Button>
        <Icon name="edit" title="編輯班級" onClick={goPage(EDIT_STATUS.EDIT)} />
        <Icon
          name="delete"
          title="刪除班級"
          type='danger'
          onClick={() => setState({ deleteModalState: true, actionClassId: id })}
        />
      </UiTableButtonBox>
    );
  };

  const fetchGetClasses = () => {
    getClasses({
      nowPage,
      rowsPage,
      name,
      ownerName,
      ownerMobileNumber,
      createdAfter,
      status,
      expired,
    });
  };

  const onSubmitHandler = (value, key) => {
    if (key === 'condition') {
      if (goal === '') {
        setAlert('請選擇搜尋目標!', 'wranning');
        return;
      } else {
        setState({
          ownerName: '',
          ownerMobileNumber: '',
          [goal]: value,
          nowPage: 0
        });
      }
    }
    setState({
      [key]: value,
      nowPage: 0
    });
  };

  const setDeleteModalStateHandler = state => {
    setState({ deleteModalState: state });
  };

  const buttons = [
    {
      text: '確認',
      func: async () => {
        await removeClassInfo(actionClassId);
        fetchGetClasses();
        setDeleteModalStateHandler(false);
      }
    },
    {
      text: '取消',
      color: 'highlight',
      func: () => { }
    }
  ];

  useEffect(() => {
    fetchGetClasses();
  }, [nowPage, rowsPage, name, ownerName, ownerMobileNumber, createdAfter, status, expired]);

  // eslint-disable-next-line react/prop-types
  const ModalText = ({ name }) => (
      <div>
        您確定要將 <UiEmphasize>{name}</UiEmphasize> 刪除嗎?這樣班級中的課程將會消失、班級成員也都無法再回到這個班級喔!!
      </div>
    );

  return (
    <UiTable>
      <UiActionBox>
        <UiflexBox>
          <IconInput
            placeholder='搜尋班級名稱'
            onChange={value => onSubmitHandler(value, 'name')}
          />
          <Select
            label="班級狀態"
            options={expiredOptions}
            submitHandler={value => onSubmitHandler(value, 'expired')}
          />
          <DatePicker
            label="搜尋建班時間"
            value={createdAfter}
            onChange={dateHandler}
          />
          <Select
            label="搜尋目標"
            options={selectInputOptions}
            submitHandler={value => onSubmitHandler(value, 'goal')}
          />
          {
            goal && <IconInput
              placeholder='搜尋條件'
              onChange={value => onSubmitHandler(value, 'condition')}
            />
          }
        </UiflexBox>
        <UiflexBox>
          <Button
            buttonColor='highlight'
            icon='add'
            onClick={() => history.push(`/organization/${organizationId}/class/create`)}>新增班級</Button>
        </UiflexBox>
      </UiActionBox>
      <Table
        data={
          classData.map(item => {
            item.createDate = format(new Date(item.createdAt), 'yyyy-MM-dd');
            item.expirationTime = format(new Date(item.expirationTime), 'yyyy-MM-dd');
            return item;
          })
        }
        schema={schema}
        stateShowText={expiredShowText}
        changePage_Rows={changePage_Rows}
        totalPage={classTotalPage}
        ActionComponents={ActionComponents}
        nowPage={nowPage}
      />

      <Modal
        isOpen={deleteModalState}
        content={<ModalText name={classDataMap[actionClassId] && classDataMap[actionClassId].name}/>}
        getModalState={state => setDeleteModalStateHandler(state)}
        buttons={buttons}
      />
    </UiTable>);
};



