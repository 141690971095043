import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { BsHeaderTab } from 'components';


/**
 * 在這邊描述這個組件
 */

export const BsCustomerManagementPageHeaderTab = ({ activeIndex = 0 }) => {
  const { organizationId } = useParams();
  const history = useHistory();
  const goPath = path => history.push(path);

  const tabData = [
    { label: '學生列表', onClick: () => goPath(`/organization/${organizationId}/customer`) },
    { label: '邀請中', onClick: () => goPath(`/organization/${organizationId}/customer/inviting`) }
  ];
  return (
    <BsHeaderTab data={tabData} activeIndex={activeIndex} />
  );
};

BsCustomerManagementPageHeaderTab.propTypes = {
  activeIndex: PropTypes.number
};


