import React from 'react';
import { HomeGroupLayout, HomeOnlineExamTable } from 'components';

/**
 * 線上測驗列表
 */

export const HomeExamPage = () => {
  return (
    <HomeGroupLayout>
      <HomeOnlineExamTable/>
    </HomeGroupLayout>
  );
};
