import React, { useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import { Table, IconInput, Select, TransferList,Modal, Button, BsModal, Icon, OutlineButton } from 'components';
import { useSetState } from 'utils/hooks/useSetState';
import {
  getOrganizationCustomers as getOrganizationCustomersApi
} from 'services/api/organization/customer';
import { useAlert } from 'utils/hooks/useAlert';
import {
  UiTable,
  UiActionBox,
  UiflexBox,
  UiTableButtonBox,
} from 'styles';
import {
  UiButtonWrapper,
  UiButtonSvg,
  UiHomeworkLink,
  UiHiddenInput,
  UiInviteClassCode,
  UiInviteClassCodeDate,
  UiSvg,
  UiSearchBox
} from './MemberTable.style';
import { useCustomer } from 'store/customer';
import { useUser } from 'store/user';
import { useClass } from 'store/class';
import { ORGANIZATION_SETTINGS_VALUE } from 'constants/index';

/**
 * 學生列表Table
 */


const selectOptions = [
  {
    name: '手機',
    value: 'mobileNumber'
  },
  {
    name: '暱稱',
    value: 'nickname'
  }
];

const selectModalOptions = [
  {
    name: '手機號碼',
    value: 'mobileNumber'
  },
  {
    name: '機構暱稱',
    value: 'nickname'
  },
  {
    name: '帳號ID',
    value: 'customerId'
  }
];


export const MemberTable = ({
  tabIndex = 0,
}) => {
  const history = useHistory();
  const { organizationId, classId } = useParams();
  const [
    { customers },
    {
      getCustomers,
      addUserToGroup,
      removeUserToGroup
    }
  ] = useCustomer();

  // class store
  const [{ myOrganization, myOrganization: { isLoaded: isLoadedMyOrg }, profile: { id: myUserId } }] = useUser();
  const [{ myClasses }, { getClassInviteCode }] = useClass();
  const isOwner = myClasses.dataInfo.isOwner;
  const ownerId = myClasses.dataInfo.ownerId;
  const isHost = myClasses.dataInfo.hosts?.includes(myUserId) || false;
  const { groupOwnerInviteCustomerSetting } = myOrganization?.organization || {};

  const [{
    nowPage,
    rowsPage,
    searchType,
    searchValue,
    nickname,
    mobileNumber,
    isLoading,
    isOpen,
    inviteClassCode, // 代碼
    inviteClassDate, // 代碼到期時間
    isInvitationCodeExpired, // 代碼是否過期
    cancelDisplay,
    isOpenModal,
    organizationCustomers,
    organizationCustomersTotal,
    organizationCustomersNowPage,
    isInit,
    isCustomerLoading,
    organizationSearchType,
    organizationSearchValue
  }, setState] = useSetState({
    nowPage: 0,
    rowsPage: 10,
    nickname: '',
    mobileNumber: '',
    searchType: '',
    searchValue: '',
    isLoading: false,
    isOpen: false,
    inviteClassCode: null,
    inviteClassDate: null,
    isInvitationCodeExpired: null,
    cancelDisplay: true,
    isOpenModal: false,
    organizationCustomers:[],
    organizationCustomersTotal:0,
    organizationCustomersNowPage:0,
    isInit:false,
    isCustomerLoading:false,
    organizationSearchType:'',
    organizationSearchValue:''
  });

  // user store
  const [{ profile }] = useUser();
  const profileId = profile.id;

  const { setAlert } = useAlert();

  const schema = {
    nickname: {
      name: '暱稱',
      defaultValue: '無'
    }
  };

  const getModalStateHandler = state => {
    setState({
      isOpenModal: state
    });
    if(!state) {
      setState({
        organizationCustomers:[],
        organizationCustomersNowPage:0,
        isInit:true,
        organizationSearchType:'',
        organizationSearchValue:''
      });
    }
  };

  const closeHandler = () => {
    setState({
      isOpenModal: false,
      organizationCustomers:[],
      organizationCustomersNowPage:0,
      isInit:true,
      organizationSearchType:'',
      organizationSearchValue:''
    });
  };

  const schemaAttendeeNumber = {
    attendeeNumber: {
      name: '座號',
      defaultValue: ''
    },
    nickname: {
      name: '暱稱',
      defaultValue: '無'
    }
  };

  const nextSchema = useCallback(() => {
    let sendSchema = {};
    if (Object.keys(customers.sendData).length <= 0) {
      sendSchema = schema;
    } else {
      sendSchema = schemaAttendeeNumber;
    }

    sendSchema = {
      ...sendSchema, homeworkLink: {
        name: '作業總覽',
        defaultValue: '-'
      }
    };

    return sendSchema;
  }, [customers.sendData]);

  const goToStudentSubmittedHomeWorkInfo = id => {
    history.push(`/home/${organizationId}/class/${classId}/homeworks/submitted/${id}
?ownerId=${isHost ? myUserId : ''}`);
  };

  const checkHomeworkUrl = (targetId) => myUserId === targetId || isOwner || isHost;

  const HomeworkUrl = ({ id }) => checkHomeworkUrl(id) ? (
    <UiHomeworkLink onClick={(e) => {
      e.preventDefault();
      goToStudentSubmittedHomeWorkInfo(id);
    }}>
      開啟
    </UiHomeworkLink>
  ) : null;



  const dataFormat = data => {
    return data.map((item, index) => ({
      ...item,
      homeworkLink: <HomeworkUrl id={item.id} />,
    }));
  };

  // table 操作
  const ActionComponents = ({ params }) => {
    return (
      <UiTableButtonBox>
        <Button
          buttonColor='highlight'
          onClick={() => { doRemoveUserToGroup(params); }}
          isLoading={isLoading}
        >
          移出班級
        </Button>
      </UiTableButtonBox >
    );
  };

  const doRemoveUserToGroup = async (params) => {
    const newParams = { userProfiles: { ...params } };
    await removeUserToGroup(newParams);
    reloadUserList();
  };

  const reloadUserList = () => {
    setState({
      nowPage: 0
    });
    getCustomers({ nowPage, rowsPage });
  };

  const organizationCustomersOnChangeHandle = (value, key) => {
    setState({
      [key]:value
    });
  };

  const organizationCustomersSearchHandle = async () => {
    if(!organizationSearchType ||!organizationSearchValue) return;
    setState({
      organizationCustomersNowPage:0,
      isCustomerLoading:true
    });
    const payload = {
      nowPage: 0,
      rowsPage:10,
      [organizationSearchType]: organizationSearchValue
    };
    const { isSuccess,data } =
      await getOrganizationCustomersApi(organizationId, classId, payload);
    if(isSuccess){
      const { total,userProfiles } = data;
      setState({
        organizationCustomers: transferDataFormat(userProfiles),
        organizationCustomersTotal: total,
        isCustomerLoading:false
      });
    }
  };

  const getOperationAuth = () => {
    if (!isLoadedMyOrg) return false;
    return isOwner;
  };

  const getGroupOperationAuth = () => {
    return isOwner && groupOwnerInviteCustomerSetting === ORGANIZATION_SETTINGS_VALUE.ALLOW;
  };

  const changePage_Rows = params => {
    const { newPage, newRowsPage } = params;
    setState({
      nowPage: newPage,
      rowsPage: newRowsPage
    });
  };
  const onSubmitHandler = (value, key) => {
    if (key === 'searchValue') {
      if (searchType === '') return;
      setState({
        nickname: '',
        mobileNumber: '',
        [searchType]: value,
        nowPage: 0
      });
    }
    setState({
      [key]: value,
    });
  };
  const transferDataFormat = arr => {
    arr.forEach(item => {
      item.imagePath = '';
      item.title = item.nickname;
      item.content = item.email;
    });
    return arr;
  };
  const getTrnasferListValue = async params => {
    const userIdList = params.map(item => item.id);
    const isSuccess = await addUserToGroup({ userIdList, userProfiles: params });
    if (isSuccess) await getCustomers({ nowPage, rowsPage, nickname, mobileNumber });
  };

  const getValueHandler = value => {
    getTrnasferListValue(value);
    closeHandler();
  };

  const getCurrentValue = (left) => {
    setState({
      organizationCustomers:left,
    });
  };

  const getOrganizationCustomers = async () => {
    setState({
      isCustomerLoading:true
    });
    const payload = {
      nowPage: organizationCustomersNowPage ,
      rowsPage:10
    };
    if(organizationSearchType && organizationSearchValue){
      payload[organizationSearchType] = organizationSearchValue;
    }
    const { isSuccess,data } =
      await getOrganizationCustomersApi(organizationId, classId, payload);
    if(isSuccess){
      const { total,userProfiles } = data;
      const nextOrganizationCustomers = organizationCustomers.concat(userProfiles);
      setState({
        organizationCustomers: transferDataFormat(nextOrganizationCustomers),
        organizationCustomersTotal: total,
        isCustomerLoading:false
      });
    }

  };

  const onScrollToApi = isCallApi => {
    if(!isCallApi || !isInit) return;
    setState({
      organizationCustomersNowPage: organizationCustomersNowPage + 1
    });
  };

  useEffect(() => {
    if (!classId || !organizationId || isCustomerLoading) return;
    getOrganizationCustomers();
    setState({
      isInit:true
    });
  }, [organizationCustomersNowPage]);

  useEffect(() => {
    getCustomers({ nowPage, rowsPage, nickname, mobileNumber });
  }, [nowPage, rowsPage, nickname, mobileNumber]);

  useEffect(() => {
    setState({
      inviteClassCode: myClasses.dataInfo && myClasses.dataInfo.invitationCode,
      inviteClassDate: myClasses.dataInfo &&
        myClasses.dataInfo.invitationCodeExpiredAt &&
        format(new Date(myClasses.dataInfo.invitationCodeExpiredAt), 'MM月dd日 HH:mm'),
      isInvitationCodeExpired: myClasses.dataInfo && myClasses.dataInfo.isInvitationCodeExpired
    });
  }, [myClasses]);

  useEffect(() => {
    if(inviteClassCode) {
      setState({
        cancelDisplay: false,
      });
    } else {
      if(profileId === ownerId) {
        setState({
          cancelDisplay: true,
        });
      } else {
        setState({
          cancelDisplay: false,
        });
      }
    }
  },[inviteClassCode]);

  const goSetSeatsPage = () => history.push('member/seats');

  const onModalOk = () => {
    if(inviteClassCode) {
      // 判斷過期
      if(isInvitationCodeExpired) {
        if(profileId === ownerId){
          // 生成代碼
          getInviteClassCodeHandler();
        } else {
          //  關閉視窗
          modalToggle();
        }
      } else {
        //  關閉視窗
        modalToggle();
      }
    } else {

      if(profileId === ownerId){
        // 生成代碼
        getInviteClassCodeHandler();
      } else {
        //  關閉視窗
        modalToggle();
      }
    }
  };

  const modalToggle = () => {
    setState({
      isOpen : !isOpen,
    });
  };

  const onOkTextHandle = () => {
    let text = null;
    if(inviteClassCode) {
      // 判斷過期
      if(isInvitationCodeExpired) {

        if(profileId === ownerId) {
          if(profileId === ownerId){
            text = '產生';
          } else {
            text = '取消';
          }
        }

      } else {
        text = '關閉';
      }
    } else {

      if(profileId === ownerId){
        text = '產生';
      } else {
        text = '關閉';
      }
    }
    return text;
  };

  // 複製代碼
  const codeRef = useRef();
  const onCopyCode = () => {
    const copyText = codeRef.current;
    copyText.select(); // 選擇物件
    document.execCommand('Copy'); // 執行瀏覽器複製命令
    setAlert('複製成功!', 'success');  // 跳成功訊息
  };

  const getInviteClassCodeHandler = async () => {
    // 需要為班導(owner)才可啟動代碼
    if (profileId !== ownerId) return;
    setState({ isLoading: true });
    await getClassInviteCode();
    setState({ isLoading: false, isInvitationCodeExpired: false });
  };

  return (
    <UiTable>
      <UiActionBox>
        <UiflexBox>
          <Select label="搜尋條件" options={selectOptions} submitHandler={value => onSubmitHandler(value, 'searchType')} />
          <IconInput
            placeholder='搜尋條件'
            value={searchValue}
            onChange={value => onSubmitHandler(value, 'searchValue')}
          />
        </UiflexBox>
        {
          getOperationAuth() && (
            <UiButtonWrapper>
              <UiButtonSvg>
                <OutlineButton
                    onClick={modalToggle}
                    buttonColor="normal"
                  >
                    <Icon.Svg color="#EC7963" name="Invite" />
                    邀請代碼
                </OutlineButton>
              </UiButtonSvg>
              {
                tabIndex === 0 &&
                customers.data.length > 0 &&
                <Button
                  onClick={goSetSeatsPage}
                  type="table"
                  icon='edit'
                  buttonColor="new"
                >
                  設定座號
                </Button>
              }
              {
                <Button
                  onClick={() => getModalStateHandler(true)}
                >
                  新增成員
                </Button>
              }
              <Modal
                isIconShow={false}
                isOpen={isOpenModal}
                title="選擇學生"
                content={
                  <div>
                    <UiSearchBox>
                      <Select
                        label="搜尋條件"
                        options={selectModalOptions}
                        value={organizationSearchType}
                        submitHandler={value => organizationCustomersOnChangeHandle(value, 'organizationSearchType')}
                      />
                      <IconInput
                        placeholder='搜尋條件'
                        value={organizationSearchValue}
                        onClick={()=> organizationCustomersSearchHandle()}
                        onChange={value => organizationCustomersOnChangeHandle(value, 'organizationSearchValue')}
                      />
                    </UiSearchBox>
                    <TransferList
                      data={organizationCustomers || []}
                      dataTotal={organizationCustomersTotal}
                      getValue={getValueHandler}
                      closeHanlder={closeHandler}
                      onScrollToApi={onScrollToApi}
                      isLoading={isCustomerLoading}
                      getCurrentValue={getCurrentValue}
                    />
                  </div>
                }
                getModalState={getModalStateHandler}
              />
            </UiButtonWrapper>
          ) || <UiButtonSvg>
                <OutlineButton
                  onClick={modalToggle}
                  buttonColor="normal"
                >
                  <Icon.Svg color="#EC7963" name="Invite" />
                  邀請代碼
                </OutlineButton>
              </UiButtonSvg>
        }
      </UiActionBox>
      <Table
        data={dataFormat(customers.data)}
        schema={nextSchema()}
        changePage_Rows={changePage_Rows}
        totalPage={customers.total}
        nowPage={nowPage}
        ActionComponents={getGroupOperationAuth() && ActionComponents}
      />
      <BsModal
       open={isOpen}
       title="邀請代碼"
       onOk={onModalOk}
       onCancel={modalToggle}
       okText={onOkTextHandle()}
       cancelDisplay={cancelDisplay}
       isLoading={isLoading}
      >
        {
          inviteClassCode ?
            isInvitationCodeExpired ?
              profileId === ownerId ?
                <div>邀請代碼已過期，是否要產生邀請代碼?</div>
                : <div>邀請代碼已過期，請聯絡班導師！</div>
              : <>
                <UiInviteClassCode>
                  <div>透過邀請代碼可加入班級：</div>
                  <div>{inviteClassCode}</div>
                  <UiSvg onClick={onCopyCode}>
                    <Icon.Svg name="Copy" />
                  </UiSvg>
                </UiInviteClassCode>
                <UiInviteClassCodeDate>班級代碼過期日期：{inviteClassDate}</UiInviteClassCodeDate>
                <UiHiddenInput>
                  <input type="text" ref={codeRef} value={inviteClassCode} />
                </UiHiddenInput>
              </>
            : profileId === ownerId ?
              <div>目前沒有邀請代碼，是否要產生邀請代碼?</div>
              : <div>查無邀請代碼，請聯絡班導師！</div>
        }
      </BsModal>
    </UiTable>);
};


MemberTable.propTypes = {
  tabIndex: PropTypes.number,
};
