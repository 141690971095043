import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useSetState } from 'utils/hooks/useSetState';
import { Checkbox } from 'components';
import {
  UiCheckboxGroup,
} from './CheckboxGroup.style';


/**
 * checkbox group
 */

export const CheckboxGroup = ({
  data = [],
  defaultValue = [],
  type = '',
  className,
  onChange = () => { }
}) => {
  const [{ valueList }, setState] = useSetState({
    valueList: [],
  });
  const changeHandler = (value, itemValue) => {
    let nextValueList = valueList.concat();
    if (value === '') {
      setState({ valueList: nextValueList.filter(item => item !== itemValue) });
      type === 'checkList' ?
        onChange(nextValueList.filter(item => item !== itemValue), itemValue, 'popOut') :
        onChange(nextValueList.filter(item => item !== itemValue));
    } else {
      setState({
        valueList: [value, ...valueList]
      });
      type === 'checkList' ?
        onChange([value, ...valueList], itemValue, 'popIn') :
        onChange([value, ...valueList]);
    }

  };


  // toggle check
  useEffect(() => {
    setState({ valueList: defaultValue });
  }, [defaultValue]);

  return (
    <UiCheckboxGroup>
      {
        data.map((item, index) => {
          const isChecked = defaultValue.includes(item.value);
          return (
            <>
              <Checkbox
                key={item.value}
                checked={isChecked}
                onChange={val => changeHandler(val, item.value)}
                checkedValue={item.value}
                color="primary"
                label={item.label}
                type={type}
                className={className}
                disabled={item.disabled}
              />
            </>
          );
        })
      }
    </UiCheckboxGroup>
  );
};

CheckboxGroup.propTypes = {
  data: PropTypes.array,
  defaultValue: PropTypes.array,
  onChange: PropTypes.func,
  type: PropTypes.string,
  className: PropTypes.string
};


