import { stringify } from 'query-string';
import { Api } from 'services/api';


/**
 * 相簿列表
 * Get Group Albums
 */

export const getGroupAlbums = classId => async params => {
  const { nowPage = 0, rowsPage } = params;
  const queryParams = {
    limit: rowsPage ? rowsPage : 10,
  };
  if (rowsPage && nowPage * rowsPage !== 0) {
    queryParams.skip = nowPage * rowsPage;
  }
  const querystring = stringify(queryParams);
  const response = await Api.get(`/groups/${classId}/albums?${querystring}`);
  return response;
};

/**
 * 創建自訂相簿
 * Create Custom Group Album
 */

export const createCustomGroupAlbum = classId => async params => {
  const response = await Api.post(`/groups/${classId}/albums`, params);
  return response;
};

/**
 * 創建自訂相簿
 * Update Custom Group Album
 */

export const updateCustomGroupAlbum = (classId, albumId) => async params => {
  const response = await Api.put(`/groups/${classId}/albums/${albumId}`, params);
  return response;
};
/**
 * 刪除自訂相簿
 * Delete Custom Group Album
 */
export const deleteCustomGroupAlbum = classId => async albumId => {
  const response = await Api.delete(`/groups/${classId}/albums/${albumId}`);
  return response;
};

/**
 *
 * 刪除自訂相簿(導師刪學生相簿)
 * Ban Custom Group Album
 * @param {string} albumId
 *
 * @returns {object} status
 */

export const banCustomGroupAlbum = classId => async albumId => {
  const response = await Api.post(`/groups/${classId}/albums/${albumId}/ban`);
  return response;
};


/**
 * 取得相簿相片
 * Get Default Group Album Entries albumId=image or video
 * Get Custom Group Album Entries
 */
export const getGroupAlbumEntries = (classId, albumId) => async params => {
  const { nowPage = 0, rowsPage } = params;
  const queryParams = {
    limit: rowsPage ? rowsPage : 10,
  };
  if (rowsPage && nowPage * rowsPage !== 0) {
    queryParams.skip = nowPage * rowsPage;
  }
  const querystring = stringify(queryParams);
  const response = await Api.get(`/groups/${classId}/albums/${albumId}?${querystring}`);
  return response;
};


/**
 * 上傳相片
 * Add Group Album Entry
 * @param {string} content
 * @param {array of object	} attachments
 * @param {integer} publishedAt
 *
 * @return {object} status data
 */
export const addGroupAlbumEntry = (classId, albumId) => async params => {
  const { content, attachments, publishedAt } = params;
  const payload = {};
  if (content) payload.content = content;
  if (attachments.length > 0) payload.attachments = attachments;
  if (publishedAt) payload.publishedAt = publishedAt;
  const response = await Api.post(`/groups/${classId}/albums/${albumId}/posts`, payload);
  return response;

};

/**
 * Delete Custom Group Album Entry
 *
 * @param {string} albumId
 * @param {string} postId
 * @param {string} attachmentUrl
 *
 * @returns {object} status
 */
export const deleteCustomGroupAlbumEntry = async (classId, albumId, postId, pictureId) => {
  const response =
    await Api.delete(`/groups/${classId}/albums/${albumId}/posts/${postId}/attachments/${pictureId}`);
  return response;
};
