import { createGlobalStyle  } from 'styled-components';

export const BodyStyle = createGlobalStyle`
	html {
		font-size: 100%;

		/**
     *@16px = 100% = 1em
     */

		/* 1rem = 15px */
		${({ theme: { media } }) => media.desktop`
        font-size:93.75%;
      `}

		/* 1rem = 14px */
		${({ theme: { media } }) => media.notebook`
        font-size:87.5%;
      `}

		/* 1rem = 13px */
		${({ theme: { media } }) => media.pad`
        font-size:81.25%;
      `}

		/* 1rem = 12px */
		${({ theme: { media } }) => media.phone`
        font-size: 75%;
      `}
	}
`;

export const ButtonStyle = createGlobalStyle`
	button {
		border: 0;
		background-color: transparent;
	}
`;
