import { useEffect } from 'react';
import { useSetState } from 'utils/hooks/useSetState';
import { getGroupHost } from 'services/api/home/class';
import { convertArrayToMapById } from 'utils/array';

export const useMappingTeacher = classId => {
  const [{
    data,
    markersMap,
   }, setState] = useSetState({
   data: [],
   markersMap: null,
  });

 useEffect(() => {
   if (data.length <= 0 ) return;

   let markersArr = [];
   data.forEach(exam => {
     markersArr.push(...exam.markers);
   });
   markersArr = [...new Set(markersArr)];

   Promise.all(
     markersArr.map(id => {
       return getGroupHost(classId, id);
     })
   ).then(res => {
     const markers = res.filter(marker => marker.isSuccess).map( marker => marker.data);

     setState({
       markersMap: convertArrayToMapById(markers),
     });
   }).catch(err => { console.log('err');}); 
 }, [data]);

 return { markersMap, setState };
};