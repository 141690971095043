import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Tab, Table, Button } from 'components';
import { useCustomer } from 'store/customer';
import { useSessions } from 'store/sessions';
import { EXAM_PROJECT_NAME } from 'constants/index';
import { UiExamResultTable } from './ExamResultTable.style';

/**
 * 測驗結果列表
 */

 const schema = {
  seatNumber: {
    name: '座號',
    defaultValue: '-'
  },
  nickname: {
    name: '暱稱',
    defaultValue: '無'
  },
  summary: {
    name: '測驗結果',
    defaultValue: '-'
  }
};

const ExamType = {
  PreExam: 'PreExam',
  PostExam: 'PostExam'
};

// eslint-disable-next-line react/prop-types
const ExamTable = ({ examType = ExamType.PreExam }) => {
  const { sessionId } = useParams();
  const [{ sessions }] = useSessions();


  const [{ customers }, { getCustomers }] = useCustomer();
  const [{ nowPage, rowsPage }, setState] = useState({
    nowPage: 0,
    rowsPage: 10
  });

  const changePage_Rows = params => {
    const { newPage, newRowsPage } = params;
    setState({
      nowPage: newPage,
      rowsPage: newRowsPage
    });
  };

  useEffect(() => {
    getCustomers({ nowPage, rowsPage });
  },[nowPage,rowsPage]);

  const goToResult = useCallback((studentId) => {
    const session = sessions.dataMap[sessionId] || {};
    const range = examType === ExamType.PreExam ? session.preExamId : session.postExamId;
    window.open(
      // eslint-disable-next-line max-len
      `${process.env.REACT_APP_TEST_DOMAIN}/analysis.html?project=${EXAM_PROJECT_NAME}&range=${range}&user=${studentId}&roomId=${sessionId}`
    );
  },[examType]);

  const tableData = useMemo(() => {
    return customers.data.map((customer,index) => ({
      ...customer,
      seatNumber: customers.seatsData[index].num || null,
      summary: <Button onClick={() => goToResult(customer.id)}>開啟測驗結果</Button>
    }));
  },[customers.data, customers.seatsData, examType]);

  return (
    <Table
      data={tableData}
      schema={schema}
      changePage_Rows={changePage_Rows}
      totalPage={customers.total}
      nowPage={nowPage}
    />
  );
};


export const ExamResultTable = () => {
  const [tabData,setTabData] = useState([]);
  const [tabIndex,setTabIndex] = useState(0);
  const [{ sessions }] = useSessions();
  const history = useHistory();
  const { organizationId, classId, sessionId } = useParams();


  useEffect(() => {
    if(sessions.data.length < 1) {
      history.push(`/home/${organizationId}/class/${classId}/sessions`);
      return;
    }


    let tabs = [];
    if(sessions.dataMap[sessionId].preExamId){
      tabs.push( { label: '課前測驗', Component: () => null, examType: ExamType.PreExam });
    }

    if(sessions.dataMap[sessionId].postExamId){
      tabs.push({ label: '課後測驗', Component: () => null, examType: ExamType.PostExam });
    }

    setTabData(tabs);

  },[sessions]);

  return (
    <UiExamResultTable>
      <Tab data={tabData} getTabIndex={setTabIndex}/>
      <ExamTable examType={tabData[tabIndex]?.examType || ''}/>
    </UiExamResultTable>
  );
};

ExamResultTable.propTypes = {

};


