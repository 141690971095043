import libphonenumber from 'google-libphonenumber';

export const phoneNumberValid = (number) => {//+886987654321
  let isValidNumber = false;
  if (number.length < 6) return isValidNumber;

  const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
  const parseNumber = phoneUtil.parse(number);
  isValidNumber = phoneUtil.isValidNumber(parseNumber);
  return isValidNumber;
};
export const emailReg = /^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/;
