import React from 'react';
import { HomeGroupLayout, HomeworkSubmittedStudentTable } from 'components';

/**
 * 學生提交的作業列表頁面
 */

export const HomeworkSubmittedStudentPage = () => {
  return (
    <HomeGroupLayout>
      <HomeworkSubmittedStudentTable />
    </HomeGroupLayout>
  );
};

