import React from 'react';
import PropTypes from 'prop-types';
import { UiAlert } from './Alert.style';


/**
 * `type` could be `success`, `error`, `warning`, `info`, or `default`
 */

export const Alert = () => {
  return (
    <>
      <UiAlert />
    </>
  );
};

Alert.propTypes = {
  type: PropTypes.string,
  message: PropTypes.string,
  showAlert: PropTypes.bool
};


