import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  getSchools as getSchoolsApi,
  updateFreeGroup as updateFreeGroupApi,
  getSchoolById as getSchoolByIdApi,
} from 'services/api/home/school';
import { useAlert, ALERT_MESSAGE } from 'utils/hooks/useAlert';
import { convertArrayToMap } from 'utils/array';


const GET_SCHOOLS = 'GET_SCHOOLS';
const UPDATE_FREE_GROUP = 'UPDATE_FREE_GROUP';
const GET_SCHOOL_BY_ID = 'GET_SCHOOL_BY_ID';

const initState = {
  schools: {
    data: [],
    total: 0,
    dataMap: {},
  },
};

const actions = {
  getSchools: params => ({
    type: GET_SCHOOLS,
    payload: params,
  }),
  updateFreeGroup: params => ({
    type: UPDATE_FREE_GROUP,
    payload: { params },
  }),
  getSchoolById: data => ({
    type: GET_SCHOOL_BY_ID,
    payload: { data },
  })
};

export const useSchool = () => {
  const dispatch = useDispatch();
  const { schools } = useSelector(state => state.school);
  const { setAlert } = useAlert();
  const { classId } = useParams();
  /**
   *
   * 獲取學校列表
   */
  const getSchools = async params => {
    const { isSuccess, data } = await getSchoolsApi(params);
    if (!isSuccess) return;
    const { schools, total } = data;
    const dataMap = convertArrayToMap(schools, 'schoolId');
    dispatch(actions.getSchools({ data: schools, total, dataMap }));
  };

  /**
  *
  * 編輯獨立班級
  */
  const updateFreeGroup = async payload => {
    const { isSuccess, data, error } = await updateFreeGroupApi(classId)(payload);
    try {
      if (!isSuccess) throw error;
      dispatch(actions.updateFreeGroup(data));
      setAlert(ALERT_MESSAGE.UPDATE_SUCCESS, 'success');
    } catch (error) {
      const { errorCode } = error;
      setAlert(ALERT_MESSAGE.UPDATE_FAIL, 'error', errorCode);
    }
    return isSuccess;
  };

  /**
  *
  * schoolId取得班級資料
  */
  const getSchoolById = async schoolId => {
    const { isSuccess, data, error } = await getSchoolByIdApi(schoolId);
    try {
      if (!isSuccess) throw error;
      dispatch(actions.getSchoolById(data));
      return data;
    } catch (error) {
      const { errorCode } = error;
      setAlert(ALERT_MESSAGE.GET_SCHOOL_FAIL, 'error', errorCode);
    }
    return isSuccess;
  };

  return [
    { schools }, // state
    { getSchools, updateFreeGroup, getSchoolById }, // eventHanlder
  ];
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case GET_SCHOOLS: {
      const { data, dataMap, total } = action.payload;
      return {
        ...state,
        schools: { data, dataMap, total },
      };
    }
    default:
      return state;
  }
};

export default reducer;
