import { stringify } from 'query-string';
import { Api } from 'services/api';

/**
 -@param {string} organizationId
 *@param {string} classId
 *@param {string} hostId
 *@param {string} resourceId
 *@param {string} name
 *@param {number} startAt
 -@param {number} endAt
 *@創建課程
 */
export const createOrganizationGroupSession = (organizationId, classId) => async params => {
  const { data } = params;
  const response = await Api.post(`/organizations/${organizationId}/groups/${classId}/sessions`, data);
  return response;
};

export const getOrganizationSession = (organizationId, classId) => async params => {
  const {
    sessionName,
    groupName,
    hostName,
    hostMobileNumber,
    startAt,
    endAt,
    nowPage,
    rowsPage } = params;
  const queryParams = {
    limit: rowsPage ? rowsPage : 10,
  };
  if (nowPage * rowsPage !== 0) {
    queryParams.skip = nowPage * rowsPage;
  }
  if (sessionName) queryParams.sessionName = sessionName;
  if (classId) {
    queryParams.groupId = classId;
  } else if (groupName) {
    queryParams.groupName = groupName;
  }
  if (hostName) queryParams.hostName = hostName;
  if (hostMobileNumber) queryParams.hostMobileNumber = hostMobileNumber;
  if (startAt) queryParams.startAt = startAt;
  if (endAt) queryParams.endAt = endAt;
  const querystring = stringify(queryParams);
  const response = await Api.get(`/organizations/${organizationId}/sessions?${querystring}`);
  return response;
};

/**
 *  更新 課程資訊
 * @param {string} hostId
 * @param {string} name
 * @param {string} timeSpanId
 * @param {number} startAt
 * @param {number} endAt
 * @param {string} resourceId
 */
export const updateOrganizationGroupSession = (organizationId, classId, sessionId) => async params => {
  const { data } = params;
  const response =
    await Api.put(`/organizations/${organizationId}/groups/${classId}/sessions/${sessionId}`, data);
  return response;
};



export const getSessionSummary = (organizationId, sessionId, timeSpanId) => async () => {
  const response =
    await Api.get(`/organizations/${organizationId}/sessions/${sessionId}/time-span/${timeSpanId}/dashboard/summary`);
  return response;
};

export const changeSessionRecordingStatus = (organizationId, sessionId, timeSpanId) => async (payload) => {
  const response =
    await Api.post(`/organizations/${organizationId}/sessions/${sessionId}/time-span/${timeSpanId}/recording`,payload);
  return response;
};

export const deleteOrganizationGroupSession = (organizationId,classId,sessionId,timeSpanId) => async () => {
  // eslint-disable-next-line max-len
  const response = await Api.delete(`/organizations/${organizationId}/groups/${classId}/sessions/${sessionId}/time-span/${timeSpanId}`);
  return response;
};

/**
 * 寫入課堂報告
 * @param {string} sessionId 課程ＩＤ
 * @param {string} timeSpanId
 * 
 * @returns {object} 
    https://asia-east2-oneclass-test.cloudfunctions.net/
      sessions/docs/#/Session%20Report/post__sessionId__time_span__timeSpanId__reports
 */
export const keepSessionReport = async ({sessionId, timeSpanId}) => {
  const response = await Api.post(`/sessions/${sessionId}/time-span/${timeSpanId}/reports`);
  return response;
};

/**
 * 課堂報告
 * @param {string} organizationId 機構ＩＤ
 * @param {string} sessionId 課程ＩＤ
 * @param {string} timeSpanId
 * @param {number} section summary(摘要), attendance(上課記錄), performance(課堂表現), screenshot(課堂截圖)
 * 
 * @returns {object} 
    https://asia-east2-oneclass-test.cloudfunctions.net/
      sessions/docs/#/Session%20Report/
      get__sessionId__time_span__timeSpanId__reports__section_
 */
  export const getSessionReport = async ({sessionId, timeSpanId, section}) => {
    const response = await 
      Api.get(`/sessions/${sessionId}/time-span/${timeSpanId}/reports/${section}`);
    return response;
  };
