import React from 'react';
import { OrganizationResourceTable } from 'components';
import { UiOrganizationResourcePage } from './OrganizationResourcePage.style';


/**
 * 組織 教材列表頁
 */

export const OrganizationResourcePage = () => {
  return (
    <UiOrganizationResourcePage>
      <OrganizationResourceTable/>
    </UiOrganizationResourcePage>
  );
};

