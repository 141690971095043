import React, { useEffect, useMemo } from 'react';
import { UiTable } from 'styles';
import { Table } from 'components';
import { useOrganization } from 'store/organization';
import { useSetState } from 'utils/hooks/useSetState';
import { formatTimestamp } from 'utils/date';

/**
 * 教師管理邀請中列表
 */

const schema = {
  nickname: {
    name: '教師暱稱',
    defaultValue: '',
  },
  lastInvitedAt: {
    name: '邀請時間',
    defaultValue: '無'
  }
};

export const BsTeacherManagementInvitingTable = () => {
  const [{ invitations },{ getOrganizationInvitations  }] = useOrganization();
  const [{ nowPage, rowsPage }, setState] = useSetState({
    nowPage: 0,
    rowsPage: 10,
  });

  useEffect(() => {
    const params = {
      nowPage,
      rowsPage,
      role: 'staff'
    };
    getOrganizationInvitations(params);
  }, [nowPage, rowsPage]);

  const changePage_Rows = params => {
    const { newPage, newRowsPage } = params;
    setState({
      nowPage: newPage,
      rowsPage: newRowsPage
    });
  };

  const invitationsData = useMemo(() =>
  invitations.data.map(item => ({ ...item, lastInvitedAt: formatTimestamp(item.lastInvitedAt, 'yyyy-MM-dd HH:mm:ss') }))
  ,[invitations.data]);

  return (
    <UiTable>
       <Table
        data={invitationsData}
        schema={schema}
        changePage_Rows={changePage_Rows}
        totalPage={invitations.total}
        nowPage={nowPage}
      />
    </UiTable>
  );
};



