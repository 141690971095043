import React from 'react';
import PropTypes from 'prop-types';
import { UiChip } from './Chip.style';
import { Icon } from 'components';


/**
 * 芯片，又名tag
 */

export const Chip = ({ id, icon, label, onDelete }) => {
  const handleDelete = () => {
    onDelete(id);
  };

  return (
    <UiChip
      icon={icon ? <Icon name={icon} /> : ''}
      label={label}
      onDelete={onDelete ? handleDelete : ''}
    />
  );
};

Chip.propTypes = {
  id: PropTypes.string,
  icon: PropTypes.string,
  label: PropTypes.string,
  onDelete: PropTypes.func,
};


