import React from 'react';
import SVG from './svg';
import Tooltip from '@material-ui/core/Tooltip';
import { UiSvgIcon } from './Icon.style';


export const IconSvg = ({ className,name,color,onClick,title }) => {
  return (
    title ?
    <Tooltip title={title}>
      <UiSvgIcon onClick={onClick} className={className} component={SVG[name]} color={color}/>
    </Tooltip>:
    <UiSvgIcon onClick={onClick} className={className} component={SVG[name]} color={color}/>
  );
};
