import React from 'react';
import { HomeGroupLayout, HomeInvite } from 'components';
import { UiHomeInvitePage } from './HomeInvitePage.style';


/**
 * 邀請頁面
 */

export const HomeInvitePage = () => {
  return (
    <HomeGroupLayout>
      <UiHomeInvitePage>
        <HomeInvite />
      </UiHomeInvitePage>
    </HomeGroupLayout>
  );
};
