import { stringify } from 'query-string';
import { Api } from 'services/api';

const baseUrl = process.env.REACT_APP_EXAM_TEST_DOMAIN;


export const getTeacherExamList = async (params) => {
  const querystring = stringify(params);
  const response = await Api.get(`${baseUrl}/quiz/list/teacher?${querystring}`);
  return response;
};

export const getStudentExamList = async (params) => {
  const querystring = stringify(params);
  const response = await Api.get(`${baseUrl}/quiz/list/student?${querystring}`);
  return response;
};

export const updateExamStatus = async payload => {
  const response = await Api.post(`${baseUrl}/quiz/status`,payload);
  return response;
};
