import { Api } from 'services/api';

/**
 * 取得 鄉鎮市
 * 
 */
 export const getTownship = async () => {
  const response = await Api.get(`/presets/township`);
  return response;
};
