import React, { useEffect, useRef } from 'react';
import { useUser } from 'store/user';
import { useClass } from 'store/class';
import { useParams } from 'react-router-dom';
import { useInfiniteScroll } from 'react-infinite-scroll-hook';
import { useHomework } from 'store/homework';
import { HomeworkCard, Button, Select, Checkbox, EmptyPrompt, Loading, HomeTitle } from 'components';
import { format, getTime } from 'date-fns';
import { useAlert } from 'utils/hooks/useAlert';
import { useFirebaseStorage } from 'utils/hooks/useFirebaseStorage';
import { useUuid } from 'utils/hooks/useUuid';
import { useServerTime } from 'utils/hooks/useServerTime';
import { useSetState } from 'utils/hooks/useSetState';
import { ORGANIZATION_SETTINGS_VALUE } from 'constants/index';
import {
  UiHomeworkList,
  UiCardBox,
  UiButtonBox,
  UifilterBox,
  UifilterRow,
  UiCheckBox,
} from './HomeworkList.style';
import forest from 'assets/images/forest.svg';

/**
 * 作業列表
 */


const selectProps = {
  label: '選擇排序方式',
  options: [
    {
      name: '截止日',
      value: 'dueAt'
    },
    {
      name: '發布日期',
      value: 'publishedAt'
    }
  ],

};

export const HomeworkList = () => {
  const { upload } = useFirebaseStorage();
  const { setAlert } = useAlert();
  const [{ myClasses }] = useClass();
  const [{ myOrganization, profile }] = useUser();
  const [{ timestamp: serverTimestamp }] = useServerTime();
  const { organizationId, classId } = useParams();
  const [getUuid] = useUuid();
  const isFirstRun = useRef(true);
  const [
    { homeworks },
    { getHomeworks,
      clearHomeworks,
      createHomework,
      updateHomework,
      pinHomework,
      cancelPinHomework,
      deleteHomework,
      submitStudentHomework }
  ] = useHomework();
  const [{
    isLoading,
    listLoading,
    hasNextPage,
    showEdit,
    userType,
    userId,
    isAdmin,
    nowPage,
    rowsPage,
    updateHomeworkData,
    HomeworkDataList,
    sortKey,
    isSubmitted,
    userAvatar,
    userNickname
  }, setState
  ] = useSetState({
    isLoading: false,
    listLoading: false,
    hasNextPage: true,
    showEdit: false,
    userType: 'student',
    userId: '',
    isAdmin: false,
    nowPage: 0,
    rowsPage: 10,
    updateHomeworkData: [],
    HomeworkDataList: [],
    sortKey: 'publishedAt',
    isSubmitted: '',
    userAvatar: '',
    userNickname: '',
  });
  //滾動加載觸發後事件
  const handleLoadMore = async () => {
    setState({
      listLoading: true
    });
    const page = await getHomeworks({ nowPage, rowsPage, sortKey, isSubmitted });
    const pageLength = page.missions && page.missions.length;
    let isHasNextPage = ((nowPage * rowsPage) + pageLength) >= page.total ? false : true;

    setState({
      HomeworkDataList: HomeworkDataList.length > 0 ? HomeworkDataList.concat(homeworks.data) : homeworks.data,
      listLoading: false,
      nowPage: nowPage + 1,
      hasNextPage: isHasNextPage
    });
  };
  //滾動加載trigger
  const infiniteRef = useInfiniteScroll({
    loading: listLoading,
    hasNextPage,
    onLoadMore: handleLoadMore
  });
  /**
   * 重取作業列表
   */
  const reloadHomeworkList = () => {
    clearHomeworks();
    setState({
      nowPage: 0,
      hasNextPage: true,
      sortKey,
      isSubmitted,
      isLoading: false
    });
  };
  /**
   * 新增作業 更改排序
   */
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    reloadHomeworkList();
  }, [updateHomeworkData, isSubmitted, sortKey]);
  /**
   * 權限取得
   */
  useEffect(() => {
    const { id: userId, nickname, thumbnailUrl } = profile;//用戶ID
    const { dataInfo: { isOwner, userType } } = myClasses;
    if (organizationId && myOrganization.isLoaded) {
      let targetThumbnailUrl = '';
      const {
        userProfile: { nickname: OrgNickname, thumbnailUrl: OrgThumbnailUrl, role },
        organization: { staffProfileOriginSetting, customerProfileOriginSetting } } = myOrganization;
      if (role === 'staff') {
        targetThumbnailUrl =
          staffProfileOriginSetting === ORGANIZATION_SETTINGS_VALUE.DEFAULT ? thumbnailUrl : OrgThumbnailUrl;
      } else {
        targetThumbnailUrl =
          customerProfileOriginSetting === ORGANIZATION_SETTINGS_VALUE.DEFAULT ? thumbnailUrl : OrgThumbnailUrl;
      }
      setState({
        isAdmin: isOwner,
        userType,
        userId,
        userNickname: OrgNickname,
        userAvatar: targetThumbnailUrl,
      });
    } else {
      setState({
        isAdmin: isOwner,
        userType,
        userId,
        userNickname: nickname,
        userAvatar: thumbnailUrl,
      });
    }
  }, [myOrganization, myClasses]);
  /**
   * 教師更新作業
   */
  const doUpdateHomework = async data => {
    const responseData = await updateHomework(data);
    setState({ isLoading: false });
    if (!responseData) return;
    setState({
      updateHomeworkData: responseData
    });
  };
  /**
   * 學生上傳作業
   */
  const doSubmitStudentHomework = async data => {
    const responseData = await submitStudentHomework(data);
    if (!responseData) return;
    setState({
      updateHomeworkData: responseData
    });
  };
  /**
   * 上傳檔案
   */
  const onUploaded = async (prevHomeworkData, file, userType = 'teacher') => {
    setState({ isLoading: true });
    if (file.length >= 1 && userType === 'student') {
      const homeworkData = homeworks.dataMap[prevHomeworkData.id];
      const submissions = homeworkData.submissions || false;
      const userSubmissions = submissions ? homeworkData.submissions[userId] : null;
      const { submitCount } = userSubmissions ? userSubmissions : {};
      const fileName = `${homeworkData.title}_${userNickname}_${submitCount || 1}_`;
      await Promise.all(file.map(async item => {
        // eslint-disable-next-line max-len
        const uploadPath = `${organizationId}/groups/${classId}/missions/${fileName}${getUuid()}_${item.name}`;
        const { status, url } = await upload(uploadPath, item);
        if (status) {
          return {
            attachmentUrl: url,
            attachmentName: item.name
          };
        }
      })).then(res => {
        prevHomeworkData.attachments = [...(prevHomeworkData.attachments || []), ...res];
        setAlert('上傳成功!', 'success');
      }).catch(res => {
        setAlert('上傳失敗!', 'error');
      });
    }

    if (file.length >= 1 && userType !== 'student') {
      await Promise.all(file.map(async item => {
        // eslint-disable-next-line max-len
        const uploadPath = `${organizationId}/groups/${classId}/missions/${getUuid()}_${item.name}`;
        const { status, url } = await upload(uploadPath, item);
        if (status) {
          return {
            attachmentUrl: url,
            attachmentName: item.name
          };
        }
      })).then(res => {
        prevHomeworkData.attachments = [...(prevHomeworkData.attachments || []), ...res];
        setAlert('上傳成功!', 'success');
      }).catch(res => {
        setAlert('上傳失敗!', 'error');
      });
    }


    /**
     * 教師先上傳作業後才上傳檔案然後把url更新進作業中
     * 學生先上傳檔案 在上傳作業
     */
    if (userType === 'student') {
      await doSubmitStudentHomework(prevHomeworkData);
    } else {
      await doUpdateHomework(prevHomeworkData);
    }
    setState({ isLoading: false });
  };
  /**
   * 教師創建作業
   */
  const onSubmitHandler = async data => {
    setState({ isLoading: true });
    const payload = {
      ...data
    };
    await Promise.all(payload.file.map(async item => {
      // eslint-disable-next-line max-len
      const uploadPath = `${organizationId}/groups/${classId}/missions/${getUuid()}_${item.name}`;
      const { status, url } = await upload(uploadPath, item);
      if (status) {
        return {
          attachmentUrl: url,
          attachmentName: item.name
        };
      }
    })).then(res => {
      payload.attachments = [...(payload.attachments || []), ...res];
      setAlert('上傳成功!', 'success');
    }).catch(res => {
      setAlert('上傳失敗!', 'error');
    });

    delete payload.file;

    const responseData = await createHomework(payload);
    setState({ isLoading: false });
    if (!responseData) return;
    setState({
      showEdit: false,
      prevHomework: responseData,
      nowPage: 0,
      updateHomeworkData: responseData
    });
  };

  /**
   * 學生交作業
   */
  const onSubmitHomeworkHandler = async value => {
    const { data, file, comment, attachments } = value;
    const nextFile = file || [];
    const params = {
      groupId: classId,
      id: data.id,
      comment,
      attachments,
    };
    setState({
      isLoading: true,
      nowPage: 0
    });
    await onUploaded(params, nextFile, 'student');
    setState({
      isLoading: false
    });
  };
  //顯示新增作業
  const showEditCard = () => {
    setState({
      showEdit: !showEdit
    });
  };

  //置頂
  const onPinHandler = async data => {
    await pinHomework(data.id);
    reloadHomeworkList();
  };

  const onCancelPinHandler = async data => {
    await cancelPinHomework(data.id);
    reloadHomeworkList();
  };
  //刪除
  const onDeleteHandler = async data => {
    await deleteHomework(data.id);
    reloadHomeworkList();
  };

  const onCSelecthangeHandler = value => {
    setState({ sortKey: value });
  };
  //隱藏已交作業
  const onCheckboxChange = val => {
    setState({
      isSubmitted: val === 'hide' ? '0' : ''//1是顯示已繳交，0 是顯示未繳交 不帶才是全顯示
    });
  };

  const getOpenState = (isOpen) => {
    setState({
      showEdit: isOpen
    });
  };

  return (
    <UiHomeworkList ref={infiniteRef}>

      <UifilterRow>
        <HomeTitle title={'作業'} />
        <UifilterBox>
          <Select
            options={selectProps.options}
            submitHandler={value => onCSelecthangeHandler(value)}
            label={selectProps.label}
          />
        </UifilterBox>
        <UiCheckBox>
          {
            userType === 'student' &&
            <Checkbox
              onChange={onCheckboxChange}
              label="隱藏已交作業"
              checkedValue="hide"
            />
          }
          {
            userType === 'teacher' &&
            <UiButtonBox>
              <Button
                onClick={showEditCard}
                icon='add'
              >新增作業</Button>
            </UiButtonBox>
          }
        </UiCheckBox>
      </UifilterRow>

      <UiCardBox>
        <HomeworkCard
          userType={userType}
          type='edit'
          userAvatar={userAvatar}
          userNickname={userNickname}
          isLoading={isLoading}
          isOpen={showEdit}
          getOpenState={getOpenState}
          onSubmitCard={onSubmitHandler}
        />
      </UiCardBox>

      {(!listLoading && homeworks.data.length === 0) && <EmptyPrompt img={forest} message="目前暫無作業" messageColor={'#8B90A0'} />}
      <UiCardBox>
        {
          homeworks.data.length > 0 && homeworks.data.map(item => ({
            ...item,
            number: item.submittedUsers ? item.submittedUsers.length : 0,
            total: item.groupUsers ? item.groupUsers.length : 0,
            formatPublishedAt: (item.publishedAt) && format(new Date(item.publishedAt), 'yyyy-MM-dd HH:mm:ss'),
            formatDueAt: (item.dueAt && item.dueAt !== '無限期') ?
              format(new Date(item.dueAt), 'yyyy-MM-dd HH:mm:ss') :
              '無限期',
            description: item.description?.replace(/(\r\n|\n|\r)/gm, '<br />'),
            //繳交狀況 刪除權限(導師都可 教師只能操作自己的)
            canOperating: (item.ownerId === userId || isAdmin) ? true : false,
            //編輯權限 (都只能操作自己的)
            canEdit: (item.ownerId === userId) ? true : false,
            isExpired: (
              item.dueAt &&
              (!item.isLateSubmissionAllowed ||
                (item.submissions && Object.prototype.hasOwnProperty.call(item.submissions, userId))
              ) &&
              (getTime(new Date(item.dueAt)) < serverTimestamp)
            ) ? true :
              false
          })).map(homework => (
            <HomeworkCard
              key={homework.id}
              userType={userType}
              isAdmin={isAdmin}
              userAvatar={userAvatar}
              userNickname={userNickname}
              data={homework}
              onPin={onPinHandler}
              onCancelPin={onCancelPinHandler}
              onDelete={onDeleteHandler}
              onSubmitCradRead={onSubmitHomeworkHandler}
              onUploaded={onUploaded}
              isLoading={isLoading}
            />
          ))
        }

      </UiCardBox>
      {listLoading && <Loading />}

    </UiHomeworkList>
  );
};

HomeworkList.propTypes = {

};

