import React, { useEffect, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { TextField as MatTextField } from '@material-ui/core';
import { Select, PhoneForm, Form, Button, Modal } from 'components';
import { useCustomer } from 'store/customer';
import { useUser } from 'store/user';
import { useSetState } from 'utils/hooks/useSetState';
import { emailReg, phoneNumberValid } from 'utils/validation';
import { EDIT_STATUS } from 'constants/index';
import { useAlert } from 'utils/hooks/useAlert';
import { UiCustomerForm, UiCoustomerButtonBox } from './CustomerForm.style';


/**
 * 邀請學生Form
 */

const CUSTOMER_KEY = {
  USERID: 'userId',
  EMAIL: 'email',
  MOBILE_NUMBER: 'mobileNumber'
};



export const CustomerForm = ({ editStatus }) => {
  const [{
    data,
    isLoading,
    selectOption,
    blackList,
    isCreating,
    modalState
  }, setState] = useSetState({
    data: null,
    isLoading: editStatus === EDIT_STATUS.CREATE ? false : true,
    selectOption: CUSTOMER_KEY.USERID,
    blackList: [],
    isCreating: editStatus === EDIT_STATUS.CREATE,
    modalState: false
  });
  const history = useHistory();
  const { setAlert } = useAlert();
  const { organizationId, customerId } = useParams();
  const [, { changeUserRole }] = useUser();
  const [
    { customer },
    { inviteCustomers, getCustomer, updateCustomer }
  ] = useCustomer();

  const schema = {
    [CUSTOMER_KEY.USERID]: {
      component: MatTextField,
      elementProps: {
        label: '學生 id',
        value: '',
        helperText: ''
      },
    },
    [CUSTOMER_KEY.EMAIL]: {
      component: MatTextField,
      elementProps: {
        label: 'email',
        value: '',
        helperText: '',
      },
      rules: {
        required: {
          value: true,
          message: '本欄位為必填'
        },
        pattern: {
          value: emailReg,
          message: 'mail 格式錯誤',
        }
      }
    },
    [CUSTOMER_KEY.MOBILE_NUMBER]: {
      component: PhoneForm,
      elementProps: {
        label: '聯絡電話',
        defaultPhoneNum: '',
        defaultCountryNum: '',
        helperText: '',
        onChange: n => {
          if (n[1]) {
            return (n[0] + n[1]);
          }
          return null;
        }
      },
      rules: {
        required: {
          value: true,
          message: '本欄位為必填'
        },
        validate: value => {
          if (!value) return;
          return phoneNumberValid(value) || '電話格式錯誤';
        }
      }
    },
    nickname: {
      component: MatTextField,
      elementProps: {
        label: '暱稱',
        helperText: '',
      },
      rules: {
        required: {
          value: true,
          message: '本欄位為必填'
        }
      }
    },
  };

  const buttons = [
    {
      text: '確認',
      type: 'submit'
    },
    {
      text: '回到上一頁',
      color: 'info',
      icon: 'exitToApp',
      func: () => { history.push(`/organization/${organizationId}/customer`); }
    }
  ];

  const selectProps = {
    label: '註冊方式',
    options: [
      {
        name: '學生 id',
        value: CUSTOMER_KEY.USERID
      },
      {
        name: '手機號碼',
        value: CUSTOMER_KEY.MOBILE_NUMBER
      },
      {
        name: 'email',
        value: CUSTOMER_KEY.EMAIL
      }
    ],
    submitHandler: value => {
      setState({ selectOption: value });
    }
  };

  const submitformHandler = async data => {
    if (editStatus === EDIT_STATUS.READ) {
      history.push(`/organization/${organizationId}/customer`);
    }
    setState({ isLoading: true });
    let isSuccess;
    // 新增
    if (editStatus === EDIT_STATUS.CREATE) {
      const { nickname } = data;
      isSuccess = await inviteCustomers({
        customers: [
          {
            [selectOption]: data[selectOption],
            nickname
          }
        ],
      });
    }

    // 編輯
    if (editStatus === EDIT_STATUS.EDIT) {
      let isValidNumber = false;
      let isValidEmail = false;

      if (data.mobileNumber) {
        isValidNumber = phoneNumberValid('+' + data.mobileNumber);
        if (!isValidNumber) {
          setAlert('電話格式錯誤', 'error');
          setState({ isLoading: false });
          return;
        }
      }

      if (data.email) {
        isValidEmail = emailReg.test(data.email);
        if (!isValidEmail) {
          setAlert('mail格式錯誤', 'error');
          setState({ isLoading: false });
          return;
        }
      } else {
        delete data.email;
      }
      const formData = {};
      if (data.email) formData.email = data.email;
      if (data.mobileNumber) formData.mobileNumber = data.mobileNumber;
      if (data.nickname) formData.nickname = data.nickname;
      isSuccess = await updateCustomer(formData);
    }

    setState({ isLoading: false });
    if (isSuccess) history.push(`/organization/${organizationId}/customer`);
  };

  useEffect(() => {
    let nextBlackList = [];

    if (!isCreating) {
      setState({ blackList: nextBlackList });
      return;
    }

    switch (selectOption) {
      case CUSTOMER_KEY.USERID:
        nextBlackList = [CUSTOMER_KEY.EMAIL, CUSTOMER_KEY.MOBILE_NUMBER];
        break;
      case CUSTOMER_KEY.EMAIL:
        nextBlackList = [CUSTOMER_KEY.USERID, CUSTOMER_KEY.MOBILE_NUMBER];
        break;
      case CUSTOMER_KEY.MOBILE_NUMBER:
        nextBlackList = [CUSTOMER_KEY.USERID, CUSTOMER_KEY.EMAIL];
        break;
      default:
        break;
    }

    setState({ blackList: nextBlackList });
  }, [selectOption]);


  const nextData = useCallback(() => {
    // 不可被編輯的欄位
    const blackList = [CUSTOMER_KEY.USERID];
    let isRead = false;
    // 根據網址狀態判斷
    switch (editStatus) {
      // 編輯
      case EDIT_STATUS.EDIT:
        if (!customer) return;
        break;
      // 檢視
      case EDIT_STATUS.READ:
        isRead = true;
        if (!customer) return;
        break;
      case EDIT_STATUS.CREATE:
      default:
        return schema;
    }

    // schema 比對
    const newData = Object.assign({}, schema);
    Object.entries(newData).forEach(([key]) => {

      if (!customer['email']) {
        delete newData['email'].rules;
      }

      if (!customer['mobileNumber']) {
        delete newData['mobileNumber'].rules;
      }

      if (key === CUSTOMER_KEY.USERID) {
        newData[key].elementProps.defaultValue = customer['id'];
      } else {
        newData[key].elementProps.defaultValue = customer[key];
      }

      if (editStatus === EDIT_STATUS.READ) {
        newData[key].elementProps.disabled = isRead;
      } else {
        newData[key].elementProps.disabled = blackList.includes(key) ? !isRead : isRead;
      }
    });

    return newData;
  }, [data, editStatus, schema, customer]);

  //Modal buttons
  const modalButtons = [
    {
      text: '確認',
      func: async () => {
        await changeUserRole().then(isSuccess => {
          isSuccess && history.push(`/organization/${organizationId}/teachers`);
        });
      }
    },
    {
      text: '取消',
      color: 'highlight',
      func: () => { }
    }
  ];

  const openModal = () => {
    setState({
      modalState: true
    });
  };

  const getModalStateHandler = state => {
    setState({
      modalState: state
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      await getCustomer();
      setState({ isLoading: false });
    };
    if (!customerId) return;
    fetchData();
  }, [customerId]);

  return (
    <UiCustomerForm>
      <UiCoustomerButtonBox>
        <Button onClick={openModal}>修改權限為教師</Button>
      </UiCoustomerButtonBox>
      {
        isCreating && (
          <Select
            label={selectProps.label}
            value={selectOption}
            options={selectProps.options}
            submitHandler={selectProps.submitHandler}
          />
        )
      }
      <Form
        schema={!isLoading ? nextData() : {}}
        isLoading={isLoading}
        onSubmit={submitformHandler}
        buttons={buttons}
        blackList={blackList}
      />
      <Modal
        isOpen={modalState}
        text="確定要修改此使用者權限為教師"
        buttons={modalButtons}
        getModalState={getModalStateHandler}
      />
    </UiCustomerForm>
  );
};


