import React from 'react';
import PropTypes from 'prop-types';

import { Icon } from 'components';
import {
  UiHeartbeatPacketBar,
  UiBar,
  UiDot
} from './HeartbeatPacketBar.style';


/**
 * 在這邊描述這個組件
 */

// eslint-disable-next-line react/prop-types
const Dot = ({ active = false, left = 0 }) => (
  <UiDot
    active={active}
    left={left}
  >
    <Icon name="info" size="inherit" />
  </UiDot>
);

export const HeartbeatPacketBar = ({
  dots = []
}) => {
  return (
    <UiHeartbeatPacketBar>
      <UiBar>
        {
          dots.map((dot, index) => <Dot key={index} active={dot.active} left={dot.left} />)
        }
      </UiBar>
    </UiHeartbeatPacketBar>
  );
};

HeartbeatPacketBar.propTypes = {
  dots: PropTypes.arrayOf(PropTypes.shape({
    active: PropTypes.bool,
    left: PropTypes.number
  })),
  lines: PropTypes.arrayOf(PropTypes.shape({
    left: PropTypes.number
  }))
};


