import { LoginPage, CustomerServicePage, HomeRulePage } from 'components';
import { COMMON_PATH } from './constants';

import { FORGETPASSWORD, REGISTER, RULE } from 'constants/index';

const routes = [
  {
    path: COMMON_PATH.LOGIN,
    component: LoginPage,
    exact: true
  },
  {
    path: `/${FORGETPASSWORD}`,
    component: CustomerServicePage,
    exact: true,
    serviceStatus: FORGETPASSWORD,
  },
  {
    path: `/${REGISTER}`,
    component: CustomerServicePage,
    exact: true,
    serviceStatus: REGISTER,
  },
  {
    path: `/${RULE}`,
    component: HomeRulePage,
    exact: true
  },
];

export default routes;
