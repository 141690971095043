import React, { useEffect, useState, useMemo, } from 'react';
import PropTypes from 'prop-types';
import ContentTabs from './ContentTabs';
import ContentSearch from './ContentSearch';
import ContentListAll from './ContentListAll';
import ContentListChecked from './ContentListChecked';

import {
  Button,
  Loading,
} from 'components';
import { getPrivateResources as getPrivateResourcesApi } from 'services/api/home/users';
import { useResource } from 'store/resource';
import {
  UiContent,
  UiListWrapper,
  UiListAllContainer,
  UiListCheckedContainer,
  UiActionsWrapper,
} from './ResourceModalContent.style';

export const ResourceModalContent = ({
  checkedCheckbox,
  hostId,
  isCheckedCheckboxFull,
  onChangeCheckbox,
  onChangeUncheckCheckbox,
  onClickClose,
  onClickSubmit,
}) => {
  const [{
    resources,
    bookstoreBooks,
  }, { 
    getResourcesList,
    getBookstoreBooks,
  }] = useResource();
  const [isLoading, setIsLoading] = useState(true);
  const [isDidMount, setIsDidMount] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);            // tab index
  const [fileName, setFileName] = useState(null);         // 機構資源 - 搜尋後文字
  const [displayName, setDisplayName] = useState(null);   // 公開資源 - 搜尋後文字
  const [selectedOption, setSelectedOption] = useState({  // 公開資源 - 選擇後選項
    schoolYear: '',
    educationalSystem: '',
    grade: '',
    subject: ''
  });
  const [privateResources, setPrivateResources] = useState([]);           // 我的資源
  const [privateResourcesPage, setPrivateResourcesPage] = useState(0);    // 我的資源 - 頁碼
  const [privateResourcesRows, setPrivateResourcesRows] = useState(10);   // 我的資源 - 每頁列數
  const [privateResourcesTotal, setPrivateResourcesTotal] = useState(0);  // 我的資源 - 總頁數

  /* checkbox 列表 - 機構資源 */
  const checkboxListOrganization = resources.data.map(resource => ({
      id: resource.resourceId,
      name: resource.resourceId,
      label: resource.displayName,
      isChecked: checkedCheckbox.some((obj, index)=>{
        if(obj.id === resource.resourceId) return true;
        else if(index === checkedCheckbox.length - 1) return false;
      })
    })
  );

  /* checkbox 列表 - 公開資源 */
  const checkboxListPublic = bookstoreBooks.data.map(book => ({
      id: book.bookId,
      name: book.bookId,
      label: book.displayName,
      isChecked: checkedCheckbox.some((obj, index)=>{
        if(obj.id === book.bookId) return true;
        else if(index === checkedCheckbox.length - 1) return false;
      })
    })
  );

  /* checkbox 列表 - 我的資源 */
  const checkboxListPrivate = privateResources && privateResources.map(resource => ({
      id: resource.resourceId,
      name: resource.resourceId,
      label: resource.displayName,
      isChecked: checkedCheckbox.some((obj, index)=>{
        if(obj.id === resource.resourceId) return true;
        else if(index === checkedCheckbox.length - 1) return false;
      })
    })
  );

  /* enter 搜尋按鈕，設定搜尋參數 */
  const searchInputChangeHandler = (inputValue) => {
    tabIndex === 0 && setFileName(inputValue);
    tabIndex === 1 && setDisplayName(inputValue);
  };

  /* click 搜尋按鈕，設定搜尋參數 */
  const searchInputClickHandler = (inputValue) => {
    tabIndex === 0 && setFileName(inputValue);
    tabIndex === 1 && setDisplayName(inputValue);
  };

  /* change tab 選擇狀況 */
  const tabChangeHandler = (index) => {
    setTabIndex(index);
  };

  /* select 選擇後 */
  const selectChangeHandler = (key) => (value) => {
    let newSelectedOption = { ...selectedOption };
    newSelectedOption[key] = value;
    setSelectedOption(newSelectedOption);
  };

  /* 打API，取得機構資源 */
  const fetchResourceOrganization = async () => {
    isDidMount && setIsLoading(true);
    const params = { fileName: fileName };
    await getResourcesList(params);
    isDidMount && setIsLoading(false);
  };

  /* 打API，取得公開資源 */
  const fetchResourcePublic = async () => {
    isDidMount && setIsLoading(true);
    const params = {
      nowPage: 0,
      rowsPage: 100,
      displayName,
      tags: Object.values(selectedOption).filter(item => item)
    };
    await getBookstoreBooks(params);
    isDidMount && setIsLoading(false);
  };

  /* change privateResource 頁碼 */
  const changePrivateResourcePageHandler = (event, newPage) => {
    setPrivateResourcesPage(newPage);
    // setState({
    //   privateResourcesPage:newPage
    // });
  };

  /* change privateResource 每頁列數 */
  const changePrivateResourceRowsPerPageHandler = (event) => {
    
    setPrivateResourcesPage(0);
    setPrivateResourcesRows(parseInt(event.target.value, 10));
    // setState({
    //   privateResourcesPage:0,
    //   privateResourcesRows:parseInt(event.target.value, 10),
    // });
  };

  /* 打API，取得我的資源 */
  const fetchResourcePrivate = async () => {
    isDidMount && setIsLoading(true);
    const params = {
      nowPage: privateResourcesPage,
      rowsPage:privateResourcesRows
    };
    
    const { data, isSuccess } = await getPrivateResourcesApi(params);
    if(isSuccess){
      const resourcesData = data.resources.map(resource=>{
        return {
          ...resource,
          id: resource.resourceId,
          title:resource.displayName,
        };
      });
      setPrivateResources(resourcesData);
      setPrivateResourcesTotal(data.total);
    }else{
      setPrivateResources([]);
      setPrivateResourcesTotal(0);
    }
    isDidMount && setIsLoading(false);
  };

  /* 打API，取得所有資源 */
  const fetchData = async () => {
    setIsLoading(true);
    await Promise.all([fetchResourceOrganization(), fetchResourcePublic(), fetchResourcePrivate()]);
    setIsLoading(false);
    setIsDidMount(true);
  };

  /* 第一次載入取得所有資源，防止 ui loading 提前消失 */
  useEffect(() => {
    fetchData();
  }, []);

  /* 取得機構資源 */
  useEffect(() => {
    isDidMount && fetchResourceOrganization();
  }, [fileName]);

  /* 取得公開資源 */
  useEffect(() => {
    isDidMount && fetchResourcePublic();
  }, [displayName, selectedOption]);

  /* 取得我的資源 */
  useEffect(() => {
    isDidMount && fetchResourcePrivate();
  }, [privateResourcesPage, privateResourcesRows]);

  /* 選到的tab，對應的checkboxList */
  const selectedCheckboxList = useMemo(() => {
    switch (tabIndex) {
      case 0:
        return checkboxListOrganization;
      case 1:
        return checkboxListPublic;
      case 2:
        return checkboxListPrivate;
      default:
        break;
    }
  }, [resources, bookstoreBooks, privateResources, tabIndex, checkedCheckbox]);

  return(
    <>
      <UiContent>
        <UiListWrapper>
          <UiListAllContainer>
            {/* 教學資源、公開資源、我的資源 */}
            <ContentTabs tabIndex={tabIndex} onChange={tabChangeHandler} hostId={hostId} />
            {/* 搜尋列 */}
            <ContentSearch
              placeholder={'搜尋資源名稱'}
              selectedOption={selectedOption}
              tabIndex={tabIndex}
              onChangeSelect={selectChangeHandler}
              onChangeSearch={searchInputChangeHandler}
              onClickSearch={searchInputClickHandler}
              privateResourcesPage={privateResourcesPage}
              privateResourcesRows={privateResourcesRows}
              privateResourcesTotal={privateResourcesTotal}
              labelRowsPerPage={'每頁顯示'}
              onChangePage={changePrivateResourcePageHandler}
              onChangeRowsPerPage={changePrivateResourceRowsPerPageHandler} />
            {
              /* checkbox 列表 */
              isLoading
                ? <Loading />
                : <ContentListAll data={selectedCheckboxList} onChange={onChangeCheckbox} />
            }
          </UiListAllContainer>
          <UiListCheckedContainer>
            {/* 右邊勾選的checkbox列表 */}
            <ContentListChecked
              checkedCheckbox={checkedCheckbox}
              isCheckedCheckboxFull={isCheckedCheckboxFull}
              onDelete={onChangeUncheckCheckbox} />
          </UiListCheckedContainer>
        </UiListWrapper>
        <UiActionsWrapper>
          <Button 
            buttonColor='cancel' 
            onClick={onClickClose}
          >取消</Button>
          <Button
            buttonColor='new'
            onClick={onClickSubmit}
            disabled={checkedCheckbox.length === 0}
          >確定</Button>
        </UiActionsWrapper>
      </UiContent>
    </>
  );
};

export default ResourceModalContent;

ResourceModalContent.propTypes = {
  checkedCheckbox: PropTypes.array,
  hostId: PropTypes.string,
  isCheckedCheckboxFull: PropTypes.bool,
  onChangeCheckbox: PropTypes.func,
  onChangeUncheckCheckbox: PropTypes.func,
  onClickClose: PropTypes.func,
  onClickSubmit: PropTypes.func,
};
