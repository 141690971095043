import React from 'react';

import { HomeSessionsTable, HomeGroupLayout } from 'components';

/**
 * 前台課表
 */

export const HomeSessionPage = () => {
  return (
    <HomeGroupLayout>
      <HomeSessionsTable />
    </HomeGroupLayout>
  );
};

