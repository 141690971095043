import React from 'react';
import { HomeGroupLayout, HomeSupplementaryMaterialsTable } from 'components';


/**
 * 輔助教材資源頁面
 */

export const HomeSupplementaryMaterialsPage = () => {
  return (
    <HomeGroupLayout>
      <HomeSupplementaryMaterialsTable/>
    </HomeGroupLayout>
  );
};
