import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  PictureBox,
  ClickablePopoverMenu,
  Icon,
  Modal
} from 'components';
import {
  UiPictureCard,
  UiPictureContent,
  UiPictureLabel,
} from './PictureCard.style';
import { useSetState } from 'utils/hooks/useSetState';
import videoImage from 'assets/images/video.png';

/**
 * 相片列表卡牌
 */
const EDIT_STATUS = {
  ALIGN_TOP: 'verticalAlignTop',
  DELETE: 'delete',
  EDIT: 'edit'
};

export const PictureCard = ({
  data = {},
  sliderIndex = 0,
  onDelete = () => { },
  onClick = () => { },
  changeLikeUserState = () => { },
  type = 'picture',
  isDefault = true,
}) => {
  const { path, attachmentType, postInfo, isAdmin, userId, canDelete } = data;
  const [
    {
      isModalOpen,
      editStatus,
      actionShow,
      awesomeCount,
      isLiked,
    },
    setState] = useSetState({
      isModalOpen: false,
      editStatus: EDIT_STATUS.READ,
      actionShow: false,
      awesomeCount: 0,
      isLiked: false,
    });

  useEffect(() => {
    setState({
      awesomeCount: postInfo.likedUsers ? postInfo.likedUsers.length : 0,
      isLiked: postInfo.likedUsers ? postInfo.likedUsers.some(item => item === userId) : false,
    });
  }, [postInfo.likedUsers ? postInfo.likedUsers.length : postInfo.likedUsers]);

  //修改成編輯or刪除模式
  const setEditStatus = status => () => setState({ editStatus: status, isModalOpen: status !== EDIT_STATUS.EDIT });
  const popoverMenuList = [
    {
      label: '刪除',
      func: setEditStatus(EDIT_STATUS.DELETE),
      iconName: 'delete',
      isShow: true
    },
  ];
  const deleteModalConfig = {
    text: '是否要將此圖片刪除',
    confirmAction: () => onDelete(data)
  };
  const ModalConfig = editStatus === EDIT_STATUS.DELETE ? deleteModalConfig : '';

  const buttons = [
    {
      text: '確認',
      func: ModalConfig.confirmAction
    },
    {
      text: '取消',
      color: 'highlight',
      func: () => { }
    }
  ];

  //貼文操作
  const getModalStateHandler = state => {
    setState({ isModalOpen: state });
  };

  return (
    <UiPictureCard
      tabIndex={0}
      aria-label={`相片${sliderIndex + 1}`}
      onMouseEnter={() => setState({
        actionShow: true
      })}
      onMouseLeave={() => setState({
        actionShow: false
      })}
      onFocus={() => setState({
        actionShow: true
      })}
      onClick={() => onClick(postInfo, sliderIndex)}
      onKeyDown={(e) => e.key === 'Enter' && onClick(postInfo, sliderIndex)}
    >
      <Card>
        <PictureBox path={attachmentType.split('/')[0] === 'image' ? path : videoImage} ></PictureBox>
        <UiPictureContent style={{ opacity: actionShow ? 1 : 0, }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <UiPictureLabel
            isLiked={isLiked}
          >
            <Icon
              title={isLiked ? '收回讚' : '讚'}
              name='thumbUp'
              haveBg={false}
              onClick={(e) => {
                changeLikeUserState(postInfo.id, isLiked, 'picture');
              }}
            />
            <span>{awesomeCount || 0}</span>
          </UiPictureLabel>
          {
            postInfo.ownerId && (userId === postInfo.ownerId || isAdmin) && canDelete &&
            <ClickablePopoverMenu menuList={popoverMenuList}>
              <Icon haveBg={false} name="moreHoriz" icolor="#fff"></Icon>
            </ClickablePopoverMenu>
          }

        </UiPictureContent>
        <Modal
          isOpen={isModalOpen}
          text={ModalConfig.text}
          buttons={buttons}
          getModalState={getModalStateHandler}
        />
      </Card>
    </UiPictureCard>
  );
};

PictureCard.propTypes = {
  data: PropTypes.object,
  sliderIndex: PropTypes.number,
  onClick: PropTypes.func,
  canDelete: PropTypes.bool,
  canEdit: PropTypes.bool,
  onDelete: PropTypes.func,
  type: PropTypes.string,
  isDefault: PropTypes.bool,
  isAdmin: PropTypes.bool,
  changeLikeUserState: PropTypes.func,
};


