import { stringify } from 'query-string';
import { Api } from 'services/api';

/**
 * 取得作業列表
 * @param {string} sortKey (publishedAt, dueAt)  排序條件
 * @param {string} sortOrder (asc, desc)  排序升降冪
 */
export const getHomeworks = classId => async params => {
  const { nowPage = 0, rowsPage, isSubmitted = 0, sortKey, sortOrder } = params;
  const queryParams = {
    limit: rowsPage ? rowsPage : 10,
  };
  if (rowsPage && nowPage * rowsPage !== 0) {
    queryParams.skip = nowPage * rowsPage;
  }
  if (isSubmitted !== '') {
    queryParams.isSubmitted = isSubmitted;
  }
  if (sortKey) {
    queryParams.sortKey = sortKey;
    queryParams.sortOrder = sortKey === 'dueAt' ? 'asc' : 'desc';//預設排序
  }
  if (sortOrder) {
    queryParams.sortOrder = sortOrder;
  }
  const querystring = stringify(queryParams);
  const response = await Api.get(`/groups/${classId}/missions?${querystring}`);
  return response;
};

export const createHomework = classId => async params => {

  if (!params.dueAt) delete params.dueAt;
  if (!params.publishedAt) delete params.publishedAt;
  if (!params.isLateSubmissionAllowed) delete params.isLateSubmissionAllowed;
  if (!params.accessLevel) delete params.accessLevel;

  const response = await Api.post(`/groups/${classId}/missions`, params);
  return response;
};

export const updateHomework = classId => async params => {
  const { id, title, description, dueAt, publishedAt, attachments, isLateSubmissionAllowed,accessLevel } = params;
  const queryParams = {};
  if (title) queryParams.title = title;
  if (description) queryParams.description = description;
  if (dueAt) queryParams.dueAt = dueAt;
  if (publishedAt) queryParams.publishedAt = publishedAt;
  if (attachments) queryParams.attachments = attachments;
  queryParams.isLateSubmissionAllowed = isLateSubmissionAllowed;
  queryParams.accessLevel = accessLevel;
  const response = await Api.put(`/groups/${classId}/missions/${id}`, queryParams);
  return response;
};


export const pinHomework = (classId, homeworkId) => async () => {
  const response = await Api.post(`/groups/${classId}/missions/${homeworkId}/pin`);
  return response;
};

export const cancelPinHomework = (classId, homeworkId) => async () => {
  const response = await Api.delete(`/groups/${classId}/missions/${homeworkId}/pin`);
  return response;
};

export const deleteHomework = (classId, homeworkId) => async () => {
  const response = await Api.delete(`/groups/${classId}/missions/${homeworkId}`);
  return response;
};

export const getSubmittedHomeworks = (classId, homeworkId) => async params => {
  const { nowPage = 0, rowsPage, } = params;
  const queryParams = {
    limit: rowsPage ? rowsPage : 10,
  };
  if (rowsPage && nowPage * rowsPage !== 0) {
    queryParams.skip = nowPage * rowsPage;
  }
  const querystring = stringify(queryParams);
  const response = await Api.get(`/groups/${classId}/missions/${homeworkId}/submissions?${querystring}`);
  return response;

};



//學生提交作業
export const submitHomework = (classId) => async params => {
  const { id, attachments, comment } = params;
  const data = {
    attachments,
    comment,
  };

  const response = await Api.post(`/groups/${classId}/missions/${id}/submit`, data);
  return response;
};


/**
 * Get User Submissions
 * 查詢學生提交的作業列表
 * @param {string} ownerId 作業出題老師 Id
 * @param {string} missionTitle 搜尋作業標題(部分符合)
 * @param {string} timeType (publishedAt ,dueAt ,submittedAt) 搜尋時間類型
 * @param {string} timeSpan 查詢時間區間 (123456789-134567890) 需與 timeType 併用,合法數字區間：小-大
 * @param {string} isAttachmentOnly 是否僅搜尋有附件之繳交作業
 * @param {string} skip 分頁選項-跳過幾項
 * @param {string} limit 分頁選項-最多幾項
 */

export const getUserSubmissions = (classId, userId) => async params => {
  const {
    ownerId,
    missionTitle,
    timeType,
    startAt,
    endAt,
    isAttachmentOnly,
    nowPage = 0,
    rowsPage,
  } = params;
  const queryParams = {
    limit: rowsPage ? rowsPage : 10,
  };
  if (rowsPage && nowPage * rowsPage !== 0) {
    queryParams.skip = nowPage * rowsPage;
  }
  if (ownerId) queryParams.ownerId = ownerId;
  if (missionTitle) queryParams.missionTitle = missionTitle;
  if (timeType && startAt && endAt) {
    queryParams.timeType = timeType;
    queryParams.timeSpan = `${startAt}-${endAt}`;
  }
  if (isAttachmentOnly) queryParams.isAttachmentOnly = isAttachmentOnly;
  const querystring = stringify(queryParams);
  const response = await Api.get(`/groups/${classId}/submissions/${userId}?${querystring}`);
  return response;
};
