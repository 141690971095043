import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useAlert } from 'utils/hooks/useAlert';

import { FREE_GROUP_ROLES } from 'constants/index';
import { useClass } from 'store/class';
import { useSetState } from 'utils/hooks/useSetState';
import { Select, Checkbox, NewBatchList, Button, Breadcrumbs } from 'components';
import {
  UiInviteFreeGroupUserForm,
  UiCheckboxWrapper,
  UiBatchList,
  UiButtonBox
} from './InviteFreeGroupUserForm.style';


/**
 * 獨立班級成員邀請
 */

const INPUT_KEY = {
  USERID: 'userId',
  EMAIL: 'email',
  MOBILE_NUMBER: 'mobileNumber'
};


export const InviteFreeGroupUserForm = () => {
  const history = useHistory();
  const { classId } = useParams();
  const { setAlert } = useAlert();
  const [, { inviteUsersToFreeGroup }] = useClass();
  const [{
    isLoading,
    selectOption,
    isTeacher,
    data,
    errorData,
  }, setState] = useSetState({
    isLoading: false,
    selectOption: INPUT_KEY.USERID,
    isTeacher: false,
    reset: false,
    data: null,
    errorData: null,
  });

  const submitformHandler = async data => {
    setState({ isLoading: true });
    if (!data || data.length <= 0) {
      setState({ isLoading: false });
      setAlert('請填入成員資料', 'error');
      return;
    }
    const { isSuccess, userError } = await inviteUsersToFreeGroup({
      users: data,
      role: isTeacher ? FREE_GROUP_ROLES.HOST_CANDIDATE : FREE_GROUP_ROLES.USER
    });

    setState({ isLoading: false });
    if (isSuccess) {
      setState({ reset: true });
      history.push(`/home/class/${classId}/member`);
    } else {
      setState({ errorData: userError }); // 將錯誤訊息顯示在畫面上
    }
  };


  const selectProps = {
    label: '註冊方式',
    options: [
      {
        name: '使用者 id',
        value: INPUT_KEY.USERID
      },
      {
        name: '手機號碼',
        value: INPUT_KEY.MOBILE_NUMBER
      },
      {
        name: 'email',
        value: INPUT_KEY.EMAIL
      }
    ],
    submitHandler: value => {
      setState({ selectOption: value });
    }
  };

  const checkboxChange = val => {
    setState({ isTeacher: Boolean(val) });
  };

  const batchListChangeHandler = data => {
    setState({ data });
  };

  //麵包屑
  const BreadcrumbsList = [
    {
      name: '成員',
    },
    {
      name: '學生列表',
      link: `/home/class/${classId}/member`
    },
    {
      name: '邀請成員',
    }
  ];

  return (
    <>
      <Breadcrumbs list={BreadcrumbsList} />
      <UiInviteFreeGroupUserForm>

        <UiCheckboxWrapper>
          <Select
            label={selectProps.label}
            value={selectOption}
            options={selectProps.options}
            submitHandler={selectProps.submitHandler}
          />
          <Checkbox
            onChange={checkboxChange}
            label="設定為教師"
            checkedValue="isTeacher"
          />
        </UiCheckboxWrapper>
        <UiBatchList>
          <NewBatchList
            type={selectOption}
            onChange={batchListChangeHandler}
            errorData={errorData}
          />
        </UiBatchList>
        <UiButtonBox>
          <Button
            onClick={() => submitformHandler(data)}
            loading={isLoading}
          >確認
        </Button>
          <Button
            buttonColor="info"
            icon='exitToApp'
            onClick={() => history.push(`/home/class/${classId}/member`)}
          >回到上一頁
        </Button>
        </UiButtonBox>
      </UiInviteFreeGroupUserForm >
    </>
  );
};
