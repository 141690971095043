import React from 'react';

import { Aside } from 'components';
import { getAdminAsideRoutes } from 'routes/routesConfig/adminAsideRoutes';

/**
 * 管理者左側欄
 */

export const AdminAside = () => {
  return (
    <Aside asideRoutes={getAdminAsideRoutes()} />
  );
};



