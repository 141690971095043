import React from 'react';
import PropTypes from 'prop-types';

import { ClickableUpload, Button, Icon } from 'components';

import { UiUploadButtonLabel } from './UploadButton.style';


/**
 * 上傳檔案按鈕
 */

export const UploadButton = ({
  label,
  fileUrl,
  onChange,
  filesLimit = 1,
  defaultValue = []
}) => {
  return (
    <ClickableUpload
      filesLimit={filesLimit}
      onUploaded={onChange}
      fileUrl={fileUrl}
      defaultValue={defaultValue}
      isShowFileList={true}
    >
      <Button buttonColor='new'>
        <UiUploadButtonLabel>{label}</UiUploadButtonLabel>
      </Button>
    </ClickableUpload>
  );
};

UploadButton.propTypes = {
  label: PropTypes.string,
  fileUrl: PropTypes.string,
  onChange: PropTypes.func,
  filesLimit: PropTypes.number,
  defaultValue:PropTypes.array
};


