import React from 'react';
import { Route, Redirect, useParams } from 'react-router-dom';
import { availableEntryNavigation } from 'constants/index';
import { useClass } from 'store/class';

const AuthGuard = ({ children, ...props }) => {
  // const [{ profile: { organizations }, myAllOrganizations }] = useUser();
  const [{ myClasses: { dataInfo: { navigationFunctions } } }] = useClass();
  const { classId } = useParams();

  if(classId && navigationFunctions){
    
    const { location:{ pathname } } = props;
    const routeGroup = pathname.split('/');
    const frontendNavigationFunctions = navigationFunctions.map(navigationFunction=>{
      return availableEntryNavigation[navigationFunction];
    });
    if(frontendNavigationFunctions.includes('homeworks')) frontendNavigationFunctions.push('submitted');
    frontendNavigationFunctions.push('summary');
    frontendNavigationFunctions.push('onlineExam'); // TODO 靠api
    frontendNavigationFunctions.push('onlineExamResult');
    const isNeedRedirect = frontendNavigationFunctions.some(item=> routeGroup.includes(item));
    if(!isNeedRedirect){
      return <Redirect to="/home" />;
    }
  }
  return children;
};

const RouteWithSubRoutes = route => {
  return (
    <Route
      path={route.path}
      exact={route.exact}
      render={props =>
        <AuthGuard {...props} {...route}>
          <route.component {...props} {...route} routes={route.routes} />
        </AuthGuard>
      }
    />
  );
};

const RenderRoutes = ({ routes = [] }) => (
  <>
    {
      routes.map((route, i) => (
        <RouteWithSubRoutes key={i} {...route} />
      ))
    }
  </>
);


export default RenderRoutes;
