import React from 'react';
import { UiBsContentContainer } from 'styles';
import {
  BsHeaderTitle,
  BsResourceManagementPageHeaderTab,
  BsPublicResourceTable
 } from 'components';


/**
 * 機構後台公開資源頁面
 */

export const BsPublicResourceManagementPage = () => {
  return (
    <>
      <BsHeaderTitle title="資源列表" />
      <BsResourceManagementPageHeaderTab activeIndex={1} />
      <UiBsContentContainer>
        <BsPublicResourceTable />
      </UiBsContentContainer>
    </>
  );
};

BsPublicResourceManagementPage.propTypes = {

};


