import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { Icon } from 'components';
import {
  Stepper as MatStepper,
  Step as MatStep,
  StepLabel as MatStepLabel,
  StepConnector as MatStepConnector
} from '@material-ui/core';
// import { useSetState } from 'utils/hooks/useSetState';


/**
 * 步驟組件
 */


const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  },
});
const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  completed: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  line: {
    transition: '0.5s',
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(MatStepConnector);

const ColorlibStepIcon = ({ active, completed, icon }) => {
  const classes = useColorlibStepIconStyles();

  const icons = {
    1: <Icon name='phoneAndroid' haveBg={false} />,
    2: <Icon name='visibility' haveBg={false} />,
    3: <Icon name='done' haveBg={false} />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(icon)]}
    </div>
  );
};

export const Stepper = ({ steps = [], activeStep }) => {
  return (
    <MatStepper activeStep={activeStep} alternativeLabel connector={<ColorlibConnector />}>
      {
        steps.map(step => (
          <MatStep key={step}>
            <MatStepLabel StepIconComponent={ColorlibStepIcon}>
              {step}
            </MatStepLabel>
          </MatStep>
        ))
      }
    </MatStepper>
  );
};

Stepper.propTypes = {
  activeStep: PropTypes.number,
  steps: PropTypes.array
};


