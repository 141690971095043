import React, { Fragment } from 'react';
// import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
import {
  Drawer as MatDrawer,
  List as MatList,
  ListItemText as MatListItemText
} from '@material-ui/core';

import { Icon } from 'components';
import { useUser } from 'store/user';
import { PERMISSIONS } from 'constants/index';
import {
  UiItem,
  UiItemCategory,
  UiCategoryHeader,
  UiOfficialName,
  UiLink,
  UiItemIcon,
  UiDivider
} from './BsNavigator.style';


/**
 * Backstage 側邊欄
 */

const checkPermission = (routes, permissions) => {
  return routes.map(item => {
    const checkedRoutes = item.children.filter(child => permissions.includes(child.permission));
    return { ...item, children: checkedRoutes };
  }).filter(item => item.children.length > 0);
};

const getCategories = ({ organizationId, myPermissions }) => {

  const ORGANIZATION_BASE_PATH = `/organization/${organizationId}`;


  const categories = [
    {
      id: '學務管理',
      children: [
        {
          id: '班級管理',
          icon: 'school',
          path: `${ORGANIZATION_BASE_PATH}/class`,
          permission: PERMISSIONS.VIEW_GROUP
        },
        {
          id: '教師管理',
          icon: 'person',
          path: `${ORGANIZATION_BASE_PATH}/teachers`,
          permission: PERMISSIONS.VIEW_STAFF_PROFILE
        },
        {
          id: '學生管理',
          icon: 'people',
          path: `${ORGANIZATION_BASE_PATH}/customer`,
          permission: PERMISSIONS.VIEW_CUSTOMER_PROFILE
        },
        {
          id: '課程總覽',
          icon: 'importContacts',
          path: `${ORGANIZATION_BASE_PATH}/class/session`,
          permission: PERMISSIONS.VIEW_GROUP
        }
      ],
    },
    {
      id: '資源管理',
      children: [
        {
          id: '資源列表',
          icon: 'viewList',
          path: `${ORGANIZATION_BASE_PATH}/resources`,
          permission: PERMISSIONS.VIEW_RESOURCE
        },
        {
          id: '取用權限',
          icon: 'settingsInputComponent',
          path: '',
          permission: PERMISSIONS.MANAGE_RESOURCE
        },
        {
          id: '看課備份',
          icon: 'backup',
          path: '',
          permission: PERMISSIONS.MANAGE_RESOURCE
        },
      ],
    },
    {
      id: '教務管理',
      children: [
        {
          id: '看課管理',
          icon: 'visibility',
          path: '',
          permission: PERMISSIONS.MONITOR_SESSION
        },
        {
          id: '課堂報告',
          icon: 'poll',
          path: '',
          permission: PERMISSIONS.VIEW_SESSION_REPORT
        },
      ],
    },
    {
      id: '機構管理',
      children: [
        {
          id: '機構設定',
          icon: 'settings',
          path: `${ORGANIZATION_BASE_PATH}/setting`,
          permission: PERMISSIONS.EDIT_ORGANIZATION_SETTING
        },
        {
          id: '設定說明',
          icon: 'info',
          path: '',
          permission: PERMISSIONS.EDIT_ORGANIZATION_SETTING
        },
        {
          id: '相關文檔',
          icon: 'watchFile',
          path: '',
          permission: PERMISSIONS.VIEW_ORGANIZATION_SETTING
        },
      ],
    },
    {
      id: '權限管理',
      children: [
        {
          id: '權限設定',
          icon: 'tune',
          path: `${ORGANIZATION_BASE_PATH}/permissionSetting`,
          permission: PERMISSIONS.EDIT_ORGANIZATION_SETTING
        }
      ],
    }
  ];

  return checkPermission(categories, myPermissions);
};

export const BsNavigator = ({ ...otherProps }) => {

  const history = useHistory();
  const { organizationId } = useParams();
  const [{
    myOrganization: { myPermissions },
    myAllOrganizations,
  }] = useUser();

  const goOtherOrg = id => {
    history.push(`/organization/${id}`);
  };

  return (
    <MatDrawer variant="permanent" {...otherProps}>
      <MatList disablePadding>
        <UiItemCategory
          button
          onClick={() => history.push(`/organization/${organizationId}`)}
        >
          <UiOfficialName>
            <select onChange={e => goOtherOrg(e.target.value)}>
              {
                Object.entries(myAllOrganizations.ownOrganizationsDataMap).map(([key, item]) => {
                  return (
                    <option value={key} key={key} selected={key === organizationId ? true : false}>
                      {item.officialName}
                    </option>
                  );
                })
              }
            </select>
          </UiOfficialName>
        </UiItemCategory>

        <UiItemCategory>
          <UiLink to="/home">
            <UiItemIcon>
              <Icon name="home" haveBg={false} />
            </UiItemIcon>
            <MatListItemText>
              回到前台列表
            </MatListItemText>
          </UiLink>
        </UiItemCategory>
        {getCategories({ organizationId, myPermissions }).map(({ id, children }) => (
          <Fragment key={id}>
            <UiCategoryHeader>
              <MatListItemText>
                {id}
              </MatListItemText>
            </UiCategoryHeader>
            {children.map(({ id: childId, icon, path }) => (
              <UiItem
                key={childId}
                button
                disabled={!path}
              >
                <UiLink
                  to={path}
                  isActive={match => match}
                  activeClassName="active"
                  exact
                >
                  <UiItemIcon>
                    <Icon haveBg={false} name={icon}></Icon>
                  </UiItemIcon>
                  <MatListItemText>
                    {childId}
                  </MatListItemText>
                </UiLink>
              </UiItem>
            ))}
            <UiDivider />
          </Fragment>
        ))}
      </MatList>
    </MatDrawer>
  );
};

BsNavigator.propTypes = {

};


