import React, { useEffect } from 'react';
import { EDIT_STATUS } from 'constants/index';
import { useParams, useHistory } from 'react-router-dom';
import {
  Table,
  Button,
  IconInput,
  Select,
  TransferListModal,
  Modal,
  Icon,
  Breadcrumbs
} from 'components';
import { useSetState } from 'utils/hooks/useSetState';
import { UiTable, UiActionBox, UiflexBox } from 'styles';
import { useCustomer } from 'store/customer';
import { useClass } from 'store/class';

/**
 * 學生列表Table
 */
const schema = {
  nickname: {
    name: '暱稱',
    defaultValue: '無'
  },
  mobileNumber: {
    name: '手機',
    defaultValue: '無'
  },
  email: {
    name: '信箱',
    defaultValue: '無'
  },
  status: {
    name: '狀態',
    defaultValue: '無'
  },
  brief: {
    name: '簡介',
    defaultValue: '無'
  }
};

const stateShowText = [
  {
    id: 'status',
    state: {
      invited: '邀請中(等待同意)',
      rejected: '拒絕邀請',
      joined: '已加入',
      departed: '已離開'
    }
  }
];

const selectOptions = [
  {
    name: '手機',
    value: 'mobileNumber'
  },
  {
    name: '暱稱',
    value: 'nickname'
  }
];

export const CustomerTable = () => {
  const { organizationId, classId } = useParams();
  const history = useHistory();
  const [
    { customers, subtractionCustomers },
    { getCustomers, getSubtractionCustomers, addUserToGroup, removeUserToGroup }] = useCustomer();
  const [{ classes }] = useClass();
  const [{
    nowPage,
    rowsPage,
    searchType,
    searchValue,
    nickname,
    mobileNumber,
  }, setState] = useSetState({
    nowPage: 0,
    rowsPage: 10,
    nickname: '',
    mobileNumber: '',
    searchType: '',
    searchValue: '',
  });

  //麵包屑
  const BreadcrumbsList = [
    {
      name: '班級管理',
      link: `/organization/${organizationId}/class`,
      icon: 'school'
    },
    {
      name: `${classes.dataInfo.name}-學生管理`,
      link: `/organization/${organizationId}/class/${classId}/customer`,
      icon: 'people'
    }
  ];


  const goCreateSession = () => {
    history.push(`/organization/${organizationId}/customer/${EDIT_STATUS.CREATE}`);
  };
  const changePage_Rows = params => {
    const { newPage, newRowsPage } = params;
    setState({
      nowPage: newPage,
      rowsPage: newRowsPage
    });
  };
  const onSubmitHandler = (value, key) => {
    if (key === 'searchValue') {
      if (searchType === '') return;
      setState({
        nickname: '',
        mobileNumber: '',
        [searchType]: value,
        nowPage: 0
      });
    }
    setState({
      [key]: value,
    });
  };
  const transferDataFormat = arr => {
    arr.forEach(item => {
      item.imagePath = '';
      item.title = item.nickname;
      item.content = item.email;
    });
    return arr;
  };
  const getTrnasferListValue = async params => {
    const userIdList = params.map(item => item.id);
    const isSuccess = await addUserToGroup({ userIdList, userProfiles: params });
    if (isSuccess) await getCustomers({ nowPage, rowsPage, nickname, mobileNumber });
  };

  // eslint-disable-next-line react/prop-types
  const ActionComponents = ({ params }) => {
    const [{ isOpen }, setModal] = useSetState({
      isOpen: false
    });
    const userProfiles = params;
    const buttons = [
      {
        text: '確認',
        func: async () => {
          const isSuccess = await removeUserToGroup({ userProfiles });
          if (isSuccess) await getCustomers({ nowPage, rowsPage, nickname, mobileNumber });
        }
      },
      {
        text: '取消',
        color: 'highlight',
      }
    ];
    const remove = () => {
      setModal({
        isOpen: true
      });
    };
    const getModalStateHandler = state => {
      setModal({
        isOpen: state
      });
    };
    return (
      <>
        <Button
          onClick={remove}
          type="table"
        >
          移出班級
      </Button>
        <Modal
          text="此操作將會將此學生從班級移除"
          isOpen={isOpen}
          getModalState={getModalStateHandler}
          buttons={buttons}
        />
      </>
    );
  };

  // eslint-disable-next-line react/prop-types
  const orgCustomersActionComponents = ({ params: { id } }) => {
    const goPage = editStatus => () => history.push(`/organization/${organizationId}/customer/${id}/${editStatus}`);

    return (
      <>
        <Icon name="watchFile" onClick={goPage(EDIT_STATUS.READ)} />
        <Icon name="edit" onClick={goPage(EDIT_STATUS.EDIT)} />
      </>
    );
  };

  useEffect(() => {
    if (!classId) return;
    getSubtractionCustomers({ organizationId, classId });
  }, []);

  useEffect(() => {
    getCustomers({ nowPage, rowsPage, nickname, mobileNumber });
  }, [nowPage, rowsPage, nickname, mobileNumber]);

  return (
    <UiTable>
      {
        classId && <Breadcrumbs list={BreadcrumbsList} />
      }
      <UiActionBox>
        <UiflexBox>
          <Select label="搜尋條件" options={selectOptions} submitHandler={value => onSubmitHandler(value, 'searchType')} />
          <IconInput
            placeholder='搜尋條件'
            value={searchValue}
            onChange={value => onSubmitHandler(value, 'searchValue')}
          />
        </UiflexBox>
        {
          classId ?
            <UiflexBox>
              <Button
                buttonColor='info'
                icon='exitToApp'
                onClick={() => history.goBack()}
              >
                回到上一頁
              </Button>
              <TransferListModal
                title="選擇學生"
                name='ownerId'
                type="multipleChoice"
                data={transferDataFormat(subtractionCustomers.data) || []}
                onChange={getTrnasferListValue}
              />
            </UiflexBox> :
            <Button
              buttonColor='highlight'
              icon='add'
              onClick={() => goCreateSession()}>邀請學生</Button>
        }
      </UiActionBox>
      <Table
        data={customers.data}
        schema={schema}
        stateShowText={stateShowText}
        changePage_Rows={changePage_Rows}
        totalPage={customers.total}
        ActionComponents={classId ? ActionComponents : orgCustomersActionComponents}
        nowPage={nowPage}
      />
    </UiTable>);
};
