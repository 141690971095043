import React from 'react';
import { UiBsContentContainer } from 'styles';
import { CustomerTable, BsHeaderTitle, BsCustomerManagementPageHeaderTab } from 'components';

/**
 * 學生管理列表
 */

export const CustomersPage = () => {
  return (
    <>
      <BsHeaderTitle title="學生管理" />
      <BsCustomerManagementPageHeaderTab active/>
      <UiBsContentContainer>
        <CustomerTable />
      </UiBsContentContainer>
    </>
  );
};



