import React, { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { usePageVisibility } from 'utils/hooks/usePageVisibility';
import { paginationFilter } from 'utils/pagination';
import { useClass } from 'store/class';
import { useUser } from 'store/user';
import { useCustomer } from 'store/customer';
import { useSetState } from 'utils/hooks/useSetState';
import { useQuery } from 'utils/hooks/useQuery';
import { Table, Button, Loading, EmptyPrompt, HomeTitle } from 'components';
import { Tab } from '@material-ui/core';
import { getTeacherAssignedVideoRecord } from 'services/api/home/video';
import { UiHomeTeacherVideoTable, UiMatTabs, UiEmptyContent} from './HomeTeacherVideoTable.style';
import forest from 'assets/images/forest.svg';


/**
 * 老師影音列表
 */

const ViewedTable = ({ classId, userId, setShowAssignButton}) => {
  const history = useHistory();
  const [{ customers }, { getCustomers }] = useCustomer();

  const [
    {
      nowPage,
      rowsPage,
      isLoading,
    }, setState] = useSetState({
      nowPage: 0,
      rowsPage: 10,
      isLoading: true
    });

  const schema = {
    attendeeNumber: {
      name: '座號',
      defaultValue: '-'
    },
    nickname: {
      name: '姓名',
      defaultValue: '--'
    },
  };

  const changePage_Rows = params => {
    const { newPage, newRowsPage } = params;
    setState({
      nowPage: newPage,
      rowsPage: newRowsPage
    });
  };

  const ActionComponents = ({ params }) => {
    const checkViewHistory = () => {
      history.push(`video/history/${params.id}`);
    };
    return (
      <Button buttonColor="new" onClick={checkViewHistory}>查看觀看紀錄</Button>
    );
  };

  const fetchAssignVideoList = async () => {
    const res = await getCustomers({ nowPage, rowsPage });
    setState({ data: res.userProfiles, isLoading: false });
  };
  useEffect(() => {
    setShowAssignButton(true);
  }, []);

  useEffect(() => {
    fetchAssignVideoList({ classId, teacherId: userId });
  }, [userId, classId]);


  const tableData = useMemo(() => {
    return paginationFilter(customers.data, nowPage, rowsPage);
  }, [customers.data, nowPage, rowsPage]);

  return isLoading ? (
    <Loading />
  ) : (<>
    <Table
      data={tableData}
      schema={schema}
      changePage_Rows={changePage_Rows}
      totalPage={customers.total || 10}
      ActionComponents={ActionComponents}
      nowPage={nowPage}
    />
    { tableData.length === 0 && <EmptyPrompt img={forest} message={'尚無班級學生'} messageColor={'#8B90A0'}/> }
    </>);
};

const AssignedTable = ({ classId, userId, goAssignVideo, setShowAssignButton}) => {
  const isVisible = usePageVisibility();
  const history = useHistory();
  const [
    {
      data,
      nowPage,
      rowsPage,
    }, setState] = useSetState({
      data: [],
      nowPage: 0,
      rowsPage: 10,
    });

  const schema = {
    item: {
      name: '品項',
      default: '--'
    },
    name: {
      name: '影片名稱',
      defaultValue: '--'
    },
    time: {
      name: '影片時長',
      defaultValue: '00:00'
    },
    num: {
      name: '已觀看人數',
      defaultValue: 0
    },
  };

  const changePage_Rows = params => {
    const { newPage, newRowsPage } = params;
    setState({
      nowPage: newPage,
      rowsPage: newRowsPage
    });
  };

  const ActionComponents = ({ params }) => {
    const checkViewHistory = () => {
      history.push(`video/recordHistory/${params.teacher}/${params.no}`);
    };
    return (
      <Button buttonColor="new" onClick={checkViewHistory}>查看觀看紀錄</Button>
    );
  };

  const fetchAssignVideoList = async ({ classId, teacherId }) => {
    const res = await getTeacherAssignedVideoRecord({ classId, teacherId });
    if (res.isSuccess) {
      setState({ data: Object.values(res.data) });
    }
  };

  useEffect(() => {
    fetchAssignVideoList({ classId, teacherId: userId });
  }, [userId, classId, isVisible]);

  const tableData = useMemo(() => {
    return paginationFilter(data, nowPage, rowsPage);
  }, [data, nowPage, rowsPage]);


  useEffect(() => {
    if(tableData.length === 0) {
      setShowAssignButton(false);
    } else {
      setShowAssignButton(true);
    }
  }, [tableData]);

  return (<>
    <Table
      data={paginationFilter(data, nowPage, rowsPage)}
      schema={schema}
      changePage_Rows={changePage_Rows}
      totalPage={data.length}
      ActionComponents={ActionComponents}
      nowPage={nowPage}
    />
    { 
      tableData.length === 0 && 
        <UiEmptyContent>
            <p tabIndex={0}>尚無指派紀錄</p>
            <Button buttonColor="new" onClick={goAssignVideo}>指派影片</Button>
        </UiEmptyContent> 
    }
  </>);
};

export const HomeTeacherVideoTable = () => {
  const [{ profile }] = useUser();
  const { id: userId } = profile;
  const query = useQuery();
  const queryTabIndex = query.get('tab') ? parseInt(query.get('tab')) : 0;
  const [{ myClasses: { dataInfo: { id: classId } } }] = useClass();
  const [{ tabIndex }, setState] = useSetState({ tabIndex: queryTabIndex});
  const [showAssignButton, setShowAssignButton] = useState(true);
  const goAssignVideo = () => {
    window.open(`${process.env.REACT_APP_VIDEO_BANK_DOMAIN}?teacher=${userId}&course=${classId}`);
  };

  const getTabIndexHandler = (e, tabIndex) => {
    setState({
      tabIndex
    });
  };

  return (
    <UiHomeTeacherVideoTable>
      <div className="flex">
        <HomeTitle title={'影音學習'} />
        { showAssignButton && <Button buttonColor="new" onClick={goAssignVideo}>指派影片</Button> }
      </div>
      <UiMatTabs
        value={tabIndex}
        onChange={getTabIndexHandler}
      >
        <Tab label="學生觀看紀錄" />
        <Tab label="指派紀錄" />
      </UiMatTabs>
      {
        tabIndex === 0 && <ViewedTable classId={classId} userId={userId} setShowAssignButton={setShowAssignButton}/>
      }
      {
        tabIndex === 1 && <AssignedTable classId={classId} userId={userId} goAssignVideo={goAssignVideo} setShowAssignButton={setShowAssignButton}/>
      }
    </UiHomeTeacherVideoTable>
  );
};

HomeTeacherVideoTable.propTypes = {

};


