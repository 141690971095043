import styled from 'styled-components';
import {
  Tabs as MatTabs,
} from '@material-ui/core';

export const UiContentTabs = styled.div`
  background-color: #FFF;
  margin-bottom: 16px;
`;

export const UiMatTabs = styled(MatTabs)`
  border-bottom: 1px solid #e8e8e8;
  margin-left: 0;

  & .Mui-selected{
    color: ${({ theme }) => theme.palette.btn.normal};
  };

  & > div > span {
    background-color: ${({ theme }) => theme.palette.btn.normal};
  };

  & > div > div > button {
    font-family: Roboto;
    font-weight: 400;
    margin: 0;
    padding: 0 16px;
  }
`;
