import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { UiTitle } from './HomeTitle.style';

export const HomeTitle = ({ title, controlMessage = '' }) => {

  const { pathname } = useLocation();
  const homeOnLineExamRef = useRef();

  useEffect(() => {
    if (!homeOnLineExamRef.current) return;
    pathname && homeOnLineExamRef.current.focus();
  }, [homeOnLineExamRef, pathname]);

  return (
    <div>
      <UiTitle tabIndex={0} ref={homeOnLineExamRef} aria-label={controlMessage}>{title}</UiTitle>
    </div>
  );
};

HomeTitle.propTypes = {
  title: PropTypes.string,
  controlMessage: PropTypes.string
};


