import React from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';

import { BsHeaderTab } from 'components';


/**
 * 課程管理header Tab
 */

export const BsClassPageHeaderTab = ({ activeIndex }) => {
  const { organizationId } = useParams();
  const history = useHistory();
  const goPath = path  => history.push(path);
  const tabData = [
    { label: '班級設定', onClick: () => goPath(`/organization/${organizationId}/class/create`) }
  ];
  return (
    <BsHeaderTab data={tabData} activeIndex={activeIndex} />
  );
};

BsClassPageHeaderTab.propTypes = {
  activeIndex: PropTypes.number
};


