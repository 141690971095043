import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Icon } from 'components';
import { useSetState } from 'utils/hooks/useSetState';
import { UiSeatBox, UiSeatItem, UiRemoveItem } from './ChooseSeatModal.style';


/**
 * 選擇座號彈窗
 */

export const ChooseSeatModal = ({
  seats = [],
  seatsNumber = null,
  isOpen = false,
  getModalState = () => { },
  getSeats = () => { },
  updateSeatsData = () => { },
}) => {
  const [{ choosed, isOpenState, seatsList }, setState] = useSetState({
    choosed: null,
    isOpenState: isOpen,
    seatsList: seats
  });
  const getSeatsHandler = num => {
    getSeats(num);
    setState({
      isOpenState: false
    });
  };
  useEffect(() => {
    setState({ isOpenState: isOpen, choosed: seatsNumber });
  }, [isOpen]);

  const buttons = [
    {
      text: '取消',
      color: 'cancel',
      func: () => setState({
        isOpenState: false
      })
    },
    {
      text: '確認',
      color: 'new',
      func: () => getSeatsHandler(choosed)
    },
  ];

  const chooseNum = (num, canChoosed) => {
    if (canChoosed) return;
    if (num === choosed) {
      setState({
        choosed: null,
      });
    } else {
      setState({
        choosed: num
      });
    }

  };
  //新增座號欄位
  const addNewItem = () => {
    const newItem = {
      num: seatsList.length + 1,
      isChoosed: false,
    };
    const newSeatsList = [...seatsList, newItem];
    setState({
      seatsList: newSeatsList,
    });
    updateSeatsData(newSeatsList);
  };

  //移除項目
  const removeItem = removeNum => {
    const nextSeatsList = seatsList.filter(item => item.num !== removeNum);
    setState({
      seatsList: nextSeatsList,
    });
    updateSeatsData(nextSeatsList);

  };
  useEffect(() => {
    setState({
      seatsList: seats
    });
  }, [seats]);
  const Seats = () => {
    return (
      <UiSeatBox>
        {
          seatsList.map((seat, index) => {

            return (
              <UiSeatItem
                active={seat.num === choosed ? true : false}
                isChoosed={(seat.num === seatsNumber) ? false : seat.isChoosed}
                key={seat.num}
                onClick={() => chooseNum(seat.num, (seat.num === seatsNumber) ? false : seat.isChoosed)}
              >
                {
                  ((index + 1) === seatsList.length && !seat.isChoosed && seat.num !== choosed) &&
                  <UiRemoveItem onClick={(e) => { e.stopPropagation(); removeItem(seat.num); }}>
                    <Icon name='close' type='danger' />
                  </UiRemoveItem>
                }
                {seat.num}
              </UiSeatItem>
            );
          })
        }
        <UiSeatItem
          onClick={() => { addNewItem(); }}
        >
          +
              </UiSeatItem>
      </UiSeatBox>
    );
  };
  return (
    <Modal
      isOpen={isOpenState}
      title="編輯座號"
      buttons={buttons}
      isIconShow={false}
      getModalState={getModalState}
    >
      <Seats />
    </Modal>
  );
};

ChooseSeatModal.propTypes = {
  seats: PropTypes.array,
  seatsNumber: PropTypes.number,
  isOpen: PropTypes.bool,
  getModalState: PropTypes.func,
  getSeats: PropTypes.func
};


