/* eslint-disable max-len */
import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Link, useParams, useLocation } from 'react-router-dom';
import { Icon, BlindBrick } from 'components';
import { useClass } from 'store/class';
import { useUser } from 'store/user/index';
import logo from 'assets/images/logo.png';

import {
  UiSideBar,
  UiSideBarItemsContainer,
  UiSideBarItem,
  UiSideBarSecond,
  UiSideBarSecondBox,
  UiSideBarSecondItem,
  UiSideBarSecondItemIcon,
  UiNew,
  UiLogo,
} from './SideBar.style';


export const OutsideAlerter = (ref, callback) => {


  const handleClickOutside = ((event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      callback();
    }
  });

  document.addEventListener('mousedown', handleClickOutside);
  document.addEventListener('touchstart', handleClickOutside);
};


/**
 * 左側欄
 */

const checkIsAlbum = (pathName) => {
  const urlSplit = pathName.split('/');
  return urlSplit[urlSplit.length - 2] === 'album' ? 'album' : pathName.split('/').pop();
};

export const SideBar = ({ type = 'home', sideBarData = [] }) => {
  const { organizationId, classId } = useParams();

  const { pathname } = useLocation();
  const [{ myOrganization }] = useUser();
  const targetPage = checkIsAlbum(pathname);
  const sidebarRef = useRef();

  const [{ myClasses: { dataInfo: { isOwner, isHidden, navigationFunctions, userType } } }] = useClass();
  const {
    organizationCustomLogoUsageSetting,
    organizationLogoOriginSetting,
  } = useMemo(() => {
    return myOrganization?.organization ?? '';
  }, [myOrganization]);

  const disableLink = e => {
    e.preventDefault();
  };

  const getBasePath = useCallback(() =>
    organizationId ? `/home/${organizationId}/class` : '/home/class'
    , [organizationId]);

  let data = type === 'home' ?
    [
      {
        isPublic: true,
        link: '/home',
        label: '回首頁',
        icon: 'BackToList',
        disabled: false,
      },
      {
        isPublic: true,
        link: `${getBasePath()}/${classId}/info`,
        label: '簡介',
        icon: 'Introduction',
        code: 'brief',
        active: 'info',
        disabled: false,
      },
      {
        isPublic: true,
        link: `${getBasePath()}/${classId}/announcement`,
        label: '公告',
        icon: 'Bullhorn',
        code: 'announcement',
        active: 'announcement',
        disabled: false,
      },
      {
        isPublic: true,
        link: `${getBasePath()}/${classId}/sessions`,
        label: '課程',
        icon: 'BookReader',
        code: 'session',
        active: 'session',
        disabled: false,
      },
      {
        isPublic: true,
        link: `${getBasePath()}/${classId}/homeworks`,
        label: '作業',
        icon: 'Homework',
        code: 'mission',
        active: 'homeworks',
        disabled: false,
      },
      {
        isPublic: true,
        link: '',
        label: '學習資源',
        icon: 'Lightbulb',
        new: {
          startDate: '2021/06/21 00:00:00',
          endDate: '2021/7/21 00:00:00',
        },
        showMenu: false,
        menu: [
          {
            isPublic: true,
            link: `${getBasePath()}/${classId}/onlineExam`,
            label: '線上測驗',
            icon: 'Exam',
            active: 'onlineExam',
            code: 'onlineExam',
            disabled: false,
          },
          {
            isPublic: true,
            link: `${getBasePath()}/${classId}/exam`,
            label: '線上派卷',
            icon: 'ScrollSolid',
            active: 'exam',
            code: 'onlineQuiz',
            disabled: false,
          },
          {
            isPublic: true,
            link: `${getBasePath()}/${classId}/video`,
            label: '影音學習',
            icon: 'FullVideo',
            active: 'video',
            code: 'multimediaLearning',
            disabled: false,
          },
          {
            isPublic: true,
            link: `${getBasePath()}/${classId}/supplementary`,
            label: '教學資源',
            icon: 'Shapes',
            active: 'supplementary',
            disabled: false,
            code: 'supplementaryResource',
            new: {
              startDate: '2021/06/21 00:00:00',
              endDate: '2021/7/21 00:00:00',
            }
          },
        ]
      },
      {
        isPublic: true,
        link: '',
        label: '社群',
        icon: 'Comments',
        showMenu: false,
        menu: [
          {
            isPublic: true,
            link: `${getBasePath()}/${classId}/paste`,
            label: '貼文',
            icon: 'CommentAlt',
            code: 'post',
            active: 'paste',
            disabled: false,
          },
          {
            isPublic: true,
            link: `${getBasePath()}/${classId}/album`,
            label: '相簿',
            icon: 'Album',
            code: 'album',
            active: 'album',
            disabled: false,
          },
        ],
      },
      {
        isPublic: true,
        link: isHidden ? '' : `${getBasePath()}/${classId}/secretdiscuss`,
        label: '導師商談室',
        icon: 'Community',
        onClick: e => {
          isHidden &&
            disableLink(e);
        },
        code: 'counseling',
        active: 'secretdiscuss',
        disabled: isHidden ? true : false,
      },
      {
        isPublic: false,
        link: `${getBasePath()}/${classId}/summary`,
        label: '學習歷程',
        icon: 'Stopwatch',
        active: 'summary',
        disabled: false,
      },
      {
        isPublic: true,
        link: `${getBasePath()}/${classId}/webResource`,
        label: '常用資源',
        icon: 'AttachFile',
        code: 'webResource',
        active: 'webResource',
        disabled: false,
      },
      {
        isPublic: (userType !== 'student'),
        link: `${getBasePath()}/${classId}/member`,
        label: '成員',
        icon: 'Users',
        code: 'member',
        active: 'member',
        disabled: false,
      },
      {
        isPublic: (userType !== 'student'),
        link: isOwner ? `${getBasePath()}/${classId}/setting` : '',
        label: '設定',
        icon: 'Cog',
        onClick: e => {
          !isOwner &&
            disableLink(e);
        },
        code: 'setting',
        active: 'setting',
        disabled: !isOwner ? true : false,
      }
    ] :
    [
      {
        isPublic: true,
        link: '/home/memberCenter/profile',
        label: '基本資料',
        icon: 'AddressCard',
        active: 'profile',
        disabled: false,
      },
      {
        isPublic: true,
        link: '/home/memberCenter/changePassword',
        label: '修改密碼',
        icon: 'UserLock',
        active: 'changePassword',
        disabled: false,
      },
    ];


  const [menuStatus, setMenuStatus] = useState([]);

  useEffect(() => {
    if (!sidebarRef.current) return;
    OutsideAlerter(sidebarRef, () => {
      setMenuStatus(menuStatus.map((status) => false));
    });
  }, [sidebarRef]);

  if (sideBarData.length > 0) data = sideBarData;

  const lists = useMemo(() => {
    if (!navigationFunctions) return data;
    const menus = [];
    const settingSet = new Set(navigationFunctions);
    const newData = data.filter(item => !item.code || settingSet.has(item.code));
    const new2ndData = newData.filter((item, index) => {
      menus[index] = false;
      // 判斷『二級選單』是否有顯示權限
      if (item.menu) {
        item.menu = item.menu.filter(list => {
          if (!list.code || settingSet.has(list.code)) {
            return true;
          }
        });
        if (item.menu.length > 0) return item;
      } else {
        return item;
      }
    });
    setMenuStatus(menus);
    return (new2ndData);
  }, [navigationFunctions, setMenuStatus]);


  const sidebarFocus = useCallback((e, index) => {
    let arr = [];
    arr = arr.concat(menuStatus);
    arr[index] = true;
    setMenuStatus(arr);
  }, [menuStatus, setMenuStatus]);

  const sidebarOutFocus = useCallback((e, index) => {
    let arr = [];
    arr = arr.concat(menuStatus);
    arr[index] = false;
    setMenuStatus(arr);
  }, [menuStatus, setMenuStatus]);

  return (
    <UiSideBar ref={sidebarRef}>
      <BlindBrick className='accesskey' id='left_container' accessKey='l' href='#left_container' title='左方選單區塊' />
      <UiSideBarItemsContainer>
        {
          data && lists.filter(item => {
            return isOwner ? item : item.isPublic === true;
          }).map((item, index) => {
            const isActive = item.menu ? item.menu.some(list => list.active === targetPage) : (targetPage.indexOf(item.active) !== -1);
            const today = Date.parse(new Date().toDateString());
            const startDate = Date.parse(item?.new?.startDate).valueOf();
            const endDate = Date.parse(item?.new?.endDate).valueOf();

            return (
              <UiSideBarItem key={index} active={isActive ? true : false} disabled={item.disabled ? true : false} onFocus={(e) => sidebarFocus(e, index)} >
                {
                  !item.menu && <NavLink
                    to={`${item.link}#center_container`}
                    onClick={item.onClick}
                  >
                    <Icon.Svg name={item.icon} color={isActive ? '#ec7963' : '#e4e7ec'} />
                    {item.label}
                  </NavLink>
                }
                {
                  item.menu && <UiSideBarSecond showMenu={menuStatus[index]} active={isActive ? true : false} >
                    <Icon.Svg name={item.icon} color={isActive ? '#ec7963' : '#e4e7ec'} />
                    <button>{item.label}</button>
                    {
                      today >= startDate && today < endDate && <UiNew>NEW</UiNew>
                    }
                    <UiSideBarSecondItemIcon>
                      <Icon.Svg name='ChevronRight' color="#e4e7ec" />
                    </UiSideBarSecondItemIcon>
                    <UiSideBarSecondBox tabIndex={-1} >
                      {
                        item.menu.map((item2, index2) => {
                          const isActive2nd = targetPage.indexOf(item2.active) !== -1;
                          const startDate = Date.parse(item2.new?.startDate).valueOf();
                          const endDate = Date.parse(item2.new?.endDate).valueOf();
                          return (
                            <UiSideBarSecondItem active={isActive2nd ? true : false} disabled={item2.disabled} key={index2} onBlur={(e) => { index2 === item.menu.length - 1 && sidebarOutFocus(e, index); }}>
                              <NavLink to={`${item2.link}#center_container`} onClick={item2.onClick} disabled={item2.disabled} >
                                <Icon.Svg name={item2.icon} color={isActive ? '#ec7963' : '#e4e7ec'} />
                                <div tabIndex={-1}>{item2.label}</div>
                                {
                                  today >= startDate && today < endDate && <UiNew>NEW</UiNew>
                                }
                              </NavLink>
                            </UiSideBarSecondItem>
                          );
                        })
                      }
                    </UiSideBarSecondBox>
                  </UiSideBarSecond>
                }
              </UiSideBarItem>
            );
          })
        }
      </UiSideBarItemsContainer>
      {
        (
          organizationCustomLogoUsageSetting === 'allow' &&
          organizationLogoOriginSetting === 'custom'
        ) &&
        <UiLogo>
          <Link to='/home'>
            <img src={logo} alt='onelink親師生數位雲端互動平台，點擊可回到首頁' />
          </Link>
        </UiLogo>
      }
    </UiSideBar>
  );
};

SideBar.propTypes = {
  type: PropTypes.string,
  sideBarData: PropTypes.array
};
