import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import {
  getGroupAnnouncements as getGroupAnnouncementsApi,
  createGroupAnnouncement as createGroupAnnouncementApi,
  updateGroupAnnouncement as updateGroupAnnouncementApi,
  pinGroupAnnouncement as pinGroupAnnouncementApi,
  cancelPinGroupAnnouncement as cancelPinGroupAnnouncementApi,
  deleteGroupAnnouncement as deleteGroupAnnouncementApi,
  confirmGroupAnnouncementCheckListItem as confirmGroupAnnouncementCheckListItemApi,
  unconfirmGroupAnnouncementCheckListItem as unconfirmGroupAnnouncementCheckListItemApi,
  getGroupAnnouncementById as getGroupAnnouncementByIdApi
} from 'services/api/home/announcement';
import { useAlert, ALERT_MESSAGE } from 'utils/hooks/useAlert';



const GET_ANNOUNCEMENTS = 'GET_ANNOUNCEMENTS';
const CLEAR_ANNOUNCEMENTS = 'CLEAR_ANNOUNCEMENTS';
const CREATE_ANNOUNCEMENT = 'CREATE_ANNOUNCEMENT';
const UPDATE_ANNOUNCEMENT = 'UPDATE_ANNOUNCEMENT';
const PIN_ANNOUNCEMENT = 'PIN_ANNOUNCEMENT';
const CANCEL_PIN_ANNOUNCEMENT = 'CANCEL_PIN_ANNOUNCEMENT';
const DELETE_ANNOUNCEMENT = 'DELETE_ANNOUNCEMENT';
const CONFIRM_ANNOUNCEMENT_CHECKLISTITEM = 'CONFIRM_ANNOUNCEMENT_CHECKLISTITEM';
const UNCONFIRM_ANNOUNCEMENT_CHECKLISTITEM = 'UNCONFIRM_ANNOUNCEMENT_CHECKLISTITEM';
const GET_ANNOUNCEMENT = 'GET_ANNOUNCEMENT';

const initState = {
  announcement: null,
  announcements: {
    data: [],
    total: 0,
  },
};

const actions = {
  getAnnouncements: ({ data, total }) => ({
    type: GET_ANNOUNCEMENTS,
    payload: { data, total }
  }),
  clearAnnouncements: () => ({
    type: CLEAR_ANNOUNCEMENTS,
  }),
  createAnnouncement: params => ({
    type: CREATE_ANNOUNCEMENT,
    payload: params
  }),
  updateAnnouncement: params => ({
    type: UPDATE_ANNOUNCEMENT,
    payload: params
  }),
  pinAnnouncement: params => ({
    type: PIN_ANNOUNCEMENT,
    payload: params
  }),
  cancelPinAnnouncement: params => ({
    type: CANCEL_PIN_ANNOUNCEMENT,
    payload: params
  }),
  deleteAnnouncement: params => ({
    type: DELETE_ANNOUNCEMENT,
    payload: params
  }),
  confirmAnnouncementCheckListItem: params => ({
    type: CONFIRM_ANNOUNCEMENT_CHECKLISTITEM,
    payload: params
  }),
  unconfirmAnnouncementCheckListItem: params => ({
    type: UNCONFIRM_ANNOUNCEMENT_CHECKLISTITEM,
    payload: params
  }),
  getAnnouncement: params => ({
    type: GET_ANNOUNCEMENT,
    payload: params
  })
};

export const useAnnouncement = () => {
  const dispatch = useDispatch();
  const { organizationId,classId } = useParams();
  const history = useHistory();
  const { setAlert } = useAlert();
  const { announcement, announcements } = useSelector(state => state.announcement);

  const getAnnouncements = async params => {
    const { isSuccess, data } = await getGroupAnnouncementsApi(organizationId,classId)(params);
    if (!isSuccess) return;
    const { announcements, total } = data;

    dispatch(actions.getAnnouncements({ data: announcements, total }));
    return { announcements, total };
  };
  const clearAnnouncements = () => dispatch(actions.clearAnnouncements());

  const createAnnouncement = async params => {
    dispatch(actions.createAnnouncement(params));
    const { isSuccess, error } = await createGroupAnnouncementApi(organizationId,classId)(params);
    try {
      if (!isSuccess) throw error;
      setAlert(ALERT_MESSAGE.CREATE_SUCCESS, 'success');
      return isSuccess;
    } catch (error) {
      const { errorCode } = error;
      setAlert(ALERT_MESSAGE.CREATE_FAIL, 'error', errorCode);
      return isSuccess;
    }
  };

  const updateAnnouncement = announcementId => async params => {
    dispatch(actions.updateAnnouncement(params));
    const { isSuccess, error } = await updateGroupAnnouncementApi(organizationId,classId, announcementId)(params);
    try {
      if (!isSuccess) throw error;
      setAlert(ALERT_MESSAGE.UPDATE_SUCCESS, 'success');
      return isSuccess;
    } catch (error) {
      const { errorCode } = error;
      setAlert(ALERT_MESSAGE.UPDATE_FAIL, 'error', errorCode);
      return isSuccess;
    }
  };

  const pinAnnouncement = async id => {
    const { isSuccess } = await pinGroupAnnouncementApi(organizationId,classId, id)();
    try {
      if (!isSuccess) return;
      setAlert(ALERT_MESSAGE.UPDATE_SUCCESS, 'success');
    } catch (error) {
      const { errorCode } = error;
      setAlert(ALERT_MESSAGE.UPDATE_FAIL, 'error', errorCode);
    }
    dispatch(actions.pinAnnouncement({ announcementId: id }));
    return isSuccess;
  };

  const cancelPinAnnouncement = async id => {
    const { isSuccess } = await cancelPinGroupAnnouncementApi(organizationId,classId, id)();
    try {
      if (!isSuccess) return;
      setAlert(ALERT_MESSAGE.UPDATE_SUCCESS, 'success');
    } catch (error) {
      const { errorCode } = error;
      setAlert(ALERT_MESSAGE.UPDATE_FAIL, 'error', errorCode);
    }
    dispatch(actions.cancelPinAnnouncement({ announcementId: id }));
    return isSuccess;
  };

  const deleteAnnouncement = async id => {
    const { isSuccess } = await deleteGroupAnnouncementApi(organizationId,classId, id)();
    try {
      if (!isSuccess) return;
      setAlert(ALERT_MESSAGE.DELETE_SUCCESS, 'success');
    } catch (error) {
      const { errorCode } = error;
      setAlert(ALERT_MESSAGE.DELETE_FAIL, 'error', errorCode);
    }
    dispatch(actions.deleteAnnouncement({ announcementId: id }));
    return isSuccess;
  };

  const confirmAnnouncementCheckListItem = async (announcementId, checkListId) => {
    const { isSuccess } = await confirmGroupAnnouncementCheckListItemApi(
      organizationId,
      classId,
       announcementId,
       checkListId);
    try {
      if (!isSuccess) return;
      setAlert(ALERT_MESSAGE.UPDATE_SUCCESS, 'success');
    } catch (error) {
      const { errorCode } = error;
      setAlert(ALERT_MESSAGE.UPDATE_FAIL, 'error', errorCode);
    }
    dispatch(actions.deleteAnnouncement({ announcementId: checkListId }));
    return isSuccess;
  };

  const unconfirmAnnouncementCheckListItem = async (announcementId, checkListId) => {
    const { isSuccess } = await unconfirmGroupAnnouncementCheckListItemApi(
      organizationId,
      classId,
       announcementId,
       checkListId);
    try {
      if (!isSuccess) return;
      setAlert(ALERT_MESSAGE.UPDATE_SUCCESS, 'success');
    } catch (error) {
      const { errorCode } = error;
      setAlert(ALERT_MESSAGE.UPDATE_FAIL, 'error', errorCode);
    }
    dispatch(actions.deleteAnnouncement({ announcementId: checkListId }));
    return isSuccess;
  };

  const getAnnouncementById = async id => {
    const { isSuccess, data } = await getGroupAnnouncementByIdApi(organizationId,classId, id)();
    if (!isSuccess) {
      history.goBack();
      return;
    }
    dispatch(actions.getAnnouncement(data));
    return data;
  };

  useEffect(() => {
    clearAnnouncements();
  }, [classId]);

  return [
    { announcement, announcements }, // state
    {
      getAnnouncements,
      clearAnnouncements,
      createAnnouncement,
      updateAnnouncement,
      pinAnnouncement,
      cancelPinAnnouncement,
      deleteAnnouncement,
      confirmAnnouncementCheckListItem,
      unconfirmAnnouncementCheckListItem,
      getAnnouncementById
    }, // eventHanlder
  ];
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case GET_ANNOUNCEMENTS: {
      const { data, total } = action.payload;
      const newData = state.announcements.data.concat(data);
      return {
        ...state,
        announcements: {
          data: newData,
          total
        }
      };
    }
    case CLEAR_ANNOUNCEMENTS: {
      return {
        ...state,
        announcements: {
          ...initState.announcements
        }
      };
    }
    case GET_ANNOUNCEMENT: {
      const data = action.payload;
      return {
        ...state,
        announcement: data
      };
    }
    default:
      return state;
  }
};

export default reducer;
