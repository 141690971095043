import React from 'react';
import { HomeGroupLayout, HomeworkList } from 'components';


/**
 * 在這邊描述這個組件
 */

export const HomeWorkPage = () => {
  return (
    <HomeGroupLayout>
      <HomeworkList />
    </HomeGroupLayout>
  );
};

HomeWorkPage.propTypes = {

};


