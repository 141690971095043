import React from 'react';
import { HomeGroupLayout, ExamResultTable } from 'components';


/**
 * 測驗結果頁面
 */

export const HomeExamResultPage = () => {
  return (
    <HomeGroupLayout>
      <ExamResultTable />
    </HomeGroupLayout>
  );
};

HomeExamResultPage.propTypes = {

};


