import React from 'react';
import { UiAccountEditPage } from './AccountEditPage.style';
import { AccountForm } from 'components';

/**
 * 後臺帳號編輯
 */

export const AccountEditPage = props => {
  return (
    <UiAccountEditPage>
      <AccountForm {...props} />
    </UiAccountEditPage>
  );
};

AccountEditPage.propTypes = {

};


