/* eslint-disable max-len */
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { EDIT_STATUS } from 'constants/index';
import { useParams, useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import {
  Table,
  Button,
  IconInput,
  Select,
  TimePicker,
  Icon,
  Breadcrumbs,
  Loading,
  BsModal
} from 'components'; //IconInput, Select,
import { UiTable, UiActionBox, UiflexBox } from 'styles';
import { useSetState } from 'utils/hooks/useSetState';
import { useSessions } from 'store/sessions';
import { useClass } from 'store/class';
import { useUser } from 'store/user';
import { useAlert } from 'utils/hooks/useAlert';
import { useDateInterval } from 'utils/hooks/useDateInterval';
import { SESSION_START_TIME_TYPE } from 'constants/index';
import { getTime } from 'date-fns';
import { UiTableInfo } from 'styles';
import { UiButtonBox, UiStartTimeIcon, UiIconBox } from './SessionManagementTable.style';

/**
 * 課程列表
 */

const NOW = 'NOW';
const PROCESSING = 'PROCESSING';
const PAST = 'PAST';
const FUTURE = 'FUTURE';
const ALL = 'ALL';

const SESSION_START_TIME_TYPE_INFO = {
  [SESSION_START_TIME_TYPE.YET]: '尚未開始',
  [SESSION_START_TIME_TYPE.ALREADY]: '已結束',
  [SESSION_START_TIME_TYPE.PROCESSING]: '進行中'
};

const selectOptions = [
  {
    name: '今日課程',
    value: NOW
  },
  {
    name: '當前課程',
    value: PROCESSING
  },
  {
    name: '過去課程',
    value: PAST
  },
  {
    name: '未來課程',
    value: FUTURE
  },
  {
    name: '所有課程',
    value: ALL
  }
];

export const SessionManagementTable = ({
  noFilter = false,
  defaultDuration = ALL
}) => {
  const history = useHistory();
  const { setAlert } = useAlert();
  const [, { todayInterval, processingInterval, pastInterval, futureInterval, allInterval }] = useDateInterval();
  const { organizationId, classId } = useParams();
  const [{ sessions },
    {
      getOrganizationSessions,
      changeSessionRecordingStatus,
      deleteOrganizationGroupSession
    }] = useSessions();
  const [{ classes }] = useClass();
  const [{ myOrganization }] = useUser();
  const [
    { sessionName,
      groupName,
      hostName,
      hostMobileNumber,
      startAt,
      endAt,
      nowPage,
      rowsPage,
      goal,
      condition,
      dateInterval,
      schema,
      isLoading,
      targetSessionId,
      targetTimeSpanId
    }, setState
  ] = useSetState({
    sessionName: '',
    groupName: '',
    hostName: '',
    hostMobileNumber: '',
    startAt: null,
    endAt: null,
    nowPage: 0,
    rowsPage: 10,
    goal: '',
    condition: '',
    dateInterval: 'now',
    schema: {},
    isLoading: true,
    targetSessionId: '',
    targetTimeSpanId: ''
  });

  const [isOpen, setIsOpen] = useState(false);
  const [targetSession, setTargetSession] = useState('');

  //麵包屑
  const BreadcrumbsList = [
    {
      name: '班級管理',
      link: `/organization/${organizationId}/class`,
      icon: 'school'
    },
    {
      name: `${classes.dataInfo.name}-課程總覽`,
      link: `/organization/${organizationId}/class/${classId}/customer`,
      icon: 'importContacts'
    }
  ];

  const defaultSchema = {
    groupName: {
      name: '班級名稱',
      defaultValue: ''
    },
    name: {
      name: '課程名稱',
      defaultValue: ''
    },
    subject: {
      name: '科目',
      defaultValue: '--'
    },
    recordingStatusText: {
      name: '狀態',
      defaultValue: '--'
    },
    startTime: {
      name: '時間',
      defaultValue: ''
    },
    classTime: {
      name: '課程時數',
      defaultValue: ''
    },
    hostName: {
      name: '授課教師',
      defaultValue: ''
    },
    resourceId: {
      name: '上課資源',
      defaultValue: ''
    },
    attendance: {
      name: '出勤',
      defaultValue: '-'
    }
  };

  const selectInputOptions = [
    {
      name: '課程名稱',
      value: 'sessionName',
    },
    !classId &&
    {
      name: '班級名稱',
      value: 'groupName',
    },
    {
      name: '授課教師名稱',
      value: 'hostName'
    }

  ];

  const showMonitor = useMemo(() => {
    if (myOrganization?.organization?.adminMonitorSessionSetting) {
      return myOrganization.organization.adminMonitorSessionSetting === 'disallow' ? false
        : true;
    }
    return false;
  }, [myOrganization]);

  const resetFormData = (value) => {
    setState({
      sessionName: '',
      groupName: '',
      hostName: '',
      hostMobileNumber: '',
      startAt: '',
      rowsPage: 10,
      nowPage: 0,
      [goal]: value
    });
  };

  const changePage_Rows = params => {
    const { newPage, newRowsPage } = params;
    setState({
      nowPage: newPage,
      rowsPage: newRowsPage
    });
  };

  const onSubmitHandler = (value, key) => {
    if (key === 'condition') {
      if (goal === '') {
        setAlert('請選擇搜尋目標!', 'warning');
        return;
      } else {
        resetFormData(value);
      }
    }
    setState({
      [key]: value,
      nowPage: 0
    });
  };

  const onchangeHandler = (value, key) => {
    setState({
      [key]: value,
      nowPage: 0
    });
  };

  const getDateDuration = dateType => {
    switch (dateType) {
      case NOW:
        return { ...todayInterval() };
      case PAST:
        return { ...pastInterval() };
      case PROCESSING:
        return { ...processingInterval() };
      case FUTURE:
        return { ...futureInterval() };
      default:
      case ALL:
        return { ...allInterval() };
    }
  };

  const onSelectChangeHandler = selected => {
    const dateParams = getDateDuration(selected);
    setState({
      nowPage: 0,
      dateInterval: selected,
      ...dateParams
    });
  };
  const goCreateSession = () => {
    history.push(`/organization/${organizationId}/class/${classId}/session/${EDIT_STATUS.CREATE}`);
  };

  useEffect(() => {
    if (classId) delete defaultSchema.groupName;
    setState({
      schema: defaultSchema
    });
  }, []);

  const fetchSessions = async () => {
    setState({ isLoading: true });
    const dateParams = startAt && endAt ? { startAt, endAt } : getDateDuration(defaultDuration);

    const params = {
      sessionName,
      groupName,
      hostName,
      hostMobileNumber,
      ...dateParams,
      nowPage,
      rowsPage
    };
    await getOrganizationSessions(params);
    setState({ isLoading: false });
  };

  useEffect(() => {
    fetchSessions();
  }, [
    sessionName,
    groupName,
    hostName,
    hostMobileNumber,
    startAt,
    endAt,
    nowPage,
    rowsPage]);

  const getStartTimeType = (startAt, endAt) => {
    const now = new Date().getTime();
    if (now < startAt) return SESSION_START_TIME_TYPE.YET;
    if (now > endAt) return SESSION_START_TIME_TYPE.ALREADY;
    return SESSION_START_TIME_TYPE.PROCESSING;
  };

  // eslint-disable-next-line react/prop-types
  const StartTimeIcon = ({ children, type }) => (
    <UiStartTimeIcon type={type}>
      <Icon
        className="icon"
        name="info"
        size="small"
        title={SESSION_START_TIME_TYPE_INFO[type]}
        haveBg={false}
      />
      <span>{children}</span>
    </UiStartTimeIcon>
  );

  const formatSessionsData = data => data.map(item => {
    const startTimeType = getStartTimeType(item.startAt, item.endAt);
    return {
      ...item,
      classTime: ((item.endAt - item.startAt) / 60 / 1000) + '分',
      startTime: (
        <StartTimeIcon type={startTimeType}>
          {format(new Date(item.startAt), 'yyyy-MM-dd HH:mm')} ~ {format(new Date(item.endAt), 'HH:mm')}
        </StartTimeIcon>
      ),
      attendance: (
        <>
          <div>{item.hostAttendance ? item.hostName : ''}</div>
          {item.hostAttendance?.checkInAt ? format(new Date(item.hostAttendance?.checkInAt), 'HH:mm') : '-'}
          {' / '}
          {item.hostAttendance?.checkOutAt ? format(new Date(item.hostAttendance?.checkOutAt), 'HH:mm') : '-'}
        </>
      ),
    };
  });

  // eslint-disable-next-line react/prop-types
  const ActionComponents = ({ params: { id: sessionId, timeSpanId, resourceId, endAt, startAt, recordingStatus, name } }) => {
    const isExpired = (getTime(new Date(endAt)) < getTime(new Date())) ? true : false;
    const startTimeType = getStartTimeType(startAt, endAt);
    const ButtonClick = () => {
      const classRoomUrl =
        `${process.env.REACT_APP_ONEBOOK_DOMAIN}/${resourceId}`;
      window.open(classRoomUrl, '_blank');
    };
    const onRecordingChange = async () => {
      const payload = {
        action: ''
      };

      if (recordingStatus === 'ready' || recordingStatus === 'stopped') {
        payload.action = 'start';
      } else if (recordingStatus === 'recording') {
        payload.action = 'stop';
      } else {
        return;
      }
      await changeSessionRecordingStatus(sessionId, timeSpanId, payload);
      await fetchSessions();
    };
    return (
      <UiIconBox>
        {
          (
            startTimeType === SESSION_START_TIME_TYPE.PROCESSING ||
            (startTimeType === SESSION_START_TIME_TYPE.ALREADY && recordingStatus === 'recording')) &&
          showMonitor &&
          <Icon
            title={(recordingStatus === 'ready' || recordingStatus === 'stopped') ? '開始錄影' : '結束錄影'}
            onClick={onRecordingChange}
            name='videocam' className={recordingStatus === 'recording' ? 'recordingIcon' : ''} />
        }
        {
          (classId && !isExpired) && (
            <Icon
              title="編輯"
              onClick={() =>
                history.push(
                  `/organization/${organizationId}/class/${classId}/session/${sessionId}/${EDIT_STATUS.EDIT}`
                )}
              name='edit'
            />
          )
        }
        {
          startTimeType === SESSION_START_TIME_TYPE.PROCESSING && showMonitor &&
          <Icon
            title="監課"
            onClick={ButtonClick}
            name='visibility' />
        }
        <Icon
          title="課程報表"
          onClick={() =>
            history.push(
              `/organization/${organizationId}/session/${sessionId}/timespan/${timeSpanId}/summary`
            )}
          name='artTrack' />
        <Icon
          title="刪除"
          onClick={() => modalToggle(name, sessionId, timeSpanId)}
          name='delete' />
      </UiIconBox>
    );
  };

  const modalToggle = (name, sessionId, timeSpanId) => {
    name && setTargetSession(name);
    sessionId && setState({
      targetSessionId: sessionId,
      targetTimeSpanId: timeSpanId
    });
    setIsOpen(!isOpen);
  };

  const onDeleteSession = async () => {
    setState({ isLoading: true });
    const isSuccess = await deleteOrganizationGroupSession(targetSessionId, targetTimeSpanId);
    setState({ isLoading: false });
    modalToggle();
    if (isSuccess) await fetchSessions();
  };

  return (
    <UiTable>
      {
        classId && <Breadcrumbs list={BreadcrumbsList} />
      }
      {
        !noFilter && (
          <UiActionBox>
            <UiflexBox>
              <Select
                label="搜尋目標" options={selectInputOptions}
                submitHandler={value => onSubmitHandler(value, 'goal')}
                value={goal}
              />
              <IconInput
                placeholder="搜尋"
                onChange={value => onSubmitHandler(value, 'condition')}
                value={condition}
              />
              <Select
                options={selectOptions}
                value={defaultDuration}
                submitHandler={value => onSelectChangeHandler(value)}
                label="課程區間"
              />
              {
                dateInterval === NOW &&
                <>
                  <TimePicker
                    label="選擇課程開始時間"
                    value={startAt}
                    onChange={value => onchangeHandler(value, 'startAt')}
                  />
                  <TimePicker
                    label="選擇課程結束時間"
                    value={endAt}
                    onChange={value => onchangeHandler(value, 'endAt')}
                  />
                </>
              }
            </UiflexBox>
            {classId &&
              <UiButtonBox>
                <Button
                  buttonColor="info"
                  icon='exitToApp'
                  onClick={() => history.goBack()}
                >
                  回到上一頁
                </Button>
                <Button
                  buttonColor='highlight'
                  icon='add'
                  onClick={() => goCreateSession()}>新增課程</Button>
              </UiButtonBox>
            }

          </UiActionBox>
        )
      }
      {
        isLoading ? (
          <Loading />
        ) : (
          sessions.data.length > 0 ? (
            <Table
              data={formatSessionsData(sessions.data)}
              schema={schema}
              stateShowText={[]}
              changePage_Rows={changePage_Rows}
              totalPage={sessions.total}
              ActionComponents={ActionComponents}
              nowPage={nowPage}
            />
          ) : <UiTableInfo>尚無課程</UiTableInfo>
        )
      }
      <BsModal open={isOpen} title="是否要刪除此課程" onOk={onDeleteSession} onCancel={modalToggle} isLoading={isLoading}>
        確認刪除課程 <span style={{ color: '#f00' }}>{`"${targetSession}"`}</span>
      </BsModal>
    </UiTable>
  );
};

SessionManagementTable.propTypes = {
  noFilter: PropTypes.bool,
  defaultDuration: PropTypes.oneOf([ALL, NOW, PROCESSING, PAST, FUTURE, ALL])
};
