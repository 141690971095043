import styled from 'styled-components';

export const UiSessionPerformance = styled.div`

`;

export const UiPerformanceTitle = styled.div`
	margin: 66px 0 48px;
	font-size: 25px;
	font-weight: 700;
`;

export const UiPerformanceForUser = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;

	> div {
		margin: 0 10px;

		&:first-child {
			margin-left: 0;
		}

		&:last-child {
			margin-right: 0;
		}
	}
`;

export const UiPerformanceCard = styled.div`
	padding: 1rem;
	width: 25%;
	background-color: #fafafc;
	border-radius: 15px;
`;

export const UiPerformanceCardTitle = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 17px;
	color: #4e5d78;
`;

export const UiPerformanceCardIcon = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5px;
	background-color: #000;
	border-radius: 50%;

	&.trophy {
		background-color: #ffab00;
	}

	&.running {
		background-color: #ff5630;
	}

	&.raisehand {
		background-color: #377dff;
	}

	&.messageWaveform {
		background-color: #38cb89;
	}
`;

export const UiPerformanceCardContent = styled.div`
	font-size: 40px;
	font-weight: 700;
`;

export const UiPerformanceCardAverage = styled.div`
	color: #8b90a0;
`;