import styled from 'styled-components';
import {
  Avatar as MatAvatar,
} from '@material-ui/core';

export const UiButtonBox = styled.div`
	display: flex;
	justify-content: flex-end;
`;

export const UiAvatar = styled(MatAvatar)`
	margin: auto;
`;
