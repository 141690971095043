import React from 'react';
import { HomeGroupLayout, HomeVideoTable } from 'components';


/**
 * 影音
 */

export const HomeVideoPage = () => {
  return (
    <HomeGroupLayout>
      <HomeVideoTable/>
    </HomeGroupLayout>
  );
};
