import { stringify } from 'query-string';
import { Api } from 'services/api';


/**
 * 測驗列表
 */

 export const getGroupOnlineQuizzes = async (groupId, params) => {
  const { nowPage = 0, rowsPage, subjectCode,productCode, unansweredOnly } = params;
  const queryParams = {
    limit: rowsPage ? rowsPage : 10,
  };
  if (rowsPage && nowPage * rowsPage !== 0) {
    queryParams.skip = nowPage * rowsPage;
  }
  if(subjectCode){
    queryParams.subjectCode = subjectCode;
  }
  if(unansweredOnly){
    queryParams.unansweredOnly = unansweredOnly;
  }
  if(productCode){
    queryParams.productCode = productCode;
  }
  const querystring = stringify(queryParams);
  const response = await Api.get(`/groups/${groupId}/online-quizzes?${querystring}`);
  return response;
};

/**
 * 派卷
 */

export const createGroupOnlineQuizzes = async (groupId,payload) => {
  const response = await Api.post(`/groups/${groupId}/online-quizzes`,payload);
  return response;
};

/**
 * 立即派卷
 */

 export const createImmediateGroupOnlineQuizzes = async (groupId,payload) => {
  const response = await Api.post(`/groups/${groupId}/online-quizzes/immediate`,payload);
  return response;
};

/**
 * 修改測驗
 */

export const updateGroupOnlineQuizzes = async (groupId,examId,payload) => {
  const response = await Api.put(`/groups/${groupId}/online-quizzes/${examId}`,payload);
  return response;
};

/**
 * 終止測驗
 */

export const terminateGroupOnlineQuizzes = async (groupId,examId) => {
  const response = await Api.post(`/groups/${groupId}/online-quizzes/${examId}/terminate`);
  return response;
};

/**
 * 刪除測驗
 */

 export const deleteGroupOnlineQuizzes = async (groupId, examId) => {
  const response = await Api.delete(`/groups/${groupId}/online-quizzes/${examId}`);
  return response;
};

/**
 * 獲取考卷選項
 */

 export const getGroupOnlineQuizzesOptions = async (groupId) => {
  const response = await Api.get(`/groups/${groupId}/online-quizzes/options`);
  return response;
};
