import React, { useEffect } from 'react';
import { Switch, useLocation, useHistory } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom';

import { ROLES } from 'constants/index';
import { FORGETPASSWORD, REGISTER, RULE } from 'constants/index';
import { useUser } from 'store/user';
import RenderRoutes from 'routes/RenderRoutes';
import { getAuthRoutes } from 'routes/authRoutes';
import { COMMON_PATH } from 'routes/routesConfig/constants';


const AppRoutes = () => {

  const [{ profile }, { checkLogin, getUserProfile }] = useUser();
  const forgetPassword = Boolean(useRouteMatch(`/${FORGETPASSWORD}`));
  const register = Boolean(useRouteMatch(`/${REGISTER}`));
  const rule = Boolean(useRouteMatch(`/${RULE}`));

  const location = useLocation();
  const history = useHistory();

  const checkUserLoginStatus = async () => {

    if (profile.id) {

      // 如果登入狀態 在登入頁的話就跳轉
      if (location.pathname === COMMON_PATH.LOGIN) {
         // eslint-disable-next-line max-len
         profile.role === ROLES.ADMIN ? window.location.href = `https://onelink-admin${process.env.REACT_APP_ENV === 'release' ? '' : '-' + process.env.REACT_APP_ENV}.oneclass.com.tw/admin` : history.push('/home');
      }

    } else {

      // 檢查是否有token
      const isLogin = await checkLogin();

      // 登入中
      if (isLogin) {
        getUserProfile();
      } else {
        // 沒登入狀態 導回登入頁
        if (location.pathname !== COMMON_PATH.LOGIN) {
          if ((forgetPassword || register || rule)) {
            return;
          } else {
            history.push(COMMON_PATH.LOGIN);
          }
        }
      }
    }
  };

  useEffect(() => {
    checkUserLoginStatus();
  }, [location, profile]);

  return (
    <Switch>
      <RenderRoutes
        routes={getAuthRoutes(profile.role)}
      />
    </Switch>
  );
};


export default AppRoutes;
