import React from 'react';
import PropTypes from 'prop-types';

import { useUser } from 'store/user';
import {
  Tab as MatTab,
} from '@material-ui/core';
import {
  UiContentTabs,
  UiMatTabs,
} from './ContentTabs.style';

export const ContentTabs = ({ hostId, tabIndex, onChange, }) => {
  const [{ profile }] = useUser();

  /* change Tab */
  const changeHandler = (e, newTabIndex) => {
    onChange(newTabIndex);
  };

  return (
    <UiContentTabs>
      <UiMatTabs value={tabIndex} onChange={changeHandler}>
        <MatTab label='機構資源'/>
        <MatTab label='公開資源'/>
        <MatTab label='我的資源' disabled={hostId !== profile.id} />
      </UiMatTabs>
    </UiContentTabs>
  );
};

export default ContentTabs;

ContentTabs.propTypes = {
  hostId: PropTypes.string,
  tabIndex: PropTypes.string,
  onChange: PropTypes.func,
};