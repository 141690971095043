import { createMuiTheme } from '@material-ui/core/styles';
import { css } from 'styled-components';

const device = {
  desktop: '120em',
  notebook: '80em',
  pad: '62em',
  phone: '48em'
};

const media = Object.keys(device).reduce((accumulator, label) => {
  accumulator[label] = (...args) => css`
		@media (max-width: ${device[label]}) {
			${css(...args)}
		}
`;
  return accumulator;
}, {});


const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Noto Sans TC',
      'sans-serif'
    ].join(','),
    boxSizing: 'border-box'
  },
  palette: {
    main: {
      disable: '#828282',
      warning: '#FF9800',
      danger: '#F44336',
      rose: '#E91E63',
      success: '#038C8C',
      info: '#1A73E8',
      new:'#121232'
    },
    text: {
      normal: '#3c4858',
      sub: '#777777',
      placeholder: '#999999',
      show: '#00828F',
      active: '#64b5f6',
      white: '#ffffff',
      light: '#d0e0e2',
      greenblue: '#325b88',
    },
    line: {
      light: '#d0e0e2',
      dark: '#2a4865',
    },
    background: {
      main: '#F9F9F9',
      sec: '#dfeeff'
    },
    block: {
      white: '#ffffff',
      light: '#f4f3f0',
      lighthover: '#dfeeff',
    },
    btn: {
      normal: '#EC7963',
      normalhover: '#EE8773',
      menu: '#a1e4ed;',
      info: '#006db2',
      infohover: '#009be5',
      highlight: '#f38a04',
      highlighthover: '#f29f05',
      disable: '#F2F4F8',
      disablehover: '#F2F4F8',
      visibility: '#4C99A5',
      visibilityhover: '#5EA4AE',
      delete: '#f44336',
      deletehover: '#f6564b',
      bar: '#a1e6ef',
      barhover: '#71dae0',
      white: '#fff',
      new:'#121232',
      newhover:'#2A2A47',
      ui03:'#e4e7ec',
      ui03hover:'#f4f7fc',
      brand03:'#4C99A5',
      brand03hover:'#5EA4AE',
      cancel:'#FAFAFC',
      cancelhover:'#FAFAFC',
      error:'#F44336',
      errorhover:'#F6564B',
    },
    tab: {
      normal: '#a1e4ed',
      normalhover: '#71dae0',
      info: '#a9cff2',
      infohover: '#89bded',
      highlight: '#eed24f',
      highlighthover: '#dfc545',
    },
    primary: {
      main: '#8B90A0',
      sec: '#163e6f'
    },
    secondary: {
      main: '#D0E0E2',
    },
    backstage: {
      primary: {
        main: '#8B90A0'
      },
      block: {
        bar: '#64b5f6',
        title: '#0787cf',
      }
    },
    home: {
      primary: {
        main: '#06F3F9',
        sec: '#f4f3f0'
      }
    },
    user: {
      block: {
        bar: '#39C5BB',
        barhover: '#a1e6ef',
        title: '#ace5ed',
      }
    },
    dark: {
      block: '#18202b',
      blocktitle: '#232f3f',
      blockhover: '#2a303c',
    }
  },
  media,
  mediaWidth: {
    desktop: '1200px',
    notebook: '1024px',
    pad: '80%',
    phone: '90%'
  },
  table: {
    backstage: {
      titleColor: '#8B90A0',
      padding: '1rem',
      thBgColor: '#8B90A0',
      tdBgColorBlue: '#dfeeff',
      tdBgColorGray: '#f4f3f0',
      tdBgColorWhite: '#ffffff',
      thColor: '#ffffff',
      tdColor: '#004b62'
    },
    home: {
      titleColor: '#8B90A0',
      padding: '1rem',
      thBgColor: '#f4f3f0',
      tdBgColorBlue: '#dfeeff',
      tdBgColorGray: '#f4f3f0',
      tdBgColorWhite: '#ffffff',
      thColor: '#004b62',
      tdColor: '#004b62',
      tdColorDanger: '#f44336'
    }
  },
  input: {
    borderColor: '1px solid #00000023',
    borderHoverColor: '1px solid #acdae8'
  },
  text: {
    fontFamily: [
      'Noto Sans TC',
      'sans-serif'
    ].join(','),
  },
  oneClass: {
    header: {
      background: '#8B90A0'
    },
    text: {
      palette: {
        default: '#004b62',
        error: '#fd4232',
        warning: '#eb8602',
        info: '#305dc3',
        success: '#038c8c',
        normal: '#00828f'
      }
    },
    border: {
      palette: {
        default: '#d0e0e2',
        normal: '#00828f'
      }
    },
    button: {
      background: {
        default: '#00828f',
        highlight: '#f38a04',
        pink: '#f2766b',
        grey: '#a5a5a5'
      },
      text: {
        default: '#ffffff',
      },
      mouseOver: {
        background: {
          default: '#03a696',
          highlight: '#f29f05',
          pink: '#f0958c',
          grey: '#a5a5a5'
        }
      }

    }
  }
});



export default theme;
