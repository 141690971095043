import styled from 'styled-components';
import { FormHelperText } from '@material-ui/core';
export const UiTransferListModal = styled.div`
	display: flex;
	align-items: center;

	& > button {
		margin-right: 1rem;
	}
`;

export const UiFormHelperText = styled(FormHelperText)`
	color: #f44336;
`;

export const UiErrorMsg = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 350px;
`;
