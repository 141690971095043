import styled from 'styled-components';

import { ATTENDANCE_STATUS } from 'constants/index';
import { SectionCardItem } from 'components';
import { UiGridContainer, UiGridItem } from 'styles';

export const UiSessionSummary = styled.div`
	display: block;
`;

export const UiSectionCardTitleRow = styled(UiGridContainer)`
	font-size: 1rem;
`;

export const UiSectionCardRowItem = styled(SectionCardItem)`
	padding: 0;
`;



export const UiScreenshot = styled.div`
	padding: 1rem;
	width: 100%;
	text-align: center;

	> img {
		width: 100%;
	}

	> div {
		padding: 0.5rem 0;
		color: ${({ theme }) => theme.palette.primary.main};
	}
`;


export const UiColumn = styled(UiGridItem)`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	transform: ${({ x }) => `translatex(${x}%) `};
`;

export const UiColumnTimeInfo = styled(UiGridItem)`
	width: 100%;
`;

export const UiLine = styled.div`
	position: relative;
	margin: 0 auto;
	width: 0;
	height: 100%;
	min-height: 30px;
	border-left: 1px dotted #f00;
	z-index: 1;
`;

export const UiGridContainerRelative = styled(UiGridContainer)`
	position: relative;
	width: 100%;
`;

export const UiHeartbeatPacketWrapper = styled.div`
	position: absolute;
	top: 50%;
	left: 0;
	width: 100%;
	transform: translateY(-50%);
`;

export const UiColumnHeartbeatPacket = styled(UiGridItem)`
display: flex;
align-items: center;
justify-content: center;
padding: 0;
flex-wrap: wrap;

`;

export const UiAttendanceStatus = styled.div`
	color:
		${({ status }) => {
		switch (status) {
		case ATTENDANCE_STATUS.ABSENCE:
		return '#f00';
		case ATTENDANCE_STATUS.LATE:
		case ATTENDANCE_STATUS.LEAVE_EARLY:
		return '#ff9800';
		default:
		return 'inherit';
		}
		}};
`;


export const UiClassStatusWrapper = styled(UiColumn)`
	padding: 0 3rem;
	width: 100%;
	font-size: 0.8rem;
`;
