import React from 'react';
import PropTypes from 'prop-types';
import { UiCard, UiCardContent } from './Card.style';


/**
 * 卡牌組件
 */

export const Card = ({ header, children, footer, backgroundColor = '#fff' }) => {
  return (
    <UiCard backgroundColor={backgroundColor}>
      {header}
      <UiCardContent>
        {children}
      </UiCardContent>
      {footer}
    </UiCard>
  );
};

Card.propTypes = {
  header: PropTypes.node,
  children: PropTypes.node,
  footer: PropTypes.node,
  backgroundColor: PropTypes.string
};


