import React from 'react';
import PropTypes from 'prop-types';

import {
  FormControl,
  FormGroup,
  FormControlLabel,
} from '@material-ui/core';
import {
  UiContentListAll,
  UiTitleContainer,
  UiListContainer,
  UiCheckboxContainer,
  UiMatCheckbox,
} from './ContentListAll.style';

export const ContentListAll = ({ data = [], onChange }) => {
  return (
    <UiContentListAll>
      <UiTitleContainer>
        <span>列表</span>
      </UiTitleContainer>
      <UiListContainer>
        <FormControl component="fieldset">
          <FormGroup>
            {
              data.map(item => {
                return (
                  <UiCheckboxContainer key={item.id}>
                    <FormControlLabel
                      control={<UiMatCheckbox
                                  checked={item.isChecked}
                                  id={item.id}
                                  name={item.label}
                                  onChange={onChange} />}
                      label={`${item.label}(ID:${item.id})`} />
                  </UiCheckboxContainer>
                );
              })
            }
          </FormGroup>
        </FormControl>
      </UiListContainer>
    </UiContentListAll>
  );
};

export default ContentListAll;

ContentListAll.propTypes = {
  data: PropTypes.array,
  onChange: PropTypes.func,
};
