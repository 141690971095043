import styled from 'styled-components';

export const UiSectionCard = styled.div`
	display: block;
	border: 1px solid #d0e0e2;
	margin-bottom: 1rem;
`;

export const UiSectionCardTitle = styled.div`
padding: 0.5rem 1rem;
font-size: 1.5rem;
color:
	${(
		{ theme,
		primary,
		secondary }
	) => {
	if (primary) return theme.palette.common.white;
	if (secondary) return theme.palette.common.black;
	}};
border-bottom: 1px solid #d0e0e2;
background-color:
	${(
		{ theme,
		primary,
		secondary }
	) => {
	if (primary) return theme.palette.primary.main;
	if (secondary) return theme.palette.secondary.main;
	}};
`;

export const UiSectionCardContent = styled.div`
	padding: 0.5rem 1rem;
	background-color: ${({ theme }) => theme.palette.common.white};
`;
