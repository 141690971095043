import React from 'react';
import { HomeGroupLayout, InviteOrganizationGroupHostForm } from 'components';


/**
 * 機構邀請老師頁面
 */

export const HomeInviteOrganizationHostPage = () => {
  return (
    <HomeGroupLayout>
      <InviteOrganizationGroupHostForm />
    </HomeGroupLayout>
  );
};

