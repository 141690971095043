/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { useParams, useHistory, useLocation, } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useClass } from 'store/class';
import { useUser } from 'store/user';
import { getPageName } from 'utils/pagePathSlicer';
import { useAlert } from 'utils/hooks/useAlert';
import { useMappingTeacher } from 'utils/hooks/useMappingTeacher';
import { usePagination } from 'utils/hooks/usePagination';
import { useServerTime } from 'utils/hooks/useServerTime';
import { useSetState } from 'utils/hooks/useSetState';
import {
  getGroupExamList,
  attendGroupExamForGroupUser,
  getGroupExamOptions,
  deleteOrganizationGroupExam,
  editOrganizationGroupExam
} from 'services/api/home/onlineExam';
import ExamModal from '../HomeOnlineExam/ExamModal';
import { Button, Icon, BsModal, Loading, } from 'components';
import {
  Table as MatTable,
  Collapse,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  TablePagination,
} from '@material-ui/core';
import { format } from 'date-fns';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import emptyImage from 'assets/images/empty.png';
import {
  UiHomeOnlineExamTableV2,
  UiTitleRow,
  UiContentRow,
  UiCell,
  UiTableBody,
  UiNoDataBox,
  UiPictureBox,
  UiNoDataText,
  UiEditBox,
  UiFilterBox,
  UiSortBox,
  UiSort,
} from './HomeOnlineExamTableV2.style';
import SortImage from 'assets/images/sort.png';


/**
 * 線上測驗 V2
 */
const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

const TEST_STATUS = {
  NOT_READY: 'notReady',
  READY: 'ready', // 可以開始作答
  TO_BE_CORRECTED: 'toBeCorrected', // 批改非選
  FINISHED: 'finished', // 測驗結束可以看結果
  MISSED: 'missed', // 缺考
  ANSWERED: 'answered', // 作答完成
};

const StudentTable = ({ data = [], actionHandle }) => {
  const classes = useRowStyles();

  // 二層列表開關
  const changeCollapseStatus = (id, status) => {
    const nextExamData = examData.map(exam => {
      if (exam.id === id) {
        return {
          ...exam,
          isCollapseOpen: !status
        };
      } else {
        return {
          ...exam,
          isCollapseOpen: false
        };
      }
    });
    setState({
      examData: nextExamData
    });
  };

  const [{
    examData,
  }, setState] = useSetState({
    examData: [],
  });

  useEffect(() => {
    setState({
      examData: data,
    });
  }, [data]);

  return (
    <TableContainer>
      <MatTable>
        <TableHead>
          <UiTitleRow>
            <TableCell style={{ width: '48px', padding: '16px 9px' }} />
            <TableCell style={{ width: '200px' }}>測驗名稱</TableCell>
            <TableCell>學制科目</TableCell>
            <TableCell>開始時間</TableCell>
            <TableCell></TableCell>
          </UiTitleRow>
        </TableHead>
        <TableBody>
          {
            examData.map((exam, index) => {
              const {
                id,
                name,
                eduSubjectName,
                startAt,
                endAt,
                isCollapseOpen,
                period,
                status,
              } = exam;

              return (
                <>
                  <UiContentRow className={classes.root} isCollapseOpen={isCollapseOpen}>
                    <TableCell style={{ width: '48px', padding: '16px 9px' }}>
                      <IconButton size="small" onClick={() => changeCollapseStatus(id, isCollapseOpen)}>
                        {isCollapseOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                      </IconButton>
                    </TableCell>
                    <TableCell style={{ width: '200px' }}>{name}</TableCell>
                    <TableCell>{eduSubjectName}</TableCell>
                    <TableCell>{format(startAt, 'yyyy-MM-dd HH:mm')}</TableCell>
                    <TableCell>
                      {actionHandle(status, id, name, endAt, startAt, period)}
                    </TableCell>
                  </UiContentRow>
                  <UiTitleRow>
                    <TableCell style={{ padding: 0, paddingLeft: '48px', width: '100%' }} colSpan={7}>
                      <Collapse in={isCollapseOpen} timeout="auto" unmountOnExit>
                        <Box>
                          <MatTable size="small">
                            <TableHead>
                              <TableRow>
                                <UiCell style={{ width: '200px' }}>結束時間</UiCell>
                                <UiCell>作答時間</UiCell>
                              </TableRow>
                            </TableHead>
                            <UiTableBody>
                              <TableRow>
                                <UiCell>
                                  {format(endAt, 'yyyy-MM-dd HH:mm')}
                                </UiCell>
                                <UiCell>
                                  {period} 分鐘
                                </UiCell>
                              </TableRow>
                            </UiTableBody>
                          </MatTable>
                        </Box>
                      </Collapse>
                    </TableCell>
                  </UiTitleRow>
                </>
              );
            })
          }
        </TableBody>
      </MatTable>
    </TableContainer>
  );
};

const TeacherTable = ({ data = [], actionHandle, markersMap, onSort = () => { } }) => {
  const classes = useRowStyles();

  // 二層列表開關
  const changeCollapseStatus = (id, status) => {
    const nextExamData = examData.map(exam => {
      if (exam.id === id) {
        return {
          ...exam,
          isCollapseOpen: !status
        };
      } else {
        return {
          ...exam,
          isCollapseOpen: false
        };
      }
    });

    setState({
      examData: nextExamData
    });
  };

  const [{
    examData,
  }, setState] = useSetState({
    examData: [],
  });

  useEffect(() => {
    setState({
      examData: data,
    });
  }, [data]);

  return (
    <TableContainer>
      <MatTable>
        <TableHead>
          <UiTitleRow>
            <TableCell style={{ width: '48px', padding: '16px 9px' }} />
            <TableCell style={{ width: '200px' }}>測驗名稱</TableCell>
            <TableCell>學制科目</TableCell>
            <TableCell>
              <UiSortBox>
                開始時間
                <UiSort onClick={() => onSort('startTime')}>
                  <img src={SortImage} alt="sort" />
                </UiSort>
              </UiSortBox>
            </TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </UiTitleRow>
        </TableHead>
        <TableBody>
          {
            examData.map((exam, index) => {
              const {
                id,
                name,
                eduSubjectName,
                startAt,
                endAt,
                questionAmount,
                period,
                ownerNickname,
                markers,
                isCollapseOpen,
                status,
                paperId,
                testedUsers,
              } = exam;


              return (
                <React.Fragment key={id}>
                  <UiContentRow className={classes.root} isCollapseOpen={isCollapseOpen}>
                    <TableCell style={{ width: '48px', padding: '16px 9px' }}>
                      <IconButton size="small" onClick={() => changeCollapseStatus(id, isCollapseOpen)}>
                        {isCollapseOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                      </IconButton>
                    </TableCell>
                    <TableCell style={{ width: '200px' }}>{name}</TableCell>
                    <TableCell>{eduSubjectName}</TableCell>
                    <TableCell>{format(startAt, 'yyyy-MM-dd HH:mm')}</TableCell>
                    <TableCell>
                      {actionHandle(status, id, name, endAt, startAt, period, paperId, testedUsers)}
                    </TableCell>
                  </UiContentRow>
                  <UiTitleRow>
                    <TableCell style={{ padding: 0, paddingLeft: '48px' }} colSpan={7}>
                      <Collapse in={isCollapseOpen} timeout="auto" unmountOnExit>
                        <Box>
                          <MatTable size="small">
                            <TableHead>
                              <TableRow>
                                <UiCell>
                                  <UiSortBox>
                                    結束時間
                                    <UiSort onClick={() => onSort('endTime')}>
                                      <img src={SortImage} alt="sort" />
                                    </UiSort>
                                  </UiSortBox>
                                </UiCell>
                                <UiCell>題數</UiCell>
                                <UiCell>作答時間</UiCell>
                                <UiCell>派卷老師</UiCell>
                                <UiCell>批改老師</UiCell>
                              </TableRow>
                            </TableHead>
                            <UiTableBody>
                              <TableRow>
                                <UiCell>
                                  <UiSortBox>
                                    {endAt && format(endAt, 'yyyy-MM-dd HH:mm')}
                                  </UiSortBox>
                                </UiCell>
                                <UiCell>
                                  {questionAmount}
                                </UiCell>
                                <UiCell>
                                  {period} 分鐘
                                </UiCell>
                                <UiCell>
                                  {ownerNickname}
                                </UiCell>
                                <UiCell>
                                  {
                                    markers.map(id => {
                                      if (markersMap && markersMap[id]) {
                                        return (
                                          <div key={id}>{markersMap[id].nickname}</div>
                                        );
                                      } else {
                                        return (
                                          <div key={id}>{id}</div>
                                        );
                                      }
                                    })
                                  }
                                </UiCell>
                              </TableRow>
                            </UiTableBody>
                          </MatTable>
                        </Box>
                      </Collapse>
                    </TableCell>
                  </UiTitleRow>
                </React.Fragment>
              );
            })}
        </TableBody>
      </MatTable>
    </TableContainer>
  );
};

export const HomeOnlineExamTableV2 = () => {
  const [{ myClasses }] = useClass();
  const [{ profile }] = useUser();
  const [{ timestamp: serverTimestamp }] = useServerTime();
  const { setAlert } = useAlert();
  const { dataInfo: myClassInfo } = myClasses;
  const { classId, organizationId } = useParams();
  const { pathname } = useLocation();
  const history = useHistory();
  const userId = profile.id;
  const isUser = myClassInfo.users?.some(id => id === userId);
  const isHosts = myClassInfo.hosts?.some(id => id === userId);
  const [{
    examData,
    nowPage,
    rowsPage,
    educationKey,
    subjectKey,
    // educationalSystemOption,
    // subjectOption,
    unansweredOnly,
    sortKey,
    sortOrder,
    deleteModalState,
    isLoading,
    targetExamId,
    isOpenModal,
    examPeriod,
    startAt,
    endAt,
    examName,
  }, setState] = useSetState({
    examData: [],
    nowPage: 0,
    rowsPage: 10,
    educationKey: '',
    subjectKey: '',
    // educationalSystemOption: [],
    // subjectOption: [],
    unansweredOnly: false,
    sortKey: '',
    sortOrder: 'desc',
    deleteModalState: false,
    isLoading: true,
    targetExamId: '',
    isOpenModal: false,
    examPeriod: null,
    startAt: null,
    endAt: null,
    examName: '',
  });

  const handleChangeRowsPerPage = (event) => {
    setState({
      nowPage: 0,
      rowsPage: parseInt(event.target.value, 10),
    });
  };



  const handleChangePage = (event, newPage) => {
    setState({
      nowPage: newPage
    });
  };

  // 列表狀態
  const statusCheck = (startAt, endAt, testedUsers) => {
    // 是否有參與考試
    const isTested = testedUsers.some(name => name === profile.id);
    const nowTime = serverTimestamp;

    // 老師身分 狀態
    if (isHosts) {
      //  尚未開始
      if (nowTime < startAt) {
        return TEST_STATUS.NOT_READY;
      }

      // 開始作答
      if (nowTime > startAt && nowTime < endAt) {
        return TEST_STATUS.READY;
      }

      // 作答結束
      if (nowTime > endAt) {
        return TEST_STATUS.FINISHED;
      }
    }

    // 學生身分 狀態
    if (isUser) {
      if (!isTested) {
        // 沒考過

        // 開始作答
        if (nowTime > startAt && nowTime < endAt) {
          return TEST_STATUS.READY;
        }

        // 缺考
        if (nowTime > endAt) {
          return TEST_STATUS.MISSED;
        }
      } else {
        // 作答結束
        if (nowTime > endAt) {
          return TEST_STATUS.FINISHED;
        }

        // 完成測驗
        if (nowTime > startAt && nowTime < endAt) {
          return TEST_STATUS.ANSWERED;
        }
      }
    }
  };

  //關閉刪除彈窗
  const onCloseDeleteModal = () => {
    setState({
      targetExamId: '',
      deleteModalState: false,
    });
  };

  const onExamDeleteHandle = async () => {
    const params = {
      organizationId,
      groupId: classId,
      examId: targetExamId,
    };
    setState({
      isLoading: true,
    });
    const { isSuccess } = await deleteOrganizationGroupExam(params);
    if (isSuccess) {
      setAlert('刪除成功!', 'success');
      remove({ id: targetExamId, keyName: 'id' });
    } else {
      setAlert('刪除失敗!', 'error');
    }
    setState({
      isLoading: false,
      targetExamId: '',
      deleteModalState: false
    });
  };

  const createExam = async () => {
    const payload = {
      startAt,
      endAt,
      examName,
      period: parseInt(examPeriod),
      organizationId,
      groupId: classId,
      examId: targetExamId,
    };
    setState({
      isLoading: true
    });
    const { isSuccess, data, error } = await editOrganizationGroupExam(payload);
    if (isSuccess) {
      setAlert('派卷成功', 'success');
      examModalToggle();
      edit({ id: targetExamId, keyName: 'id', newData: data });
    } else {
      const { errorCode } = error;
      if (errorCode === 192013) {
        setAlert('此班級沒有學生', 'error');
      } else {
        setAlert('派卷失敗', 'error');
      }
    }
    setState({
      isLoading: false
    });
  };

  const examModalToggle = (params = {}) => {
    setState({
      isOpenModal: !isOpenModal,
      ...params
    });
  };

  const onModalChange = (value, key) => {
    setState({
      [key]: value
    });
  };

  const actionHandle = (status, examId, examName, endAt, startAt, period, paperId, testedUsers) => {
    // 是否有人應考
    const isMissedTest = testedUsers?.length <= 0 ? true : false;
    const nowTime = serverTimestamp;
    // 測驗跳轉
    const goExamUrl = async () => {
      const { isSuccess } = await attendGroupExamForGroupUser(classId, examId);
      if (isSuccess) {
        const openUrl = window.open('', '_blank');
        const examUrl = `${process.env.REACT_APP_ONEEXAM_DOMAIN}/user/answer/${examId}/${userId}`;
        openUrl.location = examUrl;
      } else {
        setAlert('考試網址取得失敗', 'error');
      }
    };

    // 作答跳轉
    const goResultUrl = () => {
      // 學生身份
      if (isUser) {
        const openUrl = window.open('', '_blank');
        const examUrl = `${process.env.REACT_APP_ONEEXAM_DOMAIN}/user/report/${examId}/${userId}`;
        openUrl.location = examUrl;
      }

      // 老師身份
      if (isHosts) {
        history.push({
          pathname: `/home/${organizationId}/class/${classId}/exam/${examId}/onlineExamResult`,
          state: { examName }
        });
      }
    };

    // 預覽試卷
    const previewPaper = () => {
      window.open(`${process.env.REACT_APP_ONEEXAM_DOMAIN}/paper/preview/${paperId}`);
    };

    const onDelete = () => {
      setState({
        targetExamId: examId,
        deleteModalState: true,
      });
    };

    if (myClassInfo.userType === 'student') {
      switch (status) {
        case TEST_STATUS.READY:
          return (
            <Button buttonColor="new" onClick={goExamUrl}>開始作答</Button>
          );
        case TEST_STATUS.FINISHED:
          return (
            <Button
              dataGaCategory={getPageName(pathname)}
              dataGaAction='作答結果'
              buttonColor="cancel"
              onClick={goResultUrl}
            >
              作答結果
            </Button>
          );
        case TEST_STATUS.ANSWERED:
          return (
            <Button disabled={true}>待批改</Button>
          );
        case TEST_STATUS.MISSED:
          return (
            <Button disabled={true}>缺考</Button>
          );
        default:
          return null;
      }
    } else {
      switch (status) {
        case TEST_STATUS.NOT_READY:
          return (
            <Box display="flex" alignItems="center">
              <UiEditBox>
                <Icon.Svg name="Visibility" title="預覽試卷" onClick={previewPaper} />
                <Icon.Svg
                  name="EditPen"
                  onClick={() => examModalToggle({
                    targetExamId: examId,
                    examName,
                    endAt,
                    startAt,
                    examPeriod: period
                  })}
                  color={nowTime > endAt ? '#E5E4E0' : '#121232'}
                />
                <Icon.Svg
                  name="Delete"
                  onClick={onDelete}
                />
              </UiEditBox>
              <Button disabled={true} buttonColor="error">尚未開始</Button>
            </Box>
          );
        case TEST_STATUS.READY:
          return (
            <Box display="flex" alignItems="center">
              <UiEditBox>
                <Icon.Svg name="Eye" title="預覽試卷" onClick={previewPaper} />
                <Icon.Svg
                  name="Delete"
                  onClick={onDelete}
                />
              </UiEditBox>
              <Button buttonColor="cancel" onClick={goResultUrl}>作答狀況</Button>
            </Box>
          );
        case TEST_STATUS.FINISHED:
          return (
            <Box display="flex" alignItems="center">
              <UiEditBox>
                <Icon.Svg name="Eye" title="預覽試卷" onClick={previewPaper} />
                <Icon.Svg
                  name="Delete"
                  onClick={onDelete}
                />
              </UiEditBox>
              {
                isMissedTest && <Button disabled={true}>全員缺考</Button>
              }
              {
                !isMissedTest && <Button buttonColor="cancel" onClick={goResultUrl}>作答狀況</Button>
              }
            </Box>
          );
        case TEST_STATUS.TO_BE_CORRECTED:
          return (
            <Box display="flex" alignItems="center">
              {/* <UiEditBox disabled={!isCanEdit}>
                <span>
                  <Icon.Svg name="Edit" color={isCanEdit ? '#D5D7DE': '#000'} title="編輯測驗"/>
                </span>
                <div></div>
                <span>
                  <Icon.Svg name="Delete" color={isCanEdit ? '#D5D7DE': '#000'} title="刪除測驗"/>
                </span>
              </UiEditBox>
              <Button buttonColor="new" onClick={goCorrected}>批改非選</Button> */}
            </Box>
          );
        default:
          return null;
      }
    }
  };

  // 科目 select
  // const onSubjectSelectHandler = value => {
  //   if (value === 'all') {
  //     setState({
  //       educationKey: '',
  //     });
  //   } else {
  //     setState({
  //       educationKey: educationalSystemOption[0].code,
  //     });
  //   }

  //   setState({
  //     subjectKey: value,
  //   });
  // };

  // 搜尋
  // const onSearchHandle = () => {
  //   run({ isNeedReset: true });
  // };

  // checked
  // const onChangeCheckHandle = value => {
  //   setState({
  //     unansweredOnly: value,
  //   });
  // };

  const onSort = (type) => {
    const typeKey = {
      startTime: 'startAt',
      endTime: 'endAt',
    };
    switch (type) {
      case 'startTime':
        setState({
          sortKey: 'startAt',
        });
        break;
      case 'endTime':
        setState({
          sortKey: 'endAt'
        });
        break;
      default:
        break;
    }
    if (sortKey === typeKey[type]) {
      setState({
        sortOrder: sortOrder === 'desc' ? 'asc' : 'desc',
        nowPage: 0,
      });
    } else {
      setState({
        sortOrder: 'asc',
        nowPage: 0,
      });
    }
  };

  // 分頁邏輯
  const { isFetching, data: tableData, total: tableTotal, run, remove, edit } = usePagination(
    (params) => getGroupExamList(
      classId,
      {
        unansweredOnly,
        educationalSystem: educationKey,
        subject: subjectKey === 'all' ? '' : subjectKey,
        limit: 100,
        sortKey,
        sortOrder: sortKey ? sortOrder : '',
      }
    ),
    {
      resKey: 'exams',
      resKeyId: 'id',
      deps: [classId],
      page: nowPage,
      limit: rowsPage,
      formatResult: data => {
        return data.map(exam => {
          return {
            ...exam,
            isCollapseOpen: false,
          };
        });
      }
    });

  const { markersMap, setState: setMappingState } = useMappingTeacher(classId);

  // 下拉選單
  const fetchGroupExamOptions = async () => {
    const { isSuccess, data } = await getGroupExamOptions(classId);
    if (isSuccess) {
      const { educationalSystems, subjects } = data;

      const subjectMap = subjects;
      const subjectOption = subjectMap[educationalSystems[0].code].map(item => ({
        ...item,
        value: item.code,
      }));

      const nextSubject = [
        {
          code: 'all',
          value: 'all',
          name: '全部科目'
        },
        ...subjectOption
      ];

      setState({
        educationalSystemOption: educationalSystems,
        subjectOption: nextSubject,
        subjectKey: '',
      });
    } else {
      setAlert('取得選項失敗', 'error');
    }
  };

  useEffect(() => {
    fetchGroupExamOptions();
  }, []);

  // useEffect(() => {
  //   setState({ isLoading: isFetching });
  // }, [isFetching]);

  useEffect(() => {
    if(!serverTimestamp) return;
    setMappingState({
      data: tableData,
    });

    setState({
      isLoading: isFetching,
      examData: tableData.map(exam => ({
        ...exam,
        status: statusCheck(exam.startAt, exam.endAt, exam.testedUsers),
      })),
    });
  }, [isFetching, serverTimestamp, tableData]);

  useEffect(() => {
    run({ isNeedReset: true });
  }, [unansweredOnly]);

  useEffect(() => {
    if (sortKey === '') return;
    run({ isNeedReset: true });
  }, [sortKey, sortOrder]);


  return (
    <UiHomeOnlineExamTableV2>
      {
        <UiFilterBox>
          {/* <UiSelectBox>
              <InlineSelect label="全部科目" options={subjectOption} onChange={onSubjectSelectHandler} />
              <Button
                onClick={onSearchHandle}
                dataGaCategory={getPageName(pathname)}
                dataGaAction='搜尋'
              >
                搜尋
              </Button>
            </UiSelectBox> */}
          {/* {
              isUser &&
                <Checkbox
                  style={{marginLeft: 'auto'}}
                  label="僅顯示尚未作答測驗"
                  checkedValue={true}
                  onChange={value => onChangeCheckHandle(value)}
                  checked={unansweredOnly}
                  data-ga-category={getPageName(pathname)}
                  data-ga-action='僅顯示尚未作答測驗'
                />
            } */}
        </UiFilterBox>
      }
      {
        isLoading
          ? <Loading />
          : (myClassInfo.userType === 'student'
            ? (examData.length > 0
              ? <>
                  <StudentTable
                    data={examData}
                    actionHandle={actionHandle}
                  />
                  <Box py={2} display="flex" justifyContent="flex-end">
                    <TablePagination
                      count={tableTotal}
                      page={nowPage}
                      onChangePage={handleChangePage}
                      rowsPerPage={rowsPage}
                      labelRowsPerPage={'每頁顯示'}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                      component="div"
                    />
                  </Box>
                </>
              : <UiNoDataBox >
                  <UiPictureBox path={emptyImage} />
                  <UiNoDataText tabIndex={0}>
                    <div>目前老師尚無指派測驗</div>
                  </UiNoDataText>
                </UiNoDataBox>)
            : (examData.length > 0
              ? <>
                  <TeacherTable
                    data={examData}
                    actionHandle={actionHandle}
                    markersMap={markersMap}
                    onSort={onSort}
                  />
                  <Box py={2} display="flex" justifyContent="flex-end">
                    <TablePagination
                      count={tableTotal}
                      page={nowPage}
                      onChangePage={handleChangePage}
                      rowsPerPage={rowsPage}
                      labelRowsPerPage={'每頁顯示'}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                      component="div"
                    />
                  </Box>
                </>
              : <UiNoDataBox >
                  <UiPictureBox path={emptyImage} />
                  <UiNoDataText tabIndex={0}>
                    <div>目前老師尚無指派測驗</div>
                  </UiNoDataText>
                </UiNoDataBox>))
        }
      <BsModal
        open={deleteModalState}
        title="是否刪除測驗"
        isFull={true}
        onOk={onExamDeleteHandle}
        onCancel={onCloseDeleteModal}
        isLoading={isLoading}
      >
        若學生已完成測驗，作答資料將一併刪除，是否確認刪除該測驗？
      </BsModal>
      <ExamModal
        title="編輯派卷"
        isOpen={isOpenModal}
        onClick={createExam}
        onCancel={examModalToggle}
        isLoading={isLoading}
        onChange={onModalChange}
        examPeriod={examPeriod}
        startAt={startAt}
        endAt={endAt}
        examName={examName}
        defaultType='reserve'
        disabledTab={true}
      />
    </UiHomeOnlineExamTableV2>
  );
};
