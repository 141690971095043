import { useEffect } from 'react';
import PropTypes from 'prop-types';


/**
 * Beforeunload 用組件
 */

const useBeforeunload = () => {

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') return;
    const handleBeforeunload = event => {
      let returnValue = '';

      if (event.defaultPrevented) {
        event.returnValue = '';
      }

      if (typeof returnValue === 'string') {
        event.returnValue = returnValue;
        return returnValue;
      }
    };

    window.addEventListener('beforeunload', handleBeforeunload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeunload);
    };
  }, []);

};

export const Beforeunload = props => {
  const { children } = props;

  useBeforeunload();

  return children;
};

Beforeunload.propTypes = {
  children: PropTypes.node
};

