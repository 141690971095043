export const FORGETPASSWORD = 'forgetPassword';

export const REGISTER = 'register';

export const RULE = 'rule';

export const EXAM_PROJECT_NAME = 'onelink';

export const SERVICE_NAME = 'OneLink';

export const COMPANY_DOMAIN = 'oneclass.com.tw';

export const second = 1000;
export const minute = 60 * second;
export const hours = 60 * minute;
export const day = 24 * hours;

export const PREVIEW_TIMESTAMP = 10 * minute; // 預課時間，10分鐘

export const COMMON_ACTION_TYPE = {
  LOCATION_CHANGE: '@@router/LOCATION_CHANGE'
};

export const ROLES = {
  ADMIN: 'admin',
  USER: 'user'
};

export const FREE_GROUP_ROLES = {
  HOST_CANDIDATE: 'host',
  USER: 'user'
};

export const HomeworkCardStatus = {
  EDIT: 'edit',
  READ: 'read'
};
export const AnnouncementCardStatus = {
  EDIT: 'edit',
  READ: 'read'
};

export const PostCardStatus = {
  EDIT: 'edit',
  READ: 'read'
};

export const EDIT_STATUS = {
  CREATE: 'create',
  EDIT: 'edit',
  READ: 'read',
  ALIGN_TOP: 'verticalAlignTop',
  SUBMIT: 'submit'
};

export const SUBMIT_STATUS = {
  submitted: '已繳交',
  unsubmitted: '未繳交',
  lateSubmit: '遲交',
};

// 目前沒用到
// export const PERMISSION_GROUPS = {
//   OWNER: 'owner',
//   SESSION_REPORT_MANAGEMENT: 'session_report_management',
//   SESSION_MANAGEMENT: 'session_management',
//   ORGANIZATION_SETTING_MANAGEMENT: 'organization_setting_management',
//   RESOURCE_MANAGEMENT: 'resource_management',
//   STAFF_PERMISSION_MANAGEMENT: 'staff_permission_management',
//   STAFF_MANAGEMENT: 'staff_management',
//   SESSION_MONITORING_MANAGEMENT: 'session_monitoring_management',
//   CUSTOMER_MANAGEMENT: 'customer_management',
//   GROUP_MANAGEMENT: 'group_management'
// };

export const PERMISSIONS = {
  // session
  VIEW_SESSION_REPORT: 'view_session_report',
  MANAGE_SESSION: 'manage_session',
  MONITOR_SESSION: 'monitor_session',

  // organization
  VIEW_ORGANIZATION_SETTING: 'view_organization_setting',
  EDIT_ORGANIZATION_SETTING: 'edit_organization_setting',

  // resource
  VIEW_RESOURCE: 'view_resource',
  MANAGE_RESOURCE: 'manage_resource',

  // staff
  MANAGE_STAFF_PERMISSION: 'manage_staff_permission',
  VIEW_STAFF_PROFILE: 'view_staff_profile',
  ADD_STAFF: 'add_staff',
  EDIT_STAFF_PROFILE: 'edit_staff_profile',

  // customer
  VIEW_CUSTOMER_PROFILE: 'view_customer_profile',
  INVITE_CUSTOMER: 'invite_customer',
  EDIT_CUSTOMER_PROFILE: 'edit_customer_profile',

  // group
  VIEW_GROUP: 'view_group',
  MANAGE_GROUP: 'manage_group'
};


export const DASHBOARD_SUMMARY_CATEGORY = {
  GROUP: 'group',
  STAFF: 'staff',
  CUSTOMER: 'customer',
  RESOURCE: 'resource',
  USER: 'user',
  HOSTCANDIDATE: 'hostCandidate',
  ANNOUNCEMENT: 'announcement',
  MISSION: 'mission',
  POST: 'post',
  SESSION: 'session'
};


export const ATTENDANCE_STATUS = {
  LATE: 'late',
  LEAVE_EARLY: 'leave_early',
  ON_TIME: 'on_time',
  ABSENCE: 'absence'
};

// 學制
export const EDUCATION_LEVEL = {
  ELEMENTARY: 'E',
  JUNIOR: 'J',
  SENIOR: 'H',
  OTHER: 'other'
};

// 年級
export const EDUCATION = {
  [EDUCATION_LEVEL.ELEMENTARY]: [
    { label: '1 年級', value: '1' },
    { label: '2 年級', value: '2' },
    { label: '3 年級', value: '3' },
    { label: '4 年級', value: '4' },
    { label: '5 年級', value: '5' },
    { label: '6 年級', value: '6' },
  ],
  [EDUCATION_LEVEL.JUNIOR]: [
    { label: '7 年級', value: '7' },
    { label: '8 年級', value: '8' },
    { label: '9 年級', value: '9' },
  ],
  [EDUCATION_LEVEL.SENIOR]: [
    { label: '10 年級', value: '10' },
    { label: '11 年級', value: '11' },
    { label: '12 年級', value: '12' },
  ],
};


export const SESSION_START_TIME_TYPE = {
  YET: 'YET',
  ALREADY: 'ALREADY',
  PROCESSING: 'PROCESSING'
};

export const setPremiumStatus = {
  pending: '申請中',
  trial: '體驗版',
  approved: '已開通',
  expired: '已過期',
};


export const ORGANIZATION_SETTINGS_VALUE ={
  ALLOW: 'allow',
  DISALLOW: 'disallow',
  SINGLE: 'single',
  MULTIPLE: 'multiple',
  PRIVATE: 'private',
  PUBLIC: 'public',
  NONE:'none',
  HOST:'host',
  DEFAULT:'default',
  ENABLED:'enabled',
  DISABLED:'disabled'
};

export const GROUP_SETTINGS_VALUE = {
  ALLOW: 'allow',
  DISALLOW: 'disallow',
};

export const HOMEWORK_SETTINGS_VALUE = {
  GROUP:'group',
  OWNER:'owner',
};

export const availableEntryNavigation = {
  session: 'sessions',
  brief: 'info',
  announcement: 'announcement',
  post: 'paste',
  album: 'album',
  mission: 'homeworks',
  counseling: 'secretdiscuss',
  webResource: 'webResource',
  member: 'member',
  setting: 'setting',
  onlineQuiz: 'exam',
  multimediaLearning: 'video',
  supplementaryResource: 'supplementary',
};

export const ONEPAPER_STATUS = {
  loginCookieName: 'nani_oneclass_serviceRoot',
};
