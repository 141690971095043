import React from 'react';
// import PropTypes from 'prop-types';
import { HomeGroupLayout, HomeExamResults } from 'components';


/**
 * 作答結果 頁面
 */

export const HomeExamResultsPage = () => {
  return (
    <HomeGroupLayout>
      <HomeExamResults />
    </HomeGroupLayout>
  );
};

HomeExamResultsPage.propTypes = {

};


