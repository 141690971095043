import { stringify } from 'query-string';
import { Api } from 'services/api';


/**
 * 試卷列表
 *
 * @param {string} organizationId 組織ＩＤ
 * @param {string} groupId 班級ＩＤ
 * @param {string} educationalSystem 學制
 * @param {string} subject 科目
 * @param {string} paperName 測驗名稱搜尋
 * @param {number} limit 一頁顯示幾筆
 * @param {string} beforeId 在 試卷ID 之前
 * @param {string} afterId 在 試卷ID 之後
 *
 * @return {object}
 */
 export const getOrganizationGroupPaperList = async params => {
  const { organizationId, groupId, educationalSystem, subject, paperName, beforeId, afterId } = params;

  const queryParams = {
    limit: 100
  };

  if (educationalSystem) queryParams.educationalSystem = educationalSystem;

  if (paperName) queryParams.paperName = paperName;

  if (subject) queryParams.subject = subject;

  if(beforeId) queryParams.beforeId = beforeId;

  if(afterId) queryParams.afterId = afterId;

  const querystring = stringify(queryParams);
  const response = await Api.get(`/organizations/${organizationId}/groups/${groupId}/papers?${querystring}`);
  return response;
};

/**
 * 試卷列表 學制＆科目 下拉選單
 *
 * @param {string} organizationId 組織ＩＤ
 * @param {string} groupId 班級ＩＤ
 *
 * @return {object}
 */
 export const getOrganizationGroupPaperOptions = async params => {
  const { organizationId, groupId } = params;
  const response = await Api.get(`/organizations/${organizationId}/groups/${groupId}/papers/options`);
  return response;
};

/**
 * 建立派卷
 *
 * @param {string} organizationId 組織ＩＤ
 * @param {string} groupId 班級ＩＤ
 * @param {string} paperId 試卷ＩＤ
 * @param {string} name 測驗名稱
 * @param {string} startAt 開始時間
 * @param {string} endAt 結束時間
 * @param {number} period 作答時間
 * @param {array} markers 批改老師ＩＤ
 *
 * @return {object}
 */
 export const createOrganizationGroupExam = async params => {
  const { organizationId, groupId, paperId, eduSubjectName, name, startAt, endAt, period, markers } = params;
  const payload = {
    paperId,
    eduSubjectName,
    name,
    startAt,
    endAt,
    period,
    markers,
  };
  const response = await Api.post(`/organizations/${organizationId}/groups/${groupId}/exams`, payload);
  return response;
};

/**
 * 測驗列表
 *
 * @param {string} groupId 班級ＩＤ
 * @param {string} educationalSystem 學制
 * @param {string} subject 科目
 * @param {string} examName 測驗名稱
 * @param {boolean} unansweredOnly 僅顯示尚未作答測驗
 * @param {string} sortKey 排序ＫＥＹ  開始時間 startAt/結束時間 endAt
 * @param {string} sortOrder 針對『排序ＫＥＹ』升冪 asc/降冪 desc
 * @param {number} limit 一頁顯示幾筆
 * @param {array} beforeId 在 試卷ID 之前
 * @param {array} afterId 在 試卷ID 之後
 *
 * @return {object} https://asia-east1-live-oneclass-test.cloudfunctions.net/groups/docs/#/Exam/get__groupId__exams
 */
 export const getGroupExamList = async (groupId, params) => {
  const { examName, educationalSystem, subject, unansweredOnly, sortKey, sortOrder, limit, beforeId, afterId } = params;

  let queryParams = {};
  if (educationalSystem) queryParams.educationalSystem = educationalSystem;
  if (subject) queryParams.subject = subject;
  if (examName) queryParams.examName = examName;
  if (unansweredOnly) queryParams.unansweredOnly = unansweredOnly;
  if (sortKey) queryParams.sortKey = sortKey;
  if (sortOrder) queryParams.sortOrder = sortOrder;
  if (limit) queryParams.limit = limit;
  if (beforeId) queryParams.beforeId = beforeId;
  if (afterId) queryParams.afterId = afterId;

  const querystring = stringify(queryParams);
  const response = await Api.get(`/groups/${groupId}/exams?${querystring}`);
  return response;
};

/**
 * 測驗前檢查
 *
 * @param {string} groupId 班級ＩＤ
 * @param {string} examId 考試ＩＤ
 *
 * @return {object}
    https://asia-east1-live-oneclass-test.cloudfunctions.net/groups/docs/#/Exam/post__groupId__exams__examId__attend
 */
export const attendGroupExamForGroupUser = async (groupId, examId) => {
  const response = await Api.post(`/groups/${groupId}/exams/${examId}/attend`);
  return response;
};

/**
 * 作答結果
 *
 * @param {string} groupId 班級ＩＤ
 * @param {string} examId 考試ＩＤ
 *
 * @return {object}
    https://asia-east1-live-oneclass-test.cloudfunctions.net/groups/docs/#/Exam/get__groupId__exams__examId__results
*/
export const getGroupExamResults = async (groupId, examId) => {
  const response = await Api.get(`/groups/${groupId}/exams/${examId}/results`);
  return response;
};

/**
 * 科目下拉選單
 *
 * @param {string} groupId 班級ＩＤ
 *
 * @return {object}
    https://asia-east1-live-oneclass-test.cloudfunctions.net/groups/docs/#/Exam/get__groupId__exams_options
*/
export const getGroupExamOptions = async groupId => {
  const response = await Api.get(`/groups/${groupId}/exams/options`);
  return response;
};

/**
 * 編輯派卷
 *
 * @param {string} organizationId 組織ＩＤ
 * @param {string} groupId 班級ＩＤ
 * @param {string} examId 測驗ＩＤ
 * @param {string} name 測驗名稱
 * @param {string} startAt 開始時間
 * @param {string} endAt 結束時間
 * @param {number} period 作答時間
 * @param {array} markers 批改老師ＩＤ
 *
 * @return {object}
 */
 export const editOrganizationGroupExam = async params => {
  const { organizationId, groupId, examId, name, startAt, endAt, period, markers } = params;
  const payload = {
    name,
    startAt,
    endAt,
    period,
    markers,
  };
  const response = await Api.put(`/organizations/${organizationId}/groups/${groupId}/exams/${examId}`, payload);
  return response;
};

/**
 * 刪除測驗
 *
 * @param {string} organizationId 組織ＩＤ
 * @param {string} groupId 班級ＩＤ
 * @param {string} examId 試卷ＩＤ
 * @return {object}
 */
 export const deleteOrganizationGroupExam = async params => {
  const { organizationId, groupId, examId } = params;
  const response = await Api.delete(`/organizations/${organizationId}/groups/${groupId}/exams/${examId}`);
  return response;
};
