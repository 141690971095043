import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'components';

import {
  UiFormCheckBoxGroup,
  FormLabel,
  CheckBoxGroup,
  UiFormHelperText
} from './FormCheckBoxGroup.style';


/**
 * 在這邊描述這個組件
 */

export const FormCheckBoxGroup = ({
  label,
  data = [],
  value = [],
  type = '',
  className,
  onChange = () => { },
  helperText = '',
  error,
  groupClassName
}) => {

  const changeHandler = (isChecked, itemValue) => {
    let nextValue = value.concat();
    if (isChecked) {
      const index = nextValue.indexOf(itemValue);
      if (index > -1) {
        nextValue.splice(index, 1);
      }
    } else {
      nextValue.push(itemValue);
    }
    onChange(nextValue);
  };

  return (
    <UiFormCheckBoxGroup className={groupClassName}>
      <FormLabel>{label}</FormLabel>
      <CheckBoxGroup>
        {
          data.map((item, index) => {
            const isChecked = value.includes(item.value);
            return (
              <Checkbox
                key={item.value}
                checked={isChecked}
                onChange={() => changeHandler(isChecked, item.value)}
                checkedValue={item.value}
                color="primary"
                label={item.label}
                type={type}
                className={className}
                disabled={item.disabled}
              />
            );
          })
        }
        {(helperText && error) && (
          <UiFormHelperText >
            {helperText}
          </UiFormHelperText>
        )}
      </CheckBoxGroup>
    </UiFormCheckBoxGroup>);
};

FormCheckBoxGroup.propTypes = {
  label: PropTypes.string,
  data: PropTypes.array,
  value: PropTypes.array,
  onChange: PropTypes.func,
  type: PropTypes.string,
  className: PropTypes.string,
  helperText: PropTypes.string,
  error: PropTypes.object,
  groupClassName: PropTypes.string
};


