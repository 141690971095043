import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { UiTransferListModal, UiFormHelperText, UiErrorMsg } from './TransferListModal.style';
import { Button, TransferList, Modal, IconInput } from 'components';
import { useSetState } from 'utils/hooks/useSetState';

/**
 * 選擇列表彈窗with 按鈕
 */

export const TransferListModal = ({
  type,
  title,
  buttonName,
  data,
  onChange = () => { },
  search,
  value,
  helperText,
  error,
  isPreson = true,
  resetData = () => { },
  errorMsg = '',
  children,
  tagContent,
  tabs
}) => {

  const [{ changeModalState, choice, isLoading, isDisabled, }, setState] =
    useSetState({
      changeModalState: false,
      choice: [],
      isLoading: false,
      isDisabled: false,
    });

  const getModalStateHandler = state => {
    setState({
      changeModalState: state
    });
  };

  const getValueHandler = value => {
    closeHandler();
    if (!Array.isArray(value)) {
      setState({
        choice: [value]
      });
    }
    onChange && onChange(value);
  };

  const closeHandler = () => {
    resetData && resetData();
    setState({
      changeModalState: false
    });
  };

  const onSubmitHandler = value => {
    search && search.onSearchHandler(value);
  };

  useEffect(() => {
    setState({ isLoading: true });

    // 判斷 data 是否有值
    if (data.length === 0) {
      setState({
        isLoading: false,
      });
      return;
    }

    const nextData = data.filter(item => {
      if (item.id === value) {
        return item.title;
      }
    });
    setState({
      choice: nextData,
      isLoading: false,
      isDisabled: false,
    });
  }, [data]);

  const radioChangeHandler = (value) => {
    if (!value) return;
  };




  return (
    <UiTransferListModal>
      <Button
        loading={isLoading}
        disabled={isDisabled}
        icon='add'
        onClick={() => getModalStateHandler(true)}
      >
        {buttonName || '請選擇'}
      </Button>
      {(helperText && error && choice.length === 0) && (
        <UiFormHelperText >
          {helperText}
        </UiFormHelperText>
      )}
      <span>
        {
          choice.length === 0 ?
            choice :
            choice.map(item => (<span key={item.id}> {item.title} (ID:{item.id}) {item.content || ''} </span>))
        }
      </span>
      <Modal
        isIconShow={false}
        isOpen={changeModalState}
        title={title}
        content={
          <>
            {tabs}
            {tagContent}
            {search && <IconInput placeholder={search.placeholder} onChange={onSubmitHandler} />}
            {children}
            {data.length > 0 && <TransferList
              type={type}
              data={data}
              getValue={getValueHandler}
              closeHanlder={closeHandler}
              value={value}
              isPreson={isPreson}
              onRadioChange={radioChangeHandler}
              errorMsg={errorMsg}
            />}
            {data.length === 0 && <UiErrorMsg>{errorMsg}</UiErrorMsg>}
          </>
        }
        getModalState={getModalStateHandler}
      />
    </UiTransferListModal >
  );
};

TransferListModal.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  buttonName: PropTypes.string,
  data: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  search: PropTypes.shape({
    placeholder: PropTypes.string.isRequired,
    onSearchHandler: PropTypes.func.isRequired
  }),
  value: PropTypes.string,
  helperText: PropTypes.string,
  error: PropTypes.bool,
  isPreson: PropTypes.bool,
  resetData: PropTypes.func,
  children: PropTypes.node,
  tabs: PropTypes.node,
  tagContent: PropTypes.node,
  errorMsg: PropTypes.string,
};


