import React from 'react';
import { UiBsContentContainer } from 'styles';
import { BsHeaderTitle, BsTeacherManagementPageHeaderTab, BsTeacherManagementInvitingTable } from 'components';
// import { UiTeacherManagementInvitingPage } from './TeacherManagementInvitingPage.style';


/**
 * 在這邊描述這個組件
 */

export const TeacherManagementInvitingPage = () => {
  return (
    <>
      <BsHeaderTitle title="教師管理" />
      <BsTeacherManagementPageHeaderTab activeIndex={1} />
      <UiBsContentContainer>
        <BsTeacherManagementInvitingTable />
      </UiBsContentContainer>
    </>
  );
};


