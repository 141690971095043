import React from 'react';
import PropTypes from 'prop-types';
import { Header, Footer, SideBar, BlindBrick } from 'components';
import { UiHomeGroupLayout,  UiHomeGroupContent, UiChildrenContainer } from './HomeGroupLayout.style';


/**
 * 前台頁面組件 沒有SideBar 用於條款頁
 */

export const HomeGroupLayout = ({ children, showSideBar = true }) => {
  return (
    <UiHomeGroupLayout>
      {/* <HomeBanner showSideBar={showSideBar} /> */}

      <UiHomeGroupContent>
        <Header isNeedLogo={true} />
        {
          showSideBar &&
          <SideBar />
        }
        <UiChildrenContainer>
        <BlindBrick className='accesskey' id='accessibility_center_container' accessKey='c' href='#accessibility_center_container' title='中央內容區塊，為本頁主要內容區。' />
          {children}
          <Footer />
        </UiChildrenContainer>
      </UiHomeGroupContent>
    </UiHomeGroupLayout>
  );
};

HomeGroupLayout.propTypes = {
  children: PropTypes.node,
  showSideBar: PropTypes.bool
};


