import React from 'react';
import { HomePasteTable, HomeGroupLayout } from 'components';

/**
 * 在這邊描述這個組件
 */

export const HomePastePage = () => {
  return (
    <HomeGroupLayout>
      <HomePasteTable />
    </HomeGroupLayout>
  );
};

