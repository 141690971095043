import styled from 'styled-components';

export const UiButtonSelect = styled.div`
	display: block;
`;

export const UiButtonBox = styled.div`
	position: relative;
`

export const UiButtonOptionBackgound = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	width: 100vw;
	height: 100vh;
	z-index: 99;
`

export const UiButtonOption = styled.div`
	position: absolute;
	right: 0;
	width: 158px;
	background-color: #fff;
	border-radius: 4px;
	z-index: 100;
	box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
`

export const UiButtonOptionText = styled.div`
	padding: 1rem;
	font-size: 14px;
	color: #2f3649;

	:hover {
		cursor: pointer;
		background-color: #f5f5f5;
	}

	&:first-child {
		margin-top: 1rem;
	}

	&:last-child {
		margin-bottom: 1rem;
	}
`