import React from 'react';
import PropTypes from 'prop-types';

import { DropzoneArea as MatDropzoneArea } from 'material-ui-dropzone';
import { useTranslation } from 'react-i18next';
import { UiDropzoneUploader } from './DropzoneUploader.style';


/**
 * 上傳組件
 */

export const DropzoneUploader = ({ filesLimit, onChange, acceptedFiles = [], ...otherProps }) => {
  const { t } = useTranslation();
  return (
    <UiDropzoneUploader>
      <MatDropzoneArea
        acceptedFiles={acceptedFiles}
        dropzoneText={t('common.dropzone_text')}
        getFileLimitExceedMessage={() => t('common.dropzone_limit_text')}
        getFileAddedMessage={() => t('common.dropzone_add_text')}
        getFileRemovedMessage={() => t('common.dropzone_remove_text')}
        filesLimit={filesLimit}
        onChange={file => onChange(file)}
        maxFileSize={300000000}
        {...otherProps}
      />
    </UiDropzoneUploader>
  );
};

DropzoneUploader.propTypes = {
  props: PropTypes.object,
  filesLimit: PropTypes.number,
  onChange: PropTypes.func,
  acceptedFiles: PropTypes.array
};


