import React from 'react';
import { UiHomeMemberTable } from './HomeMemberTable.style';
import {
  MemberTable,
  HomeTeacherTable,
  HomeInvitationTable,
  Tab,
  Breadcrumbs,
} from 'components';
import { useClass } from 'store/class';
import { useSetState } from 'utils/hooks/useSetState';
import { useParams } from 'react-router-dom';

/**
 * 會員列表與切換標籤,麵包屑
 */
export const HomeMemberTable = () => {

  const { organizationId } = useParams();
  const [{
    tabIndex,
  }, setState] = useSetState(
    {
      tabIndex: 0,
    });

  const [{ myClasses: { dataInfo: {  isOwner } } }] = useClass();

  let tabData = [
    { label: '學生列表', Component: MemberTable },
    { label: '教師列表', Component: HomeTeacherTable }
  ];

  if(isOwner){
    tabData.push({ label: '邀請中', Component: HomeInvitationTable });
  }


  //麵包屑
  const BreadcrumbsList = [
    {
      name: '成員',
    },
    {
      name: tabData[tabIndex].label,
    }
  ];
  const getTabIndexHandler = (tabIndex) => {
    setState({
      tabIndex
    });
  };

  return (
    <UiHomeMemberTable>
      {
        !organizationId &&
        <Breadcrumbs list={BreadcrumbsList} />
      }
      <h4>成員</h4>
      <Tab data={tabData} getTabIndex={getTabIndexHandler} />

      {/* {isFreeGroup ?
        <Tab data={tabData} getTabIndex={getTabIndexHandler} /> :
        <MemberTable tabIndex={tabIndex} />
      } */}
    </UiHomeMemberTable>);
};

HomeMemberTable.propTypes = {

};


