import React, { useMemo, useEffect, useState, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import MatImage from 'material-ui-image';
import lrz from 'lrz';
import { TextField, Box,InputAdornment } from '@material-ui/core';
import { useSetState } from 'utils/hooks/useSetState';
import { useUser } from 'store/user';
import { useTeacher } from 'store/teacher';
import { useClass } from 'store/class';
import { EDUCATION_LEVEL, EDUCATION, ORGANIZATION_SETTINGS_VALUE } from 'constants/index';
import {
    Button,
    Select,
    DatePicker,
    TransferListModal,
    FormCheckBoxGroup,
    ClickablePopoverMenu,
    Icon,
    Modal,
    RadioGroup
} from 'components';
import { EDIT_STATUS } from 'constants/index';
import { convertArrayToMap, uniqueArray } from 'utils/array';
import { useAllSchoolYear } from 'utils/hooks/useSchoolYear';
import { add, getTime } from 'date-fns';
import { isExist } from 'utils/helper';
import { useFirebaseStorage } from 'utils/hooks/useFirebaseStorage';
import { useUuid } from 'utils/hooks/useUuid';
import { Search } from '@material-ui/icons';
import organizationDefaultImage from 'assets/images/organization.png';


import { UiClassForm, UiSettingImage, UiImageSettingButton,UiFilter,UiSearchInput} from './ClassForm.style';

/**
 * 建立 班級資料
 */

 const selectFilterTypeOptions = [
  {
    name: '手機號碼',
    value: 'mobileNumber'
  },
  {
    name: '暱稱',
    value: 'nickname'
  },
  {
    name: '帳號ID',
    value: 'targetUserId'
  }
];

const searchPlaceholderMap = {
  mobileNumber: '請輸入教師手機號碼',
  nickname: '請輸入教師暱稱',
  targetUserId: '請輸入教師帳號ID'
};

 // eslint-disable-next-line react/prop-types
 export const ClassForm = ({ editStatus }) => {
  const { upload } = useFirebaseStorage();
  const [errors, setErrors] = useState({});
  const [{
    searchValue,
    isValidating,
    educationOptions,
    isSchoolYearRequired,
    isLoading,
    isModalOpen,
    tempThumbnailBase64,
    searchKey,
    searchIsLoading
   }, setState] = useSetState({
    searchValue: '',
    isValidating: editStatus === EDIT_STATUS.EDIT,
    isLoading: false,
    educationOptions: [],
    isSchoolYearRequired: false,
    isModalOpen: false,
    tempThumbnailBase64: '',
    searchKey:'',
    searchIsLoading:false
  });
  const history = useHistory();
  const { organizationId, classId } = useParams();
  const [{ myOrganization }] = useUser();
  const [{ teachers: { data } }, { getTeachers }] = useTeacher();
  const [{ classes }, { createClassInfo, editClassInfo }] = useClass();
  const organizationDefaultEducationName = [...myOrganization.organization.educationNames];
  const classesInitEducationName = classes.dataMap[classId]?.educationName;
  const classesInitGrades = classes.dataMap[classId]?.grades;
  const { 
    schoolYearOptions: schoolYearBaseOptions,
    schoolYearOptionsWithNone
   } = useAllSchoolYear();
  const [getUuid] = useUuid();
  const thumbnailRef = useRef();
  const selectProps = {
    options: [
      {
        name: '國小',
        value: EDUCATION_LEVEL.ELEMENTARY
      },
      {
        name: '國中',
        value: EDUCATION_LEVEL.JUNIOR
      },
      {
        name: '高中',
        value: EDUCATION_LEVEL.SENIOR
      },
      {
        name: '其他',
        value: EDUCATION_LEVEL.OTHER
      }
    ]
  };
  const educationOptionsMap = convertArrayToMap(selectProps.options, 'value');

  useEffect(() => {
    const params = {
      mobileNumber: searchValue
    };
    getTeachers(params);
  }, []);

    useEffect(() => {
    let options = [];
    if (editStatus === EDIT_STATUS.EDIT) {
      options = uniqueArray([...[classesInitEducationName], ...organizationDefaultEducationName]);
    } else {
      options = organizationDefaultEducationName;
    }
    options = options.map(item => {
      return educationOptionsMap[item];
    });
    setState({ educationOptions: options });
  }, []);

  const submitSelectHandler = value => {
    setState({
      searchKey: value
    });
  };
  const submitInputHandler = event => {
    setState({
      searchValue: event.target.value
    });
  };

  const submitSearchHandler = async () => {
    setState({
      searchIsLoading: true
    });
    if (!searchKey) {
      alert('請先選擇搜尋目標');
      return;
    }

    const params = {
      [searchKey]: searchValue
    };

    await getTeachers(params);
    setState({
      searchIsLoading: false
    });
  };

  const schoolYearOption = useMemo(() => 
  isSchoolYearRequired ? schoolYearBaseOptions : schoolYearOptionsWithNone
  ,[isSchoolYearRequired, schoolYearBaseOptions, schoolYearOptionsWithNone]);
  const defaultEducationName = organizationDefaultEducationName[0];

  const [formData,setFormData] = useSetState({
    name: '',
    description: '',
    ownerId: '',
    expirationTime: getTime(add(Date.now(), {
      years: 1,
      days: 1
    })),
    educationName: defaultEducationName,
    grades:  classesInitGrades || [],
    year: schoolYearOption[0].value || '',
    thumbnailUrl: ''
  });


  const handleUpdate = (property,value) => {
    switch(property){
      case 'educationName':
        if(value !== formData.educationName){
          setFormData({
            [property]: value,
            grades:  []
          });
        }
        break;
      default:
        setFormData({ [property]:  value });
        break;
    }
  };

  const formValidationRules = {
    name: [
      {
        message: '本欄位為必填',
        validate: value => isExist(value)
    },
      { message: '最大長度為 20 個字元', validate: value => value.length <= 20 }
    ],
    description: [
      { message: '最大長度為 200 個字元', validate: value => value.length <= 200 }
    ],
    ownerId: [
      { message: '請選擇導師', validate: value => value && value.length > 0 }
    ],
    educationName: [
      { message: '班級學制不符合機構學制',  validate: value => {
          const educationNameIsAvailable = organizationDefaultEducationName.indexOf(value) !== -1;
          return educationNameIsAvailable;
        }
      }
    ],
    grades: [
      {
        message: '此學制下，本欄位為必填',
        validate: value => {
          if(formData.educationName === 'other') return true;
          if(isSchoolYearRequired){
            return  value && value.length > 0;
          }
          return true;
        }
     }
    ],
    year: [
      {
        message: '此學制下，本欄位為必填',
        validate: value => {
          if(isSchoolYearRequired){
            return !!value;
          }
          return true;
        }
      }
    ]
  };

  const validateForm = () => {
    let errors = {};
    Object.entries(formData).forEach(([key,value])=>{
      if(!formValidationRules[key]) return;
      formValidationRules[key].forEach(rule => {
        if(!rule.validate(value)){
          errors[key] = {
            message: rule.message
          };
        } else {
          delete errors[key];
        }
      });
    });
    return { errors };
  };

  useEffect(() => {
    if(!isValidating) return;
    const { errors } = validateForm();
    setErrors(errors);

  },[isValidating, formData]);

  const submitHandler = async () => {
    setState({ isLoading: true, isValidating: true });

    const { errors, errorTotal } = validateForm();
    if(errorTotal > 0){
      setState({ isLoading: false });
      setErrors(errors);
      return;
    }

    const data = {
      ...formData,
      grades: formData.grades || [],
      expirationTime:  getTime(new Date(formData.expirationTime))
    };

    if(formData.educationName === EDUCATION_LEVEL.OTHER){
      data.grades = [];
    }

    if(thumbnailRef.current) {
      const url = await onUploaded(thumbnailRef.current);
      if (url){
        data.thumbnailUrl = url;
      }
    }

    const success = editStatus === EDIT_STATUS.EDIT ? await editClassInfo(data) : await createClassInfo(data);
    setState({ isLoading: false });
    if (success) {
      history.push(`/organization/${organizationId}/class`);
    }
  };


  const gradesData = useMemo(() => {
    return formData.educationName ? EDUCATION[formData.educationName] : [];
  },[formData.educationName]);

  useEffect(()=>{
    setState({ isSchoolYearRequired: formData.educationName !== EDUCATION_LEVEL.OTHER});
  },[formData.educationName]);

    useEffect(() => {
    // 寫入 組織名稱
    if (classId) {
      const data = {};
      Object.keys(formData).forEach((key) => {
        data[key] = classes.dataMap[classId][key];
      });
      setFormData({ ...data });
    }
  }, [classId]);

  const fileUploader = useRef(null);
  const popoverMenuList = [
    {
      label: '上傳圖片',
      func: () => fileUploader.current.click(),
      iconName: 'upload',
    },
    {
      label: '刪除',
      func: () => {
        setState({ isModalOpen: true });
      },
      iconName: 'delete',
    },
  ];

  const onUploaded = async file => {
    if (!file.type) return;
    const uploadPath = `${classId}/classAlbum/${getUuid()}${file.name}`;
    const { status, url } = await upload(uploadPath, file);

    if (status) {
      return url;
    } else {
      return false;
    }
  };

  const changeThumbnailUrl = async file => {
    await lrz(file).then(async res => {
      setState({
        tempThumbnailBase64: res.base64
      });

      let thumbnail = '';
      for (let value of res.formData.values()) {
        thumbnailRef.current = value;
      }
      handleUpdate('thumbnailUrl',thumbnail);
    });
  };

  const buttons = [
    {text: '確認', func: () => {
      setFormData({ ...formData, thumbnailUrl: '' });
      thumbnailRef.current = '';
      setState({ tempThumbnailBase64: ''});
      fileUploader.current.value = '';
    }},
    {
      text: '取消',
      color: 'highlight',
      func: () => { }
    }
  ];

  const getModalStateHandler = state => {
    setState({ isModalOpen: state });
  };

  const ImageSrc = useMemo(() =>{
    return formData.thumbnailUrl || tempThumbnailBase64 || organizationDefaultImage;
  } ,[formData.thumbnailUrl, tempThumbnailBase64]);

  return (
    <UiClassForm>
      <Box>
        <UiSettingImage>
          <MatImage src={ImageSrc} alt="org" />
        </UiSettingImage>
        <ClickablePopoverMenu menuList={popoverMenuList} width={'100%'}>
          <UiImageSettingButton>
            <Icon name="image" haveBg={false} />
            班級圖片變更
            <input type="file" onChange={e => changeThumbnailUrl(e.target.files[0])} ref={fileUploader} />
          </UiImageSettingButton>
        </ClickablePopoverMenu>
      </Box>
      <Box px={3}>
        <Box>
          <TextField
            label="機構"
            value={myOrganization.organization.officialName}
            disabled={true}
            fullWidth
          />
        </Box>
        <Box py={3}>
          <TextField
            label="班級名稱"
            name="name"
            onChange={(e) => handleUpdate('name',e.target.value)}
            value={formData.name}
            error={!!errors.name}
            helperText={errors.name && errors.name.message}
            fullWidth
          />
          </Box>
          <Box py={3}>
            <TextField
              label="班級簡介"
              name="description"
              onChange={(e) => handleUpdate('description',e.target.value)}
              value={formData.description}
              error={!!errors.description}
              helperText={errors.description && errors.description.message}
              fullWidth
              multiline
            />
          </Box>
        <Box py={3}>
          <TransferListModal
            name='ownerId'
            label='導師'
            title='教師列表'
            buttonName='選擇導師'
            type='singleChoice'
            error={!!errors.ownerId}
            helperText={errors.ownerId && errors.ownerId.message}
            data={data.map(item => (
              {
                id: item.id,
                imagePath: item.thumbnailUrl,
                title: item.nickname,
                content: item.mobileNumber,
              }
            ))}
            value={formData.ownerId}
            onChange={({ id }) => handleUpdate('ownerId',id)}
            fullWidth
          >
            <UiFilter>
              <Select label="搜尋目標" options={selectFilterTypeOptions}
                value={searchKey} submitHandler={submitSelectHandler} />
              <UiSearchInput>
                <TextField placeholder={searchPlaceholderMap[searchKey]}
                  defaultValue={searchValue}
                  onChange={event => submitInputHandler(event)}
                  fullWidth={true}
                  type="search" variant="outlined" label='搜尋' InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}></TextField>
              </UiSearchInput>
              <Button onClick={submitSearchHandler} loading={searchIsLoading}>搜尋</Button>
            </UiFilter>
          </TransferListModal>
        </Box>
        <Box py={3}>
          <DatePicker
            name='expirationTime'
            label='有效時間'
            minDate={Date.now()}
            value={formData.expirationTime}
            error={!!errors.expirationTime}
            onChange={(timestamp) => handleUpdate('expirationTime',  timestamp)}
            helperText={errors.expirationTime && errors.expirationTime.message}
            fullWidth
          />
        </Box>
        <Box py={3}>
          {educationOptions && <Select
            name='educationName'
            label='學制'
            value={formData.educationName}
            error={!!errors.educationName}
            onChangeHandler={(value) => handleUpdate('educationName', value)}
            helperText={errors.educationName && errors.educationName.message}
            options={educationOptions || []}
            fullWidth
          />}
        </Box>
        {
              myOrganization.organization.groupGradeSelectionSetting === ORGANIZATION_SETTINGS_VALUE.SINGLE ? (
                 <RadioGroup
                  data={gradesData}
                  value={formData.grades[0] || ''}
                  onChange={(e) => handleUpdate('grades',[e.target.value])}
                  error={!!errors.grades}
                  helperText={errors.grades && errors.grades.message}
                />
              ) : (
                <FormCheckBoxGroup
                  name='grades'
                  label='年級'
                  error={!!errors.grades}
                  helperText={errors.grades && errors.grades.message}
                  data={gradesData}
                  onChange={(value) => handleUpdate('grades', value)}
                  value={formData.grades}
                />
              )
            }
        <Box py={3}>
          {schoolYearOption && <Select
            name='year'
            label='學年度'
            value={formData.year}
            error={!!errors.year}
            helperText={errors.year && errors.year.message}
            options={schoolYearOption}
            onChangeHandler={(value) => handleUpdate('year',value)}
          />}
        </Box>
        <Box py={3} my={3} display="flex" justifyContent="center">
        <Box mx={1}>
          <Button loading={isLoading} onClick={submitHandler}>確認</Button>
        </Box>
        <Box mx={1}>
          <Button
            buttonColor="info"
            icon="exitToApp"
            type="submit"
            loading={isLoading}
            onClick={() => { history.push(`/organization/${organizationId}/class`); }}
          >
            回到上一頁
          </Button>
        </Box>
      </Box>
      </Box>
      <Modal
        isOpen={isModalOpen}
        text={'確定要<span>移除圖片</span>嗎?'}
        buttons={buttons}
        getModalState={getModalStateHandler}
      />
    </UiClassForm>
  );
 };

ClassForm.propTypes = {};
