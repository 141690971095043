import React from 'react';
// import PropTypes from 'prop-types';

import { Stepper, IconInput, Button, TimeButton } from 'components';
import { useUser } from 'store/user';
import { REGISTER } from 'constants/index';
import { useHistory } from 'react-router-dom';
import {
  UiCustomerService,
  UiCustomerContent,
  UiTitle,
  UiErrorMessage,
  UiInputBox,
  UiPrompt
} from './CustomerService.style';
import { useSetState } from 'utils/hooks/useSetState';

/**
 * 註冊/忘記密碼
 */


export const CustomerService = ({ serviceStatus }) => {
  const steps = [serviceStatus === REGISTER ? '註冊帳號' : '忘記密碼', '手機驗證', '密碼確認'];
  const history = useHistory();
  const [, {
    accountIsExsit,
    forgetPassword,
    forgetPasswordCheckMobileValid,
    revisePassword,
    registerSendValodCode,
    registerCheckMobileValid,
    register
  }] = useUser();
  const [
    {
      activeStep,
      errorMessage,
      phoneNumber,
      verificationCode,
      newPassword,
      checkPassword,
      loading
    }, setState] = useSetState({
      activeStep: 0,
      errorMessage: '',
      phoneNumber: '',
      verificationCode: '',
      newPassword: '',
      checkPassword: '',
      loading: false
    });

  const onChangeHandler = (value, key) => {
    setState({
      [key]: value
    });
  };
  const getVerificationCode = async () => {
    if (!phoneNumber) return;
    const passwordParams = {
      mobile: phoneNumber,
      regionCode: 'TW'
    };
    serviceStatus === REGISTER ? await registerSendValodCode(passwordParams) : await forgetPassword(passwordParams);
  };
  // 下一步
  const nextStepHandler = async () => {
    switch (activeStep) {
      case 0: {
        if (!(/^09[0-9]{8}$/.test(phoneNumber))) {
          setState({
            errorMessage: '請填寫手機號碼'
          });
          return;
        }
        const params = {
          username: phoneNumber
        };
        setState({
          loading: true
        });
        const response = await accountIsExsit(params);
        //判斷 註冊還是忘記密碼
        if (serviceStatus === REGISTER) {
          //註冊 ->使用者不存在才到下一個步驟
          if (response === '使用者不存在') {
            getVerificationCode();
            setState({
              activeStep: activeStep + 1,
              errorMessage: ''
            });
          } else {
            setState({
              errorMessage: '*使用者已存在'
            });
          }
        } else {
          if (response === '使用者已存在') {
            getVerificationCode();
            setState({
              activeStep: activeStep + 1,
              errorMessage: ''
            });
          } else {
            setState({
              errorMessage: '*使用者不存在'
            });
          }
        }

        setState({
          loading: false
        });
        return;
      }
      case 1: {
        if (!verificationCode) {
          setState({
            errorMessage: '*驗證碼尚未填寫'
          });
          return;
        }
        const params = {
          mobile: phoneNumber,
          eventCode: verificationCode
        };
        setState({
          loading: true
        });
        const { isSuccess } =
          serviceStatus === REGISTER ?
            await registerCheckMobileValid(params) :
            await forgetPasswordCheckMobileValid(params);
        if (isSuccess) {
          setState({
            activeStep: activeStep + 1,
            errorMessage: ''
          });
        } else {
          setState({
            errorMessage: '*驗證碼錯誤'
          });
        }
        setState({
          loading: false
        });
        return;
      }
      case 2: {
        if (newPassword === '' || checkPassword === '') {
          setState({
            errorMessage: '有欄位尚未填寫'
          });
          return;
        }
        if (newPassword !== checkPassword) {
          setState({
            errorMessage: '輸入的密碼不一致'
          });
          return;
        }
        const params = {
          mobile: phoneNumber,
          eventCode: verificationCode,
          password: newPassword
        };
        setState({
          loading: true
        });
        const { isSuccess } =
          serviceStatus === REGISTER ?
            await register(params) :
            await revisePassword(params);
        if (isSuccess) {
          setState({
            errorMessage: ''
          });
          history.push('/');
        } else {
          setState({
            errorMessage: '錯誤'
          });
        }
        setState({
          loading: false
        });
        return;
      }
      default:
        break;
    }
  };



  //步驟內容
  const stepContents = step => {
    switch (step) {
      case 0:
        return (
          <>
            <UiTitle tabIndex={0} >{serviceStatus === REGISTER ? '註冊帳號' : '忘記密碼'}</UiTitle>
            <UiInputBox>
              <IconInput
                icon='phoneAndroid'
                placeholder="輸入您的手機號碼"
                getValue={value => onChangeHandler(value, 'phoneNumber')}
              />
              {
                errorMessage && <UiErrorMessage>{errorMessage}</UiErrorMessage>
              }
            </UiInputBox>

            <Button onClick={() => nextStepHandler()} loading={loading}>下一步</Button>
          </>
        );
      case 1:
        return (
          <>
            <UiTitle tabIndex={0} >手機驗證</UiTitle>
            <UiPrompt tabIndex={0}>
              <div>已將驗證碼以簡訊傳送至</div>
              <div>{phoneNumber}</div>
            </UiPrompt>
            <UiInputBox>
              <IconInput
                icon='vpnKey'
                placeholder="請輸入驗證碼(600秒)"
                getValue={value => onChangeHandler(value, 'verificationCode')}
              />
              {
                errorMessage && <UiErrorMessage>{errorMessage}</UiErrorMessage>
              }
            </UiInputBox>
            <TimeButton onClick={() => getVerificationCode()} defaultTime={60} loading={true}>獲取驗證碼</TimeButton>
            <Button onClick={() => nextStepHandler()} loading={loading}>下一步</Button>
          </>
        );
      case 2:
        return (
          <>
            <UiTitle tabIndex={0} >密碼確認</UiTitle>
            <UiInputBox>
              <span>請輸入密碼:</span>
              <IconInput
                type="password"
                icon='vpnKey'
                placeholder="請輸入密碼"
                getValue={value => onChangeHandler(value, 'newPassword')}
              />
            </UiInputBox>
            <UiInputBox>
              <span>請再次輸入密碼:</span>
              <IconInput
                type="password"
                icon='vpnKey'
                placeholder="請再次輸入密碼"
                getValue={value => onChangeHandler(value, 'checkPassword')}
              />
            </UiInputBox>
            {
              errorMessage && <UiErrorMessage>{errorMessage}</UiErrorMessage>
            }
            <Button onClick={() => nextStepHandler()} loading={loading}>完成</Button>
          </>
        );
      default:
        return;
    }
  };

  return (
    <UiCustomerService>

      <UiCustomerContent>
        <Stepper steps={steps} activeStep={activeStep} />
        {
          stepContents(activeStep)
        }
      </UiCustomerContent>
    </UiCustomerService>
  );
};

CustomerService.propTypes = {

};


