import {
  MyOrganizationsPage,
  HomeSessionPage,
  HomeExamResultPage,
  HomeWorkPage,
  HomeOrganizationAnnouncementPage,
  HomeOrganizationAnnouncementFormPage,
  HomeSessionFormPage,
  HomeworkSubmittedPage,
  HomePastePage,
  HomePostFormPage,
  HomeAnnouncementPage,
  HomeAnnouncementFormPage,
  HomeMemberPage,
  HomeInvitePage,
  InviteFreeGroupUserPage,
  HomeInviteOrganizationHostPage,
  HomeSchoolFormPage,
  ClassSummaryPage,
  HomeAlbumPage,
  HomePicturePage,
  HomeMemberProfilePage,
  HomeMemberChangePasswordPage,
  MemberSeatsSelectPage,
  SecretDiscussPage,
  WebResourcePage,
  HomeSettingPage,
  HomeInfoPage,
  MySessionsPage,
  // UpgradeAccountPage,
  HomeworkSubmittedStudentPage,
  HomeExamPage,
  HomeVideoPage,
  HomeVideoHistoryTablePage,
  HomeStudentHistoryPage,
  HomeSupplementaryMaterialsPage,
  SessionReportPage,
  HomeOnlineExamPage,
  HomeExamResultsPage
} from 'components';
import { EDIT_STATUS } from 'constants/index';

const organization = '/home/:organizationId';
const organizationGroup = '/home/:organizationId/class/:classId';
const schoolGroup = '/home/class/:classId';

const routes = [
  {
    path: '/home',
    component: MyOrganizationsPage,
    exact: true
  },
  {
    path: '/sessions',
    component: MySessionsPage,
    exact: true
  },
  // {
  //   path: '/upgradeAccount',
  //   component: UpgradeAccountPage,
  //   exact: true
  // },
  {
    path: '/home/memberCenter/profile',
    component: HomeMemberProfilePage,
    exact: true
  },
  {
    path: '/home/memberCenter/changePassword',
    component: HomeMemberChangePasswordPage,
    exact: true
  },
  {
    path: `/home/school/${EDIT_STATUS.CREATE}`,
    component: HomeSchoolFormPage,
    exact: true,
    editStatus: EDIT_STATUS.CREATE,
  },
  /**
   * Organization
   */
  /**
   * 組織機構的機構頁
   */
  {
    path: `${organization}/announcement`,
    component: HomeOrganizationAnnouncementPage,
    exact: true,
  },
  {
    path: `${organization}/announcement/${EDIT_STATUS.CREATE}`,
    component: HomeOrganizationAnnouncementFormPage,
    exact: true,
    editStatus: EDIT_STATUS.CREATE
  },
  {
    path: `${organization}/announcement/:announcementId/${EDIT_STATUS.EDIT}`,
    component: HomeOrganizationAnnouncementFormPage,
    exact: true,
    editStatus: EDIT_STATUS.EDIT
  },
  {
    path: `${organizationGroup}/sessions`,
    component: HomeSessionPage,
    exact: true,
  },
  {
    path: `${organizationGroup}/sessions/${EDIT_STATUS.CREATE}`,
    component: HomeSessionFormPage,
    exact: true,
    editStatus: EDIT_STATUS.CREATE
  },
  {
    path: `${organizationGroup}/sessions/:sessionId/exam-result`,
    component: HomeExamResultPage,
    exact: true,
  },
  {
    path: `${organizationGroup}/summary`,
    component: ClassSummaryPage,
    exact: true,
  },
  {
    path: `${organizationGroup}/homeworks`,
    component: HomeWorkPage,
    exact: true
  },
  {
    path: `${organizationGroup}/homeworks/:homeworkId/submitted`,
    component: HomeworkSubmittedPage,
    exact: true
  },
  {
    path: `${organizationGroup}/homeworks/submitted/:studentId`,
    component: HomeworkSubmittedStudentPage,
    exact: true
  },
  {
    path: `${organizationGroup}/paste`,
    component: HomePastePage,
    exact: true
  },
  {
    path: `${organizationGroup}/paste/${EDIT_STATUS.CREATE}`,
    component: HomePostFormPage,
    exact: true,
    editStatus: EDIT_STATUS.CREATE
  },
  {
    path: `${organizationGroup}/announcement`,
    component: HomeAnnouncementPage,
    exact: true
  },
  {
    path: `${organizationGroup}/announcement/${EDIT_STATUS.CREATE}`,
    component: HomeAnnouncementFormPage,
    exact: true,
    editStatus: EDIT_STATUS.CREATE
  },
  {
    path: `${organizationGroup}/announcement/:announcementId/${EDIT_STATUS.EDIT}`,
    component: HomeAnnouncementFormPage,
    exact: true,
    editStatus: EDIT_STATUS.EDIT
  },
  {
    path: `${organizationGroup}/member`,
    component: HomeMemberPage,
    exact: true
  },
  {
    path: `${organizationGroup}/member/seats`,
    component: MemberSeatsSelectPage,
    exact: true
  },
  {
    path: `${organizationGroup}/invite`,
    component: HomeInvitePage,
    exact: true
  },
  {
    path: `${organizationGroup}/member/invite`,
    component: HomeInviteOrganizationHostPage,
    exact: true
  },
  {
    path: `${organizationGroup}/album`,
    component: HomeAlbumPage,
    exact: true
  },
  {
    path: `${organizationGroup}/album/:albumCategory`,
    component: HomePicturePage,
    exact: true
  },
  {
    path: `${organizationGroup}/setting`,
    component: HomeSettingPage,
    exact: true
  },
  {
    path: `${organizationGroup}/exam`,
    component: HomeExamPage,
    exact: true
  },
  {
    path: `${organizationGroup}/onlineExam`,
    component: HomeOnlineExamPage,
    exact: true
  },
  {
    path: `${organizationGroup}/exam/:examId/onlineExamResult`,
    component: HomeExamResultsPage,
    exact: true
  },
  {
    path: `${organizationGroup}/video`,
    component: HomeVideoPage,
    exact: true
  },
  {
    path: `${organizationGroup}/video/history/:userId`,
    component: HomeVideoHistoryTablePage,
    expect: true
  },
  {
    path: `${organizationGroup}/video/recordHistory/:teacherId/:videoId`,
    component: HomeStudentHistoryPage,
    expect: true
  },
  {
    path: `${organizationGroup}/info`,
    component: HomeInfoPage,
    exact: true
  },
  {
    path: `${organizationGroup}/supplementary`,
    component: HomeSupplementaryMaterialsPage,
    exact: true
  },
  {
    path: `${organizationGroup}/secretdiscuss`,
    component: SecretDiscussPage,
    exact: true
  },
  {
    path: `${organizationGroup}/webResource`,
    component: WebResourcePage,
    exact: true
  },
  /*
  * school
  */
  {
    path: `${schoolGroup}/sessions`,
    component: HomeSessionPage,
    exact: true,
  },
  {
    path: `${schoolGroup}/sessions/:sessionId/timespan/:timeSpanId/report`,
    component: SessionReportPage,
    exact: true,
  },
  {
    path: `${schoolGroup}/sessions/${EDIT_STATUS.CREATE}`,
    component: HomeSessionFormPage,
    exact: true,
    editStatus: EDIT_STATUS.CREATE
  },
  {
    path: `${schoolGroup}/summary`,
    component: ClassSummaryPage,
    exact: true,
  },
  {
    path: `${schoolGroup}/homeworks`,
    component: HomeWorkPage,
    exact: true
  },
  {
    path: `${schoolGroup}/homeworks/:homeworkId/submitted`,
    component: HomeworkSubmittedPage,
    exact: true
  },
  {
    path: `${schoolGroup}/paste`,
    component: HomePastePage,
    exact: true
  },
  {
    path: `${schoolGroup}/paste/${EDIT_STATUS.CREATE}`,
    component: HomePostFormPage,
    exact: true,
    editStatus: EDIT_STATUS.CREATE
  },
  {
    path: `${schoolGroup}/announcement`,
    component: HomeAnnouncementPage,
    exact: true
  },
  {
    path: `${schoolGroup}/announcement/${EDIT_STATUS.CREATE}`,
    component: HomeAnnouncementFormPage,
    exact: true,
    editStatus: EDIT_STATUS.CREATE
  },
  {
    path: `${schoolGroup}/announcement/:announcementId/${EDIT_STATUS.EDIT}`,
    component: HomeAnnouncementFormPage,
    exact: true,
    editStatus: EDIT_STATUS.EDIT
  },
  {
    path: `${schoolGroup}/member`,
    component: HomeMemberPage,
    exact: true
  },
  {
    path: `${schoolGroup}/member/invite`,
    component: InviteFreeGroupUserPage,
    exact: true
  },
  {
    path: `${schoolGroup}/member/seats`,
    component: MemberSeatsSelectPage,
    exact: true
  },
  {
    path: `${schoolGroup}/invite`,
    component: HomeInvitePage,
    exact: true
  },
  {
    path: `${schoolGroup}/album`,
    component: HomeAlbumPage,
    exact: true
  },
  {
    path: `${schoolGroup}/album/:albumCategory`,
    component: HomePicturePage,
    exact: true
  },
  {
    path: `${schoolGroup}/setting`,
    component: HomeSettingPage,
    exact: true
  },
  {
    path: `${schoolGroup}/info`,
    component: HomeInfoPage,
    exact: true
  },
  {
    path: `${schoolGroup}/secretdiscuss`,
    component: SecretDiscussPage,
    exact: true
  },
  {
    path: `${schoolGroup}/exam`,
    component: HomeExamPage,
    exact: true
  },
  {
    path: `${schoolGroup}/video`,
    component: HomeVideoPage,
    exact: true
  },
];

export default routes;
