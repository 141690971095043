import styled from 'styled-components';

import {
  AppBar as MatAppBar,
} from '@material-ui/core';

export const UiAppBar = styled(MatAppBar)`
	background-color: ${({ theme }) => theme.palette.backstage.block.bar};
`;

export const UiBsHeaderTitle = styled.div`
	display: block;
`;
