import styled from 'styled-components';

export const UiHomeExamResults = styled.div`
	display: block;
`;

export const UiHomeExamGoBack = styled.div`
	display: flex;
	align-items: center;
	margin-top: 1rem;

	span {
		cursor: pointer;
	}

	svg {
		cursor: pointer;
		transform: rotate(180deg);
	}
`;

export const UiHomeExamTitle = styled.div`
	margin: 20px 0;
	font-size: 20px;
	font-weight: 700;
`;

export const UiHomeExamTable = styled.div`
`;

export const UiEmptyBox = styled.div`
	margin: 0 auto;
	text-align: center;
`;

export const UiEmptyImage = styled.div`
	margin: auto;
	width: 36%;
	height: 240px;
	background: url("${({ path }) => path}");
	background-position: center;
	background-size: cover;
`;

export const UiEmptyText = styled.div`
	margin: 3rem 0;
`;