import React, { useEffect, useState, } from 'react';
import PropTypes from 'prop-types';
import defaultImage from 'assets/images/teachingMaterials.png';

import {
  BsModal,
  Loading,
  RadioGroup,
} from 'components';
import { getSessionResourcesById as getSessionResourceByIdApi } from 'services/api/organization/resources';
import {
  UiModal,
  UiFigureContainer
} from './ResourceModal.style';

export const ResourceModal = ({
  sessionId,
  isOpenResourceModal,
  onCancel,
  onOk,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [defaultValue, setDefaultValue] = useState(null);
  const [resourceData, setResourceData] = useState([]);     // 課程教材內容 { label, value }
  const [resourceValue, setResourceValue] = useState(null); // radioGroup 選到的 value

  /* change RadioGroup */
  const changeRadioGroupHandler = (e) => {
    setResourceValue(e.target.value);
  };

  /* click Modal 確認按鈕 */
  const clickModalOkHandler = () => {
    onOk(resourceValue);
  };

  /* 打API，取得課程資源 */
  const fetchData = async () => {
    setIsLoading(true);
    const { status, data } = await getSessionResourceByIdApi(sessionId);
    if(status === 'success') {
      const newResourceData = data.map((resource) => {
        return {
          label: resource.displayName,
          value: resource.resourceId,
        };
      });
      setResourceData(newResourceData);
      setDefaultValue(newResourceData[0].value);
      setResourceValue(newResourceData[0].value);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    sessionId && fetchData();
  }, [sessionId]);

  return (
    <BsModal
      title='選擇課程教材'
      open={isOpenResourceModal}
      okDisabled={resourceData.length === 0}
      onCancel={onCancel}
      onOk={clickModalOkHandler} >
      {
        isLoading
          ? <Loading />
          : resourceData.length > 0
              ? <RadioGroup
                  defaultValue={defaultValue}
                  data={resourceData}
                  isRow={false}
                  onChange={changeRadioGroupHandler}
                />
              : <UiModal>
                  <UiFigureContainer>
                    <img alt='無課程教材' src={defaultImage} />
                    <figcaption>目前尚無課程教材</figcaption>
                  </UiFigureContainer>
                </UiModal>
      }
    </BsModal>
  );
};

ResourceModal.propTypes = {
  sessionId: PropTypes.string,
  isOpenResourceModal: PropTypes.bool,
  onCancel: PropTypes.func,
  onOk: PropTypes.func,
};