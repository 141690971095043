import { stringify } from 'query-string';
import { Api } from 'services/api';


/**
 * 取得&查詢 機構教師 list
 * @param {string}} organizationId
 * @param {number}} nowPage
 * @param {number}} rowsPage
 * @param {string}} nickname
 * @param {string}} mobileNumber
 */
export const getTeachers = (isHome) => (organizationId, classId) => async params => {
  const { nowPage = 0, rowsPage, nickname, mobileNumber,targetUserId } = params;
  const queryParams = {
    limit: rowsPage ? rowsPage : 10,
  };
  if (rowsPage && nowPage * rowsPage !== 0) {
    queryParams.skip = nowPage * rowsPage;
  }
  if (nickname) {
    queryParams.nickname = nickname;
  }
  if (mobileNumber) {
    queryParams.mobileNumber = mobileNumber;
  }
  if(targetUserId) {
    queryParams.targetUserId = targetUserId;
  }
  const querystring = stringify(queryParams);

  let url = '';

  if (isHome) {
    url = `/groups/${classId}/host-candidates?${querystring}`;
    if (organizationId) {
      url = `/groups/${classId}/hosts?${querystring}`;
    }
  } else {
    url = `/organizations/${organizationId}/staffs?${querystring}`;
  }

  const response = await Api.get(url);
  return response;
};

// 機構成員
export const getOrganizationStaffs = (isHome) => (organizationId, classId) => async params => {
  const { nowPage = 0, rowsPage, nickname, mobileNumber, targetUserId, currentGroupHostOnly } = params;
  const queryParams = {
    limit: rowsPage ? rowsPage : 10,
  };
  const groupId = classId;
  if (rowsPage && nowPage * rowsPage !== 0) {
    queryParams.skip = nowPage * rowsPage;
  }
  if (nickname) {
    queryParams.nickname = nickname;
  }
  if (mobileNumber) {
    queryParams.mobileNumber = mobileNumber;
  }
  if (targetUserId) {
    queryParams.targetUserId = targetUserId;
  }
  if (isHome && currentGroupHostOnly) {
    queryParams.currentGroupHostOnly = currentGroupHostOnly;
  }

  const querystring = stringify(queryParams);

  let url = '';

  if (isHome) {
    url = `/organizations/${organizationId}/groups/${groupId}/staffs?${querystring}`;
  } else {
    url = `/organizations/${organizationId}/staffs?${querystring}`;
  }

  const response = await Api.get(url);
  return response;
};

/**
 * 建立 機構教師 information
 * @param {object} payload
 */
export const createTeachInfo = organizationId => async params => {
  const response = await Api.post(`/organizations/${organizationId}/staffs`, params);
  return response;
};

export const updateTeachInfo = (organizationId, staffId) => async params => {
  const response = await Api.put(`/organizations/${organizationId}/staffs/${staffId}`, params);
  return response;
};

export const inviteTeachersToOrganizationGroup = (organizationId, classId) => async params => {
  const response = await Api.post(`/organizations/${organizationId}/groups/${classId}/hosts/invite`, params);
  return response;
};

/**
 * Remove Group Host Candidate
 * 移出 無組織教師(目前無課程才可移除) for 獨立班級
 * @param {object} payload
 */
export const removeGroupHostCandidate = groupId => async (userId) => {
  const response = await Api.delete(`/groups/${groupId}/host-candidates/${userId}`);
  return response;
};

/**
 * Remove Group Host
 * 移出 無組織教師(目前無課程才可移除)
 * @param {object} payload
 */
 export const removeGroupHost = async (groupId, userId) => {
   console.log('groupId', groupId);
   console.log('userId', userId);
  const response = await Api.delete(`/groups/${groupId}/hosts/${userId}`);
  return response;
};

/**
 * Add Group Hosts From Organization Staffs
 * 老師加入班級（校內）
 * 
 * @param {array} hostIdList 老師id列表
 */
 export const addGroupHostsFromOrganizationStaffs = async (groupId, params) => {
  const response = await Api.put(`/groups/${groupId}/hosts`, params);
  return response;
};

/**
 * Get Organization Staff For Group Owner
 * 校內邀請 列表
 * 
 * @param {string} targetUserId
 * @param {boolean} currentGroupHostOnly 是否只顯示老師
 * @param {boolean} excludeCurrentGroupHost 是否過濾已加入班級的老師，不能與 currentGroupHostOnly 共為true，會變成空集合
 * @param {string} nickname
 * @param {string} mobileNumber
 * @param {number} skip 略過前面幾筆
 * @param {number} limit 最多顯示幾筆
 */
 export const getOrganizationStaffForGroupOwner = async (organizationId, groupId, params) => {
  const { targetUserId, currentGroupHostOnly, excludeCurrentGroupHost, nickname, mobileNumber, nowPage, rowsPage } = params;
  let queryParams = {
    limit: rowsPage ? rowsPage : 10,
  };

  if (rowsPage && nowPage * rowsPage !== 0) {
    queryParams.skip = nowPage * rowsPage;
  }

  if (targetUserId) {
    queryParams.targetUserId = targetUserId;
  }

  if (currentGroupHostOnly) {
    queryParams.currentGroupHostOnly = currentGroupHostOnly;
  }

  if (excludeCurrentGroupHost) {
    queryParams.excludeCurrentGroupHost = excludeCurrentGroupHost;
  }
  
  if (nickname) {
    queryParams.nickname = nickname;
  }

  if (mobileNumber) {
    queryParams.mobileNumber = mobileNumber;
  }

  const querystring = stringify(queryParams);
  const response = await Api.get(`/organizations/${organizationId}/groups/${groupId}/staffs?${querystring}`);
  return response;
};