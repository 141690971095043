import React from 'react';
import PropTypes from 'prop-types';
import {
  UiHomeMemberCenterLayout,
  UiMemberCenterTitle,
  UiHomeMemberCenterContent,
  UiChildrenContainer,
} from './HomeMemberCenterLayout.style';
import { Header, Footer, SideBar, BlindBrick } from 'components';

/**
 * 會員中心layout
 */

export const HomeMemberCenterLayout = ({ children }) => {

  return (
    <UiHomeMemberCenterLayout>
      <Header />
      <UiMemberCenterTitle tabIndex={0}>
        會員中心
      </UiMemberCenterTitle>
      <UiHomeMemberCenterContent >

        <SideBar type='memberCenter' />
        <UiChildrenContainer>
          <BlindBrick className='accesskey' id='accessibility_center_container' accessKey='c' href='#accessibility_center_container' title='中央內容區塊，為本頁主要內容區。' />
          {children}
        </UiChildrenContainer>
      </UiHomeMemberCenterContent>
      <Footer />
    </UiHomeMemberCenterLayout>
  );
};

HomeMemberCenterLayout.propTypes = {
  children: PropTypes.node,
};


