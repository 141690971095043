import React from 'react';
import {HomeOrganizationLayout,HomeAnnouncementForm} from 'components';
/**
 * 組織機構公告表單頁
 */

export const HomeOrganizationAnnouncementFormPage = props => {
  return (<HomeOrganizationLayout>
    <HomeAnnouncementForm {...props} />
  </HomeOrganizationLayout>);
};
