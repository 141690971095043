import React from 'react';
import PropTypes from 'prop-types';
import { Header, Footer } from 'components';
import { UiNormalLayout,UiNormalContent } from './NormalLayout.style';


/**
 * 沒有限制最大寬度的layout
 */

export const NormalLayout = ({ children }) => {
  return (
    <UiNormalLayout>
      <Header/>
      <UiNormalContent>
       {children}
      </UiNormalContent>
      <Footer/>
    </UiNormalLayout>
  );
};

NormalLayout.propTypes = {
  children: PropTypes.node,
};


