import React from 'react';

import {
  HomeGroupLayout,
  HomeMemberTable
} from 'components';
/**
 * 前台 成員頁面
 */

export const HomeMemberPage = () => {

  return (
    <HomeGroupLayout>
      <HomeMemberTable />
    </HomeGroupLayout>
  );
};

HomeMemberPage.propTypes = {

};


