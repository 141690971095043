import React from 'react';
import { HomeGroupLayout, WebResource } from 'components';

/**
 * 常用資源頁面
 */

export const WebResourcePage = () => {
  return (
  <HomeGroupLayout>
    <WebResource />
  </HomeGroupLayout>);
};


