import styled from 'styled-components';

export const UiHomeMemberProfile = styled.div`
	display: block;
`;

export const UiTipBox = styled.div`
		margin: auto;
		width: 75%;
`;

export const UiTip = styled.div`
	text-align: left;
	color: #8b90a0;
`