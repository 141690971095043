import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { Icon } from 'components';
import { useSetState } from 'utils/hooks/useSetState';
import { useUuid } from 'utils/hooks/useUuid';
import { UiFileList, UiTitle, UiFileItem, UiClose } from './FileList.style';


/**
 * 上傳檔案列表
 */

const useStyles = makeStyles({
	root: {
		cursor: 'pointer',
	},
});

export const FileList = ({ data = [], limit = 1, onChange = () => { } }) => {
  const classes = useStyles();
  const [getUuid] = useUuid();
  const [{
    fileList,
  }, setState] = useSetState({
    fileList: data,
  });

  const removeFile = id => {
    const nextFileList = fileList.filter(file => file.id !== id);
    setState({
      fileList: nextFileList,
    });
  };

  const addNewFile = file => {
    const newFile = {
      id: getUuid(),
      value: file
    };
    setState({
      fileList: [newFile],
    });
  };

  useEffect(() => {
    onChange(fileList);
  }, [fileList]);

  useEffect(() => {
    setState({
      fileList: data
    });
  }, [data]);

  return (
    <UiFileList>
      <UiTitle>
        <Icon.Svg name="Attachment" />
        附加檔案
      </UiTitle>
      {
        fileList.map(fileItem => (
          <UiFileItem key={fileItem.id}>
            <Icon name='checkBoxOutlineBlank' />
            {`${fileItem.value.name}`}
            <UiClose>
              <Icon className={classes.root} title="移除項目" name='close' onClick={() => removeFile(fileItem.id)} />
            </UiClose>
          </UiFileItem>
        ))
      }
      {
        fileList.length >= limit ||
        <UiFileItem isClick={true}>
          <Icon title='新增檔案' name='add' />
          新增檔案
          <input
            type="file"
            onChange={e => addNewFile(e.target.files[0])}
          />
        </UiFileItem>
      }
    </UiFileList>
  );
};

FileList.propTypes = {
  data: PropTypes.array,
  limit: PropTypes.number,
  onChange: PropTypes.func
};


