import React,{ useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { UiMemberCenterCard, UiCardContent, UiCardTitle } from './MemberCenterCard.style';


/**
 * 會員中心的卡牌組件
 */

export const MemberCenterCard = ({
  title = '預設標題',
  children,
}) => {
  const { pathname } = useLocation();
  const homeOnLineExamRef = useRef();

  useEffect(() => {
    if (!homeOnLineExamRef.current) return;
    pathname && homeOnLineExamRef.current.focus();
  }, [homeOnLineExamRef, pathname]);
  return (
    <UiMemberCenterCard>
      <UiCardTitle tabIndex={0} ref={homeOnLineExamRef}>{title}</UiCardTitle>
      <UiCardContent>
        {children}
      </UiCardContent>
    </UiMemberCenterCard>
  );
};

MemberCenterCard.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};


