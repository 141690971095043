import React from 'react';
import PropTypes from 'prop-types';

import { HomeworkCardRead, HomeworkCardEdit } from 'components';
import { HomeworkCardStatus } from 'constants/index';
import { UiHomeworkCard, UiMatAvatar } from './HomeworkCard.style';
import defaultAvatarImage from 'assets/images/Avatar_Member_Default.png';

/**
 * 作業卡牌組件
 */

export const HomeworkCard = ({
  type = 'read',
  userType = 'student',
  canOperating = false,
  isAdmin = false,
  isLoading = false,
  isOpen = false,
  userAvatar = '',
  userNickname = '',
  onSubmitCard = () => { },
  onSubmitCradRead = () => { },
  onDelete = () => { },
  onPin = () => { },
  onCancelPin = () => { },
  onUploaded = () => { },
  getOpenState = () => {},
  data,
}) => {
  const onSubmitHandler = value => {
    onSubmitCard(value);
  };
  const onSubmitCradReadHandler = value => {
    onSubmitCradRead(value);
  };
  return (
    <UiHomeworkCard>
      {
        (type === HomeworkCardStatus.READ || isOpen) &&
        <UiMatAvatar
          src={type === HomeworkCardStatus.READ ?
            data.ownerThumbnailUrl || defaultAvatarImage :
            userAvatar || defaultAvatarImage}
        />
      }
      {
        type === HomeworkCardStatus.READ ? (
          <HomeworkCardRead
            data={data}
            userType={userType}
            onDelete={onDelete}
            onPin={onPin}
            onCancelPin={onCancelPin}
            onCardReadFormSubmit={onSubmitCradReadHandler}
            onUploaded={onUploaded}
            isLoading={isLoading}
            canOperating={canOperating}
            isAdmin={isAdmin}
          />
        ) : (
            <HomeworkCardEdit
              data={data}
              isOpen={isOpen}
              onSubmit={onSubmitHandler}
              isLoading={isLoading}
              getOpenState={getOpenState}
            />
          )
      }
    </UiHomeworkCard>
  );
};

HomeworkCard.propTypes = {
  type: PropTypes.string,
  userType: PropTypes.string,
  data: PropTypes.object,
  onSubmitCard: PropTypes.func,
  onDelete: PropTypes.func,
  onPin: PropTypes.func,
  onCancelPin: PropTypes.func,
  onSubmitCradRead: PropTypes.func,
  onUploaded: PropTypes.func,
  isLoading: PropTypes.bool,
  canOperating: PropTypes.bool,
  isAdmin: PropTypes.bool,
  isOpen: PropTypes.bool,
  userNickname: PropTypes.string,
  userAvatar: PropTypes.string,
};


