import React from 'react';
import PropTypes from 'prop-types';

import { Checkbox, DropzoneUploader, Form } from 'components';
import { UiPdfUploader } from './PdfUploader.style';

/**
 * pdf 上傳組件
 */

const schema = {
  svg_realtext: {
    component: Checkbox,
    elementProps: {
      checked: false,
      label: '向量可選字',
      checkedValue: 'svg_realtext'
    },
    style: {
      display: 'inline-flex',
      margin: 0
    }
  },
  compressPdfImg: {
    component: Checkbox,
    elementProps: {
      checked: false,
      label: '轉檔時壓縮pdf文件內圖檔',
      checkedValue: 'compressPdfImg'
    },
    style: {
      display: 'inline-flex',
      margin: 0
    }
  },
  file: {
    component: DropzoneUploader,
    elementProps: {
      label: 'input1 label',
      value: '',
      filesLimit: 1
    }
  }
};

const buttons = [
  {
    text: '確定',
    type: 'submit',
  },
];

export const PdfUploader = ({ getFileHandler, isLoading }) => {
  const upLoadFile = data => {
    getFileHandler(data);
  };
  return (
    <UiPdfUploader>
      <Form schema={schema} buttons={buttons} onSubmit={upLoadFile} isLoading={isLoading} />
    </UiPdfUploader>
  );
};

PdfUploader.propTypes = {
  getFileHandler: PropTypes.func.isRequired,
  isLoading: PropTypes.bool
};


