import React from 'react';
import PropTypes from 'prop-types';

import RenderRoutes from 'routes/RenderRoutes';
import { OrganizationAside, Header, Footer } from 'components';
import { UiHeader, UiMain, UiAside, UiContent } from './OrganizationPageLayout.style';

/**
 * 管理介面的主要版型
 */

export const OrganizationPageLayout = ({ routes = [] }) => {
  return (
    <>
      <UiHeader>
        <Header />
      </UiHeader>
      <UiMain>
        <UiAside>
          <OrganizationAside />
        </UiAside>
        <UiContent>
          <RenderRoutes routes={routes} />
        </UiContent>
      </UiMain>
      <Footer />
    </>
  );
};

OrganizationPageLayout.propTypes = {
  routes: PropTypes.array
};
