import { stringify } from 'query-string';
import { Api } from 'services/api';

/**
 *
 * @param {number} nowPage 當前頁數
 * @param {number} rowsPage 一頁幾筆
 * @param {string} name 課程名稱
 * @param {timeStamp} startAt 課程開始時間
 * @param {timeStamp} endAt 課程結束時間
 * @param {string} classId 班級Id
 */
export const getSessions = classId => async params => {
  const { nowPage, rowsPage, name, startAt, endAt } = params;
  const queryParams = {
    limit: rowsPage ? rowsPage : 10,
  };
  if (nowPage * rowsPage !== 0) queryParams.skip = nowPage * rowsPage;

  if (classId) queryParams.groupId = classId;

  if (name) queryParams.name = name;

  if (startAt) queryParams.startAt = startAt;

  if (endAt) queryParams.endAt = endAt;

  const querystring = await stringify(queryParams);
  const response = await Api.get(`/sessions?${querystring}`);
  return response;
};

/**
 *
 * @param {string} hostId 教師 Id
 * @param {string} name 課程名稱
 * @param {number} startAt 開始時間
 * @param {number} endAt 結束時間
 * @param {string} resourceId 教學資源 Id
 */
export const createSession = classId => async params => {
  const { data } = params;
  const response = await Api.post(`/groups/${classId}/sessions`, data);
  return response;
};


export const getMySessions = async params => {
  const { nowPage, rowsPage, startAt, endAt, isHost } = params;
  const queryParams = {
    limit: rowsPage ? rowsPage : 8,
  };
  if (nowPage * rowsPage !== 0) queryParams.skip = nowPage * rowsPage;

  if (startAt) queryParams.startAt = startAt;

  if (endAt) queryParams.endAt = endAt;

  if (isHost) queryParams.isHost = isHost;

  const querystring = await stringify(queryParams);

  const response = await Api.get(`/sessions?${querystring}`);
  return response;
};

export const attendanceSession = (sessionId,timeSpanId) => async payload => {
  const response = await Api.post(`/sessions/${sessionId}/time-span/${timeSpanId}/attendance`, payload);
  return response;
};
