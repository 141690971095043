import styled from 'styled-components';
import {
  Tabs as MatTabs,
  Tab as MatTab,
  Typography as MatTypography
} from '@material-ui/core';

export const UiMatTabs = styled(MatTabs)`
	display: block;
	margin: 1rem 0;

	.MuiTab-textColorInherit.Mui-selected {
		color: #ec7963;
	}

	> div {
		border-bottom: 1px solid #e4e7ec;
	}

	> div > span {
		background-color: #ec7963;
	}
`;

export const UiTab = styled(MatTab)`

`;

export const UiTypography = styled(MatTypography)`

`;
