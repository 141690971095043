import React from 'react';

import { HomeGroupLayout, HomeAnnouncementForm } from 'components';
// import { UiHomeAnnouncementFormPage } from './HomeAnnouncementFormPage.style';


/**
 *  新增編輯公告頁面
 */

export const HomeAnnouncementFormPage = props => {
  return (
    <HomeGroupLayout>
      <HomeAnnouncementForm {...props} />
    </HomeGroupLayout>
  );
};

