import styled from 'styled-components';
import {
	Dialog as MatDialog,
	TextareaAutosize as MatTextareaAutosize,
	DialogContent as MatDialogContent,
	DialogContentText as MatDialogContentText,
	DialogActions as MatDialogActions
} from '@material-ui/core';

export const UiPostModal = styled(MatDialog)`
	.MuiPaper-elevation24 {
		position: relative;
		overflow: hidden;
		width: 550px;
		background-color: #fff;
		border-radius: 10px;
	}
`;

export const UiPostTitle = styled.div`
	margin: 1.5rem 0;
	padding: 0 1.5rem;
	font-size: 16px;
	font-weight: 700;
	color: #091b3d;
`;

export const UiPostContent = styled.div`
	padding: 0 1.5rem;
`;

export const UiPostFlexBox = styled.div`
	display: flex;
	justify-content: space-between;
`;

export const UiCloseButton = styled.button`
	position: absolute;
	top: 1rem;
	right: 1rem;
	transition: 0.35s;
	cursor: pointer;

	svg {
		width: 24px;
		height: 24px;
	}

	&:hover {
		opacity: 0.8;
	}
`;

export const UiUserInfo = styled.div`
	display: flex;
	align-items: center;
	padding: 1rem 0;

	.MuiAvatar-root {
		margin-right: 5px;
	}

	span {
		font-size: 14px;
		font-weight: bold;
		color: #242c3f;
	}
`;

export const UiTextarea = styled(MatTextareaAutosize)`
	overflow-y: scroll !important;
	padding-top: ${({ isOnlyImage }) => isOnlyImage && '1rem'};
	width: 100%;
	max-height: 150px;
	border: none;
	outline: none;
	resize: none;
`;

export const UiFileBox = styled.div`
	display: -webkit-box;
	margin-bottom: 1rem;
	overflow-y: hidden;
`;

export const UiImageItem = styled.div`
	position: relative;
	margin-right: 5px;
	width: 100px;
	height: 100px;
	font-size: 0;

	img {
		max-width: 100%;
		object-fit: contain;
		object-position: 50% 50%;
	}

	svg {
		position: absolute;
		top: 5px;
		right: 5px;
		font-size: 1.4rem;
		cursor: pointer;
		opacity: 0.7;
		transition: 0.35s;

		&:hover {
			opacity: 0.9;
		}
	}
`;

export const UiFileItem = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0.5rem;
	width: 100%;
	background-color: #eee;
	border: 1px solid ${({ theme }) => theme.palette.line.light};
	border-radius: 5px;

	& > svg {
		cursor: pointer;
		font-size: 1.2rem;
	}
`;

export const UiAddFileItem = styled.label`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100px;
	height: 100px;
	font-size: 2rem;
	border: 1px dashed #777;
	cursor: pointer;

	& > input {
		display: none;
	}
`;

export const UiUpdateFileBox = styled.div`
	display: flex;
	padding: 0.5rem;
	margin-bottom: 1rem;
	flex-wrap: wrap;

	span {
		display: inline-block;
		width: 100%;
		line-height: 2;
		color: ${({ theme }) => theme.palette.text.Placeholder};
	}

	svg {
		margin: 0 0.25rem;
		font-size: 1.3rem;
		color: ${({ theme }) => theme.palette.btn.NormalHover};
		cursor: pointer;
	}

	input {
		display: none;
	}
`;

export const UiUpdateFileItem = styled.label.attrs(() => ({ tabIndex: '0', role: 'button' }))`
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 5px 10px;
	padding: 5px 10px;
	width: 47.9%;
	font-size: 14px;
	background-color: #fafafc;
	border-radius: 16px;
	cursor: pointer;

	&:hover {
		opacity: 0.8;
	}

	&:nth-child(odd) {
		margin-left: 0;
	}

	&:nth-child(even) {
		margin-right: 0;
	}

	& svg {
		font-size: 2rem;

		& path {
			fill: ${({ type }) => type === 'Picture' ? '#FFA31A' : (type === 'Video' ? '#F6564B' : '#5EB762')};
		}
	}
`;

export const UiDialogContent = styled(MatDialogContent)`
	padding: 0.8rem 8rem;
`;

export const UiDialogContentText = styled(MatDialogContentText)`
	font-size: 1.8rem;
	text-align: center;
	color: #333;
`;

export const UiDialogActions = styled(MatDialogActions)`
	justify-content: center;
	padding: 1.5rem;

	div {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
	}
`;

export const UiBottonBox = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px;

	& button {
		width: 90%;
		border-radius: 10px;
	}
`;
