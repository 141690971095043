import styled from 'styled-components';
import {
  Dialog as MatDialog,
  DialogContent as MatDialogContent,
  DialogContentText as MatDialogContentText,
  DialogTitle as MatDialogTitle,
  DialogActions as MatDialogActions
} from '@material-ui/core';
import { Icon } from 'components';

export const UiModal = styled(MatDialog)`
	& > div > div {
		padding: 1.33rem 1.6rem;
		width: ${({ size }) => {
			if(size === 'small') return '22rem';
			else if(size === 'medium') return '46rem';
			else if(size === 'large') return '880px';
		}};
		max-width: 100%;
		border-radius: 0.3125rem;
	}
`;

export const UiMatDialogTitle = styled(MatDialogTitle)`
	font-weight: bold;
	color: #fff;
	background-color: ${({ theme }) => theme.palette.btn.normal};
`;

export const UiMatDialogContent = styled(MatDialogContent)`
	font-family: ${({ theme }) => theme.text.fontFamily};
	padding: 0;
	/* color: ${({ theme }) => theme.oneClass.text.palette.default}; */
`;

export const UiMatDialogContentText = styled(MatDialogContentText)`
	font-size: 1.5rem;
	text-align: center;
	color: #595959;

	& > span {
		color: #f00;
	}
`;

export const UiMatDialogActions = styled(MatDialogActions)`
	justify-content: flex-end;
	margin-top: 1.25rem;

	button {
		padding: 0.625rem 2rem;
		font-size: 1rem;
		font-weight: 500;
		border: 0;
		border-radius: 0.25rem;
	}
`;

export const UiIcon = styled(Icon)`
	margin: auto;
	font-size: 7rem;
	color: #facea8;
`;
