import React from 'react';
import { BsModal, Icon, DateTimePicker } from 'components';
import PropTypes from 'prop-types';
import { useSetState } from 'utils/hooks/useSetState';
import {
  TextField as MatTextField
 } from '@material-ui/core';
import { UiFormBox, UiTabBox, UiTabItem } from './HomeOnlineExam.style';

const CREATE_TYPE = {
  NOW:'now',
  RESERVE:'reserve'
};

const ExamModal = ({
  title = '我要派卷',
  isOpen,
  onClick,
  onCancel,
  isLoading,
  defaultType,
  onChange,
  examPeriod,
  startAt,
  endAt,
  examName,
  disabledTab = false
}) => {
  const [{
    createExamType
  },setState] = useSetState({
    createExamType : defaultType || CREATE_TYPE.NOW
  });
  const onChangeCreateType = type => {
    setState({
      createExamType:type
    });
  };

  const onSubmit = () => {
    onClick(createExamType);
  };

  return (
    <BsModal
      title={title}
      height='536px'
      open={isOpen}
      onOk={onSubmit}
      onCancel={onCancel}
      isLoading={isLoading}
      okText="派卷"
    >
    {!disabledTab &&<UiTabBox>
      <UiTabItem
        className={createExamType === CREATE_TYPE.NOW ? 'active' : ''}
        onClick={()=> onChangeCreateType(CREATE_TYPE.NOW)}
      >
        <Icon.Svg name="Rocket" color={createExamType === CREATE_TYPE.NOW ? '#fff':'#8B90A0'}/>
        <span>立即派卷</span>
      </UiTabItem>
      <UiTabItem
        className={createExamType === CREATE_TYPE.RESERVE ? 'active' : ''}
        onClick={()=> onChangeCreateType(CREATE_TYPE.RESERVE)}
      >
        <Icon.Svg name="Calendar"color={createExamType === CREATE_TYPE.RESERVE ? '#fff':'#8B90A0'}/>
        <span>預約派卷</span>
      </UiTabItem>
    </UiTabBox>}
    <UiFormBox position={createExamType === CREATE_TYPE.NOW ? '24%' : '72%'}>
      <MatTextField
        label="測驗名稱*"
        variant="outlined"
        style={{width: '70%', margin: '10px 0'}}
        value={examName || ''}
        onChange={e => onChange(e.target.value,'examName')}
      />
      <MatTextField
        label="作答時間(分鐘)*"
        variant="outlined"
        type="number"
        style={{width: '70%', margin: '10px 0'}}
        value={examPeriod || null}
        onChange={e => onChange(e.target.value,'examPeriod')}
      />
      {createExamType === CREATE_TYPE.RESERVE &&
      <DateTimePicker
        label="開始時間*"
        style={{width: '70%', margin: '10px 0'}}
        value={startAt || null}
        onChange={value => onChange(value,'startAt')}
      />}
      {
        (examPeriod && startAt && createExamType === CREATE_TYPE.RESERVE) &&
        <DateTimePicker
          label="結束時間*"
          style={{width: '70%', margin: '10px 0'}}
          value={endAt || null}
          onChange={value => onChange(value,'endAt')}
        />
      }
    </UiFormBox>
    </BsModal>
  );
};

ExamModal.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  onClick: PropTypes.func,
  onCancel: PropTypes.func,
  isLoading : PropTypes.bool,
  defaultType: PropTypes.string,
  onChange: PropTypes.func,
  examPeriod: PropTypes.number,
  startAt: PropTypes.number,
  endAt: PropTypes.number,
  examName: PropTypes.string,
  disabledTab: PropTypes.bool
};

export default ExamModal;
