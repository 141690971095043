
export const useDetectionDevice = () => {
  const regexp = /\(.+?\)/g;
  const ua = navigator.userAgent.toLowerCase();
  const getDeviceInfo = () => {
    let userDevice = ua.match(regexp)[0];
    if (userDevice.indexOf('iphone') !== -1) {
      userDevice = 'iphone';
    } else {
      userDevice = 'other';
    }
    return userDevice;
  };
  const getBrowserInfo = () => {
    let sys = {};
    sys.edge = (ua.match(/edge\/([\d.]+)/)) ? ua.match(/edge\/([\d.]+)/)[1] : 0;
    sys.ie = (ua.match(/rv:([\d.]+)\) like gecko/)) ? ua.match(/rv:([\d.]+)\) like gecko/)[1] : 0;
    sys.ie = (ua.match(/msie ([\d.]+)/)) ? ua.match(/msie ([\d.]+)/)[1] : 0;
    sys.firefox = (ua.match(/firefox\/([\d.]+)/)) ? ua.match(/firefox\/([\d.]+)/)[1] : 0;
    sys.chrome = (ua.match(/chrome\/([\d.]+)/)) ? ua.match(/chrome\/([\d.]+)/)[1] : 0;
    sys.opera = (ua.match(/opera.([\d.]+)/)) ? ua.match(/opera.([\d.]+)/)[1] : 0;
    sys.safari = (ua.match(/version\/([\d.]+).*safari/)) ? ua.match(/version\/([\d.]+).*safari/)[1] : 0;

    if (sys.edge) return { broswer: 'Edge', version: sys.edge };
    if (sys.ie) return { broswer: 'IE', version: sys.ie };
    if (sys.firefox) return { broswer: 'Firefox', version: sys.firefox };
    if (sys.chrome) return { broswer: 'Chrome', version: sys.chrome };
    if (sys.opera) return { broswer: 'Opera', version: sys.opera };
    if (sys.safari) {
      sys.chrome = (ua.match(/crios\/([\d.]+)/)) ? ua.match(/crios\/([\d.]+)/)[1] : 0;
      if (sys.chrome) return { broswer: 'Chrome', version: sys.chrome };
      return { broswer: 'Safari', version: sys.safari };
    }

    return { broswer: '', version: '0' };
  };
  return { getDeviceInfo, getBrowserInfo };
};
