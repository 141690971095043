import React, { useEffect, useState, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSetState } from 'utils/hooks/useSetState';
import { TextField, Box, } from '@material-ui/core';

import { EDIT_STATUS, EDUCATION_LEVEL, ORGANIZATION_SETTINGS_VALUE } from 'constants/index';
import {
  Button,
  TransferListModal,
  Select,
  IconInput,
  FormCheckBoxGroup,
  Checkbox,
  UploadImageBox,
  CitySelector
 } from 'components';
import { useAdmin } from 'store/admin';
import { isExist,validURL } from 'utils/helper';
import { useFirebaseStorage } from 'utils/hooks/useFirebaseStorage';

import { UiOrganizationForm, UiButtonBox, UiFilterBox, UiButtonWrapper } from './OrganizationForm.style';

/**
 * 『機構管理』表單
 */
const statuOptions = [
  {
    name: '開通',
    value: 'active',
  },
  {
    name: '未開通',
    value: 'inactive',
  },
  {
    name: '停權',
    value: 'suspended',
  }
];

const educationNamesList = [{
  label: '國小',
  value: EDUCATION_LEVEL.ELEMENTARY
}, {
  label: '國中',
  value: EDUCATION_LEVEL.JUNIOR
}, {
  label: '高中',
  value: EDUCATION_LEVEL.SENIOR
}, {
  label: '其他',
  value: EDUCATION_LEVEL.OTHER
}];

export const OrganizationForm = ({ editStatus }) => {
  const { upload } = useFirebaseStorage();
  const [formData, setFormData] = useSetState({
    officialName: '',
    GUINumber: '',
    cityName: '',
    districtName: '',
    address: '',
    officialSiteUrl:'',
    thumbnailUrl:'',
    ownerMobileNumber: '',
    ownerName: '',
    comment: '',
    ownerId: '',
    educationNames: [],
    status: '',
    groupCreationSetting: ORGANIZATION_SETTINGS_VALUE.PRIVATE,
    publicFields: [],
    groupOwnerInviteStaffSetting: ORGANIZATION_SETTINGS_VALUE.DISALLOW,
    groupGradeSelectionSetting: ORGANIZATION_SETTINGS_VALUE.SINGLE,
    groupOwnerCreateSessionSetting: ORGANIZATION_SETTINGS_VALUE.DISALLOW,
    accessToPublicResourceSetting:ORGANIZATION_SETTINGS_VALUE.DISALLOW,
    adminMonitorSessionSetting:ORGANIZATION_SETTINGS_VALUE.DISALLOW,
    sessionAttendanceManagementSetting:ORGANIZATION_SETTINGS_VALUE.NONE
  });

  const [{
    isLoading,
    transferListData,
    searchValue,
    selectOption,
    defaultUsersList,
    isValidating,
    imageFile
  }, setState] = useSetState({
    isLoading: editStatus === EDIT_STATUS.CREATE ? false : true,
    transferListData: [],
    searchValue: null,
    selectOption: '',
    defaultUsersList: [],
    isValidating: editStatus === EDIT_STATUS.EDIT,
    imageFile:null
  });
  const [errors, setErrors] = useState({});
  const history = useHistory();
  const { organizationId } = useParams();
  const [
    { users, orgs },
    { getAllUsersList, getOrgInfo, createOrganzitionsInfo, updateOrganizationInfo }
  ] = useAdmin();
  const { dataInfo: orgInfo } = orgs;
  const { data: usersData, total } = users;

  const fetchUsersList = (data) => {
    const userData = data.map(item => {
      const obj = {
        id: item.id || '',
        imagePath: item.thumbnailUrl || '',
        title: item.nickname || '',
        content: item.mobileNumber || '',
      };
      return obj;
    });

    setState({ transferListData: userData });
  };

  const getAllUser = async () => {
    const defaultUsersList = await getAllUsersList();
    setState({
      defaultUsersList
    });
  };

  useEffect(() => {
    // 取得所有使用者
    getAllUser();
    if (editStatus === EDIT_STATUS.CREATE) return;
    // 取得該組織資料
    getOrgInfo();
    setState({ isLoading: true });
  }, []);

  useEffect(() => {
    // edit & read
    if (organizationId) {
      setFormData({ ...orgInfo });
    }
    setState({ isLoading: false });
  }, [orgInfo]);

  useEffect(() => {
    if (total > 0) fetchUsersList(usersData);
  }, [usersData]);

  // 驗證
  const formValidationRules = {
    officialName: [
      {
        message: '本欄位為必填',
        validate: value => isExist(value)
      }
    ],
    GUINumber: [
      {
        message: '格式不正確',
        validate: taxId => {
          if (!taxId) return true;
          const invalidList = '00000000,11111111';
          if (/^\d{8}$/.test(taxId) === false || invalidList.indexOf(taxId) !== -1) {
            return false;
          }

          const validateOperator = [1, 2, 1, 2, 1, 2, 4, 1];
          let sum = 0;
          let calculate = function (product) { // 個位數ㄏ  + 十位數
            let ones = product % 10,
              tens = (product - ones) / 10;
            return ones + tens;
          };
          for (let i = 0; i < validateOperator.length; i++) {
            sum += calculate(taxId[i] * validateOperator[i]);
          }
          return (sum % 10 === 0 || (taxId[6] === '7' && (sum + 1) % 10 === 0));
        }
      }
    ],
    cityName: [
      {
        message: '本欄位為必填',
        validate: value => isExist(value)
      }
    ],
    address: [
      {
        message: '本欄位為必填',
        validate: value => isExist(value)
      }
    ],
    officialSiteUrl:[
      {
        message:'本欄位只能填入網址',
        validate: value => validURL(value)
      }
    ],
    ownerName: [
      {
        message: '本欄位為必填',
        validate: value => isExist(value)
      }
    ],
    ownerId: [
      {
        message: '本欄位為必填',
        validate: value => isExist(value)
      }
    ],
    status: [
      {
        message: '本欄位為必填',
        validate: value => isExist(value)
      }
    ]
  };

  const validateForm = () => {
    let errors = {};
    Object.entries(formData).forEach(([key, value]) => {
      if (!formValidationRules[key]) return;
      formValidationRules[key].forEach(rule => {
        if (!rule.validate(value)) {
          errors[key] = {
            message: rule.message
          };
        } else {
          delete errors[key];
        }
      });
    });
    return { errors };
  };

  useEffect(() => {
    if (!isValidating) return;
    const { errors } = validateForm();
    setErrors(errors);
  }, [isValidating, formData]);

  // 表單送出
  const submitHandler = async () => {
    setState({ isLoading: true, isValidating: true });
    if(imageFile){
      const fileName = imageFile.type.split('/')[0];
      const fileType = imageFile.type.split('/')[1];
      const uploadPath = `${organizationId}/organizationPicture/${fileName}.${fileType}`;
      const { url } = await upload(uploadPath, imageFile);
      formData.thumbnailUrl = url;
    }
    const { errors } = validateForm();
    if (Object.keys(errors).length > 0) {
      setState({ isLoading: false });
      setErrors(errors);
      return;
    }


    setState({ isLoading: true });
    const isEdit = editStatus === EDIT_STATUS.EDIT;
    const success = isEdit ?
      await updateOrganizationInfo(organizationId, formData) :
      await createOrganzitionsInfo(formData);
    setState({ isLoading: false });
    if (success) history.push('/admin/organizations');
  };

  const resetDataHandler = () => {
    fetchUsersList(defaultUsersList);
  };

  // 下拉選單系列
  const selectProps = {
    label: '篩選條件',
    options: [
      {
        name: '暱稱',
        value: 'nickname'
      },
      {
        name: '手機號碼',
        value: 'mobileNumber'
      },
      {
        name: '使用者 Id',
        value: 'userId'
      }
    ],
    submitHandler: value => {
      setState({ selectOption: value, nowPage: 0 });
    }
  };

  // 搜尋系列
  const IconInputProps = {
    placeholder: '搜尋欄位名稱',
    onChange: value => {
      setState({ searchValue: value, nowPage: 0 });
    }
  };

  useEffect(() => {
    let params = {};
    params[selectOption] = searchValue;
    // 取得所有使用者
    getAllUsersList(params);
  }, [searchValue]);

  const updateFormDataHandler = (property, value) => {
    setFormData({ [property]: value });
  };

  const ownerIdChangeHandler = (ownerInfo) => {
    const { content: mobileNumber, title: ownerName, id } = ownerInfo;
    setFormData({
      ownerMobileNumber: mobileNumber,
      ownerName: ownerName
    });
    updateFormDataHandler('ownerId', id);
  };

  const uploadHandle = (src,file) => {
    setState({
      imageFile:file
    });
    setFormData({
      thumbnailUrl:src
    });
  };

  const removeHandle = () => {
    setState({
      imageFile:''
    });
    setFormData({
      thumbnailUrl:''
    });
  };

  const citySelectorChangeHandler = city => {
    const { county: cityName, township: districtName } = city;
    updateFormDataHandler('cityName',cityName);
    updateFormDataHandler('districtName',districtName);
  };

  const citySelectorValue = useMemo(() => ({
    addressCountyName: formData.cityName,
    addressDistrictName: formData.districtName
  }),[formData.cityName,formData.districtName]);

  return (
    <UiOrganizationForm>
      <div className="left">
        <UploadImageBox
           upload={uploadHandle}
           remove={removeHandle}
           src={formData.thumbnailUrl}
        />
      </div>
      <div className="right">
      <UiButtonBox>
        <Button>新增付費</Button>
      </UiButtonBox>
      <Box py={2}>
        <TextField
          name='officialName'
          label="機構名稱*"
          value={formData.officialName}
          error={!!errors.officialName}
          helperText={errors.officialName && errors.officialName.message}
          onChange={e => updateFormDataHandler('officialName', e.target.value)}
          fullWidth
        />
      </Box>
      <Box py={2}>
        <TextField
          name='GUINumber'
          label="統一編號"
          value={formData.GUINumber}
          error={!!errors.GUINumber}
          helperText={errors.GUINumber && errors.GUINumber.message}
          onChange={e => updateFormDataHandler('GUINumber', e.target.value)}
          fullWidth
        />
      </Box>
      <Box py={2}>
        <CitySelector variant="filled" value={citySelectorValue} required onChange={citySelectorChangeHandler}/>
      </Box>
      <Box py={2}>
        <TextField
          name='address'
          label="住址*"
          value={formData.address}
          error={!!errors.address}
          helperText={errors.address && errors.address.message}
          onChange={e => updateFormDataHandler('address', e.target.value)}
          fullWidth
        />
      </Box>
      <Box py={2}>
        <TextField
          name='officialSiteUrl'
          label="官方網站*"
          value={formData.officialSiteUrl}
          error={!!errors.officialSiteUrl}
          helperText={errors.officialSiteUrl && errors.officialSiteUrl.message}
          onChange={e => updateFormDataHandler('officialSiteUrl', e.target.value)}
          fullWidth
        />
      </Box>
      <Box py={2}>
        <TransferListModal
          name='ownerId'
          data={transferListData}
          value={formData.ownerId}
          resetData={resetDataHandler}
          buttonName='選擇機構管理者*'
          title='選擇機構管理者'
          type='singleChoice'
          error={!!errors.ownerId}
          helperText={errors.ownerId && errors.ownerId.message}
          onChange={ownerInfo => ownerIdChangeHandler(ownerInfo)}
        >
          <UiFilterBox>
            <Select
              label={selectProps.label}
              value={selectOption}
              options={selectProps.options}
              submitHandler={selectProps.submitHandler}
            />
            <IconInput
              placeholder={IconInputProps.placeholder}
              onChange={IconInputProps.onChange}
            />
          </UiFilterBox>
        </TransferListModal>
      </Box>
      <Box py={2}>
        <TextField
          name='ownerMobileNumber'
          label="負責人帳號(手機)"
          value={formData.ownerMobileNumber}
          onChange={e => updateFormDataHandler('ownerMobileNumber', e.target.value)}
          disabled
          fullWidth
        />
      </Box>
      <Box py={2}>
        <TextField
          name='ownerName'
          label="負責人名稱*"
          value={formData.ownerName}
          error={!!errors.ownerName}
          helperText={errors.ownerName && errors.ownerName.message}
          onChange={e => updateFormDataHandler('ownerName', e.target.value)}
          disabled
          fullWidth
        />
      </Box>
      <Box py={2}>
        <TextField
          name='comment'
          label="備註"
          value={formData.comment}
          multiline
          rows={5}
          onChange={e => updateFormDataHandler('comment', e.target.value)}
          fullWidth
        />
      </Box>
      <Box py={2}>
        <FormCheckBoxGroup
          name='educationNames'
          label="學制"
          value={formData.educationNames}
          data={educationNamesList}
          onChange={value => updateFormDataHandler('educationNames', value)}
          fullWidth
        />
      </Box>
      <Box py={2}>
        <Select
          name='status'
          label="組織狀態*"
          options={statuOptions}
          error={!!errors.status}
          helperText={errors.status && errors.status.message}
          onChangeHandler={value => updateFormDataHandler('status', value)}
          isOutline={false}
          fullWidth
        />
      </Box>
      <Box py={1}>
        <Checkbox
          name='groupCreationSetting'
          label="非機構教師可建班加入機構"
          helperText=''
          checkedValue={true}
          checked={formData.groupCreationSetting === ORGANIZATION_SETTINGS_VALUE.PUBLIC}
          onChange={value => {
            const isChecked = (value === true) ? ORGANIZATION_SETTINGS_VALUE.PUBLIC :
              ORGANIZATION_SETTINGS_VALUE.PRIVATE;
            updateFormDataHandler('groupCreationSetting', isChecked);
          }}
        />
      </Box>
      <Box py={1}>
        <Checkbox
          name='publicFields'
          label="前台顯示所在縣市"
          helperText=''
          checkedValue={true}
          checked={formData.publicFields.includes('cityName')}
          onChange={value => {
            const isChecked = (value === true) ? ['cityName'] : [];
            updateFormDataHandler('publicFields', isChecked);
          }}
        />
      </Box>
      <Box py={1}>
        <Checkbox
          name='groupGradeSelectionSetting'
          label="班級所在年級可複選"
          helperText=''
          checkedValue={true}
          checked={formData.groupGradeSelectionSetting === ORGANIZATION_SETTINGS_VALUE.MULTIPLE}
          onChange={value => {
            const isChecked = (value === true) ? ORGANIZATION_SETTINGS_VALUE.MULTIPLE :
              ORGANIZATION_SETTINGS_VALUE.SINGLE;
            updateFormDataHandler('groupGradeSelectionSetting', isChecked);
          }}
        />
      </Box>
      <Box py={1}>
        <Checkbox
          name='groupOwnerCreateSessionSetting'
          label="導師是否可以在前台開課"
          helperText=''
          checkedValue={true}
          checked={formData.groupOwnerCreateSessionSetting === ORGANIZATION_SETTINGS_VALUE.ALLOW}
          onChange={value => {
            const isChecked = (value === true) ? ORGANIZATION_SETTINGS_VALUE.ALLOW :
              ORGANIZATION_SETTINGS_VALUE.DISALLOW;
            updateFormDataHandler('groupOwnerCreateSessionSetting', isChecked);
          }}
        />
      </Box>

      <Box py={1}>
        <Checkbox
          name='groupOwnerInviteStaffSetting'
          label="導師是否可以在前台邀請老師"
          helperText=''
          checkedValue={true}
          checked={formData.groupOwnerInviteStaffSetting === ORGANIZATION_SETTINGS_VALUE.ALLOW}
          onChange={value => {
            const isChecked = (value === true) ? ORGANIZATION_SETTINGS_VALUE.ALLOW :
              ORGANIZATION_SETTINGS_VALUE.DISALLOW;
            updateFormDataHandler('groupOwnerInviteStaffSetting', isChecked);
          }}
        />
      </Box>

      <Box py={1}>
        <Checkbox
          name='accessToPublicResourceSetting'
          label="可依學制使用對應公開教學資源"
          helperText=''
          checkedValue={true}
          checked={formData.accessToPublicResourceSetting === ORGANIZATION_SETTINGS_VALUE.ALLOW}
          onChange={value => {
            const isChecked = (value === true) ? ORGANIZATION_SETTINGS_VALUE.ALLOW :
              ORGANIZATION_SETTINGS_VALUE.DISALLOW;
            updateFormDataHandler('accessToPublicResourceSetting', isChecked);
          }}
        />
      </Box>
      <Box py={1}>
        <Checkbox
          name='adminMonitorSessionSetting'
          label="監課管理"
          helperText=''
          checkedValue={true}
          checked={formData.adminMonitorSessionSetting === ORGANIZATION_SETTINGS_VALUE.ALLOW}
          onChange={value => {
            const isChecked = (value === true) ? ORGANIZATION_SETTINGS_VALUE.ALLOW :
              ORGANIZATION_SETTINGS_VALUE.DISALLOW;
            updateFormDataHandler('adminMonitorSessionSetting', isChecked);
          }}
        />
      </Box>
      <Box py={1}>
        <Checkbox
          name='sessionAttendanceManagementSetting'
          label="啟用簽到/簽退系統"
          helperText=''
          checkedValue={true}
          checked={formData.sessionAttendanceManagementSetting === ORGANIZATION_SETTINGS_VALUE.HOST}
          onChange={value => {
            const isChecked = (value === true) ? ORGANIZATION_SETTINGS_VALUE.HOST :
              ORGANIZATION_SETTINGS_VALUE.NONE;
            updateFormDataHandler('sessionAttendanceManagementSetting', isChecked);
          }}
        />
      </Box>
      <UiButtonWrapper>
        <Button loading={isLoading} onClick={submitHandler}>確認</Button>
        <Button
          buttonColor="info"
          icon="exitToApp"
          type="submit"
          loading={isLoading}
          onClick={() => { history.goBack(); }}
        >
          回到上一頁
          </Button>
      </UiButtonWrapper>
      </div>
    </UiOrganizationForm>
  );
};

OrganizationForm.propTypes = {
  children: PropTypes.node,
};
