import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { setOnePaperLoginCookieByServiceRoot } from 'helper/onePaper';
import { login, checkLogin, getProfile, logout } from 'services/api/login';
import {
   getOrganizations as getOrganizationsApi,
   getOrganizationGroupAccess as getOrganizationGroupAccessApi,
   getOrgProfile as getOrgProfileApi,
   getOrgInfo as getOrgInfoApi } from 'services/api/organization/organizations';
import {
  accountIsExsit as accountIsExsitApi,
  forgetPassword as forgetPasswordApi,
  forgetPasswordCheckMobileValid as forgetPasswordCheckMobileValidApi,
  revisePassword as revisePasswordApi,
  registerSendValodCode as registerSendValodCodeApi,
  registerCheckMobileValid as registerCheckMobileValidApi,
  register as registerApi,
  changePassword as changePasswordApi,
  checkTokenAndUserPassword as checkTokenAndUserPasswordApi,
} from 'services/api/customerService';
import {
  getOrganizationInvitations as getOrganizationInvitationsApi,
  getGroupInvitations as getGroupInvitationsApi,
  acceptOrganizationInvitation as acceptOrganizationInvitationApi,
  acceptGroupInvitation as acceptGroupInvitationApi,
  getFreeGroupUserInvitations as getFreeGroupUserInvitationsApi,
  cancelFreeGroupUserInvitation as cancelFreeGroupUserInvitationApi,
  refreshProfile as refreshProfileApi,
  updateMyProfile as updateMyProfileApi,
  setPremiumUsers as setPremiumUsersApi,
} from 'services/api/home/users';
import {
  changeUserRole as changeUserRoleApi,
  editOrganizationGroupControls as editOrganizationGroupControlsApi
} from 'services/api/organization/user';
import { ROLES, FREE_GROUP_ROLES } from 'constants/index';
import { convertArrayToMapById } from 'utils/array';
import { useAlert, ALERT_MESSAGE } from 'utils/hooks/useAlert';

const UPDATE_PROFILE = 'UPDATE_PROFILE';
const GET_MY_INVITATIONS = 'GET_MY_INVITATIONS';
const GET_MY_GROUP_INVITATIONS = 'GET_MY_GROUP_INVITATIONS';
const ACCEPT_INVITATION = 'ACCEPT_INVITATION';
const ACCEPT_GROUP_INVITATION = 'ACCEPT_GROUP_INVITATION';
const SET_MY_PERMISSIONS = 'SET_MY_PERMISSIONS';
const GET_MY_ALL_ORGANIZATIONS = 'GET_MY_ALL_ORGANIZATIONS';
const SET_MY_ORGANIZATION = 'SET_MY_ORGANIZATION';
const CHANGE_USER_ROLE = 'CHANGE_USER_ROLE';
const GET_GROUP_TEACHER_INVITATIONS = 'GET_GROUP_TEACHER_INVITATIONS';
const GET_GROUP_MEMBER_INVITATIONS = 'GET_GROUP_MEMBER_INVITATIONS';
const CANCEL_GROUP_INVITATION = 'CANCEL_GROUP_INVITATION';
const CLEAR_GROUP_INVITATION = 'CLEAR_GROUP_INVITATION';
const ACCOUNT_IS_EXSIT = 'ACCOUNT_IS_EXSIT';
const FORGETPASSWORD = 'FORGETPASSWORD';
const FORGETPASSWORD_CHECK_MOBILEVALID = 'FORGETPASSWORD_CHECK_MOBILEVALID';
const REVISE_PASSWORD = 'REVISE_PASSWORD';
const REGISTER_SEND_VALIDCODE = 'REGISTER_SEND_VALIDCODE';
const REGISTER_CHECK_MOBILEVALID = 'REGISTER_CHECK_MOBILEVALID';
const REGISTER = 'REGISTER';
const CHANGEPASSWORD = 'CHANGEPASSWORD';
const CHECK_TOKEN_AND_USER_PASSWORD = 'CHECK_TOKEN_AND_USER_PASSWORD';
const SET_PREMIUM_USERS = 'SET_PREMIUM_USERS';
const SET_MY_ORGANIZATION_GROUP_CONTROLS = 'SET_MY_ORGANIZATION_GROUP_CONTROLS';
const UPDATE_MY_ORGANIZATION_GROUP_CONTROLS = 'UPDATE_MY_ORGANIZATION_GROUP_CONTROLS';
const RELOAD_DASHBOARD = 'RELOAD_DASHBOARD';


const initState = {
  profile: {
    id: '',
    role: '',
    organizations: []
  },
  myAllOrganizations: {
    isLoaded: false,
    data: [],
    dataMap: {},
    ownOrganizationsDataMap: {},
  },
  myOrganization: {
    isLoaded: false
  },
  myOrganizationSetting: [],
  myInvitations: {
    total: 0,
    organizationInvitations: []
  },
  myGroupInvitations: {
    total: 0,
    groupInvitations: []
  },
  groupTeacherInvitations: {
    total: 0,
    invitationsList: []
  },
  groupMemberInvitations: {
    total: 0,
    invitationsList: []
  },
  isNeedReload:false
};

const actions = {
  updateProfile: profile => ({
    type: UPDATE_PROFILE,
    payload: { profile },
  }),
  setMyOrganization: myOrganization => ({
    type: SET_MY_ORGANIZATION,
    payload: {
      myOrganization,
    }
  }),
  getMyAllOrganizations: ({ data, dataMap, ownOrganizationsDataMap }) => ({
    type: GET_MY_ALL_ORGANIZATIONS,
    payload: {
      isLoaded: data.length > 0,
      data,
      dataMap,
      ownOrganizationsDataMap,
    },
  }),
  getMyInvitations: myInvitations => ({
    type: GET_MY_INVITATIONS,
    payload: { myInvitations },
  }),
  getMyGroupInvitations: myGroupInvitations => ({
    type: GET_MY_GROUP_INVITATIONS,
    payload: { myGroupInvitations },
  }),
  acceptInvitation: () => ({
    type: ACCEPT_INVITATION
  }),
  acceptGroupInvitation: () => ({
    type: ACCEPT_GROUP_INVITATION
  }),
  changeUserRole: () => ({
    type: CHANGE_USER_ROLE
  }),
  getGroupTeacherInvitations: groupTeacherInvitations => ({
    type: GET_GROUP_TEACHER_INVITATIONS,
    payload: { groupTeacherInvitations },
  }),
  getGroupMemberInvitations: groupMemberInvitations => ({
    type: GET_GROUP_MEMBER_INVITATIONS,
    payload: { groupMemberInvitations },
  }),
  cancelGroupInvitation: () => ({
    type: CANCEL_GROUP_INVITATION
  }),
  clearGroupInvitation: () => ({
    type: CLEAR_GROUP_INVITATION
  }),
  accountIsExsit: params => ({
    type: ACCOUNT_IS_EXSIT,
    payload: params
  }),
  forgetPassword: params => ({
    type: FORGETPASSWORD,
    payload: params
  }),
  forgetPasswordCheckMobileValid: params => ({
    type: FORGETPASSWORD_CHECK_MOBILEVALID,
    payload: params
  }),
  revisePassword: params => ({
    type: REVISE_PASSWORD,
    payload: params
  }),
  registerSendValodCode: params => ({
    type: REGISTER_SEND_VALIDCODE,
    payload: params
  }),
  registerCheckMobileValid: params => ({
    type: REGISTER_CHECK_MOBILEVALID,
    payload: params
  }),
  register: params => ({
    type: REGISTER,
    payload: params
  }),
  changePassword: params => ({
    type: CHANGEPASSWORD,
    payload: params
  }),
  checkTokenAndUserPassword: params => ({
    type: CHECK_TOKEN_AND_USER_PASSWORD,
    payload: params
  }),
  setPremiumUsers: params => ({
    type: SET_PREMIUM_USERS,
    payload: params
  }),
  setOrganizationGroupControls: params => ({
    type: SET_MY_ORGANIZATION_GROUP_CONTROLS,
    payload: params
  }),
  updateOrganizationGroupControls: params => ({
    type: UPDATE_MY_ORGANIZATION_GROUP_CONTROLS,
    payload: params
  }),
  reloadDashBoard: params => ({
    type: RELOAD_DASHBOARD,
    payload: params
  }),
};

export const useUser = () => {
  const { setAlert } = useAlert();
  const dispatch = useDispatch();
  const {
    profile,
    myOrganization,
    myAllOrganizations,
    myInvitations,
    myGroupInvitations,
    groupTeacherInvitations,
    groupMemberInvitations,
    myOrganizationSetting,
    isNeedReload
  } = useSelector(state => state.user);

  const history = useHistory();
  const { organizationId, classId, customerId, teacherId } = useParams();

  const fetchOrganizationById = async (id) => {
    const { data:organization } = await getOrgInfoApi(id);
    const { data:profileInfo } = await getOrgProfileApi(id);
    const { permissionGroups,groupAccessControlSetting } = organization;
    const permissionGroupsMap = convertArrayToMapById(permissionGroups);
    const result = {
      ...organization,
      permissionGroupsMap: permissionGroupsMap,
      groupAccessControlSetting
    };
    dispatch(actions.setMyOrganization({
      organization:result,
      userProfile:profileInfo,
      permissionGroupsMap,
      isLoaded:true
    }));
  };

  // useEffect(() => {
  //   console.log('myOrganization.isLoaded',myOrganization.isLoaded);
  //   if (myOrganization.isLoaded || !organizationId || !classId) return;
  //   fetchOrganizationById(organizationId);
  // }, [organizationId,myOrganization.isLoaded,classId]);

  /**
   * 登入
   * @param {string} username
   * @param {string} password
   */

  const userLogin = async ({ username, password }) => {
    const { isSuccess, data: profile, error } = await login({ username, password });
    try {
      if (!isSuccess) throw error;
      if(profile.role === 'admin'){
        // eslint-disable-next-line max-len
        window.location.href = `https://onelink-admin${process.env.REACT_APP_ENV === 'release' ? '' : '-' + process.env.REACT_APP_ENV}.oneclass.com.tw/admin`;
        return;
      }
      const isTeacher = profile.verificationInfoList.some(verificationInfo => verificationInfo.isTeacher);
      dispatch(actions.updateProfile({ ...profile, isTeacher }));

      const isAdmin = profile.role === ROLES.ADMIN;
      if (!isAdmin) {
        getMyAllOrganizations();
      }

      history.push(isAdmin ? '/admin' : '/home');

      setOnePaperLoginCookieByServiceRoot();

    } catch (error) {
      const { errorCode } = error;
      setAlert('登入失敗', 'error', errorCode);
    }
  };

  /**
   * 取得 global user profile
   */
  const getUserProfile = async () => {
    const { isSuccess, data: profile } = await getProfile();
    if (!isSuccess) return;
    const isTeacher = profile.verificationInfoList.some(verificationInfo => verificationInfo.isTeacher);
    dispatch(actions.updateProfile({ ...profile, isTeacher }));

    if (profile.organizations.length > 0) {
      getMyAllOrganizations();
    }

  };

  /**
   * update user profile
   */
  const updateUserProfile = async (params) => {
    try {
      const { isSuccess, data: profile, error } = await updateMyProfileApi(params);
      if (!isSuccess) throw error;
      setAlert(ALERT_MESSAGE.UPDATE_SUCCESS, 'success');
      dispatch(actions.updateProfile({ ...profile }));
      return isSuccess;
    } catch (error) {
      const { errorCode } = error;
      setAlert(ALERT_MESSAGE.UPDATE_FAIL, 'error', errorCode);
      return null;
    }
  };
  /**
 * change user password
 */
  const changeUserPassword = async (params) => {
    try {
      const { code, isSuccess } = await changePasswordApi(params);
      if (!isSuccess) throw code;
      setAlert(ALERT_MESSAGE.UPDATE_SUCCESS, 'success');
      dispatch(actions.changePassword(code));
      return isSuccess;
    } catch (error) {
      return false;
    }
  };

  /**
 * check user password
 */
  const checkTokenAndUserPassword = async (params) => {
    try {
      const { code, isSuccess } = await checkTokenAndUserPasswordApi(params);
      if (!isSuccess) throw code;
      dispatch(actions.checkTokenAndUserPassword(code));
      return isSuccess;
    } catch (error) {
      return false;
    }
  };

  /**
  * 登出
  */
  const userLogout = async () => {
    await logout();
    localStorage.removeItem('organizationId');
    history.go(0);
  };

  const userRefreshProfile = async () => {
    await refreshProfileApi();
    history.go(0);
  };

  /**
  * 取得我的組織列表
  */
  const getMyAllOrganizations = async (force = false) => {
    if (!force && myAllOrganizations.isLoaded) return myAllOrganizations;

    const { data: { organizations } } = await getOrganizationsApi();
    let dataMap = {};
    let ownOrganizationsDataMap = [];
    organizations.forEach(item => {
      const { organization, userProfile } = item;
      const { permissionGroups,groupAccessControlSetting } = organization;
      const permissionGroupsMap = convertArrayToMapById(permissionGroups);
      const userPermissions = userProfile.permissionGroups ?
        userProfile.permissionGroups.map(permissionId => permissionGroupsMap[permissionId].permissions) : [];
      dataMap[organization.id] = {
        ...item,
        myPermissions: userPermissions.flat(),
        permissionGroupsMap: permissionGroupsMap,
        groupAccessControlSetting
      };
      if (permissionGroups && permissionGroups.length > 0) {
        ownOrganizationsDataMap[organization.id] = {
          ...organization
        };
      }
    });
    dispatch(actions.getMyAllOrganizations({ data: organizations, dataMap, ownOrganizationsDataMap }));

    return dataMap;
  };

  /**
   * 取得組織邀請列表
   */
  const getMyInvitations = async () => {
    const response = await getOrganizationInvitationsApi();
    dispatch(actions.getMyInvitations(response));
    return response;
  };

  /**
   * 取得非組織邀請列表
   */
  const getMyGroupInvitations = async () => {
    const response = await getGroupInvitationsApi();
    dispatch(actions.getMyGroupInvitations(response));
    return response;
  };

  /**
   * 接受組織邀請
   */
  const acceptInvitation = async orgId => {
    const isSuccess = await acceptOrganizationInvitationApi(orgId);
    if (isSuccess) {
      dispatch(actions.acceptInvitation());
      setAlert(ALERT_MESSAGE.INVITE_SUCCESS, 'success');
    } else {
      setAlert(ALERT_MESSAGE.INVITE_FAIL, 'error');
    }

    return isSuccess;
  };

  /**
 * 接受非組織邀請
 */
  const acceptGroupInvitation = async classId => {
    const isSuccess = await acceptGroupInvitationApi(classId);
    if (isSuccess) {
      dispatch(actions.acceptGroupInvitation());
      setAlert(ALERT_MESSAGE.INVITE_SUCCESS, 'success');
    } else {
      setAlert(ALERT_MESSAGE.INVITE_FAIL, 'error');
    }

    return isSuccess;
  };


  /**
   * 更改使用者權限
   */
  const changeUserRole = async () => {
    const userId = customerId ? customerId : teacherId;
    const payload = {
      role: customerId ? 'staff' : 'customer'
    };
    const { isSuccess, error } = await changeUserRoleApi(organizationId, userId)(payload);
    try {
      if (!isSuccess) throw error;
      dispatch(actions.changeUserRole());
      setAlert(ALERT_MESSAGE.UPDATE_SUCCESS, 'success');
    } catch (error) {
      const { errorCode, userJoinedGroupIdList, userOwnedGroupIdList } = error;
      if (userJoinedGroupIdList) {
        setAlert(ALERT_MESSAGE.CUSTOMER_ALREADY_CLASS, 'error', errorCode);
      } else if (userOwnedGroupIdList) {
        setAlert(
          userOwnedGroupIdList.length > 1 ?
            ALERT_MESSAGE.TEACHER_ALREADY_CLASSES :
            ALERT_MESSAGE.TEACHER_ALREADY_CLASS,
          'error',
          errorCode
        );
      } else {
        setAlert(ALERT_MESSAGE.UPDATE_FAIL, 'error', errorCode);
      }
    }
    return isSuccess;
  };


  const getGroupTeacherInvitations = async params => {
    const { isSuccess, data } = await getFreeGroupUserInvitationsApi(classId)({
      role: FREE_GROUP_ROLES.HOST_CANDIDATE,
      ...params
    });
    if (!isSuccess) return;
    const { invitations, total } = data;
    dispatch(actions.getGroupTeacherInvitations({ invitations, total }));
    return data;
  };

  const getGroupMemberInvitations = async params => {
    const { isSuccess, data } = await getFreeGroupUserInvitationsApi(classId)({
      role: FREE_GROUP_ROLES.USER,
      ...params
    });
    if (!isSuccess) return;
    const { invitations, total } = data;
    dispatch(actions.getGroupMemberInvitations({ invitations, total }));
    return data;
  };

  const cancelGroupInvitation = async id => {
    dispatch(actions.cancelGroupInvitation({ classId, userId: id }));
    try {
      const { isSuccess, data, error } = await cancelFreeGroupUserInvitationApi(classId)(id);
      if (!isSuccess) throw error;
      setAlert(ALERT_MESSAGE.CANCEL_INVITE_SUCCESS, 'success');
      return data;
    } catch (error) {
      const { errorCode } = error;
      setAlert(ALERT_MESSAGE.CANCEL_INVITE_FAIL, 'error', errorCode);
      return null;
    }
  };

  const clearGroupInvitation = () => dispatch(actions.clearGroupInvitation());

  /**
   * 判斷帳號是否存在
   */
  const accountIsExsit = async params => {
    const response = await accountIsExsitApi(params);
    dispatch(actions.accountIsExsit(params));
    return response;
  };

  /**
   * 忘記密碼傳送簡訊
   */
  const forgetPassword = async params => {
    const response = await forgetPasswordApi(params);
    dispatch(actions.forgetPassword(params));
    return response;
  };

  /**
   * 檢查忘記密碼簡訊
   */
  const forgetPasswordCheckMobileValid = async params => {
    const response = await forgetPasswordCheckMobileValidApi(params);
    dispatch(actions.forgetPasswordCheckMobileValid(params));
    return response;
  };

  /**
   * 修改密碼
   */
  const revisePassword = async params => {
    const response = await revisePasswordApi(params);
    try {
      dispatch(actions.revisePassword(params));
      if (!response.isSuccess) throw response;
      setAlert(ALERT_MESSAGE.UPDATE_SUCCESS, 'success');
    } catch {
      setAlert(ALERT_MESSAGE.UPDATE_FAIL, 'error');
    }
    return response;
  };

  /**
   * 註冊傳送簡訊
   */
  const registerSendValodCode = async params => {
    const response = await registerSendValodCodeApi(params);
    dispatch(actions.registerSendValodCode(params));
    return response;
  };

  /**
  * 註冊檢查簡訊
  */
  const registerCheckMobileValid = async params => {
    const response = await registerCheckMobileValidApi(params);
    dispatch(actions.registerCheckMobileValid(params));
    return response;
  };

  /**
  * 註冊
  */
  const register = async params => {
    const response = await registerApi(params);
    try {
      dispatch(actions.register(params));
      if (!response.isSuccess) throw response;
      setAlert(ALERT_MESSAGE.CREATE_SUCCESS, 'success');
    } catch {
      setAlert(ALERT_MESSAGE.CREATE_FAIL, 'error');
    }
    return response;
  };

  /**
   * 申請專業版會員
   */
  const setPremiumUsers = async (params) => {
    const response = await setPremiumUsersApi(params);
    try {
      const { data, error, isSuccess } = response;
      if (!isSuccess) throw error;
      setAlert(ALERT_MESSAGE.CREATE_SUCCESS, 'success');
      dispatch(actions.setPremiumUsers(data));
    } catch (error) {
      const { errorCode } = error;
      setAlert(ALERT_MESSAGE.CREATE_FAIL, 'error', errorCode);
    }
  };


    /**
   * 更改組織使用者權限
   */
  const editOrganizationGroupControls = async (params) => {
    const response = await editOrganizationGroupControlsApi(organizationId)(params);
    try {
      const { data, error, isSuccess } = response;
      if (!isSuccess) throw error;
      setAlert(ALERT_MESSAGE.UPDATE_SUCCESS, 'success');
      dispatch(actions.updateOrganizationGroupControls(data.groupAccessControlSetting));
    } catch (error) {
      const { errorCode } = error;
      setAlert(ALERT_MESSAGE.UPDATE_FAIL, 'error', errorCode);
    }
  };

  const getOrganizationGroupAccess = async () => {
    const response = await getOrganizationGroupAccessApi(organizationId);
    try {
      const { data, error, isSuccess } = response;
      if (!isSuccess) throw error;
      dispatch(actions.setOrganizationGroupControls(data));
    } catch (error) {
      const { errorCode } = error;
      console.log(errorCode);
    }
  };

  const isNeedReloadDashBoard = async (isNeed) => {
    dispatch(actions.reloadDashBoard(isNeed));
  };

  return [
    {
      profile,
      myAllOrganizations,
      myInvitations,
      myOrganization,
      myGroupInvitations,
      groupTeacherInvitations,
      groupMemberInvitations,
      myOrganizationSetting,
      isNeedReload
    }, // state
    {
      userLogin,
      checkLogin,
      getUserProfile,
      userLogout,
      userRefreshProfile,
      getMyAllOrganizations,
      getMyInvitations,
      getMyGroupInvitations,
      acceptInvitation,
      changeUserRole,
      acceptGroupInvitation,
      getGroupTeacherInvitations,
      getGroupMemberInvitations,
      cancelGroupInvitation,
      clearGroupInvitation,
      accountIsExsit,
      forgetPassword,
      forgetPasswordCheckMobileValid,
      revisePassword,
      registerSendValodCode,
      registerCheckMobileValid,
      register,
      updateUserProfile,
      changeUserPassword,
      checkTokenAndUserPassword,
      setPremiumUsers,
      editOrganizationGroupControls,
      getOrganizationGroupAccess,
      isNeedReloadDashBoard,
      fetchOrganizationById
    }, // eventHanlder
  ];
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case UPDATE_PROFILE: {
      const { profile } = action.payload;
      return {
        ...state,
        profile,
      };
    }
    case SET_MY_ORGANIZATION: {
      const { myOrganization, isLoaded } = action.payload;
      return {
        ...state,
        myOrganization: {
          isLoaded,
          ...myOrganization
        }
      };
    }
    case GET_MY_ALL_ORGANIZATIONS: {
      const { data, dataMap, isLoaded, ownOrganizationsDataMap } = action.payload;
      return {
        ...state,
        myAllOrganizations: {
          isLoaded,
          data,
          dataMap,
          ownOrganizationsDataMap,
        }
      };
    }
    case GET_MY_INVITATIONS: {
      const { myInvitations } = action.payload;
      return {
        ...state,
        myInvitations
      };
    }
    case GET_MY_GROUP_INVITATIONS: {
      const { myGroupInvitations } = action.payload;
      return {
        ...state,
        myGroupInvitations
      };
    }
    case SET_MY_PERMISSIONS: {
      const { myPermissions } = action.payload;
      return {
        ...state,
        myPermissions
      };
    }
    case GET_GROUP_TEACHER_INVITATIONS: {
      const { groupTeacherInvitations } = action.payload;
      return {
        ...state,
        groupTeacherInvitations
      };
    }
    case GET_GROUP_MEMBER_INVITATIONS: {
      const { groupMemberInvitations } = action.payload;
      return {
        ...state,
        groupMemberInvitations
      };
    }
    case CLEAR_GROUP_INVITATION: {
      return {
        ...state,
        groupTeacherInvitations: {
          ...initState.groupTeacherInvitations
        },
        groupMemberInvitations: {
          ...initState.groupMemberInvitations
        }
      };
    }
    case SET_PREMIUM_USERS: {
      return {
        ...state,
        profile: action.payload,
      };
    }
    case SET_MY_ORGANIZATION_GROUP_CONTROLS:{
      return {
        ...state,
        myOrganizationSetting:action.payload
      };
    }
    case UPDATE_MY_ORGANIZATION_GROUP_CONTROLS:{
      return {
        ...state,
        myOrganizationSetting:{
          ...state.myOrganizationSetting,
          navigationFunctions:action.payload,
        }
      };
    }
    case RELOAD_DASHBOARD:{
      return {
        ...state,
        isNeedReload:action.payload
      };
    }
    default:
      return state;
  }
};

export default reducer;


