/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useEffect, useMemo } from 'react';

import { stringify } from 'query-string';
import { Table, IconInput, Select, TimePicker, Button, EmptyPrompt, Loading, OutlineButton, ResourceModal, HomeTitle} from 'components';
import { useSessions } from 'store/sessions';
import { useUser } from 'store/user';
import { useClass } from 'store/class';
import { keepSessionReport } from 'services/api/organization/session';
import { useAlert } from 'utils/hooks/useAlert';
import { useServerTime } from 'utils/hooks/useServerTime';
import { useSetState } from 'utils/hooks/useSetState';
import { useParams, useHistory } from 'react-router-dom';
import { EDIT_STATUS, ORGANIZATION_SETTINGS_VALUE, EXAM_PROJECT_NAME, PREVIEW_TIMESTAMP, } from 'constants/index';
import { UiTable, UiActionBox, UiflexBox } from 'styles';
import { UiSessionsTableButtonBox } from './HomeSessionsTable.style';
import forest from 'assets/images/forest.svg';

/**
 * 前台課程表
 */

const schema = {
  name: {
    name: '課程名稱',
    defaultValue: ''
  },
  subject: {
    name: '科目',
    defaultValue: '--'
  },
  startDate: {
    name: '日期',
    defaultValue: ''
  },
  startTime: {
    name: '時間',
    defaultValue: ''
  },
  hostName: {
    name: '教師',
    defaultValue: ''
  }
};

const NOW = 'NOW';
const PROCESSING = 'PROCESSING';
const PAST = 'PAST';
const FUTURE = 'FUTURE';
const ALL = 'ALL';

const selectOptions = [
  {
    name: '今日課程',
    value: NOW
  },
  {
    name: '當前課程',
    value: PROCESSING
  },
  {
    name: '過去課程',
    value: PAST
  },
  {
    name: '未來課程',
    value: FUTURE
  },
  {
    name: '所有課程',
    value: ALL
  }
];


export const HomeSessionsTable = () => {
  const history = useHistory();
  const { setAlert } = useAlert();
  const { organizationId, classId } = useParams();
  const [
    { allInterval, futureInterval, pastInterval, processingInterval, timestamp: serverTimestamp, todayInterval },
    { fetchServerTime }
  ] = useServerTime();
  const [{ myOrganization, profile }] = useUser();
  const [{ myClasses }] = useClass();
  const { dataInfo: { isOwner, userType, groupHostCreateSessionSetting, groupRole } } = myClasses;
  const [{ sessions }, { getSessions, attendanceSession }] = useSessions();
  const [
    {
      dateInterval,
      name,
      nowPage,
      rowsPage,
      startAt,
      endAt,
      sessionAttendanceManagementSetting,
      sessionId,
      isClick,
      isClickSessionResource, // 是否為點選課程教材
      isListLoading,
      isOpenResourceModal,
    }, setState] = useSetState({
      dateInterval: 'now',
      name: '',
      nowPage: 0,
      rowsPage: 10,
      startAt: null,
      endAt: null,
      officialName: '',
      sessionAttendanceManagementSetting: ORGANIZATION_SETTINGS_VALUE.NONE,
      sessionId: '',
      isClick: true,
      isClickSessionResource: null,
      isListLoading: true,
      isOpenResourceModal: false,
      emptyImage: null,
      emptyMessage: ''
    });
  useEffect(() => {
    if (myOrganization.isLoaded) {
      const { officialName, sessionAttendanceManagementSetting } = myOrganization.organization;
      setState({
        officialName,
        sessionAttendanceManagementSetting
      });
    }
  }, [myOrganization]);
  const addTeacherToClass = () => {
    history.push(`/home${organizationId ? `/${organizationId}` : ''}/class/${classId}/sessions/${EDIT_STATUS.CREATE}`);
  };
  const changePage_Rows = params => {
    const { newPage, newRowsPage } = params;
    setState({
      nowPage: newPage,
      rowsPage: newRowsPage
    });
  };
  const onchangeHandler = (value, key) => {
    setState({
      [key]: value,
      nowPage: 0
    });
  };

  const onSelectChangeHandler = selected => {
    let dateParams = {};
    setState({
      nowPage: 0
    });
    switch (selected) {
      case NOW:
        dateParams = { ...todayInterval };
        break;
      case PAST:
        dateParams = { ...pastInterval };
        break;
      case PROCESSING:
        fetchServerTime();
        dateParams = { ...processingInterval };
        break;
      case FUTURE:
        dateParams = { ...futureInterval };
        break;
      case ALL:
        dateParams = { ...allInterval };
        break;
      default:
        break;
    }
    setState({
      dateInterval: selected,
      ...dateParams
    });
  };

  useEffect(() => {
    if(!serverTimestamp) return;
    (async () => {
      setState({ isListLoading: true });
      await getSessions({ nowPage, rowsPage, name, startAt, endAt }, serverTimestamp);
      setState({ isListLoading: false });
    })();
  }, [nowPage, rowsPage, name, startAt, endAt, serverTimestamp]);

  const btnText = (type) => {
    switch (type) {
      case 'before10':
        return '進入教室';
      case 'noStart':
      case 'before20':
        return userType === 'teacher' ? '進入教室' : '預習課程';
      case 'after90':
        return '回到課堂';
      case 'end':
        return userType === 'teacher' ? '查看課程' : '複習課程';
      default:
        break;
    }

  };

  const ActionComponents = ({ params }) => {
    const {
      isCanEnterClass: { type, canEnter },
      resources,
      id,
      groupId,
      groupName,
      timeSpanId,
      isPremium,
      hostName,
      hostAttendance,
      preExamId,
      postExamId,
      type: sessionsType,
      users,
      hostId,
    } = params;
    const usersLength = users?.length || 0;
    const ButtonClick = () => {
      setState({
        isClickSessionResource: false,
        isOpenResourceModal: true,
        sessionId: id,
      });
    };
    const openExam = (testId) => {
      const openUrl = window.open('', '_blank');
      const url = `${process.env.REACT_APP_TEST_DOMAIN}?project=${EXAM_PROJECT_NAME}&range=${testId}&user=${profile.id}&roomId=${id}&teacherName=${hostName}&username=${profile.nickname}`;
      openUrl.location = url;
    };
    const attendanceHandle = async () => {
      if (!isClick) return;
      setState({
        isClick: false
      });
      let checkType = 'checkIn';
      if (hostAttendance?.checkInAt) checkType = 'checkOut';
      await attendanceSession(id, timeSpanId, {
        [checkType]: true
      });
      setTimeout(() => {
        setState({
          isClick: true
        });
      }, 1000);
    };
    const checkType = useMemo(() => {
      if (sessionAttendanceManagementSetting !== ORGANIZATION_SETTINGS_VALUE.HOST || params.hostId !== profile.id) {
        return {
          type: 'notShow'
        };
      }

      if (hostAttendance?.checkOutAt) {
        return {
          type: 'finish',
          name: '簽退完成'
        };
      } else if (hostAttendance?.checkInAt) {
        return {
          type: 'needCheckOut',
          name: '教師簽退'
        };
      } else {
        if (type === 'end') {
          return {
            type: 'finish',
            name: '課程結束'
          };
        } else {
          return {
            type: 'needCheckIn',
            name: '教師簽到'
          };
        }

      }

    }, [hostAttendance]);

    // 更新課堂報表
    const fetchKeepSessionReport = async () => {
      const payload = {
        sessionId: id,
        timeSpanId,
      };
      const { isSuccess } = await keepSessionReport(payload);
      if (!isSuccess) {
        setAlert('取得課堂報表失敗!', 'error');
      }
    };

    const goExamResultListPage = () => {
      history.push(`sessions/${id}/exam-result`);
    };

    const enterInteractiveSession = () => {
      const isCallReportApi = hostId === profile.id || users.some(id => id === profile.id);
      if (isCallReportApi) {
        fetchKeepSessionReport();
      }

      let classType = '';
      switch (sessionsType) {
        case 'interactive':
          classType = usersLength <= 1 ? 'single' : 'group';
          break;
        case 'collaborative':
          classType = usersLength <= 1 ? 'sync-single' : 'sync-multiple';
          break;
        case 'video':
          classType = 'group';
          break;
        default:
          break;
      }
      const querystring = stringify({
        bookIds: resources,
        classType: classType,
        role: userType,
        userName: profile.nickname,
        userId: profile.id,
        productType: 'onelink',
        organizationId: organizationId,
      },{ arrayFormat: 'index' });
      window.open(`${process.env.REACT_APP_ONEBOARD_DOMAIN}/${id}/setup?${querystring}`, '_blank');
    };

    const reviseVideoOnDemand = () => {
      window.open(`${process.env.REACT_APP_ONEBOARD_BS_DOMAIN}/player/${id}`);
    };

    const gotoSessionReport = () => {
      history.push({
        pathname: `/home/class/${groupId}/sessions/${id}/timespan/${timeSpanId}/report`,
        state: {
          className: groupName,
          sessionType: sessionsType,
        }
      });
    };

    const enterGroup = (t) => {
      switch (t) {
        case 'noStart':
          return (
            <Button disabled>課程尚未開始</Button>
          );
        case 'before20':
          return (
            userType === 'student' ?
              <Button
                onClick={ButtonClick}
                buttonColor="normal"
              >
                預習課程
              </Button> :
              <Button disabled>課程尚未開始</Button>
          );
        case 'before10':
          return (
            <Button
              onClick={() => enterInteractiveSession('group')}
              buttonColor="normal"
            >
              進入教室
            </Button>
          );
        case 'after90':
          return (
            <Button
              onClick={() => enterInteractiveSession('group')}
              buttonColor="normal"
            >
              回到課堂
            </Button>
          );
        case 'end':
          return (
            <Button disabled>課程結束</Button>
          );
        default:
          return null;
      }
    };

    /* 判斷現在是否為預課時間 */
    const isPreviewTime = () => {
      const { startAt } = params;       // 上課開始時間
      const nowTimestamp = serverTimestamp;  // 現在時間
      const previewStartTime = startAt - PREVIEW_TIMESTAMP;  // 預課開始時間

      if (nowTimestamp >= previewStartTime) return true;
    };

    /* click 課程教材 按鈕 */
    const clickSessionResourceHandler = () => {
      setState({
        sessionId: id,
        isClickSessionResource: true,
        isOpenResourceModal: true,
      });
    };

    return (
      <UiSessionsTableButtonBox>

        <Button onClick={clickSessionResourceHandler}>
          {
            userType === 'teacher'
              // 導師、教師 身份
              ? '課程教材'
              // 學生 身份
              : (type === 'after90' || type === 'end')
                ? '複習教材'
                : '預習教材'
          }
        </Button>
        {
          (sessionsType === 'interactive' || sessionsType === 'video' || sessionsType === 'collaborative') ? (
            <>
              {enterGroup(type)}
              {
                // 此為 OneBroad 開課
                type === 'end' &&
                <Button
                  buttonColor="new"
                  onClick={() => reviseVideoOnDemand()}
                >觀看錄影
                </Button>
              }
              {isPreviewTime() &&
                <Button
                  onClick={() => gotoSessionReport()}
                >
                  課堂報告
                </Button>
              }
            </>
          ) : (
            <>
              <Button
                onClick={ButtonClick}
                endIcon={isPremium ? 'videocam' : ''}
                buttonColor={canEnter ? 'normal' : 'new'}
              >
                {btnText(type)}
              </Button>
              {
                (checkType.type !== 'notShow' && type !== 'noStart') &&
                <OutlineButton
                  buttonColor={checkType.type === 'finish' ? 'disable' : 'normal'}
                  onClick={() => checkType.type !== 'finish' && attendanceHandle()}
                  disabled={checkType.type === 'finish'}
                  loading={!isClick}
                >
                  {checkType.name}
                </OutlineButton>
              }
              {
                (preExamId && userType === 'student') &&
                <Button
                  buttonColor="visibility"
                  onClick={() => openExam(preExamId)}
                >課前測驗
                </Button>
              }
              {
                (postExamId && userType === 'student') &&
                <Button
                  buttonColor="visibility"
                  onClick={() => openExam(postExamId)}
                >課後測驗
                </Button>
              }
              {
                ((preExamId || postExamId) && userType === 'teacher') &&
                <Button
                  buttonColor="visibility"
                  onClick={() => goExamResultListPage()}
                >測驗結果
                </Button>
              }
            </>
          )
        }
      </UiSessionsTableButtonBox>
    );
  };

  const operatorAuth = useMemo(() => {
    const { groupOwnerCreateSessionSetting } = myOrganization?.organization || {};
    return isOwner && groupOwnerCreateSessionSetting === ORGANIZATION_SETTINGS_VALUE.ALLOW;
  }, [isOwner, myOrganization]);

  const isStaffCanCreateSession = useMemo(() => {
    return groupRole === 'staff' && groupHostCreateSessionSetting === ORGANIZATION_SETTINGS_VALUE.ALLOW;
  }, [groupRole, groupHostCreateSessionSetting]);

  /* click 課程教材彈窗 - 確認按鈕 */
  const clickResourceModalOkHandler = resourceValue => {
    let classRoomUrl = null;

    isClickSessionResource
      ? classRoomUrl = `${process.env.REACT_APP_ONEBOOK_TEACHER_DOMAIN}/${resourceValue}`
      : classRoomUrl = `${process.env.REACT_APP_ONEBOOK_DOMAIN}/${resourceValue}`;
    
    window.open(classRoomUrl, '_blank');  // 跳轉至 oneBook 教材
    setState({ isOpenResourceModal: false });
  };

  /* click 課程教材彈窗 - 取消按鈕 */
  const clickResourceModalCancelHandler = () => {
    setState({ isOpenResourceModal: false });
  };

  return (
    <UiTable>
      { /* 課程教材、一般課程進入教室 彈窗 */
        isOpenResourceModal &&
          <ResourceModal
            sessionId={sessionId}
            isOpenResourceModal={isOpenResourceModal}
            onOk={clickResourceModalOkHandler}
            onCancel={clickResourceModalCancelHandler}
          />
      }

      <UiActionBox>
        <HomeTitle title={'課程'} />
        {(operatorAuth || isStaffCanCreateSession) && <Button icon='add' onClick={() => addTeacherToClass()}>新增課程</Button>}
      </UiActionBox>
      <UiActionBox>
        <UiflexBox>
          <IconInput
            placeholder='搜尋課程名稱'
            onChange={value => onchangeHandler(value, 'name')}
          />
          {
            dateInterval === NOW &&
            <>
              <TimePicker
                label="選擇課程開始時間"
                value={startAt}
                onChange={value => onchangeHandler(value, 'startAt')}
              />
              <TimePicker
                label="選擇課程結束時間"
                value={endAt}
                onChange={value => onchangeHandler(value, 'endAt')}
              />
            </>
          }

        </UiflexBox>
        <UiflexBox>
          <Select
            options={selectOptions}
            submitHandler={value => onSelectChangeHandler(value)}
            label="課程區間"
            id="courseOption"
            value={ALL}
          />
        </UiflexBox>
      </UiActionBox>
      {
        isListLoading
          ? <Loading />
          : sessions.data.length > 0
            ? <Table
                data={sessions.data}
                schema={schema}
                changePage_Rows={changePage_Rows}
                totalPage={sessions.total}
                ActionComponents={ActionComponents}
                nowPage={nowPage}
              />
            : <EmptyPrompt img={forest} message={'尚無課程'} messageColor={'#8B90A0'} />
      }
    </UiTable>
  );
};
