import styled from 'styled-components';

export const UiAccountForm = styled.div`
	display: block;
	padding: 20px 20%;
	font-family: ${({ theme }) => theme.typography.fontFamily};
`;

export const UiAccountFormTitle = styled.div`
	display: block;
	font-size: 1.4rem;
`;
export const UiAccountFormRow = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 2rem 0;
	font-size: 1.1rem;
`;
export const UiAccountFormItem = styled.div`
	display: block;
`;
export const UiAccountFormItemButton = styled.div`
	display: block;
`;
export const UiAccountFormItemTable = styled.div`
	display: block;
	width: 100%;
`;

export const UiAccountFormItemTitle = styled.div`
	display: block;
	margin: 0.5rem 0;
`;

export const UiAccountFormListRow = styled.div`
	display: flex;
	justify-content: space-between;
	font-size: 0.9rem;

	& > div {
		flex: 0.49;
	}
`;

export const UiAccountFormList = styled.div`
	width: 100%;

`;

export const UiAccountFormListHeader = styled.div`
padding: 0.5rem;
color: #fff;

color: ${({ theme, isHome }) => isHome ? theme.table.home.thColor : theme.table.backstage.thColor};
background-color:
	${(
		{ theme,
		isHome }
	) => isHome ?
	theme.palette.user.block.title :
	theme.palette.backstage.block.title};
`;

export const UiAccountFormListItem = styled.div`
padding: 0.5rem;
color: ${({ theme }) => theme.table.backstage.tdColor};

&:nth-child(odd) {
	background-color:
		${(
			{ theme,
			isHome }
		) => {
		return isHome ? theme.table.backstage.tdBgColorWhite : theme.table.backstage.tdBgColorGray;
		}};
}

&:nth-child(even) {
	background-color: ${({ theme }) => theme.table.backstage.tdBgColorWhite};
}
`;
