import { Api } from 'services/api';
import { stringify } from 'query-string';

/**
 * @取得機構中的教材資源
 * @param {number} nowPage
 * @param {number} rowsPage
 * @param {string} resourceName
 * @param {string} resourceId
 *
 * @return {object} organizations list
 */
export const getResources = organizationId => async params => {

  const { resourceId, resourceName, nowPage, rowsPage } = params;

  const queryParams = {
    limit: rowsPage ? rowsPage : 10,
  };

  if (nowPage * rowsPage !== 0) {
    queryParams.skip = nowPage * rowsPage;
  }

  if(resourceId){
    queryParams.resourceId = resourceId;
  }

  if(resourceName){
    queryParams.resourceName = resourceName;
  }

  const querystring = await stringify(queryParams);
  const response = await Api.get(`/admin/organizations/${organizationId}/resources?${querystring}`);
  return response;
};



/**
 *@刪除機構中的教材資源
 *@param {string} organizationId
 *@param {string} resourceId
 */
export const deleteResources = organizationId => async resourceIdList => {
  const response = await Api.delete(`/admin/organizations/${organizationId}/resources`, {
    resourceIdList
  });
  return response;
};


/**
 *@新增機構中的教材資源
 *@param {string} organizationId
 *@param {string} resourceId
 */
export const addResources = organizationId => async resourceIdList => {
  const response = await Api.put(`/admin/organizations/${organizationId}/resources`, {
    resourceIdList
  });
  return response;
};
