import React from 'react';

import { LoginForm,Header } from 'components';
import { UiLoginBox } from './LoginPage.style';


/**
 * 在這邊描述這個組件
 */

export const LoginPage = () => {
  return (
    <UiLoginBox>
      <Header/>
      <LoginForm />
    </UiLoginBox>
  );
};

