import React from 'react';
import PropTypes from 'prop-types';
import { UiSideBarCard, UiTitle, UiContent, UiTitleMain, UiContentMore } from './SideBarCard.style';
import { Icon, TransferListModal } from 'components';


/**
 * 側邊欄卡牌
 */

const TYPE_STATUS = {
  EDIT: 'edit',
};

export const SideBarCard = ({ type, icon, title, content, transferListData = [] }) => {

  return (
    <UiSideBarCard>
      <UiTitle>
        <UiTitleMain>
          {icon && <Icon name={icon} />}
          {title}
        </UiTitleMain>
        {type === TYPE_STATUS.EDIT && <TransferListModal
          buttonName='管理'
          data={transferListData}
          disabled={transferListData.length > 0 ? true : false}
        />}
      </UiTitle>
      <UiContent>
        {content}
        {
          // TODO 展開其餘內容
          type === TYPE_STATUS.EDIT && <UiContentMore>
            <Icon name='moreHoriz' />
          </UiContentMore>
        }
      </UiContent>
    </UiSideBarCard >
  );
};

SideBarCard.propTypes = {
  type: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string,
  transferListData: PropTypes.array,
};


