import styled from 'styled-components';

export const UiNormalLayout = styled.div`
	display: flex;
	min-height: 100vh;
	background-color: #fff;
	flex-direction: column;
`;

export const UiNormalContent = styled.div`
	flex: 1;
	display: flex;
	margin: auto;
	width: 95%;
	font-family: ${({theme})=>theme.typography.fontFamily};
`;
