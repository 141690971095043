export const getAdminAsideRoutes = () => {

  const routes = [
    {
      label: '機構管理', routes: [
        { label: '機構列表', path: '/admin/organizations' }
      ]
    },
    {
      label: '帳號管理', routes: [
        {
          label: '帳號列表', path: '/admin/account'
        },
        {
          label: '權限開通', path: '/admin/authority'
        }
      ]
    }
  ];

  return routes;
};
