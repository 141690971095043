import React, { useState, useEffect } from 'react';
import { DashBoardCard, ResourceModal, Loading } from 'components';
import { stringify } from 'query-string';
import { getMySessions } from 'services/api/home/sessions';
import { keepSessionReport } from 'services/api/organization/session';
import { useUser } from 'store/user';
import { useAlert } from 'utils/hooks/useAlert';
import { useSetState } from 'utils/hooks/useSetState';
import { useServerTime } from 'utils/hooks/useServerTime';
import { format } from 'date-fns';
import Book from 'assets/images/defaultBook.png';
import { UiSessionGroup } from './SessionGroup.style';


/**
 * 今日課程
 */


export const SessionGroup = () => {
  const { setAlert } = useAlert();
  const [sessionsData, setSessionsData] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [{ profile }] = useUser();
  const [{ timestamp: serverTimestamp }, { getToday }] = useServerTime();

  const [{
    isOpenResourceModal,
    sessionId,
  }, setState] = useSetState({
    isOpenResourceModal: false,
    sessionId: null,
  });

  /* click 課程教材彈窗 - 取消按鈕 */
  const clickResourceModalCancelHandler = () => {
    setState({ isOpenResourceModal: false });
  };

  /* click 課程教材彈窗 - 確認按鈕 */
  const clickResourceModalOkHandler = resourceValue => {
    const classRoomUrl = `${process.env.REACT_APP_ONEBOOK_DOMAIN}/${resourceValue}`;
    window.open(classRoomUrl, '_blank');  // 跳轉至 oneBook 教材
    setState({ isOpenResourceModal: false });
  };


  /* 打API，更新課堂報告 */
  const fetchKeepSessionReport = async (id, timeSpanId) => {
    const payload = {
      sessionId: id,
      timeSpanId,
    };
    const { isSuccess } = await keepSessionReport(payload);
    if (!isSuccess) {
      setAlert('取得課堂報表失敗!', 'error');
    }
  };

  /* click dashboard 的今日課程 */
  const goSessionReader = (item) => {
    const { resources, id, hostId, type, users, timeSpanId, organizationId } = item;

    const isCallReportApi = hostId === profile.id || users.some(id => id === profile.id);

    const enterInteractiveSession = () => {
      const usersLength = users?.length || 0;
      let classType = '';
      switch (type) {
        case 'interactive':
          classType = usersLength <= 1 ? 'single' : 'group';
          break;
        case 'collaborative':
          classType = usersLength <= 1 ? 'sync-single' : 'sync-multiple';
          break;
        case 'video':
          classType = 'group';
          break;
        default:
          break;
      }
      const userType = hostId === profile.id ? 'teacher' : 'student';
      const querystring = stringify({
        bookIds: resources,
        classType: classType,
        role: userType,
        userName: profile.nickname,
        userId: profile.id,
        productType: 'onelink',
        organizationId: organizationId,
      }, { arrayFormat: 'index' });
      window.open(`${process.env.REACT_APP_ONEBOARD_DOMAIN}/${id}/setup?${querystring}`, '_blank');
    };
    if (type === 'interactive' || type === 'video' || type === 'collaborative') {
      // 更新課堂報告
      isCallReportApi && fetchKeepSessionReport(id, timeSpanId);
      enterInteractiveSession();
    } else {
      setState({
        isOpenResourceModal: TextTrackCueList,
        sessionId: id,
      });
    }
  };


  const fetchSessions = async () => {
    setIsFetching(true);
    const todayStartAt = new Date(getToday('yyyy/MM/dd')).getTime();
    const todayEndAt = todayStartAt + (24 * 60 * 60 * 1000) - 1;
    const nowTime = serverTimestamp;
    const payload = {
      startAt: todayStartAt,
      endAt: todayEndAt,
      nowPage: 0,
      rowsPage: 100
    };
    const { data } = await getMySessions(payload);
    setIsFetching(false);
    const sessions = (data && data.sessions)?.map(item => {
      let isActive = true;
      if (nowTime > item.endAt) isActive = false;
      const startAtText = format(item.startAt, 'HH:mm');
      const endAtText = format(item.endAt, 'HH:mm');
      const sessionInterval = `${startAtText} - ${endAtText}`;
      return {
        ...item,
        isActive,
        sessionInterval
      };
    }) || [];
    setSessionsData(sessions);
  };

  useEffect(() => {
    if(!serverTimestamp) return;
    fetchSessions();
  }, [serverTimestamp]);

  return (
    <UiSessionGroup>
      { /* 課程教材、一般課程進入教室 彈窗 */
        isOpenResourceModal &&
        <ResourceModal
          sessionId={sessionId}
          isOpenResourceModal={isOpenResourceModal}
          onOk={clickResourceModalOkHandler}
          onCancel={clickResourceModalCancelHandler}
        />
      }
      {
        isFetching ? <Loading /> :
          sessionsData.length > 0 ?
            sessionsData.map((item, index) => {
              return (
                <DashBoardCard
                  key={index}
                  src={`https://cdn.oneclass.com.tw/uploadoutput/${item.resources[0]}/cover.jpg`}
                  title={item.name}
                  groupName={item.groupName}
                  hostName={item.hostName}
                  isActive={item.isActive}
                  sessionInterval={item.sessionInterval}
                  defaultImg={Book}
                  onClick={() => item.isActive && goSessionReader(item)}
                />
              );
            }) :
            <p tabIndex={0}>今日沒有安排任何課程喔！</p>
      }

    </UiSessionGroup>
  );
};
