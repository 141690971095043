import styled from 'styled-components';

export const UiHomeMemberCenterLayout = styled.div`
display: flex;
min-height: 100vh;
background-color: #fff;
flex-direction: column;
`;

export const UiMemberCenterTitle = styled.div`
margin: auto;
padding: 2rem 0;
font-size: 1.6rem;
font-weight: bolder;
${({ theme: { media } }) => media.desktop`
        width:${({ theme }) => theme.mediaWidth.desktop}
        `}
${({ theme: { media } }) => media.notebook`
        width:${({ theme }) => theme.mediaWidth.notebook}
        `}
${({ theme: { media } }) => media.pad`
        width:${({ theme }) => theme.mediaWidth.pad}
        `}
${({ theme: { media } }) => media.phone`
        width:${({ theme }) => theme.mediaWidth.phone}
        `}
`;
export const UiMemberCenterSiderBar = styled.div``;

export const UiHomeMemberCenterContent = styled.div`
	display: flex;
	margin: auto;
	padding: 0 0 2rem;
	width: 95%;
	font-family: ${({ theme }) => theme.typography.fontFamily};
	flex: 1;
	${({ theme: { media } }) => media.desktop`
      width:${({ theme }) => theme.mediaWidth.desktop}
      `}
	${({ theme: { media } }) => media.notebook`
      width:${({ theme }) => theme.mediaWidth.notebook}
      `}
	${({ theme: { media } }) => media.pad`
      width:${({ theme }) => theme.mediaWidth.pad}
      `}
	${({ theme: { media } }) => media.phone`
      width:${({ theme }) => theme.mediaWidth.phone}
      `}
`;
export const UiChildrenContainer = styled.div`
position: relative;
padding: 0 1rem;
width: calc(100% - 200px);
`;
