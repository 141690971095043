import styled from 'styled-components';

export const UiFooter = styled.div`
	margin-top: auto;
`;

export const UiRow = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 10px 0;
	font-size: 0.675rem;
	font-family: ${({ theme }) => theme.typography.fontFamily};
	color: #a1a4b1;
`;

export const UiContent = styled.div`
	padding: 0 1rem;
`;

export const UiLink = styled.div`
	margin: auto;
	font-size: 1.2rem;
	cursor: pointer;
`;
