import React, { useEffect, useRef } from 'react';
import MatImage from 'material-ui-image';
import lrz from 'lrz';
import { useParams, useHistory } from 'react-router-dom';

import { useClass } from 'store/class';
import { useSetState } from 'utils/hooks/useSetState';
import { useAllSchoolYear } from 'utils/hooks/useSchoolYear';
import { useAlert, ALERT_MESSAGE } from 'utils/hooks/useAlert';
import { useUuid } from 'utils/hooks/useUuid';
import { EDUCATION } from 'constants/index';
import { useFirebaseStorage } from 'utils/hooks/useFirebaseStorage';
import { makeStyles } from '@material-ui/core/styles';
import { TextField as MatTextField } from '@material-ui/core';
import { Select, SchoolCitySelector, Icon, Button, ClickablePopoverMenu, Modal } from 'components';
import organizationDefaultImage from 'assets/images/organization.png';

import {
  UiSchoolHomeSetting,
  UiHomeSettingTitle,
  UiHomeSettingContent,
  UiHomeSettingImage,
  UiHomeSettingButton,
  UiTextArea,
  UiButtonBox,
} from './SchoolHomeSetting.style';



/**
 * 設定頁面
 */
const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
	},
}));

const SAVE = 'SAVE';
const DELETEIMAGE = 'DELETEIMAGE';
const CANCEL = 'CANCEL';
const VISIBILITY = 'VISIBILITY';
const UNVISIBILITY = 'UNVISIBILITY';
const DELETECLASS = 'DELETECLASS';

const modalText = {
  [SAVE]: '儲存變更',
  [DELETEIMAGE]: '移除圖片',
  [CANCEL]: '離開',
  [VISIBILITY]: '隱藏班級',
  [UNVISIBILITY]: '開啟班級',
  [DELETECLASS]: '刪除班級'
};

const modalContent = {
  [SAVE]: '儲存目前設定。',
  [DELETEIMAGE]: '',
  [CANCEL]: '',
  [VISIBILITY]: '隱藏後，將無法再使用導師商談與邀請功能!',
  [UNVISIBILITY]: '開啟後，將可以使用導師商談與邀請功能!',
  [DELETECLASS]: '刪除班級後，所有資料將消失，此步驟無法回復，請謹慎使用!!'
};

export const SchoolHomeSetting = () => {
  const fileUploader = useRef(null);
  const history = useHistory();
  const [getUuid] = useUuid();
  const { upload } = useFirebaseStorage();
  const { organizationId, classId } = useParams();
  const [{ myClasses: { dataInfo } }, { updateClass, deleteGroup, setClassVisibility }] = useClass();
  // const [, { getSchoolById }] = useSchool();
  const classes = useStyles();
  const { setAlert } = useAlert();
  const [{
    name,
    year,
    educationName,
    schoolName,
    grades,
    thumbnailUrl,
    description,
    payload,
    isModalOpen,
    modalType,
    isButtonLoading,
  }, setState] = useSetState({
    name: '',
    year: '',
    educationName: '',
    schoolName: '',
    grades: [],
    thumbnailUrl: '',
    description: '',
    payload: {},
    isModalOpen: false,
    modalType: '',
    isButtonLoading: false
  });

  const { schoolYearOptions } = useAllSchoolYear();

  const popoverMenuList = [
    {
      label: '上傳圖片',
      func: () => fileUploader.current.click(),
      iconName: 'upload',
    },
    {
      label: '刪除',
      func: () => {
        setState({
          modalType: DELETEIMAGE,
          isModalOpen: true
        });
      },
      iconName: 'delete',
    },
  ];

  const chagePayloadValue = (key, value) => {
    setState({
      [key]: value,
      payload: {
        ...payload,
        [key]: value
      }
    });
  };

  const schoolChange = value => {
    setState({
      payload: {
        ...payload,
        ...value
      }
    });
  };

  const changeThumbnailUrl = async file => {
    await lrz(file).then(async res => {
      setState({
        thumbnailUrl: res.base64
      });
      for (let value of res.formData.values()) {
        setState({
          payload: {
            ...payload,
            thumbnailUrl: value
          }
        });
      }
    });
  };

  const onUploaded = async file => {
    if (!file.type) return;
    const uploadPath = `${classId}/classAlbum/${getUuid()}${file.name}`;
    const { status, url } = await upload(uploadPath, file);

    if (status) {
      return url;
    } else {
      return false;
    }
  };

  const getModalStateHandler = state => {
    setState({ isModalOpen: state });
  };

  //儲存變更
  const saveChange = async () => {
    setState({
      isButtonLoading: true
    });
    let showAlert = false;
    const myPayload = payload;
    if (myPayload.thumbnailUrl) {
      const url = await onUploaded(myPayload.thumbnailUrl);
      if (!url) return;
      myPayload.thumbnailUrl = url;
    }
    if (!organizationId) {
      if (myPayload.educationName !== educationName) {
        const res = EDUCATION[myPayload.educationName].some(item => item.value === myPayload.grades[0]);
        if (myPayload.schoolId === '' || !res) showAlert = true;
      }
    }

    if (showAlert) {
      setAlert(ALERT_MESSAGE.CREATE_FAIL, 'error');
    } else {
      await updateClass(myPayload);
      history.push(`/home/${organizationId ? organizationId + '/' : ''}class/${classId}/info`);
    }
    setState({
      isButtonLoading: false
    });
  };

  const buttons = [
    {
      text: '確認',
      func: async () => {
        switch (modalType) {
          case DELETEIMAGE:
            setState({
              thumbnailUrl: '',
              payload: {
                ...payload,
                thumbnailUrl: ''
              }
            });
            return;
          case SAVE:
            saveChange();
            return;
          case CANCEL:
            history.push(`/home/${organizationId ? organizationId + '/' : ''}class/${classId}/info`);
            return;
          case UNVISIBILITY:
          case VISIBILITY:
            setClassVisibility(!dataInfo.isHidden);
            return;
          case DELETECLASS: {
            const isSuccess = await deleteGroup();
            if (isSuccess) history.push('/home');
            return;
          }
          default:
            return;
        }
      }
    },
    {
      text: '取消',
      color: 'highlight',
      func: () => { }
    }
  ];
  // const getSchool = async id => {
  //   await getSchoolById(id);

  // };

  useEffect(() => {
    const { name, year, educationName, schoolName, grades, thumbnailUrl, description, isOwner } = dataInfo;
    if (isOwner === false) {
      history.push(`/home/${organizationId ? organizationId + '/' : ''}class/${classId}/info`);
    }
    setState({
      name,
      year,
      educationName,
      schoolName,
      grades,
      thumbnailUrl,
      description
    });

  }, [dataInfo]);

  return (
    <UiSchoolHomeSetting>
      <UiHomeSettingTitle>設定</UiHomeSettingTitle>
      {organizationId ? '' :
        <MatTextField
          className={classes.root}
          label='班級名稱'
          variant="outlined"
          placeholder="請輸入班級名稱"
          value={name}
          onChange={e => chagePayloadValue('name', e.target.value)}
        />
      }
      <UiHomeSettingContent>
        <div>
          <UiHomeSettingImage>
            <MatImage src={thumbnailUrl || organizationDefaultImage} alt="org" />
          </UiHomeSettingImage>
          <ClickablePopoverMenu menuList={popoverMenuList} width={'100%'}>
            <UiHomeSettingButton>
              <Icon name="image" haveBg={false} />
              班級圖片變更
              <input type="file" onChange={e => changeThumbnailUrl(e.target.files[0])} ref={fileUploader} />
            </UiHomeSettingButton>
          </ClickablePopoverMenu>
        </div>
        {organizationId ? '' :
          <div>
            <SchoolCitySelector
              width="100%"
              variant="outlined"
              isFullWidth={false}
              educationValue={educationName}
              schoolValue={schoolName}
              gradesDefaultValue={grades}
              setting={true}
              onChange={value => schoolChange(value)}
            >
              <Select
                label='學年度'
                variant='filled'
                width={'100%'}
                options={schoolYearOptions}
                value={year}
                submitHandler={value => chagePayloadValue('year', value)}
              />
            </SchoolCitySelector>
          </div>}
      </UiHomeSettingContent>
      <UiTextArea
        placeholder="請輸入班級簡介"
        value={description}
        onChange={e => chagePayloadValue('description', e.target.value)}
      />
      <UiButtonBox>
        <Button
          icon='done'
          onClick={() => setState({ modalType: SAVE, isModalOpen: true })}
          loading={isButtonLoading}
        >
          儲存變更
        </Button>
        <Button
          icon='close'
          onClick={() => setState({ modalType: CANCEL, isModalOpen: true })}
          loading={isButtonLoading}
        >
          取消變更
        </Button>
        <Button
          icon={dataInfo.isHidden ? 'visibility' : 'visibilityOff'}
          buttonColor='visibility'
          loading={isButtonLoading}
          onClick={() => setState({ modalType: dataInfo.isHidden ? UNVISIBILITY : VISIBILITY, isModalOpen: true })}
        >
          {dataInfo.isHidden ? '開啟班級' : '隱藏班級'}
        </Button>
        <Button
          icon='delete'
          buttonColor='delete'
          loading={isButtonLoading}
          onClick={() => setState({ modalType: DELETECLASS, isModalOpen: true })}
        >
          刪除班級
        </Button>
      </UiButtonBox>
      <Modal
        isOpen={isModalOpen}
        text={`確定要<span>${modalText[modalType]}</span>嗎?`}
        content={modalContent[modalType]}
        buttons={buttons}
        getModalState={getModalStateHandler}
      />
    </UiSchoolHomeSetting>
  );
};



