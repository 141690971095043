import React from 'react';
import { UiAccountPage } from './AccountPage.style';
import { AccountTable } from 'components';


/**
 * 帳號列表頁面
 */

export const AccountPage = () => {
  return (
    <UiAccountPage>
      <AccountTable />
    </UiAccountPage>
  );
};


