import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Header, SessionGroup, BlindBrick } from 'components';
import { useServerTime } from 'utils/hooks/useServerTime';
import { UiSingleLayout, UiSingleContent } from './SingleLayout.style';


/**
 * 沒有側邊欄的版型
 */

const GREETINGS = {
  MORNING: '早安',
  AFTERNOON: '午安',
  NIGHT: '晚安'
};

export const SingleLayout = ({ children, showLeftSidebar = true }) => {
  const [{ timestamp: serverTimestamp }] = useServerTime();

  const getTimeState = useCallback(() => {
    if(!serverTimestamp) return;
    const nowHour = new Date(serverTimestamp).getHours();
    if (nowHour >= 0 && nowHour <= 12) {
      return `${GREETINGS.MORNING} !` ;
    } else if (nowHour > 12 && nowHour <= 18) {
      return `${GREETINGS.AFTERNOON} !`;
    } else if (nowHour > 18 && nowHour <= 24) {
      return `${GREETINGS.NIGHT} !`;
    }
  }, [serverTimestamp]);

  return (
    <UiSingleLayout>
      <UiSingleContent showLeftSidebar={showLeftSidebar}>
        <div className="header">
          <Header isNeedLogo={true} />
        </div>
        {
          showLeftSidebar &&
            <div className="leftSidebar">
              <BlindBrick className='accesskey' id='left_container' accessKey='l' href='#left_container' title='左方選單區塊' />
             <h2 className="greetings" tabIndex={0}>{getTimeState()}</h2>
              <div className="main">
                <div className="subTitle">
                  <h3 tabIndex={0}>今日課程</h3>
                  <Link className="readMore" to="/sessions">查看課程</Link>
                </div>
                <SessionGroup />
                {/* <a className="wtfIssue" 
                        href="https://oneask.oneclass.com.tw/index.html" target="_blank" rel="noreferrer">
                  <img src={oneask} alt="oneAsk" />
                </a> */}
              </div>
            </div>
        }
        <div className="main">
          {children}
        </div>
      </UiSingleContent>
    </UiSingleLayout>

  );
};

SingleLayout.propTypes = {
  children: PropTypes.node,
};


