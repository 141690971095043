import { useState, useEffect, useMemo, } from 'react';

const useUpdateEffect = (effect, deps) => {
  useEffect(()=>{
    return effect();
  },deps);
};

export const usePagination = (func, options) => {
  const [isFetching, setIsFetching] = useState(null);
  const [fetchs, setFetch] = useState({
    data:[],
    total:0
  });
  const {
    resKey,
    resKeyId,
    deps,
    page,
    limit,
    formatResult
  } = options;

  const fetchApiData = async ({isNeedReset, params}) => {
    setIsFetching(true);
    const { data } = fetchs;
    const { isSuccess,data:fetchData } = params ? await func(params) : await func();
    if(isSuccess){
      const currentData = resKey ? fetchData[resKey] : fetchData;
      if(isNeedReset) {
        setFetch({
          data: currentData,
          total: fetchData.total
        });
      }else{
        const nextData = params.afterId ? data.concat(currentData) : currentData.concat(data);
        setFetch({
          data:nextData,
          total:fetchData.total
        });
      }
    }else{
      setFetch({
        data:[],
        total:0
      });
    }
    setIsFetching(false);
  };

  const deleteData = ({ id, keyName }) => {
    const newData = fetchs.data.filter(item => item[keyName] !== id);
    const nextTotal = fetchs.total - 1;
    setFetch({
      data: newData,
      total: nextTotal,
    });
  };

  const editData = ({ id,keyName,newData }) => {
    const nextData = fetchs.data.map(item=> {
      if(item[keyName] === id) {
        return newData;
      }
      return item;
    });
    
    setFetch({
      data: nextData,
      total: fetchs.total
    });
  };

  useUpdateEffect(()=>{
    const { data,total } = fetchs;
    if(data.length < total && page * limit === data.length) {
      const lastId = data.at(-1)[resKeyId];
      const params = {
        afterId:lastId
      };
      fetchApiData({ isNeedReset:false, params });
    }
    if(page === 0){
      if(data.length > 0){
        const firstId = data[0][resKeyId];
        const params = {
          beforeId:firstId
        };
        fetchApiData({ isNeedReset:false, params });
      }else{
        fetchApiData({ isNeedReset:true });
      }
    }
  },[...deps, page, limit]);

  const data = useMemo(() => {
    return fetchs.data.length !== 0 ? formatResult(fetchs.data.slice(page * limit, page * limit + limit)) : [];
  }, [fetchs.data, page, limit]);

  return {
    isFetching,
    data,
    total: fetchs.total,
    run: fetchApiData,
    remove: deleteData,
    edit: editData
  };
};
