import React from 'react';
import { SingleLayout, MyOrganizations } from 'components';


/**
 * Home頁
 */

export const MyOrganizationsPage = () => {
  return (
    <SingleLayout>
      <MyOrganizations />
    </SingleLayout>
  );
};

MyOrganizationsPage.propTypes = {};
