import React, { useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSetState } from 'utils/hooks/useSetState';
import { getSessionReport } from 'services/api/organization/session';
import {
  UiSessionReport,
  UiActionWrapper,
  UiActionContainer,
  UiPreviousPageButton,
  UiSessionEmpty,
  UiEmptyImg,
} from './SessionReport.style';
import {
  Icon,
  Loading
} from 'components';
import SessionSummary from './SessionSummary';
import SessionRecord from './SessionRecord';
import SessionPerformance from './SessionPerformance';
import VideoRecord from './VideoRecord';
// import Screenshots from './Screenshots';
import sessionReportEmpty from 'assets/images/sessionReportEmpty.png';
import { useUser } from 'store/user';
import { useClass } from 'store/class';


/**
 * 課堂報告
 */

export const SessionReport = () => {
  // 網址參數
  const { organizationId, sessionId, timeSpanId } = useParams();
  const history = useHistory();
  const [{ myClasses }] = useClass();
  const [{ profile }] = useUser();
  const { dataInfo } = myClasses;
  const { userType } = dataInfo;
  // 班級身份判斷
  const isUser = dataInfo?.users?.some(user => user === profile.id);

  // state
  const [{
    isLoading,
    summaryData, // 摘要
    attendanceData, // 上課記錄
    performanceData, // 課堂表現
    // screenshotData, // 課堂截圖
    sessionActualStartAt,
  }, setState] = useSetState({
    isLoading: true,
    summaryData: null,
    attendanceData: null,
    performanceData: null,
    // screenshotData: null,
  });

  // fetch
  const fetchSummaryOrganizationSessionReport = () => {
    const params = {
      organizationId,
      sessionId,
      timeSpanId,
      section: 'summary',
    };
    return getSessionReport(params);
  };

  const fetchAttendanceOrganizationSessionReport = () => {
    const params = {
      organizationId,
      sessionId,
      timeSpanId,
      section: 'attendance',
    };
    return getSessionReport(params);
  };

  const fetchPerformanceOrganizationSessionReport = () => {
    const params = {
      organizationId,
      sessionId,
      timeSpanId,
      section: 'performance',
    };
    return getSessionReport(params);
  };

  // 由於oneboard 無法顯示 onebook等教材畫面，暫時拿掉
  // const fetchScreenshotOrganizationSessionReport = () => {
  //   const params = {
  //     organizationId,
  //     sessionId,
  //     timeSpanId,
  //     section: 'screenshot',
  //   };
  //   return getSessionReport(params);
  // };

  const fetchSessionData = async () => {
    const res = await Promise.all([
      fetchSummaryOrganizationSessionReport(),
      fetchAttendanceOrganizationSessionReport(),
      fetchPerformanceOrganizationSessionReport(),
      // fetchScreenshotOrganizationSessionReport(),
    ]);

    if(res || res.length > 0){
      setState({
        summaryData: res[0].data,
        attendanceData: res[1].data,
        performanceData: res[2].data,
        // screenshotData: res[3].data,
      });
    }

    setState({
      sessionActualStartAt: res[0].data?.sessionActualStartAt,
      isLoading: false,
    });
  };

  const isUserJoin = useMemo(() => {
    if (summaryData) {
      if (userType === 'teacher') return true;
      if (summaryData.userRecords[0].status !== 'missed') return true;
      if (Date.now() >= summaryData?.sessionActualEndAt && summaryData.userRecords[0]?.viewedRecordVideoTime?.length === 0) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }, [summaryData, userType]);

  useEffect(() => {
    fetchSessionData();
  }, []);

  return (
    <UiSessionReport>
      <UiActionWrapper>    
        <UiActionContainer>
          <UiPreviousPageButton onClick={()=>{history.goBack();}}>
            <Icon title='返回上一頁' name='arrowBackIos' size='1.25rem' haveBg={false} icolor='#8B90A0' />
            返回上一頁
          </UiPreviousPageButton>
        </UiActionContainer>
      </UiActionWrapper>
      {
        isLoading && <Loading />
      }
      {
        !isLoading && isUserJoin &&
          <>
            {
              summaryData && <SessionSummary data={summaryData} />
            }
            {
              attendanceData && <SessionRecord data={attendanceData}/>
            }
            {
              performanceData && <SessionPerformance data={performanceData} />
            }
            {
              summaryData && <VideoRecord data={summaryData} />
            }
            {/* <Screenshots data={screenshotData} /> */}
          </>
      }
      {
        !isLoading && !isUserJoin && (
          !summaryData ||
          !attendanceData || 
          !performanceData||
          !sessionActualStartAt
        ) && <UiSessionEmpty>
            <UiEmptyImg path={sessionReportEmpty} />
            {
              (isUser && !summaryData)
                ? '您未出席該課程，故無報表資料可供顯示'
                : '查無此課程紀錄'
            }
          </UiSessionEmpty>
      }
    </UiSessionReport>
  );
};
