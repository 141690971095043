import React from 'react';

import { HomeGroupLayout, HomePostForm } from 'components';


/**
 * 新增編輯貼文表單頁面
 */

export const HomePostFormPage = () => {
  return (
    <HomeGroupLayout>
      <HomePostForm />
    </HomeGroupLayout>
  );
};


